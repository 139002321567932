import { Button } from 'react-bootstrap';
import style from './QuickBooks.module.scss'
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
interface Props {
    onclick: any;
    data: any;
    onCansal: any
}
const QuickBooks = (props: Props) => {
    console.log(props.data, 'proppppppppp');
    const [data_add, setData_add] = React.useState([])
    const navigate = useNavigate();
    const [render_two, setRender_two] = React.useState(true);
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    console.log(data_add,'data_adddata_add');
    useEffect(() => {
        if (render_two && dataList.CREATE_CONNECTION_SUCCESS !== undefined) {
            setData_add(dataList.CREATE_CONNECTION_SUCCESS)
            setRender_two(false)
        }
    }, [render_two, dataList]);
    useEffect(() => {
        setTimeout(() => {
            // navigate('/integrations');
            props.onclick(data_add)
        }, 5000)
    }, []);
    const Cancel = () => {
        props.onCansal()
    }
   
    return (
        <>
            <div className={style.main_dev_contean}>
                <p className={style.heding_tage_listtt}>
                    Connecting to {!!props.data&&!!props.data.name ? props.data.name : ""}
                </p>
                <div className={style.contean_loader}>
                    <div className={style.img_and_p_tage}>
                        <img src={!!props.data&&!!props.data.image ? props.data.image : ""} />
                        <p>
                            {!!props.data&&!!props.data.name ? props.data.name : ""}
                        </p>
                    </div>

                    <span className="spinner-border spinner-border-md mr-1">
                    </span>

                </div>
                <p className={style.des_text_data}>We’re establishing connection with QuickBooks. It could take some time.</p>
                <div className={style.div_button_footer}>
                    <Button onClick={() => { Cancel() }}>Cancel</Button>
                </div>
            </div>
        </>
    )
}
export default QuickBooks;