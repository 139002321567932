import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import './AuthoriseTransaction.scss';
import { getUser } from '../../../../../../_helpers';
import { payablesActions } from '../../../../../../_actions/payables.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllPayablesListData } from '../../../../../../_reducers/payables.reducer';

interface Props {
  onSave: any;
  onCancel: any;
  id: any;
  loading: any;
  time: any;
  handleSubmit_resend: any;
  enableButton: any;
}

export const AuthoriseTransaction = (props: Props) => {
  const [option, setOption] = useState('');
  const dispatch = useDispatch();
  const [otp, setOtp] = useState<any>('');
  const [checkType, setCheckType] = useState<any>();
  const [otpRender, setOtpRender] = useState(true);

  const onSave = () => {
    props.onSave();
  };
  const onCancel = () => {
    props.onCancel();
  };
  const handleSubmit_resend = () => {
    props.handleSubmit_resend(1);
  };
  const user = getUser()?.data ?? null;

  const handleSubmit = (e: any) => {
    const { name, value }: any = e.target;
    setOtp({ ...otp, [name]: value });
  };

  const onSubmitted = () => {
    let request = {
      ...otp,
      data: props.id,
    };

    dispatch(payablesActions.verifyOtpRequest(request) as any);
    props.onSave();
  };
  const otpdata = useSelector((state) => selectAllPayablesListData(state));
  useEffect(() => {
    if (otpRender && !!otpdata?.OTPVIREFAT_SUCCESS) {
      setOtpRender(false);
      setCheckType(otpdata.OTPVIREFAT_SUCCESS);
    }
  }, [otpRender, otpdata]);

  function hidePhoneNo(phoneNo: any) {
    return phoneNo.slice(0, 6) + '*****' + phoneNo.slice(12);
  }
  function hideEmail(email: any) {
    const [username, domain] = email.split('@');
    const firstChar = username.charAt(0);
    const lastChar = username.charAt(username.length - 1);
    const hiddenUsername = `${firstChar}${'*'.repeat(
      username.length - 2
    )}${lastChar}`;
    return `${hiddenUsername}@${domain}`;
  }

  return (
    <div className="auth-trans-container">
      <div className="auth-trans-container__heading">Authorise Transaction</div>
      <div className="auth-trans-container__desc">
        To authorise the transaction, please enter the security code that was
        sent to{' '}
        {!!checkType && !!checkType.title
          ? checkType.title == 'mobile'
            ? !!user && hidePhoneNo(user.mobile)
            : !!user && hideEmail(user.email)
          : !!user && hidePhoneNo(user.mobile)}
      </div>

      <div>
        <div className="auth-trans-container__label">Security code</div>
        <InputGroup className="input-wrapper input">
          <Form.Control
            className="auth-trans-form-input"
            placeholder="123456"
            value={otp?.otp}
            onChange={handleSubmit}
            name="otp"
            aria-describedby="basic-addon3"
          />
        </InputGroup>
      </div>
      <div
        id="div_authoridse_cls"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
        className="auth-trans-container__btn-row"
      >
        <div id="resend_otp">
          <Button
            className="auth-trans-container__btn-row__white"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
        </div>
        <div
          id="sumintbutton_autorise"
          className="auth-trans-container__btn-row"
          style={{ marginTop: '0px' }}
        >
          {props.enableButton == false ? (
            <Button
            id="resend_otp"
              style={{ marginRight: '10px' }}
              className="auth-trans-container__btn-row__green"
            >
              Resend OTP in {props.time > 0 ? props.time - 1 : props.time} Sec
            </Button>
          ) : (
            <Button
            id="resend_otp"
              style={{ marginRight: '10px' }}
              className="auth-trans-container__btn-row__green"
              onClick={() => {
                handleSubmit_resend();
              }}
            >
              Resend OTP
            </Button>
          )}
          <Button
            className="auth-trans-container__btn-row__green"
            id="resend_otp"
            onClick={() => {
              // onSave();
              onSubmitted();
            }}
          >
            {props.loading == true && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Authorise
          </Button>
        </div>
      </div>
    </div>
  );
};