import React from "react";
import {authHeader, handleResponse} from '../_helpers'

export const payablesService = {
    create,
    getAllPayables,
    setSelectedItem,
    createPayableRequest,
    verifyOtpRequest,
    initiatePayment,
    getTransaction, 
    BulkPayables,
    getOtpverifay,
    bulkbills,
    getPayable_id_service,
    recurring_create_bill,
    recurring_submit_bill,
    initiate_bulk_Payment,
    Transaction_bulk_Payment,
    transactionExist
}

function verifyOtpRequest(otp){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(otp)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/verify-otp`,requestOptions).then(handleResponse)
}
function transactionExist(otp){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(otp)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/transaction-exist`,requestOptions).then(handleResponse)
}
function createPayableRequest(item){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(item)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/counterparts/update-counterpart`,requestOptions).then(handleResponse)
}

function create(payables){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(payables)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/payables/create`,requestOptions).then(handleResponse)
}
function recurring_create_bill(payables){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(payables)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/payables/create-recurring-bill-data`,requestOptions).then(handleResponse)
}
function recurring_submit_bill(payables){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(payables)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/payables/create-recurring-bill-and-submit`,requestOptions).then(handleResponse)
}
function initiatePayment(payables){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(payables)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/initiate-payment`,requestOptions).then(handleResponse)
}
function initiate_bulk_Payment(payables){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(payables)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/create-bulk-payments`,requestOptions).then(handleResponse)
}
function Transaction_bulk_Payment(payables){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(payables)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/get-bulk-payments-status`,requestOptions).then(handleResponse)
}

function getTransaction(req){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/wallet/get-transaction-status/${req.id}`,requestOptions).then(handleResponse)
}
function getPayable_id_service(req){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/payables/get-payable-by-id/${req}`,requestOptions).then(handleResponse)
}
function setSelectedItem(payables){
    return payables
}
function getOtpverifay(){
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/get-otp-verification-type`,requestOptions).then(handleResponse)
}
function getAllPayables(data){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/payables/get-payables?counterpart_name=${data.search}&page=${data.token}&limit=10`,requestOptions).then(handleResponse)
}

function BulkPayables(payables){
    const requestOptions = {
        method:'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(payables)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/payables/create-bulk`,requestOptions).then(handleResponse)
}

function bulkbills(payables){
    const requestOptions = {
        method:'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(payables)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/counterparts/create-bulk`,requestOptions).then(handleResponse)
}