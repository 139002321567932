import React from "react";
import {authHeader, handleResponse } from '../_helpers'

export const userService = {
    login,
    logout,
    register,
    updatePassword,
    forgotPassword,
    verifyToken,
    changePassword,
    getOtp,
    registerByInvite,
    sendOtp,
    loginOtpVerify,
    ResendOtp,
    saveCurrencies
}

function login(user){
    const requestOptions = {
        method:'POST',
        headers:{'Content-Type': 'application/json'},
        body: JSON.stringify(user)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/login`,requestOptions)
        .then(handleResponse)
}
function sendOtp(user){
    const requestOptions = {
        method:'POST',
        headers:{'Content-Type': 'application/json'},
        body: JSON.stringify(user)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/v2-login-new`,requestOptions)
        .then(handleResponse)
}
function ResendOtp(user){
    const requestOptions = {
        method:'POST',
        headers:{'Content-Type': 'application/json'},
        body: JSON.stringify(user)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/resend-login-otp`,requestOptions)
        .then(handleResponse)
}
function loginOtpVerify(user){
    const requestOptions = {
        method:'POST',
        headers:{'Content-Type': 'application/json'},
        body: JSON.stringify(user)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/v2/login-otp-verify`,requestOptions)
        .then(handleResponse)
}
function logout(){

    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
        // body: JSON.stringify(user.req)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/logout`,requestOptions).then(handleResponse)

    
}

function register(user,api){
    const requestOptions = {
        method: 'POST', 
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(user.req)
    }
    return fetch(`${process.env.REACT_APP_API_URL}${api}`,requestOptions).then(handleResponse)
}

function updatePassword(user){
    const requestOptions = {
        method: 'PATCH',
        headers: {'Content-Type':'application/json', ...authHeader()},
        body: JSON.stringify(user.req)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/change-password`,requestOptions).then(handleResponse)
}

function forgotPassword(user){
    const requestOptions = {
        method: 'POST', 
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(user)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/forget-password`,requestOptions).then(handleResponse)
}
function saveCurrencies(user){
    const requestOptions = {
        method: 'POST', 
        headers: {'Content-Type':'application/json', ...authHeader()},
        body: JSON.stringify(user)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/v2/save-currencies`,requestOptions).then(handleResponse)
}

function verifyToken(user){
    const requestOptions = {
        method: 'POST', 
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(user)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/verify-token`,requestOptions).then(handleResponse)
}

function changePassword(user){
    const requestOptions = {
        method: 'Post',
        headers: {'Content-Type':'application/json', ...authHeader()},
        body: JSON.stringify(user.req)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/verify-account`,requestOptions).then(handleResponse)
}

function getOtp(){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/get-otp`,requestOptions).then(handleResponse)
}


function registerByInvite(user){
    const requestOptions = {
        method: 'POST', 
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(user.req)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/register-by-invitation`,requestOptions).then(handleResponse)
}