import { Box, Grid, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import '../../auth/Auth.css';
import React from 'react';

type DefaultLayoutProps = {
    children?: React.ReactNode;
};
const Layout = ({ children }: DefaultLayoutProps) => {
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid container className="lohin_container_class">
                    <Grid item md={7} className='grid_img_container'>
                    {/* <Box className="LogoContean">
                            <Box sx={{textAlign:"center"}}>
                            <img src='logo_white 2.svg' height='57' />
                            <Typography className="textHeading">
                            Centralised Finance Platform 
for Businesses
                            </Typography>
                            <Typography className="typo_logoDes">
                            Easily manage all finance operations, payments and business banking
                                </Typography>
                            </Box> 
                        </Box> */}
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    )
}
export default Layout;