import { Button } from "react-bootstrap";
import "../../../Settings.scss";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const NewVerfied = (props: any) => {
    const onSave = () => {
        props.onSave();
    }
    return (
        <>
            <div className="contenarPage">
                <div className="haderContean">
                    <div className="CloseDiv">
                        <IconButton onClick={() => {
                            if (props?.page == "fastpage") {
                                onSave()
                            } else {
                                window.location.reload()
                            }
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className="heding_Contean">
                        <p>Documents Submitted</p>

                    </div>
                </div>
                <div className="colamContean">
                    <p>Thank you, we have received the submitted documents.<br></br>
                        Our team is reviewing your documents and will notify you on the next steps, usually within one business day.</p><br>
                    </br>
                    <p>In case of any queries, reach out to us for <span>support@niobi.co</span></p>
                </div>

            </div>
        </>
    )
}
export default NewVerfied;