import Data_Sync from "./components/DataSync/Data_Sync";

interface Props {
    onclick: any;
    data:any;
}
const DataSync_page = (props: Props) => {

    return (
        <>
            <div className="balance__header" style={{ marginBottom: '35px' }}>
                <div className="balance__header__name">{!!props.data&&!!props.data.name ? props.data.name : ""} Data Sync</div>

            </div>
            <Data_Sync onclick={props.onclick} data={props.data} />
        </>
    )
}
export default DataSync_page;