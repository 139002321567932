import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import "../../../Settings.scss";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


interface Props {
    onSave: any;
}

export const Model_Reject = (props: Props) => {

    const onSave = () => {

        props.onSave();
    }

    return (

        <div className="contenarPage">
            <div className="haderContean">
                <div className="CloseDiv">
                    <IconButton onClick={() => {
                        onSave()
                    }}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className="heding_Contean">
                    <p>Account Setup Failed</p>

                </div>
            </div>
            <div className="colamContean">
                <br />
                <p>In case of any queries, reach out to us for <span>support@niobi.co</span></p>
            </div>
        </div>
        // <div className='document-verified'>
        //     <div className='document-verified__heading' style={{color:"#DF3A3A"}}>Account Setup Failed</div>

        //     <div className='document-verified__desc'>

        //         <br />
        //         In case of any queries, reach out to us for <span>support@niobi.co</span>
        //     </div>
        //     <Button className='document-verified__btn' onClick={() => onSave()}>Go back </Button>
        // </div>
    )
}
