import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import moment from "moment";
import './CounterpartsTable.scss'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getUser, isUserAuthenticated, authHeader } from "../../../../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { counterpartsActions } from '../../../../../../_actions/counterparts.actions'
import { selectAllCounterpartsListData } from "../../../../../../_reducers/counterparts.reducer";
import { Button, Form, InputGroup } from "react-bootstrap";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Menu, MenuItem, } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { CssBaseline, CircularProgress } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getKESFormateCurrency } from '../../../../../../_helpers/common';
import SkeletonCom from '../../../Skeleton/Skeleton';

interface Data {
    calories: string;
    carbs: string;
    fat: string;
    name: string;
    protein: string;
    protein_two: string,
    protein_three: string
}

function createData(
    name: string,
    calories: string,
    fat: string,
    carbs: string,
    protein: string,
    protein_two: string,
    protein_three: string
): Data {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        protein_two,
        protein_three
    };
}

// const rows = [
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Donut', 452, 25.0, 51, 4.9),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
//     createData('Honeycomb', 408, 3.2, 87, 6.5),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Jelly Bean', 375, 0.0, 94, 0.0),
//     createData('KitKat', 518, 26.0, 65, 7.0),
//     createData('Lollipop', 392, 0.2, 98, 0.0),
//     createData('Marshmallow', 318, 0, 81, 2.0),
//     createData('Nougat', 360, 19.0, 9, 37.0),
//     createData('Oreo', 437, 18.0, 63, 4.0),
// ];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    arrow: string;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Payee Name',
        arrow: 'counterpart_name'
    },
    {
        id: 'calories',
        numeric: true,
        disablePadding: false,
        label: 'Updated At',
        arrow: 'updated_at'
    },
    {
        id: 'fat',
        numeric: true,
        disablePadding: false,
        label: 'Saved Payment Details',
        arrow: ''
    },
    {
        id: 'carbs',
        numeric: true,
        disablePadding: false,
        label: 'Last Payment',
        arrow: ''
    },
    {
        id: 'protein',
        numeric: true,
        disablePadding: false,
        label: 'Total Payments to Date',
        arrow: ''
    },
    {
        id: 'protein_two',
        numeric: true,
        disablePadding: false,
        label: 'Outstanding Bills',
        arrow: ''
    },
    {
        id: 'protein_three',
        numeric: true,
        disablePadding: false,
        label: '',
        arrow: ''
    },

];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

// function EnhancedTableHead(props: EnhancedTableProps) {
//     const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
//         props;
//     const createSortHandler =
//         (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
//             onRequestSort('createSortHandler');
//         };

//     return (
//         <TableHead>
//             <TableRow>
//                 <TableCell padding="checkbox">
//                     <Checkbox
//                         color="primary"
//                         icon={<img src="/Checkbox.svg" />}
//                         checkedIcon={<img src="/Checkbox_two.svg" />}
//                         indeterminate={numSelected > 0 && numSelected < rowCount}
//                         checked={rowCount > 0 && numSelected === rowCount}
//                         onChange={onSelectAllClick}
//                         inputProps={{
//                             'aria-label': 'select all desserts',
//                         }}
//                     />
//                 </TableCell>
//                 {headCells.map((headCell) => (
//                     <TableCell
//                         key={headCell.id}
//                         align={headCell.numeric ? 'center' : 'left'}
//                         padding={headCell.disablePadding ? 'none' : 'normal'}
//                         sortDirection={orderBy === headCell.id ? order : false}
//                     >
//                         {headCell.arrow == true ?
//                             <TableSortLabel
//                                 active={orderBy === headCell.id}
//                                 direction={orderBy === headCell.id ? order : 'asc'}
//                                 onClick={createSortHandler('ASC')}
//                             >
//                                 {headCell.label}
//                                 {orderBy === headCell.id ? (
//                                     <Box component="span" sx={visuallyHidden}>
//                                         {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
//                                     </Box>
//                                 ) : null}
//                             </TableSortLabel> :
//                             <>
//                                 {headCell.label}
//                                 {orderBy === headCell.id ? (
//                                     <Box component="span" sx={visuallyHidden}>
//                                         {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
//                                     </Box>
//                                 ) : null}</>}
//                     </TableCell>
//                 ))}
//             </TableRow>
//         </TableHead>
//     );
// }

interface EnhancedTableToolbarProps {
    numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Nutrition
                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

export default function EnhancedTable() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('calories');
    const [selected, setSelected] = React.useState<any>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [render, setRender] = React.useState(true);
    const [search, setSearch] = React.useState('');
    const [ideditdata, setIdeditdata] = React.useState('')
    const [ascending, setAscending] = React.useState('DESC')
    const [ascending_name, setAscending_name] = React.useState('DESC')
    const [ascending_date, setAscending_date] = React.useState('DESC')
    const [tagsList, setTagsList] = React.useState<any>([
        {
            "name": "Payees",
            "id_name": "is_vender"
        },


    ]);
    const [requestRender, setrequestRender] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [tagsList_two, setTagsList_two] = React.useState<any>([
        {
            "name": "Individuals",
            "id_name": "individual"
        },
        {
            "name": "Companies",
            "id_name": "Companie"
        },


    ]);
    const [categ, setCateg] = React.useState('all');
    const [categ_two, setCateg_two] = React.useState('');
    const [rows_data, setRows_data] = React.useState([])
    const [delete_data, setDelete_data] = React.useState('')
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [totalCount, setTotalCount] = React.useState(10);
    const [pageneshn, setPagelist] = React.useState(1)
    const [remove, setRemovedata] = React.useState(10)
    const [type, setType] = React.useState("")
    const open = Boolean(anchorEl);

    React.useEffect(() => {
        if (isUserAuthenticated() && render) {
            getBalanceList(search, categ, categ_two, pageneshn, 10, ascending, type);
            setRender(false)
        }
    }, [render, isUserAuthenticated]);
    React.useEffect(() => {
        getBalanceList(search, categ, categ_two, pageneshn, 10, ascending, type);
    }, [search, categ_two, pageneshn, categ, ascending, type]);
    const getBalanceList = async (search: any, categ: any, categ_two: any, pageneshn: any, limet: any, ascending: any, type: any) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', ...authHeader() },
        }


        var pagelimit = 10;
        // const searchString = `?search=${datalist.search}&start-date=${stateDateApi}&end-date=${endDateApi}&page=${datalist.pagedata}&limit=${pagelimit}&category=${category}`;
        // console.log(searchString, 'searchString');
        setLoading(true)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/counterparts/get-counterpart-list?search=${search}&vender=${categ}&type=${categ_two}&page=${pageneshn}&limit=${limet}&order=${ascending}&order_by=${type}`, requestOptions)
            .then((response) => {
                if (response.data.success == true) {
                    //   setDatatebal(response.data.data.data)
                    var array: any = []
                    for (let index = 0; index < response.data.data.data.length; index++) {
                        const element = response.data.data.data[index];
                        console.log(element, "elementelement");
                        if (element.status == "active") {
                            array.push(element)
                        }

                    }
                    setRows_data(array)
                    setTotalCount(response.data.data.total)
                    //   setTotalCount(response.data.data.total)
                    setLoading(false)
                } else {
                    console.log('errr');
                    setLoading(false)
                }
                console.log(response, 'response');
                setLoading(false)
            })


    }

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows_data.map((n: any) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows_data.length) : 0;
    const handleClick_datat = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleShow = () => {
        dispatch(counterpartsActions.Delete_Counterparts(ideditdata) as any);
        setLoading(true)
        setTimeout(() => {
            getBalanceList(search, categ, categ_two, pageneshn, 10, ascending, type);
            setSelected([])
        }, 4000)
    }
    const rejectSubmit = () => {
        const body = {
            "counterparts": selected
        }
        dispatch(counterpartsActions.DeletePayee_active(body) as any);
        setLoading(true)
        setTimeout(() => {
            getBalanceList(search, categ, categ_two, pageneshn, 10, ascending, type);
            setSelected([])
        }, 4000)
    }
    return (
        <Box sx={{ width: '100%' }}>
            {/* {loading && <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <CircularProgress color="success" />
            </Box>} */}
            <div className='main_serech_box_filtar_data'>
            <SkeletonCom loader={loading}
                            element={
                <div>
                    <InputGroup className="balance__filter-wrapper input">
                        <InputGroup.Text id="basic-addon1" className="icon">
                            <SearchIcon />
                        </InputGroup.Text>
                        <Form.Control
                            className="balance-search-input"
                            placeholder="Search by name"
                            aria-label="Search"
                            aria-describedby="basic-addon1"

                            disabled={loading}
                            onChange={
                                (event) => {
                                    setTimeout(() => {
                                        if (event.target.value == '') {
                                            setSearch(event.target.value);
                                            setrequestRender(true)
                                        } else {
                                            setSearch(event.target.value);
                                            setrequestRender(true)
                                        }
                                    }, 1500)
                                   
                                }}
                        />

                    </InputGroup>
                </div>
                            }></SkeletonCom>
                            <SkeletonCom loader={loading}
                            element={
                <div className=" balance_cat" style={{ margin: "0 0 0 14px" }}>
                    <Form.Select
                        onChange={(e: any) => {
                            setCateg(e.target.value)
                        }}
                    >
                        <option selected value="all">Select payees</option>
                        {tagsList?.map((arrayElement: any, index: number) => {

                            return (
                                <option value={arrayElement.id_name} key={index + 1}>{arrayElement.name}</option>
                            )
                        })}
                    </Form.Select>
                </div>}></SkeletonCom>
                <SkeletonCom loader={loading}
                            element={
                <div className=" balance_cat" style={{ margin: "0 0 0 14px" }}>
                    <Form.Select
                        onChange={(e: any) => {
                            setCateg_two(e.target.value)
                        }}
                    >
                        <option selected value="">All</option>
                        {tagsList_two?.map((arrayElement: any, index: number) => {
                            return (
                                <option value={arrayElement.id_name} key={index + 1}>{arrayElement.name}</option>
                            )
                        })}
                    </Form.Select>
                </div>}></SkeletonCom>

            </div>
            <SkeletonCom loader={loading}
                            element={
            <Paper sx={{ width: '100%', mb: 2, boxShadow: "0px 2px 14px 2px rgba(174, 174, 174, 0.25)" }}>
                <TableContainer>
                    <Table
                        className='contenar_tebal_liner'
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        icon={<img src="/Checkbox.svg" />}
                                        checkedIcon={<img src="/Checkbox_two.svg" />}
                                        indeterminate={selected.length > 0 && selected.length < rows_data.length}
                                        checked={rows_data.length > 0 && selected.length === rows_data.length}
                                        onChange={handleSelectAllClick}
                                        inputProps={{
                                            'aria-label': 'select all desserts',
                                        }}
                                    />
                                </TableCell>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.numeric ? 'center' : 'left'}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        {headCell.arrow == 'counterpart_name' ?
                                            <div style={{ display: "flex", cursor: "pointer" }} onClick={() => {

                                                if (ascending == 'DESC') {
                                                    setAscending('ASC')
                                                    setType(headCell.arrow)
                                                    setAscending_date("ASC")
                                                } else {
                                                    setAscending('DESC')
                                                    setAscending_date("DESC")
                                                    setType(headCell.arrow)
                                                }
                                            }}>
                                                {headCell.label}
                                                {ascending_date == 'DESC' && headCell.arrow == 'counterpart_name' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                                            </div> :
                                            headCell.arrow == 'updated_at' ? <div style={{ display: "flex", cursor: "pointer" }} onClick={() => {

                                                if (ascending == 'DESC') {
                                                    setAscending('ASC')
                                                    setType(headCell.arrow)
                                                    setAscending_name('ASC')
                                                } else {
                                                    setAscending('DESC')
                                                    setType(headCell.arrow)
                                                    setAscending_name('DESC')
                                                }
                                            }}>
                                                {headCell.label}
                                                {ascending_name == 'DESC' && headCell.arrow == 'updated_at' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                                            </div>
                                                :
                                                <>
                                                    {headCell.label}
                                                    {orderBy === headCell.id ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}</>}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {rows_data && rows_data.length > 0 ?
                                rows_data.map((data: any, index: any) => {
                                    const isItemSelected = isSelected(data.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    console.log(data, 'datadata', index);

                                    return (
                                        <TableRow
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    icon={<img src="/Checkbox.svg" />}
                                                    checkedIcon={<img src="/Checkbox_two.svg" />}
                                                    onClick={(event) => handleClick(event, data.id)}
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                style={{ textAlign: "left" }}
                                            >
                                                {data.counterpart_type == 'organization' ? data.name : data.contact_first_name + " " + data.contact_last_name}
                                            </TableCell>
                                            <TableCell sx={{
                                                minWidth:"100px !important"
                                            }} align="right">{moment(data.updated_at).format("DD-MMM-YYYY")}</TableCell>
                                            <TableCell align="right">
                                                {!!data.account && !!data.account.phone_number ? <div className='main_camin_box_dataa_twoo'>
                                                    <p className='textLeft'>Send money</p>
                                                    <p className='textRight' style={{width:"53%"}}>{data.account.phone_number}</p>
                                                </div> : ""}
                                                {!!data.account && (!!data.account.paybill_number || !!data.account.account_number) ? <div className='maian_bottom_borderr'>
                                                    {!!data.account.paybill_number ?
                                                        <div className='main_camin_box_dataa_twoo'>
                                                            <p className='textLeft'>Paybill number</p>
                                                            <p className='textRight'>{data.account.paybill_number}</p>
                                                        </div> : ""} {!!data.account.account_number ?
                                                            <div className='main_camin_box_dataa_twoo'>
                                                                <p className='textLeft'>account number</p>
                                                                <p className='textRight'>{data.account.account_number}</p>
                                                            </div> : ""}
                                                </div> : ""}

                                                {!!data.account && !!data.account.till_number ? <div className='main_camin_box_dataa'>
                                                    <p className='textLeft'>Till number</p>
                                                    <p className='textRight'>{data.account.till_number}</p>
                                                </div> : ""}
                                                {!!data.account && (!!data.account.bank_name || !!data.account.account_name || !!data.account.bank_account_number) ? <div className='maian_bottom_borderr'>
                                                    {!!data.account.bank_name ? <div className='main_camin_box_dataa_twoo'>
                                                        <p className='textLeft'>Bank name</p>
                                                        <p className='textRight'>{data.account.bank_name}</p>
                                                    </div> : ""} {!!data.account && !!data.account.account_name ?
                                                        <div className='main_camin_box_dataa_twoo'>
                                                            <p className='textLeft'>account name</p>
                                                            <p className='textRight'>{data.account.account_name}</p>
                                                        </div> : ""}
                                                    {!!data.account.bank_account_number ?
                                                        <div className='main_camin_box_dataa_twoo'>
                                                            <p className='textLeft'>account number</p>
                                                            <p className='textRight'>{data.account.bank_account_number}</p>
                                                        </div> : ""}
                                                </div> : ""}
                                            </TableCell>
                                            <TableCell align="right" sx={{
                                                minWidth:"200px !important"
                                            }}>
                                                {!!data.lastest_transaction ? <>
                                                    <div className='main_camin_box_dataa_twoo'>

                                                        <p className='textLeft'>Amount</p>
                                                        <p className='textRight'>KES {data.lastest_transaction.amount}</p>
                                                    </div>
                                                    <div className='main_camin_box_dataa_twoo'>
                                                        <p className='textLeft'>Bill number</p>
                                                        <p className='textRight'>{data.lastest_transaction.invoice_no}</p>
                                                    </div>
                                                    <div className='main_camin_box_dataa_twoo'>
                                                        <p className='textLeft'>Date</p>
                                                        <p className='textRight'>{moment(data.lastest_transaction.created_at).format("DD-MMM-YYYY")}</p>
                                                    </div></> : ""}
                                            </TableCell>
                                            <TableCell align="right" sx={{
                                                minWidth:"200px !important"
                                            }}>
                                                {data.bills.paid.length == 0 ? "" : <><div className='main_camin_box_dataa_twoo'>
                                                    <p className='textLeft'>Total amount</p>
                                                    <p className='textRight'>{getKESFormateCurrency(data.bills.paid.paid_amount / 100)}</p>
                                                </div>
                                                    <div className='main_camin_box_dataa_twoo'>
                                                        <p className='textLeft'>Total bills</p>
                                                        <p className='textRight'>{data.bills.paid.paid_payable_count}</p>
                                                    </div></>}
                                            </TableCell>
                                            <TableCell align="right" sx={{
                                                minWidth:"200px !important"
                                            }}>
                                                {data.bills.outstanding.length == 0 ? "" : <><div className='main_camin_box_dataa_twoo'>
                                                    <p className='textLeft'>Total amount</p>
                                                    <p className='textRight'>{getKESFormateCurrency(data.bills.outstanding.oustanding_amount / 100)}</p>
                                                </div>
                                                    <div className='main_camin_box_dataa_twoo'>
                                                        <p className='textLeft'>Total bills</p>
                                                        <p className='textRight'>{data.bills.outstanding.outstanding_payable_count}</p>
                                                    </div></>}
                                            </TableCell>

                                            <TableCell align="right">
                                                <div style={{ cursor: "pointer" }}
                                                    onClick={(e: any) => {
                                                        setIdeditdata(data.monite_counterpart_id)
                                                        setDelete_data(data.user_id)
                                                        handleClick_datat(e)
                                                    }}>
                                                    <MoreVertIcon />
                                                </div>

                                            </TableCell>
                                        </TableRow>
                                    );
                                }) : null}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            navigate("/create-counterparts?id=" +
                                ideditdata, { replace: true, state: { id: ideditdata } });
                        }}
                        className='menuitem'>Update</MenuItem>
                    <MenuItem onClick={() => {
                        handleClose()
                        handleShow()
                    }} className='menuitem' style={{ color: "#F87161" }}>Delete
                    </MenuItem>
                </Menu>
            </Paper>}></SkeletonCom>
            <div className="addnewbtnclass_footer">
            {rows_data && rows_data.length > 0?
                <Button
                disabled={selected.length > 0 ? false : true}
                onClick={() => rejectSubmit()}
                color="#F87168" className="white_btn_two">
                Delete
            </Button>:<div></div>
}
                <div className="btnmaindivin">
                    {pageneshn == 1 ?
                        <button disabled className="nextntmistish" ><ArrowBackIosNewIcon style={{ fontSize: '16px' }} /></button> :
                        <button className="nextntmistish" onClick={() => {
                            setPagelist(pageneshn - 1)
                            setRemovedata(remove - 10)
                        }}><ArrowBackIosNewIcon style={{ fontSize: '16px' }} /></button>}
                    {remove >= totalCount ? <button disabled className="nextntmistish" ><ArrowForwardIosIcon style={{ fontSize: '16px' }} /></button> : <button className="nextntmistish" onClick={() => {
                        setPagelist(pageneshn + 1)
                        setRemovedata(remove + 10)
                    }}><ArrowForwardIosIcon style={{ fontSize: '16px' }} /></button>}
                </div>
            </div>

        </Box>
    );
}
const SearchIcon = () => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13 13L10.0748 10.1341M11.6667 6.33334C11.6667 9.27886 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27886 1 6.33334C1 3.38782 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38782 11.6667 6.33334Z"
            stroke="#111111"
            stroke-width="1.1875"
            stroke-miterlimit="1"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);