export const getPayableGridData = () => {
    return [
        {
            number: '0012897',
            supplier: "Monsoon",
            supplier_logo: "Monsoon.png",
            issue_date: '14.06.2022',
            due_date: "13.07.2022",
            status: 'Draft',
            applied_policy: " ",
            amount: '5,593.00 €',
            added_by: "Tabitha Karanja",
            added_by_logo: "Tabitha.png"
        },
        {
            number: '11100568',
            supplier: "Nexos Computer",
            supplier_logo: "NexosCompany.png",
            issue_date: '20.05.2022',
            due_date: "19.06.2022",
            status: 'Approved',
            applied_policy: " ",
            amount: '500.00 €',
            added_by: "Koos Bekker",
            added_by_logo: "Koos.png"
        },
        {
            number: '000459',
            supplier: "ID Tech",
            supplier_logo: "IDTech.png",
            issue_date: '19.05.2022',
            due_date: "18.06.2022",
            status: 'Partially paid',
            applied_policy: " ",
            amount: '20,800.00 €',
            added_by: "Eric van der Sar",
            added_by_logo: "Eric.png"
        },
        {
            number: '000458',
            supplier: "ID Tech",
            supplier_logo: "IDTech.png",
            issue_date: '14.06.2022',
            due_date: "13.07.2022",
            status: ' ',
            applied_policy: " ",
            amount: '1,500.00 €',
            added_by: "Olga Yang",
            added_by_logo: "Ogla.png"
        },
        {
            number: '890452',
            supplier: "Areas",
            supplier_logo: "Areas.png",
            issue_date: '20.05.2022',
            due_date: "19.06.2022",
            status: 'Paid',
            applied_policy: " ",
            amount: '500.00 €',
            added_by: "Eric van der Sar",
            added_by_logo: "Eric.png"
        },
        {
            number: '0126390',
            supplier: "Trustwave",
            supplier_logo: "TrustWave.png",
            issue_date: '19.05.2022',
            due_date: "18.06.2022",
            status: 'Cancelled',
            applied_policy: " ",
            amount: '60.00 €',
            added_by: "Eric van der Sar",
            added_by_logo: "Eric.png"
        }
    ]
}