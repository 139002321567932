import Accounting_System from './components/Choose_AccountingSystem/Accounting_System';

interface Props {
    onclick: any;

}
const Accounting_System_page = (props: Props) => {

    return (
        <>
            <div className="balance__header" style={{ marginBottom: '35px' }}>
                <div className="balance__header__name">Connect Accounting Softwares</div>

            </div>
            <Accounting_System onclick={props.onclick} />
        </>
    )
}
export default Accounting_System_page;