import styles from './Customers.module.scss';
import { Button } from 'react-bootstrap';
import Customers_Tebal from './components/Customers_Tebal/Customers_Tebal';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import CreateCustomer_sub from './components/CreateCustomer/CreateCustomer';

const CreateCustomer = () => {
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    return (
        <>
            <div className="balance">
                <div className="balance__header">
                    <div className="balance__header__name">Customers</div>
                    <div>
                        <>
                          
                        </>
                    </div>
                </div>
                <ThemeProvider theme={theme}>

                    <CreateCustomer_sub />
                </ThemeProvider>
            </div>
        </>
    )
}
export default CreateCustomer;