import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import "./PaymentInitiated.scss";
import { getUser } from "../../../../../../_helpers";
import { payablesActions } from "../../../../../../_actions/payables.actions";
import { useSearchParams } from "react-router-dom";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";
import { useDispatch, useSelector } from "react-redux";

interface Props {
    onSave: any;
    onCancel: any;
    counterpart: any;
    type: any;
    id: any;
}


export const PaymentInitiated = (props: Props) => {

    const [option, setOption] = useState('');
    const dispatch = useDispatch();
    const [render, setrender] = useState<any>(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const user = getUser()?.data ?? null;
    const [datadisplay, setDatadisplay] = useState('')
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    const [feesRender, setFeesRender] = useState(true);

    const [free_data, setFree_Data] = useState()
    useEffect(() => {
        if (feesRender && dataList?.GETNEWFEES_SUCCESS !== undefined) {
            setFeesRender(false);
            setFree_Data(dataList.GETNEWFEES_SUCCESS);
        }
    }, [dataList, feesRender]);
    const onSave = () => {
        onSubmitted();
        props.onSave();

    }
    const onCancel = () => {
        props.onCancel();
    }
    const onSubmitted = () => {
        const id_data = { "data": props.id }
        dispatch(payablesActions.initiate_bulk_Payment(id_data) as any)
    }
    return (
        <div className='pay-initiated-container'>
            <div className='pay-initiated-container__logo'><TimeLogo /></div>
            <div className='pay-initiated-container__heading'>
                Confirm Details
            </div>
            <div className='pay-initiated-container__desc'>Please confirm the  payment details
                before you proceed</div>

            <div className='pay-initiated-container__table'>
                <div className='dflex b-bot'>
                    <div className='left'>Total Transactions</div>
                    <div className='right'>
                        {!!props.counterpart.total_transactions ? props.counterpart.total_transactions : 0} </div>
                </div>
                <div className='dflex b-bot'>
                    <div className='left'>Total Gross Payout</div>
                    <div className='right'>
                        KES {!!props.counterpart.gross_total ? props.counterpart.gross_total : 0} </div>
                </div>

                <div className='dflex b-bot'>
                    <div className='left'>Transaction Fees</div>
                    <div className='right'>KES {!!props.counterpart.total_fees ? props.counterpart.total_fees:props.counterpart.total_fees==null?0 : 0}</div>
                </div>
                <div className='dflex b-bot'>
                    <div className='left'>Total Net Payout</div>
                    <div className='right'>KES {!!props.counterpart.total_net_payout ? props.counterpart.total_net_payout : 0}</div>
                </div>

            </div>

            <div className='pay-initiated-container__btn-row'>
                <Button className='auth-trans-container__btn-row__white' onClick={() => onCancel()}>Cancel</Button>

                <Button className='pay-initiated-container__btn-row__green' onClick={() => onSave()}>Pay</Button>
            </div>
        </div>
    )
}

const TimeLogo = () => (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.0003 0.666504C15.3743 0.666504 11.8298 1.74174 8.81488 3.75623C5.79998 5.77072 3.45015 8.63399 2.06254 11.984C0.674936 15.334 0.311875 19.0202 1.01927 22.5765C1.72667 26.1328 3.47275 29.3995 6.03671 31.9635C8.60067 34.5274 11.8674 36.2735 15.4237 36.9809C18.98 37.6883 22.6662 37.3252 26.0162 35.9376C29.3662 34.55 32.2294 32.2002 34.2439 29.1853C36.2584 26.1704 37.3337 22.6258 37.3337 18.9998C37.3337 16.5923 36.8595 14.2083 35.9381 11.984C35.0168 9.75967 33.6664 7.73862 31.964 6.03621C30.2615 4.33381 28.2405 2.98338 26.0162 2.06205C23.7919 1.14071 21.4079 0.666504 19.0003 0.666504ZM19.0003 33.6665C16.0995 33.6665 13.2639 32.8063 10.852 31.1947C8.44005 29.5831 6.56019 27.2925 5.4501 24.6125C4.34002 21.9325 4.04957 18.9836 4.61548 16.1385C5.1814 13.2935 6.57826 10.6801 8.62943 8.62894C10.6806 6.57777 13.294 5.1809 16.139 4.61499C18.9841 4.04907 21.933 4.33952 24.613 5.4496C27.293 6.55969 29.5836 8.43955 31.1952 10.8515C32.8068 13.2634 33.667 16.099 33.667 18.9998C33.667 22.8897 32.1218 26.6202 29.3712 29.3707C26.6207 32.1213 22.8902 33.6665 19.0003 33.6665ZM24.6837 20.1548L20.8337 17.9365V9.83317C20.8337 9.34694 20.6405 8.88062 20.2967 8.53681C19.9529 8.19299 19.4866 7.99984 19.0003 7.99984C18.5141 7.99984 18.0478 8.19299 17.704 8.53681C17.3602 8.88062 17.167 9.34694 17.167 9.83317V18.9998C17.167 18.9998 17.167 19.1465 17.167 19.2198C17.1779 19.3462 17.2088 19.4699 17.2587 19.5865C17.2964 19.6953 17.3456 19.7997 17.4053 19.8982C17.4555 20.0024 17.517 20.1007 17.5887 20.1915L17.882 20.4298L18.047 20.5948L22.8137 23.3448C23.0931 23.5032 23.4092 23.5854 23.7303 23.5832C24.1363 23.586 24.5317 23.454 24.8545 23.208C25.1774 22.9619 25.4094 22.6156 25.5143 22.2234C25.6191 21.8313 25.5909 21.4154 25.4339 21.041C25.2769 20.6667 25.0002 20.355 24.647 20.1548H24.6837Z" fill="#257D6B" />
    </svg>
);