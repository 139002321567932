import styles from './Preview_nodata.module.scss';

const Preview_nodata = () => {

    return (
        <>
            <div className={styles.div_pirwushdata}>
                <div className={styles.div_contenar_center}>
                    <p>Enter invoice details to start</p>
                    <p>seeing a preview</p></div>
            </div>
        </>
    )
}
export default Preview_nodata;