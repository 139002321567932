import { Dropdown, Form, InputGroup } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./PayableGridStyles.scss";

export const PayableDetail = (props: any) => {
  return (
    <>
      <div className="payable-grid-filter-box">
        <InputGroup className="payable-filter-wrapper input">
          <InputGroup.Text id="basic-addon1" className="icon">
            <SearchIcon />
          </InputGroup.Text>
          <Form.Control
            className="payable-search-input"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="basic-addon1"
          />
        </InputGroup>

        <div>
          <Form.Select className="payable-filter-wrapper dropdown">
            <option>Any status</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
        </div>

        <div>
          <Form.Select className="payable-filter-wrapper dropdown lg">
            <option>Any creator</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
        </div>

        <div className="payable-filter-wrapper custom">
          <div>More</div>
          <span>
            <MoreIcon />
          </span>
        </div>
      </div>
      <div className="payable-grid-wrapper">
        <Table responsive>
          <thead>
            <tr>
              <th>
                <Form.Check type="checkbox" id="checkbox-payable" label="" />
              </th>
              <th>Number</th>
              <th>Beneficiary</th>
              <th>Issue date</th>
              <th>Due date</th>
              <th>Status</th>
              <th>Applied policy</th>
              <th>Amount</th>
              <th>Added by</th>
            </tr>
          </thead>
          <tbody>
            {props &&
              props.data &&
              props.data.map((obj: any, index: any) => {
                return (
                  <tr>
                    <td></td>
                    <td>{obj.number}</td>
                    <td>
                      <div className="logo-wrapper">
                        <img
                          src={"/assets/logos/" + obj.supplier_logo}
                          alt={obj.supplier}
                        />{" "}
                        {obj.supplier}
                      </div>
                    </td>
                    <td>{obj.issue_date}</td>
                    <td>{obj.due_date}</td>
                    <td>
                      <div
                        className={
                          obj.status == "Partially paid"
                            ? "status-wrapper Partially-paid"
                            : "status-wrapper " + obj.status
                        }
                      >
                        {obj.status}
                      </div>
                    </td>
                    <td>{obj.applied_policy}</td>
                    <td>{obj.amount}</td>
                    <td>
                      <div className="logo-wrapper">
                        <img
                          src={"/assets/logos/" + obj.added_by_logo}
                          alt={obj.added_by}
                        />{" "}
                        {obj.added_by}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>

      <div className="navigator">
        <div>
          <PreviousIcon />
          <span>Prev</span>
        </div>
        <div>
          <span>Next</span>
          <NextIcon />
        </div>
      </div>
    </>
  );
};

const PreviousIcon = () => (
  <svg
    width="12"
    height="14"
    style={{ marginRight: "14px" }}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 6.00019H3.41002L6.71002 2.71019C6.89832 2.52188 7.00411 2.26649 7.00411 2.00019C7.00411 1.73388 6.89832 1.47849 6.71002 1.29019C6.52172 1.10188 6.26632 0.996094 6.00002 0.996094C5.73372 0.996094 5.47832 1.10188 5.29002 1.29019L0.290018 6.29019C0.198978 6.38529 0.127613 6.49743 0.0800184 6.62019C-0.0199996 6.86365 -0.0199996 7.13672 0.0800184 7.38019C0.127613 7.50294 0.198978 7.61508 0.290018 7.71019L5.29002 12.7102C5.38298 12.8039 5.49358 12.8783 5.61544 12.9291C5.7373 12.9798 5.86801 13.006 6.00002 13.006C6.13203 13.006 6.26274 12.9798 6.3846 12.9291C6.50645 12.8783 6.61706 12.8039 6.71002 12.7102C6.80375 12.6172 6.87814 12.5066 6.92891 12.3848C6.97968 12.2629 7.00582 12.1322 7.00582 12.0002C7.00582 11.8682 6.97968 11.7375 6.92891 11.6156C6.87814 11.4937 6.80375 11.3831 6.71002 11.2902L3.41002 8.00019H11C11.2652 8.00019 11.5196 7.89483 11.7071 7.70729C11.8947 7.51976 12 7.2654 12 7.00019C12 6.73497 11.8947 6.48062 11.7071 6.29308C11.5196 6.10554 11.2652 6.00019 11 6.00019Z"
      fill="#707070"
    />
  </svg>
);
const NextIcon = () => (
  <svg
    width="12"
    height="14"
    style={{ marginLeft: "14px" }}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.92 6.62019C11.8724 6.49743 11.801 6.38529 11.71 6.29019L6.71 1.29019C6.61676 1.19695 6.50607 1.12299 6.38425 1.07253C6.26243 1.02207 6.13186 0.996094 6 0.996094C5.7337 0.996094 5.4783 1.10188 5.29 1.29019C5.19676 1.38342 5.1228 1.49411 5.07234 1.61594C5.02188 1.73776 4.99591 1.86833 4.99591 2.00019C4.99591 2.26649 5.1017 2.52188 5.29 2.71019L8.59 6.00019H1C0.734784 6.00019 0.48043 6.10554 0.292893 6.29308C0.105357 6.48062 0 6.73497 0 7.00019C0 7.2654 0.105357 7.51976 0.292893 7.70729C0.48043 7.89483 0.734784 8.00019 1 8.00019H8.59L5.29 11.2902C5.19627 11.3831 5.12188 11.4937 5.07111 11.6156C5.02034 11.7375 4.9942 11.8682 4.9942 12.0002C4.9942 12.1322 5.02034 12.2629 5.07111 12.3848C5.12188 12.5066 5.19627 12.6172 5.29 12.7102C5.38296 12.8039 5.49356 12.8783 5.61542 12.9291C5.73728 12.9798 5.86799 13.006 6 13.006C6.13201 13.006 6.26272 12.9798 6.38458 12.9291C6.50644 12.8783 6.61704 12.8039 6.71 12.7102L11.71 7.71019C11.801 7.61508 11.8724 7.50294 11.92 7.38019C12.02 7.13672 12.02 6.86365 11.92 6.62019Z"
      fill="#707070"
    />
  </svg>
);
const MoreIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 6L11 6M11 6L6 1M11 6L6 11"
      stroke="#111111"
      stroke-width="1.5"
      stroke-miterlimit="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const SearchIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 13L10.0748 10.1341M11.6667 6.33334C11.6667 9.27886 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27886 1 6.33334C1 3.38782 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38782 11.6667 6.33334Z"
      stroke="#111111"
      stroke-width="1.1875"
      stroke-miterlimit="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
