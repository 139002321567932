import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';



const SelectPopup = (props: any) => {
  const [show, setShowlist] = useState(props.show == true ? 0 : '');
  const [selectedMethod_two, setSelectedMethod_two] = useState('');


  const onClose_topup = () => {
    props.onClose();
    setShowlist('300');
  };
  const OnSave = (type:any) => {
    props.OnSave(type);

  }
  return (
    <>
      <Modal
        show={show == 0 ? true : false}
        onHide={() => {
          setShowlist('');
        }}
        backdrop="static"
        keyboard={false}
        aria-labelledby="ModalHeader"
        className="topup-modal"
        centered
        size="lg"
      >
         <Box className="CloseContenar">
            <IconButton onClick={() => {
              onClose_topup();
            }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="heding_contean">
            <Typography>
            How do you want to export statement?
            </Typography>
          </Box>
        {/* <Modal.Header closeButton>
          <Modal.Title>How do you want to export statement?</Modal.Title>
        </Modal.Header> */}

        <Modal.Body>
          <div className="label">Download Options</div>
          <div className="type-card-list">
          <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${selectedMethod_two === 'Export as PDF' ? 'active' : ''
                }`}
              onClick={() =>{
                 setSelectedMethod_two('Export as PDF')
                 setShowlist(1);
                 OnSave('Export as PDF')
                }}
            >
              <div className="heding_centewr_data">
                <p className="kes_text_stylee">PDF</p>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${selectedMethod_two === 'Export as CSV' ? 'active' : ''
                }`}
              onClick={() => {

                setSelectedMethod_two('Export as CSV')
                setShowlist(1);
                OnSave('Export as CSV')
              }}
            >
              <div className="heding_centewr_data">
                <p className="kes_text_stylee">CSV</p>
              </div>
            </div>
         
          </div>

        </Modal.Body>

        {/* <Modal.Footer> */}
          {/* <Button
            className="white-btn"
            onClick={() => {
              onClose_topup();
            }}
          >
            Cancel
          </Button> */}
          {/* <Button
            id="next_button_dataaa"
            className="green-btn"
            disabled={selectedMethod_two === ''}
            onClick={() => {
  
            }}
          >
            Next
          </Button> */}
        {/* </Modal.Footer> */}
      </Modal>
    </>
  )
}
export default SelectPopup;