import { Button, Form, InputGroup } from 'react-bootstrap';
import "./TransactionDetails.scss";
import Modal from 'react-bootstrap/Modal';
import React from 'react';


interface Props {
    onClose: any;
    show: boolean;
}

interface objectType {
    id: string;
    value: string
}
export const Virefily_account = (props: Props) => {
    const [showModal, setShow] = React.useState(props.show);
    const onClose = () => {
        setShow(false);
        props.onClose(false);
    };

    return (
        <>
            <Modal
                show={showModal}
                onHide={onClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby='ModalHeader'
                className='topup-modal trans-modal'
                id='afaFafffffFFff'
                centered
            >
                <Modal.Header closeButton style={{borderBottom:"0px solid",padding:"32px 32px 0px 32px"}}>
                    <Modal.Title>Account verification</Modal.Title>
                </Modal.Header>

                <Modal.Body style={{padding:"20px 32px 32px 32px"}}>
                    <div className='trans-detail-containerX' >
                        <div style={{ padding: "10px",borderRadius: "8px",marginBottom:"18px" }} >
                            <p>
                           Some features in your account are temporarily not accessible. To fully activate your account, please complete the account verification process by going to Workspace Menu {`>`} Verification. If you would like more details about this, please contact your Account Manager or <a style={{ textDecoration: "none" }} href="mailto:support@niobi.co">support@niobi.co.</a>
                            </p>
                        </div>
                    </div>


                    <div className='trans-detail-container__btn-row'>
                        <Button style={{ width: "100%" }} className='trans-detail-container__btn-row__green' onClick={() => onClose()}>Close</Button>

                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}