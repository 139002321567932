import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { NewCreate_Account_from } from "./NewCreate_Account_from";
import './Create_Account.scss';
import { useSearchParams } from "react-router-dom";
import { entityActions } from "../../../_actions/entity.actions";
import { useDispatch, useSelector } from 'react-redux'
import { selectAllData } from "../../../_reducers/entity.reducer";

const New_create_account = () => {
    const [cereatefrom, setCereate] = React.useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [responseRender, setresponseRender] = useState(true)
    const [dataEntity, setEntity] = useState<any>([])
    const [entityId, setEntityId] = useState<any>({})
    const dispatch = useDispatch()
    const entityData = useSelector((state) => selectAllData(state));
    console.log(entityData, 'entityDataentityData');
    console.log(dataEntity,'dataEntity');

    useEffect(() => {

        const popup = searchParams.get('entityid');
        const roleFind : any = searchParams.get('roleid')
        const emailFind : any = searchParams.get('email')
        const team_id : any = searchParams.get('teamid')
        setEntityId({entityId : popup , roleId : roleFind,team_id:team_id})
        if (!!popup && !!roleFind && !!emailFind) {
            let requestBody = `entityid=${popup}&roleid=${roleFind}&email=${emailFind}`
            console.log(requestBody,'requestBody')
            dispatch(entityActions.getBusinessDetails(requestBody) as any)
        }


    }, [searchParams]);

    useEffect(() => {
        if (responseRender && entityData?.BUSINESS_DETAILS_SUCCESS !== undefined) {
            setresponseRender(false);
            setEntity(entityData?.BUSINESS_DETAILS_SUCCESS.data)
        }
    }, [responseRender,entityData.BUSINESS_DETAILS_SUCCESS])

    return (
        <>
            {cereatefrom == true ? <NewCreate_Account_from id = {entityId} />
                :
                <Box className='boxbgimg'>
                    <div className="addinvitelist">
                        <p className="hefingtage">Welcome To Niobi</p>
                        <p className='addtestdiscer'>You were invited to Niobi by the following business organisation</p>
                        <Box className='boximcedata'>
                            <div className="addnewdivfast">
                                <p className="addbusinessname">Business name</p>
                                <p className="abbdataapi">{dataEntity.name}</p>
                            </div>
                            <div>
                                <p className="addbusinessname">Business address</p>
                                <p className="abbdataapi">{ dataEntity.street_address},{dataEntity.city},{dataEntity.state},{dataEntity.country}</p>
                            </div>

                        </Box>
                        <div className="adddibbtntwo"><Button className="centerbtn">Cancel</Button><Button className="savebtn" onClick={() => { setCereate(true) }}>Continue</Button></div>
                    </div>
                </Box>}
        </>
    );
}

export default New_create_account;