import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import moment from "moment";
import '../Counterparts/components/CounterpartsTable/CounterpartsTable.scss'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DraftsIcon from '@mui/icons-material/Drafts';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { getUser, isUserAuthenticated, authHeader } from "../../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { counterpartsActions } from '../../../../_actions/counterparts.actions'
import { selectAllCounterpartsListData } from "../../../../_reducers/counterparts.reducer";
import { Button, Form, InputGroup } from "react-bootstrap";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Menu, MenuItem, } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { CssBaseline, CircularProgress } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNotifications } from '../../NotificationContext';

interface Data {
    calories: string;
    carbs: string;
    fat: string;
    name: string;
    protein: string;
    protein_two: string,
    protein_three: string
}

function createData(
    name: string,
    calories: string,
    fat: string,
    carbs: string,
    protein: string,
    protein_two: string,
    protein_three: string
): Data {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        protein_two,
        protein_three
    };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    arrow: string;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Payee Name',
        arrow: 'counterpart_name'
    },
    {
        id: 'calories',
        numeric: true,
        disablePadding: false,
        label: 'Updated At',
        arrow: 'updated_at'
    },
    {
        id: 'fat',
        numeric: true,
        disablePadding: false,
        label: 'Saved Payment Details',
        arrow: ''
    },
    {
        id: 'carbs',
        numeric: true,
        disablePadding: false,
        label: 'Last Payment',
        arrow: ''
    },
    {
        id: 'protein',
        numeric: true,
        disablePadding: false,
        label: 'Total Payments to Date',
        arrow: ''
    },
    {
        id: 'protein_two',
        numeric: true,
        disablePadding: false,
        label: 'Outstanding Bills',
        arrow: ''
    },
    {
        id: 'protein_three',
        numeric: true,
        disablePadding: false,
        label: '',
        arrow: ''
    },

];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}


interface EnhancedTableToolbarProps {
    numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Nutrition
                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        Mark as read
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

interface Notification {
    id: number;
    title: string;
    message: string;
    timestamp: string;
    read_at: string | null;
  }

export default function EnhancedTable() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selected, setSelected] = React.useState<any>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [render, setRender] = React.useState(true);
    const [ideditdata, setIdeditdata] = React.useState('')
    const [loading, setLoading] = React.useState(false);
    const [tagsList_two, setTagsList_two] = React.useState<any>([
        {
            "name": "Unread",
            "id_name": "unread"
        },
        {
            "name": "Read",
            "id_name": "read"
        },


    ]);
    const [rows_data, setRows_data] = React.useState([])
    const [rows_data2, setRows_data2] = React.useState([])
    const [rows_data3, setRows_data3] = React.useState([])
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [totalCount, setTotalCount] = React.useState(0);
    const [pageneshn, setPagelist] = React.useState(1)
    const [remove, setRemovedata] = React.useState(10)
    const open = Boolean(anchorEl);
    const { notifications, unreadCount, markAllAsRead, markAsRead} = useNotifications();
    
     React.useEffect(() => {
        getUnreadNotifications();
        getReadNotifications();
        setTotalCount(notifications.length);
        console.log('total count is ', notifications.length);

     }, [render, isUserAuthenticated]);
  
    const getUnreadNotifications =() => {
        var array: any = []
        if(rows_data != null){
            var counter = 0;
            for (let index = 0; index < rows_data.length; index++) {
                if(rows_data[index]["read_at"] == null){
                    const element = rows_data[index];
                    console.log(element, "elementelement");
                    array.push(element); 
                    counter += 1;
                }                      
            }
            setRows_data2(array);
            console.log('unread notifications are: ', rows_data2);
            
        }
    }
    const getReadNotifications =() => {
        var array: any = []
        if(rows_data != null){
            for (let index = 0; index < rows_data.length; index++) {
                if(rows_data[index]["read_at"] != null){
                    const element = rows_data[index];
                    console.log(element, "elementelement");
                    array.push(element); 
                }                      
            }
            setRows_data3(array);
            console.log('read notifications are: ', rows_data3);
        }
    }

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows_data.map((n: any) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows_data.length) : 0;
    const handleClick_datat = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleShow = () => {
        dispatch(counterpartsActions.Delete_Counterparts(ideditdata) as any);
        setLoading(true)
        setTimeout(() => {
            setSelected([])
        }, 4000)
    }
    const handleFilterChange = (event: React.SyntheticEvent, newValue: string) => {
        setFilter(newValue);
    };
   
    const [filter, setFilter] = React.useState('all');
    const CustomTab = styled(Tab)(({ theme }) => ({
        textTransform: 'none', 
        fontFamily: "Poppins, Verdana",
    }));

    return (
        <Box sx={{ width: '100%' }}>
            {loading && <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <CircularProgress color="success" />
            </Box>}
           
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Button id="button_back" style={{ color: '#025141' }} onClick={() => { navigate(`/dashboard`); }} className="payment-journey-container__detailbox__white-btn">
                         <ArrowBackIcon style={{ fontSize: "19px", marginRight: "10px" }} /> Go Back
                </Button>
                <p style={{ textAlign: "center", flex: "1", margin: "0 auto" }}>You have {unreadCount} unread notifications</p>
                <Button color="#025041" className="counterparty__save-btn" 
                    onClick={() => { setLoading(true);
                        markAllAsRead();
                        setLoading(false);
                    }}
                    >
                        Mark All as Read
                </Button>
            </div>
            <div className='main_serech_box_filtar_data'>
            <div className="balance_cat" style={{ margin: "0 0 0 14px" }}>
                <Tabs value={filter} onChange={handleFilterChange} aria-label="notification filter tabs">
                        <CustomTab label="All" value="all" />
                        <CustomTab label="Unread" value="unread" />
                        <CustomTab label="Read" value="read" />
                </Tabs>

            </div>
            </div>
            <Paper sx={{ width: '100%', mb: 2, boxShadow: "0px 2px 14px 2px rgba(174, 174, 174, 0.25)", marginLeft: "20px"}}>
                <TableContainer>
                    <Table
                        className='contenar_tebal_liner'
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <TableBody>

                            { filter && filter == 'all' ?
                                notifications && notifications.length > 0 ?
                                notifications
                                    .map((data: any, index: any) => {
                                    const isItemSelected = isSelected(data.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    console.log(data, 'datadata', index);

                                    return (
                                            <TableRow
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                            style={data.read_at === null ? { backgroundColor: "#F8F8F8" } : {}}

                                        >
                                             <TableCell padding="checkbox">
                                              
                                              </TableCell>
                                            <TableCell
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                style={{ textAlign: "left" }}
                                            >
                                                <p> <span style={{color: "#055041", fontWeight: "bold", fontSize: "15px"}}>{data.title}  </span>
                                                <span style={{fontSize: "12px", color: "#808080"}}> {moment(data.date).fromNow()}</span></p>
                                                <p style={{fontSize: "14px"}}>{data.message}</p>
                                                
                                                
                                            </TableCell>
                                           
                                           

                                            <TableCell align="right">
                                                {data.read_at === null ? 
                                                <div style={{ cursor: "pointer"}}
                                                    onClick={(e: any) => {
                                                        markAsRead(index, data.id)
                                                    }}>
                                                    <Tooltip title="Mark as read">
                                                        <EmailOutlinedIcon style={{color: "#055041"}}/>
                                                    </Tooltip>
                                                </div>
                                                :  <div style={{ cursor: "pointer"}}>
                                                    <DraftsOutlinedIcon style={{color: "#055041"}} />
                                                    </div>}
                                                

                                            </TableCell>
                                        </TableRow> 
                                    );
                                }) : null
                               : filter && filter == 'unread' ? 
                                    notifications && notifications.length > 0 ?
                                    notifications
                                    .filter((notification: {  id: number,
                                        title: string,
                                        message: string,
                                        timestamp: string,
                                        read_at: string | null; }) => notification.read_at === null)
                                    .map((data: any, index: any) => {
                                   const isItemSelected = isSelected(data.id);
                                   const labelId = `enhanced-table-checkbox-${index}`;
                                   console.log(data, 'datadata', index);

                                   return (
                                           <TableRow
                                           role="checkbox"
                                           aria-checked={isItemSelected}
                                           tabIndex={-1}
                                           key={index}
                                           selected={isItemSelected}
                                           sx={{ cursor: 'pointer' }}
                                           style={data.read_at === null ? { backgroundColor: "#F8F8F8" } : {}}

                                       >
                                          
                                           <TableCell padding="checkbox">
                                              
                                           </TableCell>
                                           <TableCell
                                               id={labelId}
                                               scope="row"
                                               padding="none"
                                               style={{ textAlign: "left" }}
                                           >
                                              <p> <span style={{color: "#055041", fontWeight: "bold", fontSize: "15px"}}>{data.title}  </span>
                                                <span style={{fontSize: "12px", color: "#808080"}}> {moment(data.date).fromNow()}</span></p>
                                                <p style={{fontSize: "14px"}}>{data.message}</p>
                                               
                                           </TableCell>
                                         

                                           <TableCell align="right">
                                               {data.read_at === null ? 
                                               <div style={{ cursor: "pointer"}}
                                                   onClick={(e: any) => {
                                                       markAsRead(data.id)
                                                   }}>
                                                   <Tooltip title="Mark as read">
                                                        <EmailOutlinedIcon style={{color: "#055041"}} />
                                                    </Tooltip>
                                               </div>
                                               : <div style={{ cursor: "pointer"}}>
                                               <DraftsOutlinedIcon style={{color: "#055041"}}/>
                                               </div>}
                                               

                                           </TableCell>
                                       </TableRow> 
                                   );
                                }) : null 
                                : filter && filter == 'read' ? 
                                notifications && notifications.length > 0 ?
                                notifications
                                .filter((notification: {  id: number,
                                    title: string,
                                    message: string,
                                    timestamp: string,
                                    read_at: string | null; }) => notification.read_at !== null)
                                .map((data: any, index: any) => {
                               const isItemSelected = isSelected(data.id);
                               const labelId = `enhanced-table-checkbox-${index}`;
                               console.log(data, 'datadata', index);

                               return (
                                       <TableRow
                                       role="checkbox"
                                       aria-checked={isItemSelected}
                                       tabIndex={-1}
                                       key={index}
                                       selected={isItemSelected}
                                       sx={{ cursor: 'pointer' }}
                                       style={data.read_at === null ? { backgroundColor: "#d3d3d3" } : {}}

                                   >
                                      
                                       <TableCell padding="checkbox">
                                          
                                       </TableCell>
                                       <TableCell
                                           id={labelId}
                                           scope="row"
                                           padding="none"
                                           style={{ textAlign: "left" }}
                                       >
                                          <p> <span style={{color: "#055041", fontWeight: "bold", fontSize: "15px"}}>{data.title}  </span>
                                                <span style={{fontSize: "12px", color: "#808080"}}> {moment(data.date).fromNow()}</span></p>
                                                <p style={{fontSize: "14px"}}>{data.message}</p>
                                           
                                       </TableCell>
                                      

                                       <TableCell align="right">
                                           {data.read_at === null ? 
                                           <div style={{ cursor: "pointer"}}
                                               onClick={(e: any) => {
                                                   markAsRead(data.id)
                                               }}>
                                               <Tooltip title="Mark as read">
                                                        <EmailOutlinedIcon style={{color: "#055041"}}/>
                                                    </Tooltip>
                                           </div>
                                           : <div style={{ cursor: "pointer"}}>
                                                <DraftsOutlinedIcon style={{color: "#055041"}}/>
                                            </div>}
                                           

                                       </TableCell>
                                   </TableRow> 
                               );
                            }) : null : null
                            } 
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={2} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            
            <div className="addnewbtnclass_footer">
                <div className="btnmaindivin">
                    {pageneshn == 1 ?
                        <button disabled className="nextntmistish" ><ArrowBackIosNewIcon style={{ fontSize: '16px' }} /></button> :
                        <button className="nextntmistish" onClick={() => {
                            setPagelist(pageneshn - 1)
                            setRemovedata(remove - 10)
                        }}><ArrowBackIosNewIcon style={{ fontSize: '16px' }} /></button>}
                    {remove >= totalCount ? <button disabled className="nextntmistish" ><ArrowForwardIosIcon style={{ fontSize: '16px' }} /></button> : <button className="nextntmistish" onClick={() => {
                        setPagelist(pageneshn + 1)
                        setRemovedata(remove + 10)
                    }}><ArrowForwardIosIcon style={{ fontSize: '16px' }} /></button>}
                </div>
            </div>

        </Box>
    );
}