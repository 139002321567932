import { Button } from "react-bootstrap";


const LoaderPage_text = (props: any) => {

    return (
        <>
            {props?.datainline == 'pending' ? (
                <div
                    style={{
                        display: 'flex',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 10,
                    }}
                ></div>
            ) : (
                ''
            )}
            {/* <div></div> */}
            <div style={{
                display: "flex", width: "100%", justifyContent: "center", height:"80vh",alignItems:"center"
            }}>
                <div style={{ padding: "48px 48px", width: "480px",background: "#FFFFFF", borderRadius: "8px", alignItems: "center", justifyContent: "center" }}>
                    {props?.datainline == 'pending' ?
                        <>
                            {props?.pendingclos == false ?
                                <div className="div_pading_data">
                                    <img src="/logo 3.svg" />
                                    <p className="des_data">
                                        Your transaction is being processed. Please do not navigate away from this screen
                                    </p>
                                    {/* <Loader_icon /> */}
                                    <img
                                        width={45}
                                        height={45}
                                        src="/Niobi Loading Animation-Cross.gif"
                                    />
                                </div>
                                :
                                <div style={{ borderRadius: "5px", background: "#FFF", width: "100%", height: "35vh", alignItems: 'center', display: "flex", justifyContent: "center" }}>
                                    <div>
                                        <div className="heding_tesyssjsj">Transaction in progress</div>

                                        <div style={{ textAlign: "center", padding: "15px 0px 0px 0px" }}>
                                            <p className="des_clos_popup">The payment is still being processed. We request for your patience as we work to complete the process.</p>
                                            <p className="des_clos_popup">In the meantime, please do not attempt making the transaction again. You can review the transaction status on the balance screen under pending transactions</p>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <Button className="balance__save-btn" style={{ marginTop: "13px" }} onClick={() => { window.location.href = '/balance' }}>Close</Button>
                                            </div>
                                        </div>

                                    </div>

                                </div>}
                        </> : props?.datainline == "success" ?
                            <div>
                                <div style={{ textAlign: "center", padding: "0px 0px 15px 0px" }}>
                                    <img
                                        width={75}
                                        height={75}
                                        src="/Niobi Loading Animation (1).gif"
                                    />
                                    {/* <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 28C0.5 20.5739 3.44999 13.452 8.70101 8.20101C13.952 2.94999 21.0739 0 28.5 0C35.9261 0 43.048 2.94999 48.299 8.20101C53.55 13.452 56.5 20.5739 56.5 28C56.5 35.4261 53.55 42.548 48.299 47.799C43.048 53.05 35.9261 56 28.5 56C21.0739 56 13.952 53.05 8.70101 47.799C3.44999 42.548 0.5 35.4261 0.5 28ZM26.9021 39.984L43.0227 19.8315L40.1107 17.5019L26.3645 34.6789L16.628 26.5664L14.2387 29.4336L26.9021 39.9877V39.984Z" fill="#025041" />
                                    </svg> */}
                                </div>
                                <div className="heding_tesyssjsj">Payment Successful </div>

                            </div>
                            : ""}
                </div>
            </div>
        </>
    )
}
export default LoaderPage_text;
const Success_icon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="57"
        height="56"
        viewBox="0 0 57 56"
        fill="none"
    >
        <g clip-path="url(#clip0_7237_14869)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.5 28C0.5 20.5739 3.44999 13.452 8.70101 8.20101C13.952 2.94999 21.0739 0 28.5 0C35.9261 0 43.048 2.94999 48.299 8.20101C53.55 13.452 56.5 20.5739 56.5 28C56.5 35.4261 53.55 42.548 48.299 47.799C43.048 53.05 35.9261 56 28.5 56C21.0739 56 13.952 53.05 8.70101 47.799C3.44999 42.548 0.5 35.4261 0.5 28ZM26.9021 39.984L43.0227 19.8315L40.1107 17.5019L26.3645 34.6789L16.628 26.5664L14.2387 29.4336L26.9021 39.9877V39.984Z"
                fill="#025041"
            />
        </g>
        <defs>
            <clipPath id="clip0_7237_14869">
                <rect width="56" height="56" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>
);