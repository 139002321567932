import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect, useState } from "react";
import { dispatchStore } from "../../../Store/store";
import { userActions } from "../../../_actions";
import {useDispatch, useSelector} from "react-redux";
import { registerSelector } from "../../../_reducers/registration.reducer";


export const VerifyEmail = ({data}:any) => {
    const [enableButton, setEnableButton] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [time, setTime] = useState(30);

console.log(data,'enableButton');
const fogotPasswordData = useSelector((state) =>
registerSelector(state)
);
console.log(fogotPasswordData, 'fogotPasswordData');

    useEffect(() => {
      setTimeout(() => {
          console.log('setTimeout', 'setTimeout');
          setEnableButton(true);
      }, 30000)
    }, []);

    if(time > 0){
      setTimeout(() => {
        setTime(time - 1)
      }, 1000)
    }
    const handleSubmit = (e: any) => {
      // console.log(e);
      setSubmitted(true);
      if (data) {
        setEnableButton(false)
        setTime(30)
        dispatchStore(userActions.forgotPassword({ email:data }) as any);
        setTimeout(() => {
          setEnableButton(true);
      }, 30000)
      }
    };

  return (
    <>
      <div className="formBx">
          <div className='register-business'>
              <span><h2>Check your email</h2></span>
          </div>
          <div className='verify-text'>
              <span><p>We sent you a message with password reset link to {data}. Please check it and don't forget to view the spam folder as well.</p></span>
          </div>
          <div className='inputBx'>
              <div className='verification-button'>
                {enableButton == false ?
                  <button 
                  disabled
                  className="form-button"
                  >Resend in {time > 0 ? time - 1 : time} Sec</button>
                 : <button 
                 onClick={handleSubmit}
                  className="form-button"
                  >Resend</button>}
              </div>

          </div>
          <div className="inputBx">
                <p>Something wrong? <a href = "mailto:support@niobi.co"> Contact Us</a>
                   {/* <Link to="../contact-us">Contact us</Link> */}
                   </p>
            </div>
      </div>
      
      </>
  )
}