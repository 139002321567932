import React from 'react';
import PageHeader from '../PageHeader';
import './ApiKey.scss';
import { Button } from 'react-bootstrap';
import { getProperties } from '../../../../_helpers';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { paymentJourneyActions } from '../../../../_actions/paymentJourney.actions';
import amplitude from 'amplitude-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import posthog from 'posthog-js';

export const CreateApiKey = () => {
  const dispatch = useDispatch();

  const [errorShow, setErrorShow] = React.useState(false);
  const [intValue, setIntValue] = React.useState('');
  const properties = getProperties();
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  const creatApi = () => {
    setErrorShow(false);
    if (!!intValue) {
      console.log('submit');
      let requestBody = {
        title: intValue,
      };
      dispatch(
        paymentJourneyActions.Create_integration_active(requestBody) as any
      );
      const event = 'Integration Created';
      const eventProperties = {
        userId:
          !!properties && !!properties.user_id ? properties.user_id : null,
        userName: !!properties && !!properties.name ? properties.name : null,
        email: !!properties && !!properties.email ? properties.email : null,
        pageUrl: window.location.href,
      };
      amplitude.getInstance().logEvent(event, eventProperties);
      posthog.capture(event, eventProperties);
    } else {
      setErrorShow(true);
    }
  };

  return (
    <>
      <div className="tags">
        <PageHeader title="Integration" />
        <Button
          id="button_back"
          style={{ color: '#025141', marginLeft: '48px' }}
          onClick={() => {
            window.location.href = '/settings/apikey';
          }}
          className="payment-journey-container__detailbox__white-btn"
        >
          <ThemeProvider theme={theme}>
            <ArrowBackIcon style={{ fontSize: '19px', marginRight: '10px' }} />
          </ThemeProvider>
          Go Back
        </Button>
        <div className="create_api_box">
          <div className="inside_sub_box">
            <p className="heading_create_api">Create new integration</p>
            <div className={'input_box'}>
              <p className={'input_lable'}>Integration Name</p>
              <input
                className={'input_box'}
                onChange={(e) => {
                  var value = e.target.value;
                  if (!!value) {
                    setErrorShow(false);
                  } else {
                    setErrorShow(true);
                  }
                  setIntValue(value);
                }}
              />
            </div>
            {errorShow ? (
              <div
                style={{ color: 'red', textAlign: 'left', marginTop: '5px' }}
              >
                Please enter an integration name
              </div>
            ) : (
              ''
            )}
            <Button
              color="#025041"
              onClick={() => creatApi()}
              className="tags__save-btn"
              style={{
                margin: '0px',
                marginTop: '30px',
              }}
            >
              Create Integration
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
