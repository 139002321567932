import { Box, styled } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';


const SkeletonCom = ({ loader, element }: any) => {

    return (
        <>
            {loader ?
                <Skeleton variant="rectangular" 
                // width={'90%'}
                sx={{textAlign:"center"}}
                >
                    <Box sx={{width:"90%"}}>
                    {element}
                    </Box>
                </Skeleton> :
                <>
                    {element}
                </>}
        </>
    )
}
export default SkeletonCom;