import styles from './Create_Bill.module.scss';
import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Button, InputGroup, Nav, Row, Tab, Tabs } from "react-bootstrap";
import DatePicker from "react-date-picker";
//import DatePicker from "./Datepicker";
import Multiselect from "multiselect-react-dropdown";
import { selectAllCounterpartsListData } from "../../../../_reducers/counterparts.reducer";
import { getUser, isUserAuthenticated } from "../../../../_helpers";
import { getBase64, getKESFormate } from "../../../../_helpers/common";
import { counterpartsActions } from "../../../../_actions/counterparts.actions";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { payablesActions } from "../../../../_actions/payables.actions";
import { Select } from "@team-monite/ui-kit-react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, ErrorMessage, useField, useFormikContext } from "formik";
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { selectAllPayablesListData } from "../../../../_reducers/payables.reducer";
import { settingActions } from "../../../../_actions/setting.actions";
import { settingSelector } from "../../../../_reducers/setting.reducer";
import moment from "moment";
import amplitude from 'amplitude-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { handleFileUpload } from '../../../utils';
interface Props {
    onClos: any,
}
export const Create_Bill = (props: Props) => {
    let navigate = useNavigate();

    const [value, onChange] = useState(new Date());
    const [options, setOptions] = useState([
        { name: "Option 1️", id: 1 },
        { name: "Option 2️", id: 2 },
    ]);
    const commonStaticFASTTT = {
        certi_of_incorpo: "{type}",
        certi_of_compliance: "{type}",
        CR: "{type}",
        KRA: "{type}",
        id: null
    }
    const [submitted, setSubmitted] = useState(false)
    const directorStatic = {
        tax: '',
        Unit_Price: "0",
        name: "",
        quantity: "0",
        description: "",
        total: 0
    }
    const directorStatic_TWO = {
        tax: '',
        name: "",
        quantity:0,
        description: "",
        total:0
    }
    const [users, setUsers] = useState<any>();
    const [invoiceImage, setinvoiceImage] = useState<any>('');
    const [invoicefile, setinvoicefile] = useState('Upload supporting documents for record');
    const [loading, setLoading] = useState(false);
    const [directors, setDirectors] = useState([directorStatic]);
    const [directors_two, setDirectors_two] = useState([directorStatic_TWO]);
    const [dataadd, setDataAdd] = useState(false)
    const [isUsers, isSetUsers] = useState<any>(true);
    const [counterpartsList, setCounterpartsList] = useState<any>();
    const [counterpartsListRender, setCounterpartsListRender] = useState(false);
    const [counterpartsListRender_two, setCounterpartsListRender_two] = useState(true);

    const [render, setRender] = useState(true);
    const counterpartsListData = useSelector((state) => selectAllCounterpartsListData(state))
    const [tagsList, setTagsList] = useState<any>([]);
    const [tagsListRender, setTagsListRender] = useState(true);
    const [newPayee, setNewPayee] = useState(false);
    const [selectedList, setSelectedList] = useState<any>([]);
    const dispatch = useDispatch()
    const [invoiceDate, setInvoiceDate] = useState<any>(new Date())
    const [dueDate, setDueDate] = useState<any>(new Date())
    const [btnType, setBtnType] = useState('submit')
    const [itemRender, setItemRender] = useState(true);
    const [item, setItem] = useState<any>();
    const [authoriseTransaction, setAuthoriseTransaction] = useState(false);
    console.log(item, 'itemitem');
    const [requestRender, setrequestRender] = useState(false);
    const [responseRender, setresponseRender] = useState(false);
    const [payeename, setpayeename] = useState('');
    const [userRender, setUserRender] = useState(true);
    const [userDetail, setUserDetail] = useState<any>();
    const [day_name_add, setDay_name_add] = useState("")
    const [month_name_add, setMonth_name_add] = useState("")
    const [date_number_add, setDate_number_add] = useState("")
    const [categoriesError, setCategoriesError] = useState<any>(false);
    const [payablesListRender, setPayablesListRender] = useState<any>(false);
    const [fileSize, setFileSize] = useState<any>('');
    const [new_select, setNew_select] = useState("Weekly")
    const [inputList, setInputList] = useState([{ firstName: "", lastName: "" }]);
    const [inputlfast, setInputListfast] = useState<any>(commonStaticFASTTT)
    const [vatall, setVatall] = useState([])
    const [data_total, setDataTotal] = useState(0)
    const [month_name, setMonth_name] = useState([
        { name: "January" },
        { name: "February" },
        { name: "March" },
        { name: "April" },
        { name: "May" },
        { name: "June" },
        { name: "July" },
        { name: "August" },
        { name: "September" },
        { name: "October" },
        { name: "November" },
        { name: "December" }
    ])
    console.log(directors_two, 'directors_twogxfdhh');
    const handleclosh = () => {
        props.onClos()
    }
    const [select_flow, setSelect_flow] = useState([
        { name: "Weekly" },
        { name: "Monthly" },
        { name: "Yearly" },
    ])
    const [dayname, setDayname] = React.useState([
        { name: "Sunday" },
        { name: "Monday" },
        { name: "Tuesday" },
        { name: "Wednesday" },
        { name: "Thursday" },
        { name: "Friday" },
        { name: "Saturday" }
    ])
    const [date_text, setDate_text] = React.useState([
        { name: "1" },
        { name: "2" },
        { name: "3" },
        { name: "4" },
        { name: "5" },
        { name: "6" },
        { name: "7" },
        { name: "8" },
        { name: "9" },
        { name: "10" },
        { name: "11" },
        { name: "12" },
        { name: "13" },
        { name: "14" },
        { name: "15" },
        { name: "16" },
        { name: "17" },
        { name: "18" },
        { name: "19" },
        { name: "20" },
        { name: "21" },
        { name: "22" },
        { name: "23" },
        { name: "24" },
        { name: "25" },
        { name: "26" },
        { name: "27" },
        { name: "28" },
        { name: "29" },
        { name: "30" },
        { name: "31" },
    ])
    const [submit_button, setSubmit_button] = useState(false)
    const [totalVat, setTotelVat] = useState(0)
    const userD = getUser()?.data ?? null;
    const dateformet = new Date();
    console.log(Number(totalVat), Number(data_total), directors, Number(data_total) / Number(totalVat), "totalVattotalVat");

    const handleAddClick2 = () => {
        setInputList([...inputList, { firstName: "", lastName: "" }]);
        // setShareholdertwo([...shareholdertwo, shareHolderStatic])

        setDirectors([...directors, directorStatic])
        setDirectors_two([...directors_two, directorStatic_TWO])
        // setShareHoldersDocs([...ShareHolderdoc, directorDocStaticTwo])
        // setGroupTwoName([...groupTwoName, commonStatiTwo])

    };
    const handleRemoveClick = (index: any) => {
        console.log(index);
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
        /* for name */

        const listName = [...directors];
        listName.splice(index, 1);
        const listName_two = [...directors_two];
        listName_two.splice(index, 1);
        setDirectors_two(listName_two)
        setDirectors(listName)
    }
    const [submittedData, setSubmittedData] = useState<any>({
        invoice_record: '',
        document_id: '',
        payment_date: '',
        amount: '0',
        supplier_id: '',
        submitted_by: '',
    });
    const [totelamount, setTotelAmount] = useState(0)

    console.log(counterpartsListData, 'counterpartsListData');

    const handleSubmit = (e: any) => {

        const { name, value }: any = e.target;

        setSubmittedData({ ...submittedData, [name]: value, 'submitted_by': userDetail?.email })

        // const request = {...submittedData,...invoiceDate,...dueDate}
    }
    //   const onHide = () => {
    //     props.props.setIsOpen(false);
    //   };

    const changeTohandle = (e: any) => {
        navigate("/Counterparts?popup=" + e.target.value, { replace: true, state: { popup: e.target.value } });
        // if(e.target.value == 0){
        //   setType(CounterpartType.ORGANIZATION)
        // }elsee.target.value
        //   setType(CounterpartType.INDIVIDUAL)
        // }

    }
    const balancelistData = useSelector((state) =>
        selectAllPayablesListData(state)
    );
    const userData = useSelector((state) => settingSelector(state))

    console.log(balancelistData, 'balancelistDatabalancelistData');
    useEffect(() => {
        if (requestRender && balancelistData?.PAYABLES_REQUEST == true) {
            setLoading(true);
            setrequestRender(false)
            setresponseRender(true);
        }
    }, [balancelistData, requestRender]);
    console.log(balancelistData?.PAYABLES_REQUEST, 'balancelistData?.PAYABLES_REQUEST');

    React.useEffect(() => {
        if (userRender && userData?.GETUSERDETAIL_SUCCESS !== undefined) {
            setUserRender(false);
            setUserDetail(userData?.GETUSERDETAIL_SUCCESS);
        }
    }, [userData, userRender]);

    useEffect(() => {
        if (responseRender && counterpartsListData?.SUBMIT_PAYABLES_REQUEST == false) {
            setLoading(false);
            setresponseRender(false);
        }
    }, [counterpartsListData, responseRender]);

    useEffect(() => {
        if (payablesListRender && balancelistData?.PAYABLES_SUCCESS !== undefined) {
            console.log(balancelistData?.PAYABLES_SUCCESS, 'balancelistData?.PAYABLES_SUCCESS')
            setPayablesListRender(false)
            const bodyData = {
                payables: [balancelistData?.PAYABLES_SUCCESS.id]
            }
            dispatch(counterpartsActions.submitPaybles(bodyData) as any)
        }
    }, [balancelistData, payablesListRender]);
    const handleImage = async (e: any) => {
        setFileSize(e.target.files[0].size)
        setinvoicefile(e.target.files[0].name);
        const fileBase64 = await handleFileUpload(e)
        setinvoiceImage(fileBase64)
    }

    useEffect(() => {
        if (getUser && isUsers) {
            isSetUsers(false);
            const bodyReq = {
                id: getUser()?.data.user_id
            }
            dispatch(settingActions.getUserDetail(bodyReq,"no") as any)
        }
    }, [isUsers, getUser]);

    useEffect(() => {
        const inVoiceObj = { invoice_date: invoiceDate.toISOString().substring(0, 10) }
        const dueDateObj = { due_date: dueDate.toISOString().substring(0, 10) }
        const imageObj = { invoice_record: invoiceImage }
        const req = [submittedData, inVoiceObj, dueDateObj, imageObj];
        const finalRequest = req.reduce(function (result: any, current) {
            return Object.assign(result, current);
        }, {});
        const tags = selectedList?.map((item: any) => {
            return item.id;
        });

        const finalArrayRequest = { ...finalRequest, tags: tags };

        // console.log('finalArrayRequest: ', finalArrayRequest);

        // if (props.props.isApiData){
        //   dispatch(payablesActions.create(finalArrayRequest) as any)
        //   props.props.setIsAPIData(false)
        //   props.props.setIsOpen(false)
        // }

        // if (props.props.isPayApiData) {
        //   dispatch(payablesActions.createPay(finalArrayRequest) as any)
        //   props.props.setIsPayAPIData(false)
        //   props.props.setIsOpen(false)
        // }
    }, [submittedData, invoiceDate, dueDate, selectedList, invoiceImage])

    // console.log(submittedData, 'submittedData')
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });

    useEffect(() => {
        if (isUserAuthenticated() && render) {
            setRender(false);
            dispatch(counterpartsActions.getAllCounterparts(setCounterpartsListRender) as any)
            dispatch(counterpartsActions.getAllTags() as any)
            dispatch(counterpartsActions.getAllvat() as any)
        }
    }, [render, isUserAuthenticated])
    useEffect(() => {
        if (counterpartsListRender && counterpartsListData?.GETCOUNTERPARTS_SUCCESS !== undefined) {
            setCounterpartsListRender(false);
            setCounterpartsList(counterpartsListData?.GETCOUNTERPARTS_SUCCESS);
        }
    }, [counterpartsListData, counterpartsListRender]);

    useEffect(() => {
        if (counterpartsListRender_two && counterpartsListData?.GETALLVAT_SUCCESS !== undefined) {
            setCounterpartsListRender_two(false);
            setVatall(counterpartsListData?.GETALLVAT_SUCCESS);
        }
    }, [counterpartsListData, counterpartsListRender_two]);
    useEffect(() => {
        let datalist: any = 0;
        let vattotal: any = 0;
        let totalamount: any = 0;
        for (let index = 0; index < directors.length; index++) {
            const element = directors[index];
            var vat = element.tax == "Exempt" ? 0 : element.tax
            console.log(+element.tax * +element.total / 100, 'elementelementhhh');
            datalist += +element.total / (1 + (Number(vat) / 100));
            vattotal += +vat;
            totalamount += +element.total

        }
        setDataTotal(datalist);
        setTotelAmount(totalamount)
        setTotelVat(vattotal);
        console.log(datalist, vattotal, 'datalist');

    }, [directors])
    useEffect(() => {
        if (tagsListRender && counterpartsListData?.GETTAGS_SUCCESS !== undefined) {
            let options: any = [];
            if (counterpartsListData.GETTAGS_SUCCESS.length > 0) {
                counterpartsListData.GETTAGS_SUCCESS.map((item: any, ind: number) => {
                    options.push({
                        id: item.id,
                        name: item.name
                    })
                })
            }
            setTagsListRender(false);
            setTagsList(options);
        }
    }, [counterpartsListData, tagsListRender]);

    const onSelect = (selectedList: any) => {
        setSelectedList(selectedList)
    };

    const getBase64 = (file: any, cb: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
    const validationSchema = Yup.object().shape({
        supplier_id: Yup.string()
            // .min(2, "*Names must have at least 2 characters")
            // .max(100, "*Names can't be longer than 100 characters")
            .required("Please select the payee's name."),
        document_id: Yup.string().required("Please enter the invoice number."),
        invoice_date: Yup.string().required("Please enter the invoice date."),
        due_date: Yup.string().required("Please provide the due date."),
        select_flow: Yup.string().required("Please select the frequency interval."),
        amount: Yup.string().required("Please enter the amount."),
        // quantity: Yup.string().required("Please enter the quantity"),
        end_date: Yup.string().required("Please select an end date."),
        categories: Yup.string().required("Please select the category."),
        invoice_record: Yup.mixed()
            .nullable()
            .notRequired()
            .test(
                "file Size",
                "File size too large, max file size is 1 Mb",
                () => {
                    let valid = true;
                    if (!!fileSize) {
                        if (1024 * 1024 <= fileSize) {
                            valid = false;
                        }
                    }
                    return valid;
                }
            )
        // blog: Yup.string()
        // .url("*Must enter URL in http://www.example.com format")
        // .required("*URL required")
    });

    const [selectedValue, setSelectedValue] = useState(new Date());
    const [field, setField] = useState<any>([]);
    const [dataone, setdateone] = useState('')
    const numberMask = createNumberMask({
        prefix: '',
        suffix: '' // This will put the dollar sign at the end, with a space.
    })

    console.log(!!data_total && !!totalVat ? (data_total / totalVat) : 0, data_total, totalVat, 'userDetail?.name');
    return (
        <>
            <div className={styles.contenar_in_main}>
                <div>
                    <p className={styles.heding_data_main_key}>Create Recurring Bill</p>
                </div>
                <Formik
                    initialValues={{
                        payment_date: '',
                        due_date: "",
                        submitted_by: '',
                        supplier_id: "",
                        invoice_record: "",
                        invoice_date: moment(dateformet).format('YYYY-MM-DD'),
                        document_id: "",
                        categories: "",
                        select_flow: "",
                        // dayformeat: "",
                        end_date: "",
                        amount: "0",
                        // numbar_date: "",
                        // month: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {

                        console.log(values, 'values.supplier_id')
                        const selected = counterpartsList.find((item: any) => {
                            return item.monite_counterpart_id == values.supplier_id
                        })
                        const payeeName = selected.counterpart_type == 'individual' ? selected.contact_first_name + ' ' + selected.contact_last_name : selected.name
                        console.log(selected, 'counterpathcounterpath');


                        const findAmount = directors.find((data: any) => data.Amount == '')

                        const findItem_name = directors.find((data: any) => data.name == '')
                        const findQuantity = directors.find((data: any) => data.quantity == ''||data.quantity == '0')
                        const findUnit_Price = directors.find((data: any) => data.Unit_Price == ''||data.Unit_Price == '0')
                        const findVat = directors.find((data: any) => data.tax == '')
                        const findDescription = directors.find((data: any) => data.description == '')
                        console.log(!findItem_name && !findQuantity && !findUnit_Price && !findAmount,findItem_name,findUnit_Price,findQuantity,findAmount, 'findItem_name');

                        if (!findItem_name && !findQuantity && !findUnit_Price && !findAmount && new_select == "Weekly" && !!day_name_add) {

                            const body = {
                                "counterpart_id": selected.monite_counterpart_id,
                                "counterpart_name": payeeName,
                                "submitted_by": userDetail?.email,
                                amount: (+totelamount * 100).toString(),
                                "document_id": values.document_id,
                                "due_date": values.due_date,
                                "invoice_date": values.invoice_date,
                                "invoice_record": invoiceImage,
                                tags: selectedList?.map((item: any) => {
                                    return item.id;
                                }),
                                "line_items": directors_two,
                                "type": values.select_flow,
                                "day": day_name_add,
                                // "every": 1,
                                // "month": "August",
                                "end_date": values.end_date
                            }


                            // dispatch(payablesActions.recurring_create_bill(body) as any)
                            console.log('susess1');

                            setLoading(true);
                            setrequestRender(true)
                            setSubmitting(true);
                            console.log(body, 'shghsgsgfsffsddf');
                            if (btnType == "save") {
                                dispatch(payablesActions.recurring_create_bill(body) as any)

                            } else {
                                dispatch(payablesActions.recurring_submit_bill(body) as any)

                            }
                        } else if (!findItem_name && !findQuantity && !findUnit_Price && !findAmount && new_select == "Monthly" && !!date_number_add) {

                            const body = {
                                "counterpart_id": selected.monite_counterpart_id,
                                "counterpart_name": payeeName,
                                "submitted_by": userDetail?.email,
                                "amount": +data_total * 100,
                                "document_id": values.document_id,
                                "due_date": values.due_date,
                                "invoice_date": values.invoice_date,
                                "invoice_record": invoiceImage,
                                tags: selectedList?.map((item: any) => {
                                    return item.id;
                                }),
                                "line_items": directors_two,
                                "type": values.select_flow,

                                "every": +date_number_add,
                                // "month": "August",
                                "end_date": values.end_date
                            }
                            console.log("susess");
                            setLoading(true);
                            setrequestRender(true)
                            setSubmitting(true);
                            if (btnType == "save") {
                                dispatch(payablesActions.recurring_create_bill(body) as any)

                            } else {
                                dispatch(payablesActions.recurring_submit_bill(body) as any)

                            }
                        } else if (!findItem_name && !findQuantity && !findUnit_Price && !findAmount && new_select == "Yearly" && !!date_number_add && !!month_name_add) {
                            const body = {
                                "counterpart_id": selected.monite_counterpart_id,
                                "counterpart_name": payeeName,
                                "submitted_by": userDetail?.email,
                                "amount": +data_total * 100,
                                "document_id": values.document_id,
                                "due_date": values.due_date,
                                "invoice_date": values.invoice_date,
                                "invoice_record": invoiceImage,
                                tags: selectedList?.map((item: any) => {
                                    return item.id;
                                }),
                                "line_items": directors_two,
                                "type": values.select_flow,
                                "every": +date_number_add,
                                "month": month_name_add,
                                "end_date": values.end_date
                            }

                            console.log("susess");
                            setLoading(true);
                            setrequestRender(true)
                            setSubmitting(true);
                            if (btnType == "save") {
                                dispatch(payablesActions.recurring_create_bill(body) as any)

                            } else {
                                dispatch(payablesActions.recurring_submit_bill(body) as any)

                            }
                        } else {

                        }
                        // @ts-ignore


                        console.log(selected, 'selected')

                    }}
                >
                    {({ values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting }) => (
                        <>

                            {console.log(new_select, values,errors,directors, 'values')}
                            <Form onSubmit={handleSubmit} >
                                <div className={styles.displya_data_fastdiv}>
                                    <div className={styles.fast_button_and_input}>
                                        <div style={{ width: "100%", margin: "10px 0px" }} className="input-form-wrapper dot">
                                            <label> Payee's name</label>
                                            <div className={styles.maihagaygajnjaauh} style={{ display: "flex", alignItems: "center" }}>
                                                <Form.Select name="supplier_id"
                                                    style={{ width: "70%" }}
                                                    //onChange={handleSubmit}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.supplier_id}
                                                    className={touched.supplier_id && errors.supplier_id ? " doc-form-input error" : 'doc-form-input'}
                                                >
                                                    <option selected value="">Select payee</option>
                                                    {counterpartsList?.map((arrayElement: any, index: number) => {
                                                        return (
                                                            <option value={arrayElement.monite_counterpart_id} key={index + 1}>{arrayElement.counterpart_type == 'individual' ? arrayElement.contact_first_name + ' ' + arrayElement.contact_last_name : arrayElement.name}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                                <Button style={{ padding: "14px 8px" }} className={styles.add_button_thisss}
                                                    onClick={() => {
                                                        navigate("/create-counterparts")
                                                        setNewPayee(!newPayee)
                                                    }}>
                                                    + Add payee
                                                </Button>
                                            </div>
                                            {touched.supplier_id && errors.supplier_id ? (
                                                <div className="error-message">{errors.supplier_id}</div>
                                            ) : null}

                                        </div>
                                        {/* {newPayee ?
                                            <div className="input-form-wrapper dot">
                                                <Form.Select name="new_supplier_id" className="doc-form-input mb-20"
                                                    onChange={changeTohandle}
                                                    //onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.new_supplier_id}
                                                >
                                                    <option selected>Select Payee Type to Add</option>
                                                    <option value={0} key={0}>Organization</option>
                                                    <option value={1} key={1}>Individual</option>
                                                </Form.Select>
                                            </div> :
                                            " "} */}
                                    </div>
                                    <div className={styles.fast_button_and_input}>
                                        <label>Attach an invoice for record</label>
                                        <ThemeProvider theme={theme}>
                                            <Tooltip title="Upload Documents (File-size: Max 1 MB. File-type: jpeg, png or pdf)">
                                                <label className='complete-profile__upload mb-0'>
                                                    <div
                                                        id='afafafsdaFfsf'
                                                        className='complete-profile__upload__label' >
                                                        <span className='complete-profile__upload__txt'>{invoicefile}</span>
                                                        <UploadIcon />
                                                    </div>
                                                    <input type="file" name='invoice_record'
                                                        // disabled
                                                        onChange={handleImage}
                                                        className={touched.invoice_record && errors.invoice_record ? "  error" : ''}

                                                        //onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.invoice_record} />
                                                    {touched.invoice_record && errors.invoice_record ? (
                                                        <div className="error-message">{errors.invoice_record}</div>
                                                    ) : null}
                                                </label>
                                            </Tooltip>
                                        </ThemeProvider>
                                    </div>
                                </div>
                                <div className={styles.displya_data_fastdiv}>
                                    <div className={styles.fast_button_and_input}>
                                        <div style={{ margin: "10px 0px" }} className="input-form-wrapper dot">
                                            <label>Invoice number</label>
                                            <InputGroup className="doc-input-wrapper">
                                                <Form.Control
                                                    className={touched.document_id && errors.document_id ? " doc-form-input error" : 'doc-form-input test valid'}
                                                    //className="doc-form-input"
                                                    placeholder="Invoice number"
                                                    value={values.document_id}
                                                    //onChange={handleSubmit}
                                                    name='document_id'
                                                    type="text"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                // value="24.06.22"

                                                />
                                            </InputGroup>
                                            {touched.document_id && errors.document_id ? (
                                                <div className="error-message">{errors.document_id}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className={styles.fast_button_and_input}>
                                        <div style={{ width: "100%", margin: "10px 0px" }}
                                            className="dot"
                                        >
                                            <label className="Category_label">Category</label>
                                            <ThemeProvider theme={theme}>
                                                <Autocomplete
                                                    sx={{
                                                        "& .MuiInputBase-root": {
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            background: '#FFFFFF',
                                                            border: '1px solid #DDDDDD',
                                                            outline: 'none',
                                                            fontStyle: 'normal',
                                                            fontWeight: 500,
                                                            fontSize: '12px',
                                                            color: '#111111',
                                                            borderRadius: '8px',
                                                            padding: '6px 9px'
                                                        }
                                                    }}
                                                    multiple
                                                    limitTags={2}
                                                    id="multiple-limit-tags"
                                                    options={tagsList}
                                                    value={selectedList}
                                                    getOptionLabel={(option: any) => option.name}
                                                    //   defaultValue={selectedValue}
                                                    onChange={(e: any, newValue: any) => {
                                                        console.log(e.target.value, newValue, "eee123");

                                                        // setCategoriesError(false)
                                                        if (newValue.length != 0) {
                                                            values.categories = "values";
                                                            errors.categories = '';
                                                            setCategoriesError(false);
                                                            setSelectedList(newValue);
                                                        } else {
                                                            values.categories = "";
                                                            setSelectedList(newValue);
                                                            setCategoriesError(true);
                                                        }

                                                        // setSelectedList(newValue)
                                                    }}
                                                    renderInput={(params: any) => (
                                                        <TextField {...params} />
                                                    )}
                                                // sx={{ width: '500px' }}
                                                />
                                            </ThemeProvider>
                                            {/* <label>Categories</label> */}
                                            {/* <ThemeProvider theme={theme}>
                      <Autocomplete
                        multiple
                        limitTags={2}
                        id="multiple-limit-tags"
                        options={tagsList}
                        getOptionLabel={(option:any) => option.name}
                        // defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                        renderInput={(params:any) => (
                          <TextField {...params} label="limitTags" placeholder="Favorites" />
                        )}
                        // sx={{ width: '500px' }}
                      />
                      </ThemeProvider> */}
                                            {/* <Multiselect
                        options={tagsList} // Options to display in the dropdown
                        selectedValues={selectedValue} // Preselected value to persist in dropdown
                        onSelect={(selectedList: any) => {
                          values.categories = "values";
                          errors.categories = undefined;
                          setCategoriesError(false);
                          setSelectedList(selectedList);
                        }} // Function will trigger on select event
                        onRemove={(selectedList: any) => {
                          if (selectedList.length == 0) {
                            values.categories = "";
                            setCategoriesError(true);
                          }
                        }}
                        displayValue="name" // Property name to display in the dropdown options
                        closeOnSelect={true}
                      /> */}
                                            {submit_button == true && errors.categories ? (
                                                <div className="error-message">Please select the category</div>
                                            ) : null}
                                        </div>
                                        {/* <div style={{ margin: "10px 0px" }} className="input-form-wrapper dot">
                                            <label>Categories</label>
                                            <Multiselect
                                                options={tagsList} // Options to display in the dropdown
                                                selectedValues={selectedValue} // Preselected value to persist in dropdown
                                                onSelect={(selectedList: any) => {
                                                    values.categories = 'values'
                                                    errors.categories = undefined
                                                    setCategoriesError(false)
                                                    setSelectedList(selectedList)
                                                }} // Function will trigger on select event
                                                onRemove={(selectedList: any) => {
                                                    if (selectedList.length == 0) {
                                                        values.categories = ''
                                                        setCategoriesError(true)
                                                    }
                                                }}
                                                displayValue="name" // Property name to display in the dropdown options
                                                closeOnSelect={true}
                                            />
                                            {categoriesError || errors.categories ? (
                                                <div className="error-message">Category is required</div>
                                            ) : null}
                                        </div> */}
                                    </div>
                                </div>
                                <div className={styles.displya_data_fastdiv}>
                                    <div className={styles.fast_button_and_input}>
                                        <div style={{ margin: "10px 0px" }} className="input-form-wrapper dot">
                                            <label>Invoice date</label>
                                            <input type='date'
                                                className="doc-date doc-form-input form-control"
                                                // onChange={setInvoiceDate}
                                                value={values.invoice_date}
                                                // defa="dd-MMM-yyyy"
                                                // dateFormat="dd-MMM-yyyy"

                                                name='invoice_date'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {touched.invoice_date && errors.invoice_date ? (
                                                <div className="error-message">{errors.invoice_date}</div>
                                            ) : null}
                                        </div>

                                    </div>
                                    <div className={styles.fast_button_and_input}>
                                        <div style={{ margin: "10px 0px" }} className="input-form-wrapper dot">
                                            <label>Due date</label>
                                            <input type="date"
                                                className="doc-date doc-form-input form-control"
                                                // onChange={setInvoiceDate}
                                                value={values.due_date}
                                                // format="yyyy-MM-dd"
                                                name='due_date'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {touched.due_date && errors.due_date ? (
                                                <div className="error-message">{errors.due_date}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                </div>
                                <div style={{margin: "0" }} className="input-form-wrapper dot">
                                    <label >Recurring intervals</label>
                                </div>
                                <div className={styles.manin_loop_collam}>
                                    <div className={styles.displya_data_fastdiv}>
                                        <div className={styles.main_contean_data_inthis}>
                                            <div style={{ width: "31%" }}>
                                                <label></label>
                                                <Form.Select style={{ fontSize: '11px' }}
                                                    name="select_flow"
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        setNew_select(e.target.value)
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.select_flow}
                                                    className={'doc-form-input'}
                                                >
                                                    <option selected value="">Select interval</option>
                                                    {select_flow?.map((arrayElement: any, index: number) => {
                                                        return (
                                                            <option value={arrayElement.name} key={index + 1}>{arrayElement.name}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                                {touched.select_flow && errors.select_flow ? (
                                                    <div className="error-message">{errors.select_flow}</div>
                                                ) : null}


                                            </div>
                                            <p className={styles.main_div_no_text}>on</p>
                                            {new_select == 'Weekly' ?
                                                <div style={{ width: "60%" }}>
                                                    <label></label>

                                                    <Form.Select style={{ fontSize: '11px' }}

                                                        onChange={(e) => { setDay_name_add(e.target.value) }}
                                                        onBlur={handleBlur}
                                                        value={day_name_add}
                                                        className={'doc-form-input'}
                                                    >
                                                        <option selected value="">Day</option>
                                                        {dayname?.map((arrayElement: any, index: number) => {
                                                            return (
                                                                <option value={arrayElement.name} key={index + 1}>{arrayElement.name}</option>
                                                            )
                                                        })}
                                                    </Form.Select>

                                                    {!day_name_add && dataadd == true ? (
                                                        <div className="error-message">Please select a day.</div>
                                                    ) : null}

                                                </div> : ""}
                                            {new_select == 'Monthly' ?
                                                <div style={{ paddingLeft: "5px", width: "60%" }}>
                                                    <label></label>

                                                    <Form.Select
                                                        style={{ fontSize: '11px' }}
                                                        onChange={(e) => { setDate_number_add(e.target.value) }}
                                                        onBlur={handleBlur}
                                                        value={date_number_add}
                                                        className={'doc-form-input'}
                                                    >
                                                        <option selected value="">Select Date</option>
                                                        {date_text?.map((arrayElement: any, index: number) => {
                                                            return (
                                                                <option value={arrayElement.name} key={index + 1}>{arrayElement.name}</option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                    {!date_number_add && dataadd == true ? (
                                                        <div className="error-message">Please select the date</div>
                                                    ) : null}
                                                </div> : ""}
                                            {new_select == 'Yearly' ?
                                                <>
                                                    <div style={{ width: "31%" }}>
                                                        <label></label>
                                                        <Form.Select
                                                            style={{ fontSize: '11px' }}
                                                            name="month"
                                                            onChange={(e: any) => { setMonth_name_add(e.target.value) }}
                                                            onBlur={handleBlur}
                                                            value={month_name_add}
                                                            className={'doc-form-input'}
                                                        >
                                                            <option selected value="">Monthly</option>
                                                            {month_name?.map((arrayElement: any, index: number) => {
                                                                return (
                                                                    <option value={arrayElement.name} key={index + 1}>{arrayElement.name}</option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                        {!month_name_add && dataadd == true ? (
                                                            <div className="error-message">Please select the month </div>
                                                        ) : null}


                                                    </div>
                                                    <div style={{ paddingLeft: "5px", width: "31%" }}>
                                                        <label></label>

                                                        <Form.Select
                                                            style={{ fontSize: '11px' }}
                                                            name="numbar_date"
                                                            onChange={(e) => { setDate_number_add(e.target.value) }}
                                                            onBlur={handleBlur}
                                                            value={date_number_add}
                                                            className={'doc-form-input'}
                                                        >
                                                            <option selected value="">Select date</option>
                                                            {date_text?.map((arrayElement: any, index: number) => {
                                                                return (
                                                                    <option value={arrayElement.name} key={index + 1}>{arrayElement.name}</option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                        {!date_number_add && dataadd == true ? (
                                                            <div className="error-message">Please select the date</div>
                                                        ) : null}
                                                    </div>
                                                </> : ""}
                                        </div>
                                        <div className={styles.main_contean_data_inthis}>
                                            <div style={{ margin: "0px 0px", width: "100%" }} className="input-form-wrapper dot">
                                                <label>End date</label>
                                                <input type="date"
                                                    className="doc-date doc-form-input form-control"
                                                    // onChange={setInvoiceDate}
                                                    value={values.end_date}
                                                    // format="yyyy-MM-dd"
                                                    name='end_date'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {touched.end_date && errors.end_date ? (
                                                    <div className="error-message">{errors.end_date}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ paddingTop: "10px" }}>
                                    {directors.map((example, i: any) => {
                                        return (
                                            <>
                                                <div className={styles.main_contenar_lineeee} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "15px" }}>
                                                    <label>Line item</label>

                                                    {i > 0 ? <Button style={{ padding: "0px" }} className='complete-profile__removebtn' onClick={() => { handleRemoveClick(i) }}>Remove</Button> : ''}
                                                </div>
                                                <div style={{ display: "flex" }} className={styles.manin_loop_collam}>
                                                    <div id={styles.main_input_datatatata} style={{ margin: "0px 0px", width: "17%" }} className="input-form-wrapper dot">
                                                        <label>Item name</label>
                                                        <InputGroup className="doc-input-wrapper">
                                                            <Form.Control
                                                                className={'doc-form-input test valid'}
                                                                placeholder="Item name"
                                                                value={example.name}
                                                                //onChange={handleSubmit}
                                                                name='Item_name'
                                                                type="text"
                                                                onChange={(e: any) => {
                                                                    console.log(e.target.value, 'eeeeee');
                                                                    const arrrr: any = []
                                                                    const newarr: any = []
                                                                    for (let index = 0; index < directors.length; index++) {
                                                                        const element = directors[index];

                                                                        if (i == index) {
                                                                            element.name = e.target.value
                                                                            arrrr.push(element)
                                                                        } else {
                                                                            arrrr.push(element)
                                                                        }
                                                                    }
                                                                    for (let index = 0; index < directors_two.length; index++) {
                                                                        const element = directors_two[index];

                                                                        if (i == index) {
                                                                            element.name = e.target.value
                                                                            newarr.push(element)
                                                                        } else {
                                                                            newarr.push(element)
                                                                        }
                                                                    }
                                                                    setDirectors_two(newarr)
                                                                    setDirectors(arrrr)

                                                                }}
                                                            />
                                                        </InputGroup>
                                                        {!example.name && dataadd == true ?
                                                            <div className="error-message">Please enter an item name.</div>
                                                            : null}
                                                    </div>
                                                    <div id={styles.main_input_datatatata} style={{ margin: "0px 0px", width: "15%", paddingLeft: "5px" }} className="input-form-wrapper dot">
                                                        <label>Unit price</label>
                                                        <InputGroup className="doc-input-wrapper input" style={{ padding: 0 }}>
                                                            <MaskedInput
                                                                mask={numberMask}
                                                                style={{ height: '100%' }}
                                                                className="doc-form-input form-control"
                                                                placeholder="Unit Price"
                                                                value={(example.Unit_Price)}
                                                                //onChange={handleSubmit}
                                                                name='Unit_Price'
                                                                onChange={(e: any) => {
                                                                    const kesValue = getKESFormate(e.target.value);

                                                                    const arrrr: any = []
                                                                    const newarr: any = []
                                                                    let amount_data: any = 0;
                                                                    for (let index = 0; index < directors.length; index++) {
                                                                        const element = directors[index];
                                                                        // const newdata = directors;

                                                                        if (i == index) {
                                                                            element.Unit_Price = e.target.value.replaceAll(',', '')
                                                                            element.total = (+e.target.value.replaceAll(',', '') * +example.quantity)
                                                                            // amount_data += +(+e.target.value.replaceAll(',', '') * +example.quantity).toString()
                                                                            arrrr.push(element)
                                                                        } else {
                                                                            arrrr.push(element)
                                                                        }
                                                                    }
                                                                    for (let index = 0; index < directors_two.length; index++) {
                                                                        const element = directors_two[index];

                                                                        if (i == index) {

                                                                            element.total = (+e.target.value.replaceAll(',', '')) * 100
                                                                            newarr.push(element)
                                                                        } else {
                                                                            newarr.push(element)
                                                                        }
                                                                    }
                                                                    setDirectors_two(newarr)
                                                                    // setDataTotal(amount_data)
                                                                    // setDirectors_two(arrrr)
                                                                    setDirectors(arrrr)
                                                                }}

                                                                aria-describedby="basic-addon3"
                                                            />
                                                            <InputGroup.Text className="input-mask" id="basic-addon3">
                                                                KES
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                        {/* {!example.Unit_Price  && dataadd == true ? (
                                                            <div className="error-message">Please enter the unit price</div>
                                                        ) : null} */}
                                                         {(!example.Unit_Price && dataadd == true) || (example.Unit_Price == '0' && dataadd)? (
                                                            <div className="error-message">
                                                                {example.Unit_Price == '0' ? "Please enter a non-zero unit price" : "Please enter the unit price"}
                                                            </div>    
                                                        ) : null}
                                                    </div>
                                                    <div id={styles.main_input_datatatata} style={{ width: "13%", margin: "0px 0px", paddingLeft: "5px" }} className="input-form-wrapper" >
                                                        <label>VAT %</label>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Form.Select name="Vat"

                                                                onChange={(e: any) => {
                                                                    const arrrr: any = []
                                                                    const newarr: any = []
                                                                    console.log(e.target.value, "e.target.value");

                                                                    for (let index = 0; index < directors.length; index++) {
                                                                        const element = directors[index];
                                                                        if (i == index) {
                                                                            element.tax = e.target.value

                                                                            arrrr.push(element)
                                                                        } else {
                                                                            arrrr.push(element)
                                                                        }
                                                                        // const newdata = directors;
                                                                        console.log(element, 'elementelement');


                                                                    }
                                                                    for (let index = 0; index < directors_two.length; index++) {
                                                                        const element = directors_two[index];

                                                                        if (i == index) {
                                                                            element.tax = e.target.value
                                                                            newarr.push(element)
                                                                        } else {
                                                                            newarr.push(element)
                                                                        }
                                                                    }
                                                                    setDirectors_two(newarr)
                                                                    setDirectors(arrrr)
                                                                }}
                                                                value={example.tax}
                                                                className={'doc-form-input'}
                                                            >
                                                                <option selected value="">VAT %</option>
                                                                {vatall?.map((arrayElement: any, index: number) => {
                                                                    return (
                                                                        <option value={arrayElement.vat} key={index + 1}>{arrayElement.vat} {arrayElement.vat == "Exempt" ? "" : "%"}</option>
                                                                    )
                                                                })}
                                                            </Form.Select>
                                                        </div>

                                                    </div>
                                                    <div id={styles.main_input_datatatata} style={{ margin: "0px 0px", width: "13%", paddingLeft: "5px" }} className="input-form-wrapper dot">
                                                        <label>Quantity </label>
                                                        <InputGroup className="doc-input-wrapper">
                                                            <Form.Control
                                                                className={'doc-form-input test valid'}
                                                                placeholder="Quantity"
                                                                value={example.quantity}
                                                                type="number"
                                                                onChange={(e: any) => {
                                                                    const arrrr: any = []
                                                                    const newarr: any = []
                                                                    let amount_data: any = 0;
                                                                    for (let index = 0; index < directors.length; index++) {
                                                                        const element = directors[index];

                                                                        if (i == index) {
                                                                            element.quantity = e.target.value
                                                                            element.total = +(+example.Unit_Price * +e.target.value)

                                                                            console.log(element.total, 'element.total');
                                                                            // amount_data += +(+example.Unit_Price * +e.target.value).toString()
                                                                            // element.amount_total=amount_data
                                                                            arrrr.push(element)
                                                                        } else {
                                                                            arrrr.push(element)
                                                                        }

                                                                    }
                                                                    for (let index = 0; index < directors_two.length; index++) {
                                                                        const element = directors_two[index];

                                                                        if (i == index) {
                                                                            element.quantity = +e.target.value
                                                                            element.total = +(+example.Unit_Price) * 100
                                                                            newarr.push(element)
                                                                        } else {
                                                                            newarr.push(element)
                                                                        }
                                                                    }
                                                                    setDirectors_two(newarr)
                                                                    // setDataTotal(amount_data)
                                                                    setDirectors(arrrr)
                                                                }}
                                                                onBlur={handleBlur}
                                                            />
                                                        </InputGroup>
                                                        {/* {!example.quantity && dataadd == true ? (
                                                            <div className="error-message">Please enter the quantity</div>
                                                        ) : null} */}
                                                          {(!example.quantity && dataadd == true) || (example.quantity == '0' && dataadd)? (
                                                                <div className="error-message">
                                                                    {example.quantity == '0' ? "Please enter a non-zero quantity" : "Please enter the quantity"}
                                                                </div>    
                                                             ) : null}
                                                    </div>
                                                    <div id={styles.main_input_datatatata} style={{ margin: "0px 0px", width: "27%", paddingLeft: "5px" }} className="input-form-wrapper">
                                                        <label>Description</label>
                                                        <InputGroup className="doc-input-wrapper">
                                                            <Form.Control
                                                                className={'doc-form-input test valid'}
                                                                placeholder="Description"
                                                                value={example.description}
                                                                type="text"
                                                                onChange={(e: any) => {

                                                                    const arrrr: any = []
                                                                    const newarr: any = []

                                                                    for (let index = 0; index < directors.length; index++) {
                                                                        const element = directors[index];
                                                                        if (i == index) {
                                                                            element.description = e.target.value
                                                                            arrrr.push(element)
                                                                        } else {
                                                                            arrrr.push(element)
                                                                        }
                                                                    }
                                                                    for (let index = 0; index < directors_two.length; index++) {
                                                                        const element = directors_two[index];

                                                                        if (i == index) {
                                                                            element.description = e.target.value
                                                                            newarr.push(element)
                                                                        } else {
                                                                            newarr.push(element)
                                                                        }
                                                                    }
                                                                    setDirectors_two(newarr)

                                                                    setDirectors(arrrr)
                                                                }}
                                                                onBlur={handleBlur}
                                                            />
                                                        </InputGroup>
                                                        {/* {!example.description && dataadd == true ? (
                                                            <div className="error-message">Description in the required field</div>
                                                        ) : null} */}
                                                    </div>
                                                    <div id={styles.main_input_datatatata} style={{ margin: "0px 0px", width: "15%", paddingLeft: "5px" }} className="input-form-wrapper dot">
                                                        <label>Amount</label>
                                                        <InputGroup className="doc-input-wrapper input" style={{ padding: 0 }}>

                                                            <MaskedInput
                                                                mask={numberMask}
                                                                style={{ height: '100%' }}
                                                                className="doc-form-input form-control"
                                                                disabled
                                                                placeholder="Amount"
                                                                value={((+example.Unit_Price) * (+example.quantity))}
                                                                onChange={(e: any) => {
                                                                    // const arrrr: any = []
                                                                    // for (let index = 0; index < directors.length; index++) {
                                                                    //     const element = directors[index];
                                                                    //     if (i == index) {
                                                                    //         element.total = (+example.Unit_Price) * (+example.quantity)
                                                                    //         arrrr.push(element)
                                                                    //     } else {
                                                                    //         arrrr.push(element)
                                                                    //     }
                                                                    // }
                                                                    // setDirectors_two(arrrr)
                                                                    // setDirectors(arrrr)
                                                                }}

                                                                aria-describedby="basic-addon3"

                                                            />
                                                            <InputGroup.Text className="input-mask" id="basic-addon3">
                                                                KES
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                        {!example.total && dataadd == true ? (
                                                            <div className="error-message">Please enter the unit price and quantity</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                {directors.length - 1 === i && <Button className='complete-profile__ADDBTNHHA' onClick={handleAddClick2}>+ Add line item</Button>}

                                            </>
                                        );
                                    })}
                                </div>
                                <div className={styles.displya_data_fastdiv}>
                                    <div className={styles.fast_button_and_input}>
                                        <div className="input-form-wrapper dot">
                                            <label>Submitted by</label>
                                            <InputGroup className="doc-input-wrapper input">{userDetail?.name}</InputGroup>
                                        </div>

                                    </div>
                                    <div className={styles.fast_button_and_input} style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div className="input-form-wrapper" style={{ width: "49%" }}>
                                            <label>VAT amount</label>
                                            <InputGroup className="doc-input-wrapper input">
                                            {(totelamount - data_total).toLocaleString(
                          'en',
                          { minimumFractionDigits: 2 }
                        )}
                                            </InputGroup>
                                        </div>
                                        <div className="input-form-wrapper" style={{ width: "49%" }}>
                                            <label>Gross amount</label>
                                            <InputGroup className="doc-input-wrapper input">
                                            {(data_total).toLocaleString(
                          'en',
                          { minimumFractionDigits: 2 }
                        )}
                                            </InputGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.displya_data_fastdiv}>
                                    <div className={styles.fast_button_and_input}>


                                    </div>
                                    <div className={styles.fast_button_and_input}>
                                        <div className="input-form-wrapper dot" style={{ padding: 0 }}>
                                            <label>Amount</label>
                                            <InputGroup className="doc-input-wrapper input" style={{ padding: 0 }}>

                                                <MaskedInput
                                                    mask={numberMask}
                                                    style={{ height: '100%' }}
                                                    disabled
                                                    className="doc-form-input form-control"
                                                    placeholder="Total"
                                                    value={(totelamount)}
                                                    //onChange={handleSubmit}
                                                    name='amount'
                                                    onChange={(e) => {
                                                        const kesValue = getKESFormate(e.target.value);
                                                        const obj = e;

                                                        // for (let index = 0; index < directors.length; index++) {
                                                        //     const element = directors[index];
                                                        //     console.log(+element.total, 'element4522');
                                                        //     amount_data+=+element.total
                                                        //     // if (i == index) {
                                                        //     //     element.total = 
                                                        //     //     arrrr.push(element)
                                                        //     // } else {
                                                        //     //     arrrr.push(element)
                                                        //     // }
                                                        // }
                                                        // console.log(amount_data, 'amount_data');
                                                        handleChange(e)
                                                    }}
                                                    onBlur={handleBlur}
                                                    // value="5593.00"
                                                    aria-describedby="basic-addon3"

                                                />
                                                <InputGroup.Text className="input-mask" id="basic-addon3">
                                                    KES
                                                </InputGroup.Text>
                                            </InputGroup> {touched.amount && errors.amount ? (
                                                <div className="error-message">{errors.amount}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div id={styles.id_buttan_contenar_list} style={{ justifyContent: 'space-between' }} className='payDetails-modal__btn-row'>
                                    <Button style={{ padding: "12px 50px" }} className='payDetails-modal__btn-row__white' variant="custom"
                                        onClick={() => { handleclosh() }}
                                    >Cancel</Button>
                                    <div id={styles.save_conteananan_dataa} style={{ marginTop: '0' }} className='payDetails-modal__btn-row_two'>
                                        <Button style={{ padding: "12px 50px" }} className='payDetails-modal__btn-row__white'
                                            variant="primary"
                                            type="submit"
                                            disabled={loading}
                                            onClick={() => {
                                                setBtnType('save')
                                                setDataAdd(true)
                                                setSubmit_button(true)
                                            }}>
                                            {loading == true && <span className="spinner-border spinner-border-sm mr-1"></span>}

                                            Save
                                        </Button>
                                        {/* {!!userD && !!userD.role_name && userD.role_name == 'Viewer' ?
                                            <Button style={{ padding: "12px 50px", cursor: 'not-allowed' }}
                                                className='payDetails-modal__btn-row__green ' variant="primary" type="submit">
                                                Submit
                                            </Button>
                                            : */}
                                        <Button style={{ padding: "12px 20px" }}
                                            className='payDetails-modal__btn-row__green '
                                            variant="primary"
                                            type="submit"
                                            // disabled={loading}
                                            onClick={() => {
                                                setDataAdd(true)
                                                setBtnType('submit')
                                                setSubmit_button(true)
                                            }}>
                                            {loading == true && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            Submit for Approval
                                        </Button>
                                    </div>
                                </div>
                            </Form>

                        </>
                    )}
                </Formik>
            </div >
        </>
    )
}
const AddICon = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM14 9H11V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5C9.73479 5 9.48043 5.10536 9.2929 5.29289C9.10536 5.48043 9 5.73478 9 6V9H6C5.73479 9 5.48043 9.10536 5.2929 9.29289C5.10536 9.48043 5 9.73478 5 10C5 10.2652 5.10536 10.5196 5.2929 10.7071C5.48043 10.8946 5.73479 11 6 11H9V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10C15 9.73478 14.8946 9.48043 14.7071 9.29289C14.5196 9.10536 14.2652 9 14 9Z"
            fill="#025041"
        />


    </svg>
);
const UploadIcon = () => (
    <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.67773 14V16C1.67773 16.5304 1.88845 17.0391 2.26352 17.4142C2.63859 17.7893 3.1473 18 3.67773 18H15.6777C16.2082 18 16.7169 17.7893 17.0919 17.4142C17.467 17.0391 17.6777 16.5304 17.6777 16V14M4.67773 6L9.67773 1L14.6777 6M9.67773 1V13"
            stroke="#B8B8B8"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);