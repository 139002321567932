import React from "react";
import { Button } from "react-bootstrap";
import { DocumentForm } from "../document/Document";
import { PaymentForm } from "../Payment/PaymentForm";
import { PayStatus } from "../Status/PayStatus";
import "./PayDetailsModal.scss";
import styles from'../../Create_Bill.module.scss';

interface Props {
  show: boolean;
  onClose: any;
}

export const PayDetailsModal = (props: any) => {
  const onClose = () => {
    props.onClose(false);
  };

  const savePayable = () => {
    props.setIsAPIData(true);
  };

  const payPayable = () => {
    props.setIsPayAPIData(true);
  };

  return (
    <>
      {props.show && (
        <div className="container_div" style={{    padding:"10px 43px"}}>
          
          <DocumentForm props={props} />
          
         </div>
      )}
    </>
  );
};
