export const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

export const getKESFormate = (amount) => {
    console.log(amount, 'get');
    const converted =  !!amount ? Number(parseFloat(amount).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}):amount ?? "0.00";
    console.log(amount, 'return');
    return converted;
}

export const getKESFormateCurrency = (amount) => {
    console.log(amount, 'get');
    const converted =  !!amount ? Number(parseFloat(amount).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}):amount ?? "0.00";
    console.log(amount, 'return');
    return "KES " + converted;
}