import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import '../../../Settings.scss';

interface Props {
  onSave: any;
}

export const DocumentsSubmitted = (props: Props) => {
  const onSave = () => {
    props.onSave();
  };

  return (
    <div className="document-submitted">
      <div className="document-submitted__heading">
        Thank you for signing up!
      </div>

      <div className="document-submitted__desc">
        Our team is reviewing your information and we may reach out to you
        should we require additional details. This process usually takes 1-2
        business days.
      </div>
      {/* <Button className="document-submitted__btn" onClick={() => onSave()}>
        Go Back{' '}
      </Button> */}
    </div>
  );
};
