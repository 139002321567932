import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import "../../../Settings.scss";


interface Props {
    onSave: any;
    page:string;
}

export const ModelumentVerified = (props: Props) => {

    const onSave = () => {
        props.onSave();
    }

    return (
        <div className='document-verified'>
            <div className='document-verified__heading'>Account Setup Awaiting Verification.</div>

            <div className='document-verified__desc'>
                <br />
                You have successfully completed the account setup process and your
                account is pending verification from our side.

                <br />
                <br />
                In the meantime,
                you have access to certain features on our platform and
                we will let you know as soon as the verification is processed
                by our team, typically within 1 business day.
                <br />
            </div>
            <Button className='document-verified__btn' onClick={() =>{
                if(props?.page=="fastpage"){
                    onSave()
                }else{
                window.location.reload()
                }
            }}>Go Back</Button>
        </div>
    )
}
