import { Box, Grid, Typography } from "@mui/material";
import styles from './customers_details.module.scss';
import moment from 'moment';

const Setp1 = ({ dataList, list }: any) => {

    return (
        <>
            <Grid container className={styles.setp_container}>
                <Grid item md={3.9} xs={12} className={styles.grid_fast}>
                    <Typography className={styles.heding_box}>Invoice issued</Typography>
                    <Box className={styles.contean_box} sx={{ paddingBottom: "10px" }}>
                        <Typography className={styles.typo_contean}>
                            Total invoices
                        </Typography>
                        <Typography className={styles.typo_contean_sub}>
                            {dataList?.issued?.Total_invoices}
                        </Typography>
                    </Box>
                    <Box className={styles.contean_box}>
                        <Typography className={styles.typo_contean}>
                            Total invoices
                        </Typography>
                        <Typography className={styles.typo_contean_sub}>
                            {dataList?.issued?.Total_amount}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={3.9} xs={12} className={styles.grid_fast}>
                    <Typography className={styles.heding_box}> Invoices paid</Typography>
                    <Box className={styles.contean_box} sx={{ paddingBottom: "10px" }}>
                        <Typography className={styles.typo_contean}>
                            Total invoices
                        </Typography>
                        <Typography className={styles.typo_contean_sub}>
                            {dataList?.paid?.Total_invoices}
                        </Typography>
                    </Box>
                    <Box className={styles.contean_box}>
                        <Typography className={styles.typo_contean}>
                            Total amount
                        </Typography>
                        <Typography className={styles.typo_contean_sub}>
                            {dataList?.paid?.currancy}{" "}{dataList?.paid?.Total_amount}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={3.9} xs={12} className={styles.grid_fast}>
                    <Typography className={styles.heding_box}>Invoices overdue</Typography>
                    <Box className={styles.contean_box} sx={{ paddingBottom: "10px" }}>
                        <Typography className={styles.typo_contean}>
                            Total invoices
                        </Typography>
                        <Typography className={styles.typo_contean_sub}>
                            {dataList?.overdue?.Total_invoices}
                        </Typography>
                    </Box>
                    <Box className={styles.contean_box}>
                        <Typography className={styles.typo_contean}>
                            Total amount
                        </Typography>
                        <Typography className={styles.typo_contean_sub}>
                            {dataList?.overdue?.currancy}{" "}{dataList?.overdue?.Total_amount}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Box className={styles.box_secend_poshan}>
                        <Box className={styles.LineStart}>

                        </Box>
                        <Typography className={styles.Recent_typo}>
                            Recent activity
                        </Typography>
                        <Box className={styles.box_last_line}>

                        </Box>
                    </Box>
                </Grid>
                <Grid container className={styles.setp_footer_container}>
                    {list.map((item: any, index: any) => {
                        return (
                            <>
                                <Grid item md={2}>
                                    <Typography className={styles.name_text}>
                                        {item.name}
                                    </Typography>
                                </Grid>
                                <Grid item md={8}>
                                    <Typography className={styles.name_text}>
                                        {item.des}
                                    </Typography>
                                </Grid>
                                <Grid item md={2}>
                                    <Typography className={styles.name_text}>
                                    {moment(item.date).format(
                            'DD-MMM-YYYY'
                          )}
                                    </Typography>
                                </Grid>
                            </>
                        )
                    })}

                </Grid>
            </Grid>
        </>
    )
}
export default Setp1;