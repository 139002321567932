import "./Gethelp.scss";
import React, { useEffect, useState } from "react";
import Faq_data from "./faq_data";
export const Contact_Us = () => {

  return (
    <div className='gethelp'>
      <div className="balance__header">
        <div className="balance__header__name">FAQs </div>

      </div>
      <div className="dashboard-container">
        <div className="box_add_new_data">
          <Faq_data />
        </div>

      </div>
    </div>
  )
}

