import { PDFDocument } from 'pdf-lib';
import React from 'react'
import { createCanvas, loadImage } from 'canvas';

const convertImageToPdf = async (imageUrl: string) => {
    const img = await loadImage(imageUrl);
    const canvas = createCanvas(img.width, img.height);
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataUrl = canvas.toDataURL('image/png');
    const arrayBuffer = await fetch(dataUrl).then(response => response.arrayBuffer());
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();
    const pngImage = await pdfDoc.embedPng(arrayBuffer);
    page.setWidth(img.width)
    page.setHeight(img.height)
    page.drawImage(pngImage, {
        x: 0,
        y: 0,
        width: img.width,
        height: img.height,
        opacity: 1,
    });
    const pdfBytes = await pdfDoc.save();
    const base64Pdf = arrayBufferToBase64(pdfBytes);
    return base64Pdf;
};

const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
};

export const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    const pdfType = ["application/pdf"]
    let base64URL: any = ""
    if (validImageTypes.includes(file.type)) {
        const imageUrl = URL.createObjectURL(file);
        const pdfBytes = await convertImageToPdf(imageUrl);
        base64URL = pdfBytes
    } else if (pdfType.includes(file.type)) {
        const arrayBuffer = await file.arrayBuffer();
        const base64Pdf = arrayBufferToBase64(arrayBuffer);
        base64URL = base64Pdf
    }
    return base64URL
}