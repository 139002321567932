import Papa from 'papaparse'
import styles from './csvlist.module.scss'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { Box, Grid, IconButton, Typography } from '@mui/material';
import '../topup-modal/Topup.scss';
import CloseIcon from '@mui/icons-material/Close';

// import { Button } from '@mui/material';
const MobileIcon = () => (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 14V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V14M4 6L9 1L14 6M9 1V13" stroke="#025041" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);
const VisaCardIcon = () => (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 14V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V14M4 6L9 1L14 6M9 1V13" stroke="#025041" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);
export class HttpClient {
    static get() {
        //         return Promise.resolve(`Invoice Number*,invoice_number,invoice_date,due_date,amount,description
        // Hassan test,1,2005-04-13,2005-04-13,10,Lorem ispum
        // Hassan,2,2005-04-13,2005-04-13,20,Lorem ispum
        // Hassan,3,2005-04-13,2005-04-13,30,Lorem ispum
        // Hassan,4,2005-04-13,2005-04-13,40,Lorem ispum
        // Hassan,7,2005-04-13,2005-04-13,50,Lorem ispum`
        // );
        return Promise.resolve(`Invoice Number*,Payee Name*,Invoice Date*(YYYY-MM-DD),Due Date*(YYYY-MM-DD),Amount*,Category*,Description
NIO-001,Please make sure the payee name entered here exactly matches the name of the payee in the Niobi App,2005-04-13,2005-04-13,Enter amount in KES,Rent,Enter description`
        );
    }
}





const Csvdata = (props) => {
    const [dataInCSV, setDataInCSV] = React.useState("");
    const [listcvdata, setlistcvdata] = React.useState([])
    const [csvname, setCsvnamedata] = React.useState("")
    const [showModal, setShow] = useState(props.show);
    const [selectedMethod, setSelectedMethod] = useState('');
    const [filllist, setFile] = useState('');
    const [show, setShowlist] = useState(false);
    const [page, setPage] = React.useState(false);
    console.log(listcvdata, 'listcvdatalistcvdata');
    console.log(filllist, 'filllist');
    let navigate = useNavigate();

    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    const handleClose = () => {
        setShowlist(false)
        props.onClose(false);
    }
    const handleShow = () => {
        // window.location.href = '/Bulktebal'
        setShow(false);
        setShowlist(true);
        setPage(true)
    }


    const onClose = () => {
        setShow(false);
        props.onClose(false);
    };
    const onSave = () => {
        setShow(false);
        props.onSave(false);
    };
    const handleCSVUpload = (file) => {
        setCsvnamedata(file.name)
        Papa.parse(file, {
            header: true,
            complete: (results) => {
                console.log(results.data, 'dfgfgfdg');
                // var userJSON = userJSON.find(item => item.buyPrice === '');

                // console.log(result,'dfrtfdtftfd');
                // element.buyPrice
                // if (results.data === '') {
                //     break;
                //   }
                // if (page == true) {
                //localStorage.setItem('Bulkdata', JSON.stringify(results.data))
                // }
                setlistcvdata(results.data)
                handleShow()

                localStorage.setItem('Bulkdata', JSON.stringify(results.data))
                onSave()
                // onClose()
                // setPage(true)
                navigate("/bills?BILLSBULK=" + 'bulkadd', { replace: true, state: { BILLSBULK: 'bulkadd' } })
                // setListcsvdsts(results.data)
            },
        });
    };

    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    React.useEffect(() => {
        setPage(false)
        HttpClient.get().then((res) => setDataInCSV(res));
    }, []);


    return (
        <>
            <Modal
                show={showModal}
                onHide={onClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby='ModalHeader'
                className='topup-modal'
                centered
                size="lg"
            >
                <ThemeProvider theme={theme}>
                    <Box className="CloseContenar">
                        <IconButton onClick={() => {
                            onClose();
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Modal.Header style={{ padding: "10px 32px 32px 32px" }} className='tophead' closeButton>
                        <Modal.Title>Import Bills</Modal.Title>
                        <ThemeProvider theme={theme}>
                            <Typography className={styles.add_text_key_loaa} style={{ marginTop: '8px', fontSize: "13px" }}>Follow the steps below to import bills  </Typography>
                        </ThemeProvider>
                    </Modal.Header>
                </ThemeProvider>
                <Modal.Body className='modalbbody'>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={0}>
                            <Grid xs={12} sm={12} md={6} style={{ paddingRight: '10px' }}>
                                <Typography className='headingCus'>Step 1</Typography>
                                <Typography className='subheading mb-3' style={{ fontSize: "13px", lineHeight: "unset" }}>Fill in and save the details in the file then proceed to step 2</Typography>
                                <div
                                    className={`type-card ${selectedMethod === 'mobile' ? 'active' : ''}`}
                                // onClick={() => setSelectedMethod('mobile')}

                                >
                                    {/* <a download="bills.csv" style={{ textDecoration: 'none' }}
                                        href={`data:text/csv;charset=utf-32,${escape(dataInCSV)}`}
                                        > */}
                                    {/* <div>
                                    
                                    <span className='type-card__name'>via M-Pesa</span>
                                </div> */}
                                    <a href='/bills.csv' style={{ textDecoration: 'none' }}
                                    >
                                        <div className={styles.addonebtn}>
                                            <div className='type-card__detail'>
                                                Download Template File
                                            </div>
                                            <div className={styles.iconadda + " " + styles.save_btn22}>
                                                <div class="m-2">Download</div>
                                                <div class={styles.download + " "}><MobileIcon /></div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </Grid>
                            <Grid xs={12} sm={12} md={6} style={{ paddingLeft: '10px' }}>
                                <Typography className='headingCus' >Step 2</Typography>
                                <Typography className='subheading' id={styles.afsfaffff} style={{ paddingBottom: '43px', fontSize: "13px" }}>Please upload the saved file</Typography>
                                <div>
                                    <label for="inputTag" style={{ width: '100%' }} id={filllist == '' ? '' : styles.active} className={`type-card ${selectedMethod === 'mobile' ? 'active' : ''}`}>
                                        <div className={styles.addonebtn}>
                                            <div className='type-card__detail'>{!!filllist && !!filllist.name ? filllist.name : 'Upload File'}</div>
                                            <div className={styles.iconadda + " " + styles.save_btn22}>
                                                <div class="m-2">Upload</div>
                                                <div class={" "}><VisaCardIcon /></div>
                                            </div>
                                        </div>
                                        <input id="inputTag" className={styles.addaffafagga}
                                            type="file" onChange={(file) => {
                                                console.log(file.target.files, 'dhdhhdhhdhhd')
                                                handleCSVUpload(file.target.files[0]);
                                                setFile(file.target.files[0]);
                                            }}
                                            accept=".csv" />
                                    </label>

                                </div>
                            </Grid>
                        </Grid>
                    </ThemeProvider>

                    {/* <div className='type-card-list'>

                        
                       
                    </div> */}

                </Modal.Body>
                {/* <Modal.Footer>
                    <Button className='white-btn' onClick={onClose}>Cancel</Button>
                    <Button id={styles.button_bills_nextt} className='green-btn' disabled={filllist === ''} onClick={() => {
                        handleShow()

                        localStorage.setItem('Bulkdata', JSON.stringify(listcvdata))
                        onSave()
                        // onClose()
                        // setPage(true)
                        navigate("/bills?BILLSBULK=" + 'bulkadd', { replace: true, state: { BILLSBULK: 'bulkadd' } })
                    }}>Next</Button>
                </Modal.Footer> */}
            </Modal>
            {/* {page==true? <Bill data={page} />:''} */}

        </>


    )
}

export default Csvdata;

