import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useMediaQuery } from 'react-responsive'

interface ApexChartProps { }

interface TravelDetailsViewProps {
  options?: any;
  series?: any;
}
const TravelDetailsView = (props: any) => {
      const chartData: ApexOptions = {
        chart: {
          height: 350,
          type: 'area',
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: props.data.chartsDate
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
          y:{
            formatter: undefined,
            title: {
                formatter: (seriesName) => `${seriesName}${'  '}${props.ChartCurrncy}`,
          }
        }
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
          }
        },
        colors: ["rgb(0, 158, 126)",'#21144c', '#F87161'], 
        legend: {
          position: "bottom",
        },
        series: [{
          name: 'Inflows',
          data: props.data?.deposits
        }, 
        {
          name: 'Outflows',
          data: props.data?.payouts
        }
      ],
        responsive: [{
          options: {
            legend: {
              position: 'bottom'
            }
          }
        }]
      };

   
  return <ReactApexChart 
  options={chartData} 
  series={chartData.series} 
  type="area" height={props.height} />;
};

const NewCharts = (props: any) => {
  return <TravelDetailsView height={props.height} ChartCurrncy={props.ChartCurrncy}  data={props.data} />;
};

export default NewCharts;
