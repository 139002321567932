import Modal from 'react-bootstrap/Modal';
import styles from "./currencySelectionModal.module.scss";
interface Props {
  showModal: boolean;
  handleClose: any;
}

const currencySelectionModal = (props: Props) => {
  return (
      <>
      <Modal
        show={props.showModal}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
         <h5 className={styles.descText}>Sorry! You need to withdraw funds from this account before closing it</h5>
        </Modal.Body>
        <div className={styles.footer}>
          <button className={styles.closeBtn} onClick={props.handleClose}>
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default currencySelectionModal;
