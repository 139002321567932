import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { CircularProgress, Box } from '@mui/material';
const Loader = () => {
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          position: 'absolute',
          top: 150,
          bottom: 0,
          right: 0,
          left: 0,
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 10,
        }}
      >
        <CircularProgress color="success" />
      </Box>
    </ThemeProvider>
  );
};

export default Loader;
