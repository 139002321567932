import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Button, Table } from 'react-bootstrap';
import moment from 'moment';
import { CssBaseline, CircularProgress } from '@mui/material';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { getUser } from '../../../../_helpers';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}
declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

interface Data {
  transaction_date: Date;
  reference: string | number;
  Type: string | number;
  beneficiary: number;
  category: string;
  description: string;
  transaction_amount: string | number;
  transaction_status: string | number;
  settled_unsettled: string | number;
  balance: string | number;
  more_info: string | number;
}

function createData(
  transaction_date: Date,
  reference: string | number,
  Type: string | number,
  beneficiary: number,
  category: string,
  description: string,
  transaction_amount: string | number,
  transaction_status: string | number,
  settled_unsettled: string | number,
  balance: string | number,
  more_info: string | number
): Data {
  return {
    transaction_date,
    reference,
    Type,
    beneficiary,
    category,
    description,
    transaction_amount,
    transaction_status,
    settled_unsettled,
    balance,
    more_info,
  };
}
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'transaction_date',
    numeric: false,
    disablePadding: true,
    label: 'Date',
  },
  {
    id: 'reference',
    numeric: false,
    disablePadding: true,
    label: 'Payment Reference',
  },
  {
    id: 'Type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'beneficiary',
    numeric: true,
    disablePadding: false,
    label: 'Payee',
  },
  {
    id: 'category',
    numeric: true,
    disablePadding: false,
    label: 'Category',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description ',
  },
  {
    id: 'transaction_amount',
    numeric: false,
    disablePadding: false,
    label: 'Transaction Amount',
  }, {
    id: 'settled_unsettled',
    numeric: false,
    disablePadding: false,
    label: 'Settled/Unsettled',
  },
  {
    id: 'transaction_status',
    numeric: false,
    disablePadding: false,
    label: 'Transaction Status',
  },
  {
    id: 'balance',
    numeric: false,
    disablePadding: false,
    label: 'Closing Balance',
  },
  {
    id: 'more_info',
    numeric: false,
    disablePadding: false,
    label: 'More Info',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  status: boolean;
  status_unsettled: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, status, status_unsettled } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => { };
  var newHeadcells = headCells;
 
  if (status_unsettled == false) {
    newHeadcells = headCells.filter(
      (person) => person.id != 'settled_unsettled'&&person.id != 'transaction_status'
    );

  }
  // if (status == false) {
   
  // }
  console.log(newHeadcells,status_unsettled,status,"newHeadcells");
  
  return (
    <thead>
      <tr>
        {newHeadcells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            className="th"
          >
            {headCell.label}
          </TableCell>
        ))}
      </tr>
    </thead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

export default function EnhancedTable(props: any) {
  const rows: any[] = props.data ? props.data : [];
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('transaction_date');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [option, setOption] = React.useState<any>({
    id: '',
  });
  const user = getUser()?.data ?? null;
  const [data_display, setData_display] = React.useState(false);
  const [Tagclass, setTagclass] = React.useState('');
  const [tagname, settagname] = React.useState<any>('');
  const dafafagga = () => {
    setData_display(false);
    setTimeout(() => {
      dafafagga();
    }, 6000);
  };
  React.useEffect(() => {
    dafafagga();
  }, []);
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  const onSelect = (el: any) => {
    console.log('called', el);
    props.onSelect(el);
    setOption(el);
  };

  React.useEffect(() => {
    const data: any = option;
    let tagclass = '';
    if (data.Type == 'pay_in') {
      tagclass = 'tag_payin-tag';
      settagname('Pay In');
    } else if (data.Type == 'pay_out') {
      tagclass = 'tag_payout-tag';
      settagname('Pay Out');
    } else if (data.Type == 'fees') {
      tagclass = 'tag_fees-tag';
      settagname('Fees');
    }
    setTagclass(tagclass);
  }, [option]);

  return (
    <>
      <ThemeProvider theme={theme}>
        
        <div className="contenar_the_data">
          <TableContainer>
            <Table>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                status={!!user && user.role_name == 'Developer' ? true : false}
                status_unsettled={!!user && user.role_name == 'Developer' ? true : false}
              />
              <tbody>
                {props.data.map((element: any, index: number) => {
                  var TAmount = element.transaction_amount;

                  var tagclass = '';
                  var textclass = '';
                  var tagname = element.type;
                  if (element.Type == 'pay_in') {
                    tagclass = 'tag_payin-tag';
                    textclass = 'text_payin-tag';
                    tagname = 'Pay In';
                  } else if (element.Type == 'pay_out') {
                    tagclass = 'tag_payout-tag';
                    textclass = 'text_payout-tag';
                    tagname = 'Pay Out';
                    TAmount = -element.transaction_amount;
                  } else if (element.Type == 'fees') {
                    tagclass = 'tag_fees-tag';
                    textclass = 'text_fees-tag';
                    tagname = 'Fees';
                    TAmount = -element.transaction_amount;
                  }
                  var trans_cat = [];
                  if (
                    !!user &&
                    user.role_name == 'Developer' &&
                    element.unified_transaction_categories
                  ) {
                    for (
                      let index = 0;
                      index < element.unified_transaction_categories.length;
                      index++
                    ) {
                      const records =
                        element.unified_transaction_categories[index];
                      if (!!records && !!records.name) {
                        trans_cat.push(records.name);
                      }
                    }
                  } else if (element.transaction_categories) {
                    for (
                      let index = 0;
                      index < element.transaction_categories.length;
                      index++
                    ) {
                      const records = element.transaction_categories[index];
                      if (!!records && !!records.name) {
                        trans_cat.push(records.name);
                      }
                    }
                  } else {
                  }
                  console.log(trans_cat, 'trans_cat');
                  const transCategory = trans_cat.toString();

                  return (
                    <TableRow>
                      <TableCell>
                        <p className={'tebaldateformette'}>
                          {moment(element.transaction_date).format(
                            'DD-MMM-YYYY'
                          )}
                        </p>
                      </TableCell>
                      <TableCell>{element.reference}</TableCell>
                      <TableCell className="type tag">
                        <span className={`tag_color ${tagclass}`}>
                          {tagname}
                        </span>
                      </TableCell>
                      <TableCell>
                        <p className={'tebaldateformette'}>
                          {element.beneficiary}
                        </p>
                      </TableCell>
                      <TableCell className="balance-category">
                        <p className={'tebaldateformette'}>{transCategory}</p>
                      </TableCell>
                      <TableCell>{element.description}</TableCell>
                      <TableCell className={`text-color`}>
                        <span className={`text_color ${textclass}`}>
                          {parseFloat(TAmount) > 0 ? '+' : '-'}
                          {element.currency}{' '}
                          {!!TAmount
                            ? parseFloat(TAmount) > 0
                              ? Number(
                                parseFloat(TAmount).toFixed(2)
                              ).toLocaleString('en', {
                                minimumFractionDigits: 2,
                              })
                              : `${Number(parseFloat(TAmount).toFixed(2))
                                .toLocaleString('en', {
                                  minimumFractionDigits: 2,
                                })
                                .replace('-', '')}`
                            : '0'}
                        </span>
                      </TableCell>
                      {!!user && user.role_name == 'Developer' ? (
                        <TableCell>
                         {tagname === 'Pay Out' || tagname === 'Fees'
                          ? 'Settled'
                          : element?.TransactionStatus}
                        </TableCell>
                      ) : (
                        ''
                      )}
                      {!!user && user.role_name == 'Developer' ? (
                        <TableCell>
                          {!!element.status ? element.status : ''}
                        </TableCell>
                      ) : (
                        ''
                      )}

                      <TableCell>
                        {element.currency}{' '}
                        {!!element.balance
                          ? Number(
                            parseFloat(element.balance).toFixed(2)
                          ).toLocaleString('en', { minimumFractionDigits: 2 })
                          : element.balance}
                      </TableCell>
                      <TableCell>
                        <p
                          className={'tebaldateformette view_detail'}
                          onClick={() => onSelect(element)}
                        >
                          View details
                        </p>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </tbody>
            </Table>
          </TableContainer>
        </div>
      </ThemeProvider>
    </>
  );
}
