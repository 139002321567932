import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'


function CarouselLogin()
{
    var items = [
        {
            name: "Random Name #1",
            description: "Probably the most random thing you have ever seen!"
        },
        {
            name: "Random Name #2",
            description: "Hello World!"
        }
    ]
    const images = [
        { url: "1 (1).gif" },
        { url: "1 (2).gif" },
        { url: "1 (3).gif" },
      ];

    return (
        <Carousel>
            {
                images.map( (image, i) => 
                    <div>
                        <img src={image.url} alt="image" style={{width: '48vh',maxWidth: 'max-content', margin: '0 auto', textAlign: 'center', display: 'block' }}/>
                    </div>
                )
            }
        </Carousel>
    )
}

export default CarouselLogin;
