import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './RolesPopup.scss'; 
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';

const RolesPopup = (props: any) => {
  const [show, setShowlist] = useState(props.show ? 0 : '');

  const onClose_topup = () => {
    props.onClose();
    setShowlist('300');
  };

//   const OnSave = () => {
//     props.OnSave();
//   };

  return (
    <>
      <Modal
        show={show === 0}
        onHide={() => {
          setShowlist('');
        }}
        backdrop="static"
        keyboard={false}
        aria-labelledby="ModalHeader"
        className="topup-modal3"
        centered
        size="xl"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Role Permissions</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ul className="list">
            {props.roles.map((role: any, index: number) => (
              <li key={index} className="card">
                <div  style={{fontSize: "17px", fontWeight: 500}}>{role.name}</div>
                {role.categories.map((category: any, idx: number) => (
                  <div key={idx} className='category_style'>
                    {category.category == "Manage Inflows" 
                    ? <p style={{fontSize: "16px", fontWeight: 400}}><ReceiptLongOutlinedIcon style={{ color: "#015241"}}/>  {category.category}</p>
                    :   category.category == "Manage Outflows" 
                        ? <p  style={{fontSize: "16px", fontWeight: 400}}><PaymentsOutlinedIcon style={{ color: "#015241"}}/>  {category.category}</p>
                        :  category.category == "Transactions" 
                          ? <p  style={{fontSize: "16px", fontWeight: 400}}><PaidOutlinedIcon style={{ color: "#015241"}}/>  {category.category}</p>
                          :category.category == "Invite Team" 
                            ? <p  style={{fontSize: "16px", fontWeight: 400}}><GroupAddOutlinedIcon style={{ color: "#015241"}}/>  {category.category}</p>
                            : category.category == "API Integration" ?
                              <p  style={{fontSize: "16px", fontWeight: 400}}><IntegrationInstructionsOutlinedIcon style={{ color: "#015241"}}/>  {category.category}</p>
                                : <p  style={{fontSize: "16px", fontWeight: 400}}>{category.category}</p>}
                    
                    <ul className='permission'>
                      {category.permissions.map((permission: string, idx: number) => (
                        <li style={{ fontSize: "14px"}} key={idx}>{permission}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </li>
            ))}
          </ul>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="white-btn"
            onClick={() => {
              onClose_topup();
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

RolesPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
};

RolesPopup.defaultProps = {
  roles: [],
};

export default RolesPopup;
