import "./Gethelp.scss";
import React, { useEffect, useState } from "react";
import HubspotContactForm from './hubspot';

export const Gethelp = () => {
  
    return (
        <div className='gethelp'>
          <div className="balance__header">
            <div className="balance__header__name">Raise a Ticket</div>
           
          </div>
          <div className="dashboard-container">

            <HubspotContactForm 
                region="eu1"
                portalId="26625235"
                formId='a4f12eea-2633-4b14-8eab-49cde2066649'
                />
            </div>
        </div>
    )
}

