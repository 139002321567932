import React, {useEffect} from "react";

    const Faq_data = (props) => {
        useEffect(() => {
            const script = document.createElement('script');
            script.src = 'https://scribehow.com/page-embed/Niobi_User_Step-by-Step_Guide__Q1kx7vo1Tf2GhVlcHjGd4g%22';
            script.async = true;
            document.body.appendChild(script);
          
            return () => {
              document.body.removeChild(script);
            };
          }, []);
        return (
            <div>
            <iframe src="https://scribehow.com/page-embed/Niobi_User_Step-by-Step_Guide__Q1kx7vo1Tf2GhVlcHjGd4g" width="100%" height="640" allowfullscreen frameborder="0"></iframe>
            </div>
        );
    };
    
    export default Faq_data;