export const settingConstants = {
    GET_BUSINESS_DETAILS_REQUEST: 'GET_BUSINESS_DETAILS_REQUEST',
    GET_BUSINESS_DETAILS_SUCCESS: 'GET_BUSINESS_DETAILS_SUCCESS',
    GET_BUSINESS_DETAILS_FAILURE: 'GET_BUSINESS_DETAILS_FAILURE',

    GETDOCUMENT_REQUEST: 'DOCUMENT_GETDOCUMENT_REQUEST',
    GETDOCUMENT_SUCCESS: 'DOCUMENT_GETDOCUMENT_SUCCESS',
    GETDOCUMENT_FAILURE: 'DOCUMENT_GETDOCUMENT_FAILURE',

    DELETE_DOCUMENT_REQUEST: "DELETE_DOCUMENT_REQUEST",
    DELETE_DOCUMENT_SUCCESS: "DELETE_DOCUMENT_SUCCESS",
    DELETE_DOCUMENT_FAILURE: "DELETE_DOCUMENT_FAILURE",


    STOREDOCUMENT_REQUEST: 'DOCUMENT_STOREDOCUMENT_REQUEST',
    STOREDOCUMENT_SUCCESS: 'DOCUMENT_STOREDOCUMENT_SUCCESS',
    STOREDOCUMENT_FAILURE: 'DOCUMENT_STOREDOCUMENT_FAILURE',

    SUBMITDOCUMENT_REQUEST: 'DOCUMENT_SUBMITDOCUMENT_REQUEST',
    SUBMITDOCUMENT_SUCCESS: 'DOCUMENT_SUBMITDOCUMENT_SUCCESS',
    SUBMITDOCUMENT_FAILURE: 'DOCUMENT_SUBMITDOCUMENT_FAILURE',

    GETENTITYDOCUMENT_REQUEST: 'GETENTITYDOCUMENT_REQUEST',
    GETENTITYDOCUMENT_SUCCESS: 'GETENTITYDOCUMENT_SUCCESS',
    GETENTITYDOCUMENT_FAILURE: 'GETENTITYDOCUMENT_FAILURE',

    ROLSDATA_REQUEST: 'ROLSDATA_REQUEST',
    ROLSDATA_SUCCESS: 'ROLSDATA_SUCCESS',
    ROLSDATA_FAILURE: 'ROLSDATA_FAILURE',

    PERMISSIONS_REQUEST: 'PERMISSIONS_REQUEST',
    PERMISSIONS_SUCCESS: 'PERMISSIONS_SUCCESS',
    PERMISSIONS_FAILURE: 'PERMISSIONS_FAILURE',

    USERDOCUMENT_REQUEST: 'USERDOCUMENT_REQUEST',
    USERDOCUMENT_SUCCESS: 'USERDOCUMENT_SUCCESS',
    USERDOCUMENT_FAILURE: 'USERDOCUMENT_FAILURE',

    USERTEAMDOCUMENT_REQUEST: 'USERTEAMDOCUMENT_REQUEST',
    USERTEAMDOCUMENT_SUCCESS: 'USERTEAMDOCUMENT_SUCCESS',
    USERTEAMDOCUMENT_FAILURE: 'USERTEAMDOCUMENT_FAILURE',

    TEAMDOCUMENT_REQUEST: 'TEAMDOCUMENT_REQUEST',
    TEAMDOCUMENT_SUCCESS: 'TEAMDOCUMENT_SUCCESS',
    TEAMDOCUMENT_FAILURE: 'TEAMDOCUMENT_FAILURE',

    CERETE_TEAM_REQUEST: 'CERETE_TEAM_REQUEST',
    CERETE_TEAM_SUCCESS:"CERETE_TEAM_SUCCESS",
    CERETE_TEAM_FAILURE:"CERETE_TEAM_FAILURE",

    INVITEDOCUMENT_REQUEST: 'INVITEDOCUMENT_REQUEST',
    INVITEDOCUMENT_SUCCESS: 'INVITEDOCUMENT_SUCCESS',
    INVITEDOCUMENT_FAILURE: 'INVITEDOCUMENT_FAILURE',

    SUBMITENTITYDOCUMENT_REQUEST: 'DOCUMENT_SUBMITENTITYDOCUMENT_REQUEST',
    SUBMITENTITYDOCUMENT_SUCCESS: 'DOCUMENT_SUBMITENTITYDOCUMENT_SUCCESS',
    SUBMITENTITYDOCUMENT_FAILURE: 'DOCUMENT_SUBMITENTITYDOCUMENT_FAILURE',

    DELETEENTITYDOCUMENT_REQUEST: 'DOCUMENT_DELETEENTITYDOCUMENT_REQUEST',
    DELETEENTITYDOCUMENT_SUCCESS: 'DOCUMENT_DELETEENTITYDOCUMENT_SUCCESS',
    DELETEENTITYDOCUMENT_FAILURE: 'DOCUMENT_DELETEENTITYDOCUMENT_FAILURE',


    DELETEUSER_REQUEST: 'DELETEUSER_REQUEST',
    DELETEUSER_SUCCESS: 'DELETEUSER_SUCCESS',
    DELETEUSER_FAILURE: 'DELETEUSER_FAILURE',

    UPDATEUSER_REQUEST: 'UPDATEUSER_REQUEST',
    UPDATEUSER_SUCCESS: 'UPDATEUSER_SUCCESS',
    UPDATEUSER_FAILURE: 'UPDATEUSER_FAILURE',

    UPDATETEAM_REQUEST: 'UPDATETEAM_REQUEST',
    UPDATETEAM_SUCCESS: 'UPDATETEAM_SUCCESS',
    UPDATETEAM_FAILURE: 'UPDATETEAM_FAILURE',

    GETUSERDETAIL_REQUEST: 'GETUSERDETAIL_REQUEST',
    GETUSERDETAIL_SUCCESS: 'GETUSERDETAIL_SUCCESS',
    GETUSERDETAIL_FAILURE: 'GETUSERDETAIL_FAILURE',

    FINDUSERDETAIL_REQUEST: 'FINDUSERDETAIL_REQUEST',
    FINDUSERDETAIL_SUCCESS: 'FINDUSERDETAIL_SUCCESS',
    FINDUSERDETAIL_FAILURE: 'FINDUSERDETAIL_FAILURE',

    DELETINACTIVEEUSER_REQUEST: 'DELETINACTIVEEUSER_REQUEST',
    DELETINACTIVEEUSER_SUCCESS: 'DELETINACTIVEEUSER_SUCCESS',
    DELETINACTIVEEUSER_FAILURE: 'DELETINACTIVEEUSER_FAILURE',

    DELETINACTIVEEUSER_TEAM_REQUEST: 'DELETINACTIVEEUSER_TEAM_REQUEST',
    DELETINACTIVEEUSER_TEAM_SUCCESS: 'DELETINACTIVEEUSER_TEAM_SUCCESS',
    DELETINACTIVEEUSER_TEAM_FAILURE: 'DELETINACTIVEEUSER_TEAM_FAILURE',
   
    GETTEAMBYID_REQUEST:"GETTEAMBYID_REQUEST",
    GETTEAMBYID_SUCCESS:"GETTEAMBYID_SUCCESS",
    GETTEAMBYID_FAILURE:"GETTEAMBYID_FAILURE",
}