import styles from './Sales.module.scss';
import Integrations from './components/Integrations/Integrations';

interface Props {
    onclick: any;
    onCansal:any;
}
const Integrations_page = (props: Props) => {

    return (
        <>

            <div className="balance__header" style={{ marginBottom: '35px' }}>
                <div className="balance__header__name">Integrations</div>

            </div>
            <Integrations onclick={props.onclick} onCansal={props.onCansal} />
        </>
    )
}
export default Integrations_page;