import { authHeader,handleResponse } from '../_helpers'

export const counterpartsService = {
    getAllCounterparts,
    getAllTags,
    getUserCounterparts,
    getCounterpartById,
    getPendingPaybles,
    updatePaybles,
    submitPaybles,
    getBudgetTags_services,
    getAllvat_service,
    getbulk_payment_payables,
    updatebulk_payment,
    rejectbulk_payment,
    CeretePaybles,
    getAllCounterparts_list,
    geteditCounterparts,
    edit_Counterparts,
    Counterparts_delete_servicers,
    deletePayee_servicers,
    Delete_Balk_bill,
    Delete_bill,
    getAllBill,
    getAllBill_status,
    CustomersCreate,
    deleteCustomer,
    getCustomerById,
    EditCustomerById,
    getCustomer,
    AlldeleteCustomer
}

// get all counterparts api
function deletePayee_servicers(id){
    const requestOptions = {
        method:'DELETE',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(id)

    }
    return fetch(`${process.env.REACT_APP_API_URL}/counterparts/delete-counterparts`,requestOptions).then(handleResponse)
}
function deleteCustomer(id){
    const requestOptions = {
        method:'DELETE',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/customers/delete-customer/${id}`,requestOptions).then(handleResponse)
}
function AlldeleteCustomer(id){
    const requestOptions = {
        method:'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(id)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/customers/delete-bulk-customer`,requestOptions).then(handleResponse)
}
function EditCustomerById(body,id){
    const requestOptions = {
        method:'PATCH',
        headers:{'Content-Type':'application/json',...authHeader()}, 
        body: JSON.stringify(body)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/customers/update-customer-by-id/${id}`,requestOptions).then(handleResponse)
}
function getCounterpartById(id){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/counterparts/get-counterpart-by-id/${id}`,requestOptions).then(handleResponse)
}
function getCustomerById(id){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/customers/get-customer/${id}`,requestOptions).then(handleResponse)
}
function getCustomer(){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/customers/get-customer-list`,requestOptions).then(handleResponse)
}
// get all counterparts api
function getUserCounterparts(){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/counterparts/get-user-counterparts`,requestOptions).then(handleResponse)
}
function getbulk_payment_payables(data,Limetdata){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/get-pending-bulk-payment-payables?page=${data}&limit=${Limetdata}`,requestOptions).then(handleResponse)
}
// get all counterparts api
function getAllCounterparts(){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/counterparts/get-counterparts`,requestOptions).then(handleResponse)
}
function geteditCounterparts(req){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/counterparts/get-counterpart/${req}`,requestOptions).then(handleResponse)
}
function getAllCounterparts_list(data,type,page,categ,limet){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/counterparts/get-counterpart-list?search=${data}&type=${type}&page=${page}&${categ}&limit=${limet}`,requestOptions).then(handleResponse)
}
function getAllBill(data,type,page,categ,limet){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/counterparts/get-counterpart-list?search=${data}&type=${type}&page=${page}&${categ}&limit=${limet}`,requestOptions).then(handleResponse)
}
function getAllBill_status(){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/counterparts/get-counterpart-list`,requestOptions).then(handleResponse)
}
function getAllTags(){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/counterparts/get-tags`,requestOptions).then(handleResponse)
}
function getAllvat_service(){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/payables/vat-list`,requestOptions).then(handleResponse)
}
function getBudgetTags_services(){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/get-team-users`,requestOptions).then(handleResponse)
}
function getPendingPaybles(){
    const requestOptions = {
        method:'GET',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/payables/get-pending-payables `,requestOptions).then(handleResponse)
}

function updatePaybles(req){
    const requestOptions = {
        method:'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(req)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/payables/update-payables`,requestOptions).then(handleResponse)
}
function updatebulk_payment(req){
    const requestOptions = {
        method:'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(req)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/update-bulk-payment-payables`,requestOptions).then(handleResponse)
}
function rejectbulk_payment(req){
    const requestOptions = {
        method:'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(req)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/update-bulk-payment-payables-reject`,requestOptions).then(handleResponse)
}
function submitPaybles(req){
    const requestOptions = {
        method:'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(req)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/payables/submit-payables`,requestOptions).then(handleResponse)
}
function CeretePaybles(req){
    const requestOptions = {
        method:'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(req)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/counterparts/create`,requestOptions).then(handleResponse)
}
function CustomersCreate(req){
    const requestOptions = {
        method:'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(req)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/customers/create`,requestOptions).then(handleResponse)
}
function edit_Counterparts (bankDetail,id){
    const requestOptions = {
        method:'PATCH',
        headers:{'Content-Type':'application/json',...authHeader()}, 
        body: JSON.stringify(bankDetail)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/counterparts/update-counterpart-by-id/${id}`,requestOptions).then(handleResponse)
}
 
function Counterparts_delete_servicers(id){
    const requestOptions = {
        method:'DELETE',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/counterparts/delete-counterpart/${id}`,requestOptions).then(handleResponse)
}
function Delete_Balk_bill(id){
    const requestOptions = {
        method:'POST',
        headers: {'Content-Type':'application/json',...authHeader()},
        body: JSON.stringify(id)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/payables/delete-bulk-payables`,requestOptions).then(handleResponse)
}
function Delete_bill(id){
    const requestOptions = {
        method:'DELETE',
        headers: {'Content-Type':'application/json',...authHeader()},
    }
    return fetch(`${process.env.REACT_APP_API_URL}/payables/delete/${id}`,requestOptions).then(handleResponse)
}