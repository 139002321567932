import React, { Component } from "react";
import './Bill_data.scss';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Box, Grid, IconButton } from "@mui/material";
import No_bill from "../No_bill/No_bill";
const Example = (props: any) => {
    return (
        <>
        {props?.data?.file?.mimetype != "application/pdf" ?
        <TransformWrapper
            initialScale={1}
            // initialPositionX={200}
            // initialPositionY={100}
        >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                    <Grid container className="grid_conteannGrid">
                        <Grid item md={12} xs={12} sm={12}>
                            <Box className='bxox_zoom_icon'>
                                <IconButton onClick={() => zoomOut()}>
                                    <Zoom_negitiv />
                                </IconButton>
                                <IconButton onClick={() => zoomIn()}>
                                    <Zoom_plas />
                                </IconButton>
                            </Box>
                        </Grid>
                       
                    </Grid>
                    {/* <button onClick={() => zoomIn()}>+</button>
                    <button >-</button>
                    <button onClick={() => resetTransform()}>x</button> */}
                    {/* </Box> */}
                    {/* { } */}
                    <TransformComponent contentStyle={{width:"100%",padding:"4px 0px 0px 0px",height:"calc(100vh - 60px)"}} wrapperStyle={{width:"100%"}}>

                        <Box className='main_contean_boxxx'>
                            {props?.data?.file?.url ?
                                <Box className='box_contean_imgzoom'>
                                    {/* {props?.data?.file?.mimetype != "application/pdf" ? */}
                                        <img src={props?.data?.file?.url} width={'100%'} height='calc(100vh - 80px)' alt="test" /> 
                                        
                                        
                                </Box> : <No_bill />}

                        </Box>
                    </TransformComponent>

                </React.Fragment>
            )}
        </TransformWrapper>:
        <Box className='main_contean_boxxx'>
         <Box className='box_contean_imgzoom' style={{height:"calc(100vh - 95px)"}}>
        <iframe src={props?.data?.file?.url} width="100%" height="100%"></iframe>
        </Box></Box>
        }
        </>
    );
};
export default Example;
const Zoom_negitiv = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 10 10" fill="none">
        <g clip-path="url(#clip0_8376_17695)">
            <path d="M9.79232 9.79134L7.3219 7.32092M2.29232 4.37467H6.45898M4.37565 0.208008C6.6769 0.208008 8.54232 2.07342 8.54232 4.37467C8.54232 6.67592 6.6769 8.54134 4.37565 8.54134C2.0744 8.54134 0.208984 6.67592 0.208984 4.37467C0.208984 2.07342 2.0744 0.208008 4.37565 0.208008Z" stroke="black" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_8376_17695">
                <rect width="10" height="10" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
const Zoom_plas = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 10 10" fill="none">
        <g clip-path="url(#clip0_8376_17701)">
            <path d="M9.79232 9.79134L7.3219 7.32092M2.29232 4.37467H6.45898M4.37565 2.29134V6.45801M4.37565 0.208008C6.6769 0.208008 8.54232 2.07342 8.54232 4.37467C8.54232 6.67592 6.6769 8.54134 4.37565 8.54134C2.0744 8.54134 0.208984 6.67592 0.208984 4.37467C0.208984 2.07342 2.0744 0.208008 4.37565 0.208008Z" stroke="black" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_8376_17701">
                <rect width="10" height="10" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
const Morebutton = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M8.00065 0.333008C8.92132 0.333008 9.66732 1.07901 9.66732 1.99967C9.66732 2.92034 8.92132 3.66634 8.00065 3.66634C7.07998 3.66634 6.33398 2.92034 6.33398 1.99967C6.33398 1.07901 7.07998 0.333008 8.00065 0.333008ZM8.00065 6.33301C8.92132 6.33301 9.66732 7.07901 9.66732 7.99967C9.66732 8.92034 8.92132 9.66634 8.00065 9.66634C7.07998 9.66634 6.33398 8.92034 6.33398 7.99967C6.33398 7.07901 7.07998 6.33301 8.00065 6.33301ZM8.00065 12.333C8.92132 12.333 9.66732 13.079 9.66732 13.9997C9.66732 14.9203 8.92132 15.6663 8.00065 15.6663C7.07998 15.6663 6.33398 14.9203 6.33398 13.9997C6.33398 13.079 7.07998 12.333 8.00065 12.333Z" stroke="#1D1D1D" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)