import styles from './Customers.module.scss';
import { Button } from 'react-bootstrap';
import Customers_Tebal from './components/Customers_Tebal/Customers_Tebal';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import Customers_details from './components/Costomers_details/index';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const Customers = () => {
    const [pages, setPages] = useState(false)
    const [dataStore, setDataStore] = useState<any>()
    const [searchParams, setSearchParams] = useSearchParams();
    const[id,setId]=useState('')

    const theme = createTheme({
        status: {
            danger: "#025041",
        },
    });
    const navigate = useNavigate();
    useEffect(() => {
        const id:any = searchParams.get('id');
            setId(id)
      }, [searchParams]);
    return (
        <>
            <div className="balance">
                <div className="balance__header">
                    <div className="balance__header__name">Customers</div>
                    <div>
                        <>
                            <Button
                                className="balance__save-btn"
                                onClick={() => {
                                    navigate("/create-customer")
                                }}
                            >
                                Create new
                            </Button>
                        </>
                    </div>
                </div>
                <ThemeProvider theme={theme}>
                    {!!id ?
                        <Customers_details id={id} data={dataStore} />
                        :<Customers_Tebal   /> 
                        }
                </ThemeProvider>
            </div>
        </>
    )
}
export default Customers;