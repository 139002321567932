import React, { FC, useEffect, useState } from 'react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import LimitedLiability from './LimitedLiability';
import LimitedLiabilityStepTwo from './LimitedLiabilityStepTwo';
import '../Settings.scss';
import {
  Formik,
  FormikValues,
  FormikHelpers,
  FormikConfig,
  setIn,
} from 'formik';
import { getBase64 } from '../../../../../../../../../_helpers/common';
import { choiceActions } from '../../../../../../../../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import { settingSelector } from '../../../../../../../../../_reducers/setting.reducer';
// import { toast } from '@team-monite/ui-widgets-react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { authHeader } from '../../../../../../../../../_helpers';

interface Props extends FormikConfig<FormikValues> {
  children: React.ReactNode;
  stepOneInfo: any;
  stepTwoInfo: any;
  businessDocs: any;
  setSetpNumberAuth: any;
  updateStep: any;
  api: any;
  LoaderApi: any;
}

// const steps = ['', ''];
const LimitedLiabilityForm: FC<Props> = ({
  children,
  initialValues,
  stepOneInfo,
  stepTwoInfo,
  businessDocs,
  onSubmit,
  setSetpNumberAuth,
  updateStep,
  api,
  LoaderApi,
}) => {
  const [stepNumber, setStepNumber] = useState(0);

  const [snapshot, setSnapshot] = useState<any>(initialValues);
  const steps = React.Children.toArray(children) as React.ReactElement[];

  console.log('steps.....:', steps);

  console.log('snapshot', snapshot);

  const step = steps[stepNumber];
  const totalSteps = steps.length;

  const isLastStep = stepNumber === totalSteps - 1;
  const dispatch = useDispatch();

  const certificateLabel = 'Registration certificate';
  const crLabel = 'Register of directors & shareholders';
  const memorandumLabel = 'Memorandum and articles of association ';
  const resolutionLabel = 'Original board resolutions on company letter head ';
  const kraLabel = 'Tax certificate';
  const ultimateBeneficiaryLabel = 'Ultimate Beneficiary owners ';
  const articleOfAssociationLabel = 'article of associations ';
  const passportLabel = 'Passport';
  const [passportFile, setPassportFile] = useState(passportLabel);
  const [certificateFile, setCertificateFile] = useState(certificateLabel);
  const [crFile, setCrFile] = useState(crLabel);
  const [memorandumFile, setMemorandumFile] = useState(memorandumLabel);
  const [resolutionFile, setResolutionFile] = useState(resolutionLabel);
  const [ultimateBeneficiaryFile, setUltimateBeneficiaryFile] = useState(
    ultimateBeneficiaryLabel
  );
  const [articleOfAssociationFile, setArticleOfAssociationFile] = useState(
    articleOfAssociationLabel
  );
  const licenseLabel = 'License';
  const [kraFile, setkraFile] = useState(kraLabel);
  const [fileSize, setFileSize] = useState<any>('');
  const [invoiceImage, setinvoiceImage] = useState<any>('');
  const [licenseFile, setLicenseFile] = useState(licenseLabel);
  const [loader, setLoader] = useState(false);
  const shareHolderDocLabel: any = {
    selfieLabel: 'Passport photo',
    idFrontLabel: 'National ID - frontside',
    idBackLabel: 'National ID - backside',
    kraPinLabel: 'Tax identification document',
    passportLabel: 'Passport',
  };
  const [shareHolderLabels, setShareHolderLabels] = useState<any>([
    { ...shareHolderDocLabel },
  ]);
  const selfieLabel = 'Passport photo';
  const idFrontLabel = 'National ID - frontside';
  const idBackLabel = 'National ID - backside';
  const kraPinLabel = 'Tax identification document';
  const [selfie, setSelfie] = useState(selfieLabel);
  const [idFront, setIdFront] = useState(idFrontLabel);
  const [idBack, setIdBack] = useState(idBackLabel);
  const [kraPin, setKraPin] = useState(kraPinLabel);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('initial values:', initialValues);
    if (!!businessDocs) {
      setCertificateFile(
        !!businessDocs.data?.data?.businessCertificate
          ? businessDocs?.data?.data?.businessCertificate?.file_name
          : certificateLabel
      );

      setLicenseFile(
        !!businessDocs.data?.data?.license?.file_name
          ? businessDocs.data?.data?.license?.file_name
          : licenseLabel
      );
      setMemorandumFile(
        !!businessDocs.data?.data?.memorandum
          ? businessDocs?.data?.data?.memorandum?.file_name
          : memorandumLabel
      );
      setResolutionFile(
        !!businessDocs.data?.data?.resolution
          ? businessDocs.data?.data?.resolution?.file_name
          : resolutionLabel
      );
      setCrFile(
        businessDocs.data?.data?.cr
          ? businessDocs.data?.data?.cr.file_name
          : crLabel
      );

      setkraFile(
        businessDocs.data?.data?.kraPinCertificate
          ? businessDocs.data?.data?.kraPinCertificate.file_name
          : kraLabel
      );

      setSnapshot({
        ...initialValues,
        businessCertificate: businessDocs.data?.data?.businessCertificate?.url,
        cr: businessDocs.data?.data?.cr?.url,
        memorandum: businessDocs.data?.data?.memorandum?.url,
        resolution: businessDocs.data?.data?.resolution?.url,
        kraPinCertificate: businessDocs.data?.data?.kraPinCertificate?.url,
      });
    }
  }, [businessDocs]);
  console.log(window?.location?.search, 'window.location.pathname');
  useEffect(() => {
    if (window?.location?.search.includes('step-3')) {
      setStepNumber(1);
    } else {
      setStepNumber(0);
    }
  }, [window?.location?.search]);
  const handleAddNewDirector = () => {
    setShareHolderLabels([...shareHolderLabels, shareHolderDocLabel]);
  };

  const handleUpdateLabel = (index: any, fieldName: any, label: any) => {
    console.log('handleUpdateLabel >>>>', index, fieldName, label);
    console.log('shareHolderLabels >>>>', shareHolderLabels);
    const updatedLabels = [...shareHolderLabels];
    updatedLabels[index][fieldName + 'Label'] = label;
    console.log('updated Labels >>>>', updatedLabels);
    setShareHolderLabels(updatedLabels);
  };

  const resetLabel = (index: any, fieldName: any) => {
    // Make a copy of the shareHolderLabels array
    const updatedLabels = [...shareHolderLabels];

    // Reset the label for the specified field name at the specified index
    updatedLabels[index][fieldName + 'Label'] =
      shareHolderDocLabel[fieldName + 'Label'];

    // Update the state with the modified labels
    setShareHolderLabels(updatedLabels);
  };
  const handleLoader = (value: boolean) => {
    setLoader(value);
  };
  const handleImage = (e: any, formik?: any, index?: any) => {
    let field = e.target.name;
    let extension = e.target.files[0].name.split('.').pop();
    extension = extension.toLowerCase();
    if (e.target.files[0] !== null && e.target.files[0] !== undefined) {
      setFileSize(e.target.files[0].size);
      let title: any = null;
      let mediaType: any = null;
      if (field === 'businessCertificate') {
        setCertificateFile(e.target.files[0].name);
        title = field;
        mediaType = 'KYCF00024';
      } else if (field === 'cr') {
        setCrFile(e.target.files[0].name);
        title = field;
        mediaType = 'KYCF00011';
      } else if (field === 'memorandum') {
        setMemorandumFile(e.target.files[0].name);
        title = field;
        mediaType = 'KYCF00012';
      } else if (field === 'resolution') {
        setResolutionFile(e.target.files[0].name);
        title = field;
        mediaType = 'KYCF00014';
      } else if (field === 'kraPinCertificate') {
        setkraFile(e.target.files[0].name);
        mediaType = 'KYCF00013';
        title = field;
      } else if (field === 'license') {
        setLicenseFile(e.target.files[0].name);
        title = field;
        mediaType = 'NIOBI00009';
      } else if (field === 'ultimateBeneficiary') {
        setUltimateBeneficiaryFile(e.target.files[0].name);
        mediaType = 'KYCF00023';
        title = field;
      } else if (field === 'articleOfAssociation') {
        setArticleOfAssociationFile(e.target.files[0].name);
        mediaType = 'KYCF00015';
        title = field;
      } else if (field.includes('selfieFile')) {
        // console.log('selfieFile getting here', e.target.files[0].name);
        // setSelfie(e.target.files[0].name);
        handleUpdateLabel(index, 'selfie', e.target.files[0].name);
        if (formik)
          formik.setFieldValue(
            `shareholders[${index}].selfieFile`,
            e.target.files[0].name
          );
        mediaType = 'KYCF00006';
        title = field;
      } else if (field.includes('idFrontSideFile')) {
        // setIdFront(e.target.files[0].name);
        handleUpdateLabel(index, 'idFront', e.target.files[0].name);
        title = field;
        mediaType = 'KYCF00001';
        if (formik)
          formik.setFieldValue(
            `shareholders[${index}].idFrontSideFile`,
            e.target.files[0].name
          );
      } else if (field.includes('passportFile')) {
        console.log('here is passportFile');
        handleUpdateLabel(index, 'passport', e.target.files[0].name);
        if (formik)
          formik.setFieldValue(
            `shareholders[${index}].passportFile`,
            e.target.files[0].name
          );
        title = e.target.files[0].name;
        mediaType = 'KYCF00001';
      } else if (field.includes('idBackSideFile')) {
        handleUpdateLabel(index, 'idBack', e.target.files[0].name);
        if (formik)
          formik.setFieldValue(
            `shareholders[${index}].idBackSideFile`,
            e.target.files[0].name
          );
        title = e.target.files[0].name;
        mediaType = 'KYCF00002';
      } else if (field.includes('kraPinFile')) {
        if (formik)
          formik.setFieldValue(
            `shareholders[${index}].kraPinFile`,
            e.target.files[0].name
          );
        handleUpdateLabel(index, 'kraPin', e.target.files[0].name);
        mediaType = 'KYCF00009';
        title = e.target.files[0].name;
      }
      getBase64(e.target.files[0], (result: any) => {
        setinvoiceImage(result);
        if (typeof formik !== 'undefined') {
          formik.setFieldValue(field, result);
        }
        const payload = {
          title: title,
          document_type: '3',
          mediaBase64: result,
          type: 'business_documents',
          mediaType,
          contentType: extension === 'pdf' ? 'pdf' : 'image',
        };
        console.log('the payload is:', payload);
        dispatch(choiceActions.uploadMedia(payload, handleLoader) as any);
        if (field.includes('passportFile')) {
          const payload = {
            title: title,
            document_type: '1',
            mediaBase64: result,
            type: 'business_documents',
            mediaType: 'KYCF00002',
            contentType: extension === 'pdf' ? 'pdf' : 'image',
          };
          console.log('the payload is:', payload);
          dispatch(
            choiceActions.uploadMedia(payload, handleLoader, true) as any
          );
        }
      });
    }
  };

  const handleRemoveImage = (
    e: any,
    fileName: string,
    formik: any,
    index?: any
  ) => {
    if (fileName !== null && fileName !== undefined) {
      switch (fileName) {
        case 'businessCertificate':
          setCertificateFile(certificateLabel);
          setinvoiceImage('');
          if (formik) formik.setFieldValue('businessCertificate', '');
          break;
        case 'license':
          setLicenseFile(licenseLabel);
          setinvoiceImage('');
          if (formik) formik.setFieldValue('license', '');
          break;
        case 'cr':
          setCrFile(crLabel);
          setinvoiceImage('');
          if (formik) formik.setFieldValue('cr', '');
          break;
        case 'memorandum':
          setMemorandumFile(memorandumLabel);
          setinvoiceImage('');
          if (formik) formik.setFieldValue('memorandum', '');
          break;
        case 'resolution':
          setResolutionFile(resolutionLabel);
          if (formik) formik.setFieldValue('resolution', '');
          setinvoiceImage('');
          break;
        case 'kraPinCertificate':
          setkraFile(kraLabel);
          setinvoiceImage('');
          if (formik) formik.setFieldValue('kraPinCertificate', '');
          break;
        case 'ultimateBeneficiary':
          setUltimateBeneficiaryFile(ultimateBeneficiaryLabel);
          if (formik) formik.setFieldValue('ultimateBeneficiary', '');
          setinvoiceImage('');
          break;
        case 'articleOfAssociation':
          setArticleOfAssociationFile(articleOfAssociationLabel);
          if (formik) formik.setFieldValue('articleOfAssociation', '');
          setinvoiceImage('');
          break;
        case 'selfie':
          resetLabel(index, 'selfie');
          if (formik)
            formik.setFieldValue(`shareholders[${index}].selfieFile`, '');
          formik.setFieldValue(`shareholders[${index}].selfieFileName`, '');
          setSelfie(selfieLabel);
          setinvoiceImage('');
          break;
        case 'idFront':
          resetLabel(index, 'idFront');
          if (formik)
            formik.setFieldValue(`shareholders[${index}].idFrontSideFile`, '');
          formik.setFieldValue(`shareholders[${index}].idFrontSideFileName`, '');
          setIdFront(idFrontLabel);
          setinvoiceImage('');
          break;
        case 'idBack':
          resetLabel(index, 'idBack');
          if (formik)
            formik.setFieldValue(`shareholders[${index}].idBackSideFile`, '');
          formik.setFieldValue(`shareholders[${index}].idBackSideFileName`, '');
          setIdBack(idBackLabel);
          setinvoiceImage('');
          break;
        case 'passport':
          resetLabel(index, 'passport');
          if (formik)
            formik.setFieldValue(`shareholders[${index}].passportFile`, '');
          formik.setFieldValue(`shareholders[${index}].passportFileName`, '');
          setPassportFile(passportLabel);
          setinvoiceImage('');
          break;
        case 'kra':
          resetLabel(index, 'kraPin');
          if (formik)
            formik.setFieldValue(`shareholders[${index}].kraPinFile`, '');
          formik.setFieldValue(`shareholders[${index}].kraPinFileName`, '');
          setKraPin(kraPinLabel);
          setinvoiceImage('');
          break;
        default:
          break;
      }
    }
    if (formik && e) {
      let field = e.target.name;
      formik.setFieldValue(field, '');
    }
  };

  const handleNext = (values: FormikValues) => {
    console.log(' $$$$ Current Formik values:', values);
    setSnapshot(values);
    setStepNumber((prevStepNumber) => prevStepNumber + 1);
  };

  const handleBack = (values: FormikValues) => {
    setSnapshot(values);
    setStepNumber((prevStepNumber) => prevStepNumber - 1);
  };

  const handleSubmit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    console.log(' $$$$ Current Formik values:', values);
    if (step.props.onSubmit) {
      await step.props.onSubmit;
    }
    if (isLastStep) {
      console.log(' $$$$ Current Formik values:', values);
      return onSubmit(values, actions);
    } else {
      console.log(' $$$$ Current Formik values:', values);

      delete values.businessCertificate
      delete values.cr
      delete values.kraPinCertificate
      delete values.memorandum
      delete values.resolution
      values.shareholders = []
      dispatch(
        choiceActions.saveBusinessDetails({ ...values }, () => {
          handleNext(values);
          if (stepNumber === 0 && api == '/v2/create-entity') {
            setSetpNumberAuth(2);
            updateStep(2, true, navigate('/v2/document-upload?step-3'));
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          }
          toast.success('Business details saved ');
        }) as any
      );
      actions.setTouched({});

    }
  };
  const uploadchaoic = (e: any, formik?: any, index?: any) => {
    getBase64(e.target.files[0], (result: any) => {
      setinvoiceImage(result);

      let field = e.target.name;
      let extension = e.target.files[0].name.split('.').pop();
      extension = extension.toLowerCase();
      var data = { file: result }
      // console.log('media data in choice service', mediaData);
      const requestOptions: any = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data),
      };
      // return response.json();
      handleLoader(true);
      fetch(`${process.env.REACT_APP_API_URL}/upload-file`,
        requestOptions
      ).then((res) => {
        return res.json();
      })
        .then((data) => {
          console.log(data, "ASDADaDdasddddsadd");
          // setPhotos(data);
          // var NewArray = []
          // for (let index = 0; index < snapshot.shareholders.length; index++) {
          //   const element = snapshot.shareholders[index];
          // if (field === 'businessCertificate') {
          //   setCertificateFile(e.target.files[0].name);
          //   formik.setFieldValue(field,
          //     data?.file_id
          //   );
          //   // title = field;
          //   // mediaType = 'KYCF00024';
          // } else if (field === 'cr') {
          //   setCrFile(e.target.files[0].name);
          //   formik.setFieldValue(field,
          //     data?.file_id
          //   );
          // } else if (field === 'memorandum') {
          //   setMemorandumFile(e.target.files[0].name);
          //   formik.setFieldValue(field,
          //     data?.file_id
          //   );
          // } else if (field === 'resolution') {
          //   setResolutionFile(e.target.files[0].name);
          //   formik.setFieldValue(field,
          //     data?.file_id
          //   );
          // } else if (field === 'kraPinCertificate') {
          //   setkraFile(e.target.files[0].name);
          //   // mediaType = 'KYCF00013';
          //   // title = field;
          // } else if (field === 'license') {
          //   setLicenseFile(e.target.files[0].name);
          //   formik.setFieldValue(field,
          //     data?.file_id
          //   );
          // } else if (field === 'ultimateBeneficiary') {
          //   setUltimateBeneficiaryFile(e.target.files[0].name);
          //   formik.setFieldValue(field,
          //     data?.file_id
          //   );
          // } else if (field === 'articleOfAssociation') {
          //   setArticleOfAssociationFile(e.target.files[0].name);
          //   formik.setFieldValue(field,
          //     data?.file_id
          //   );
          // }
          handleLoader(false);
          if (data.success === true) {
            toast.success('Upload successful');
            if (field.includes('selfieFile')) {
              // console.log('selfieFile getting here', e.target.files[0].name);
              // setSelfie(e.target.files[0].name);
              handleUpdateLabel(index, 'selfie', e.target.files[0].name);
              if (formik)
                formik.setFieldValue(
                  `shareholders[${index}].selfieFile`,
                  data?.file_id
                );
              formik.setFieldValue(
                `shareholders[${index}].selfieFileName`,
                e.target.files[0].name
              );
            } else if (field.includes('idFrontSideFile')) {
              // setIdFront(e.target.files[0].name);
              handleUpdateLabel(index, 'idFront', e.target.files[0].name);
              if (formik)
                formik.setFieldValue(
                  `shareholders[${index}].idFrontSideFile`,
                  data?.file_id
                );
              formik.setFieldValue(
                `shareholders[${index}].idFrontSideFileName`,
                e.target.files[0].name
              );
            } else if (field.includes('passportFile')) {
              console.log('here is passportFile');
              handleUpdateLabel(index, 'passport', e.target.files[0].name);
              if (formik)
                formik.setFieldValue(
                  `shareholders[${index}].passportFile`,
                  data?.file_id
                );
              formik.setFieldValue(
                `shareholders[${index}].passportFileName`,
                e.target.files[0].name
              );
            } else if (field.includes('idBackSideFile')) {
              handleUpdateLabel(index, 'idBack', e.target.files[0].name);
              if (formik)
                formik.setFieldValue(
                  `shareholders[${index}].idBackSideFile`,
                  data?.file_id
                );
              formik.setFieldValue(
                `shareholders[${index}].idBackSideFileName`,
                e.target.files[0].name
              );
            } else if (field.includes('kraPinFile')) {
              if (formik)
                formik.setFieldValue(
                  `shareholders[${index}].kraPinFile`,
                  data?.file_id
                );
              formik.setFieldValue(
                `shareholders[${index}].kraPinFileName`,
                e.target.files[0].name
              );
              handleUpdateLabel(index, 'kraPin', e.target.files[0].name);
            }
          } else {
            toast.error(data.message);
          }
          // }

        });
      // console.log(response,'sdffsffsfffff');

      // }
      // );
    })
  };
  return (
    <>
      {/* <button
        onClick={handleBack}
        className="back_btn"
        disabled={stepNumber === 0}
      >
        Back
      </button> */}
      <div className="complete-profile__heading">
        Please provide the following documents
      </div>

      <Formik
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
        enableReinitialize
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            {stepNumber === 0 && (
              <>
                <LimitedLiability
                  formik={formik}
                  certificateFile={certificateFile}
                  certificateLabel={certificateLabel}
                  crLabel={crLabel}
                  crFile={crFile}
                  memorandumLabel={memorandumLabel}
                  memorandumFile={memorandumFile}
                  resolutionLabel={resolutionLabel}
                  resolutionFile={resolutionFile}
                  ultimateBeneficiaryLabel={ultimateBeneficiaryLabel}
                  ultimateBeneficiaryFile={ultimateBeneficiaryFile}
                  articleOfAssociationLabel={articleOfAssociationLabel}
                  articleOfAssociationFile={articleOfAssociationFile}
                  licenseLabel={licenseLabel}
                  licenseFile={licenseFile}
                  kraLabel={kraLabel}
                  handleRemoveImage={handleRemoveImage}
                  handleImage={handleImage}
                  fileSize={fileSize}
                  loader={loader}
                  kraFile={kraFile}
                />
              </>
            )}
            {stepNumber === 1 && (
              <>
                <LimitedLiabilityStepTwo
                  formik={formik}
                  idFront={idFront}
                  idFrontLabel={idFrontLabel}
                  shareHolderLabels={shareHolderLabels}
                  handleAddNewDirector={handleAddNewDirector}
                  idBackLabel={idBackLabel}
                  idBack={idBack}
                  kraPin={kraPin}
                  kraPinLabel={kraPinLabel}
                  selfie={selfie}
                  selfieLabel={selfieLabel}
                  passportFile={passportFile}
                  passportLabel={passportLabel}
                  handleImage={handleImage}
                  handleRemoveImage={handleRemoveImage}
                  loader={loader}
                  uploadchaoic={uploadchaoic}
                />
              </>
            )}
            <Box className="complete-profile__boxbtndisplay">
              {stepNumber > 0 && (
                <button
                  className="complete-profile__goback"
                  onClick={() => handleBack(formik.values)}
                >
                  Back
                </button>
              )}
              {stepNumber === 0 && (
                <button type="submit" className="complete-profile__goback">
                  Save
                </button>
              )}
              <button
                type="submit"
                onClick={() => { }}
                style={{ display: 'flex', alignItems: 'center',justifyContent:"center" }}
                className="complete-profile__submitbtnlist"
              >
                {LoaderApi == true && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                {isLastStep ? 'Proceed' : 'Proceed'}
              </button>
            </Box>
          </form>
        )}
      </Formik>
      {/* <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={activeStep === steps.length - 1}
        >
          {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
        </Button>
        {activeStep > 0 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleBack}
            sx={{ marginLeft: 8 }}
          >
            Back
          </Button>
        )}
      </Grid> */}
    </>
  );
};

export default LimitedLiabilityForm;
export const FormStep = ({ stepName = '', children }: any) => children;
