import { Box, Grid, IconButton } from '@mui/material';
import './Bill_tebal.scss';
import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import { getUser, isUserAuthenticated, authHeader } from '../../../../../../_helpers';
import axios from 'axios';
import moment from 'moment';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import DatePickerll from 'react-datepicker';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useDispatch, useSelector } from "react-redux";
import { counterpartsActions } from '../../../../../../_actions/counterparts.actions'
import { CssBaseline, CircularProgress } from '@mui/material';
import SkeletonCom from '../../../Skeleton/Skeleton';

interface HeadCell {
    disablePadding: boolean;
    id: any;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'Invoice',
        numeric: false,
        disablePadding: true,
        label: 'Invoice #',
    },
    {
        id: 'Counterpart',
        numeric: true,
        disablePadding: false,
        label: 'Counterpart',
    },
    {
        id: 'Issue_date',
        numeric: true,
        disablePadding: false,
        label: 'Issue date',
    },
    {
        id: 'Invoice_date',
        numeric: true,
        disablePadding: false,
        label: 'Invoice date	',
    },

    {
        id: 'Due_date',
        numeric: true,
        disablePadding: false,
        label: 'Due date',
    },
    {
        id: 'Status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'Amount',
        numeric: true,
        disablePadding: false,
        label: 'Amount',
    },
    {
        id: 'Description',
        numeric: true,
        disablePadding: false,
        label: 'Description',
    },
    {
        id: 'pay',
        numeric: true,
        disablePadding: false,
        label: '',
    },
    {
        id: 'info',
        numeric: true,
        disablePadding: false,
        label: '',
    }

];
const Bill_tebal = () => {
    var localUser: any = localStorage.getItem("user");
    const [search, setSearch] = React.useState('');
    const [categ, setCateg] = React.useState('');
    const [loderinapi, setLoderapi] = React.useState(false);
    const [datatabal, setDatatebal] = React.useState<any>([

    ]);
    const [selectData, setSelecetData] = useState([])
    const [statsData, setStatsData] = useState([
        { name: "New", APIdata: "new" }
        , { name: "Pending", APIdata: "pending" },
        { name: "Rejected", APIdata: "rejected" },
        { name: "Waiting to be paid", APIdata: "waiting_to_be_paid" },
        { name: "Paid", APIdata: "paid" },
        { name: "Draft", APIdata: "draft" },
        { name: "Canceled", APIdata: "canceled" }])
    const [totalCount, setTotalCount] = React.useState(10);
    const [tagsList, setTagsList] = React.useState<any>([]);
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();
    const [pageneshn, setPagelist] = React.useState(1);
    const [remove, setRemovedata] = React.useState(10);
    const [dense, setDense] = React.useState(false);
    const [selected, setSelected] = React.useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [page, setPage] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [delete_data, setDelete_data] = React.useState('')
    const [ideditdata, setIdeditdata] = React.useState('')
    const [Redusar, setRedusar] = useState(true)
    const [loading, setLoading] = useState(false)
    const open = Boolean(anchorEl);
    console.log(ideditdata, "ideditdataideditdata");

    const today = new Date();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const handleClick_datat = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setDelete_data("")
        setIdeditdata("")
    };
    const getBalanceList = async (search: any, categ: any, stateDate: any, endDate: any, pageneshn: any) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', ...authHeader() },
        };
        const stateDateApi = !!stateDate
            ? moment(stateDate).format('YYYY-MM-DD')
            : '';
        const endDateApi = !!endDate
            ? moment(endDate).format('YYYY-MM-DD')
            : '';
        const category = !!categ ? categ : '';

        var pagelimit = 10;
        setLoderapi(true);
        const response = await axios
            .get(
                `${process.env.REACT_APP_API_URL}${'/payables/get-pending-payables'}?search=${search
                }&status=${category}&invoice_date=${stateDateApi}&due_date=${endDateApi}&page=${pageneshn
                }&limit=${pagelimit}`,
                requestOptions
            )
            .then((response) => {
                if (response.data.success == true) {
                    setDatatebal(response.data.data.data);
                    setTotalCount(response.data.data.total);
                    const newSelected: any = []

                    for (let index = 0; index < response.data.data.data.length; index++) {
                        const element = response.data.data.data[index];
                        if (element.status == "paid") {

                        } else {
                            newSelected.push(element.id)
                        }
                    }
                    setSelecetData(newSelected)
                    setTimeout(() => {
                        setLoderapi(false);
                    }, 2000);
                } else {
                    setTimeout(() => {
                        setLoderapi(false);
                    }, 6000);
                }
            });
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.checked, "eventevent123");

        if (event.target.checked) {
            const newSelected: any = []
            for (let index = 0; index < datatabal.length; index++) {
                const element = datatabal[index];
                if (element.status == "paid") {

                } else {
                    newSelected.push(element.id)
                }
            }
            setSelected(newSelected);
            return;
        } else {
            setSelected([]);
        }

    };
    const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };
    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - datatabal.length) : 0;
    // const emptyRows =
    // page > 0 ? Math.max(0, (1 + page) * rowsPerPage - datatabal.length) : 0;
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    function doneTyping() {
        getBalanceList(search, categ, startDate, endDate, pageneshn);
        setLoderapi(true);
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            doneTyping();
            console.log('usereffect testing');
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [search]);
    useEffect(() => {
        if (isUserAuthenticated() && Redusar) {
            const stateDateApi: any = !!startDate ? moment(startDate).format('YYYY-MM-DD') : ""
            const endDateApi = !!endDate
                ? moment(endDate).format('YYYY-MM-DD')
                : '';
            getBalanceList(search, categ, stateDateApi, endDateApi, pageneshn)
        }
    }, [Redusar, isUserAuthenticated])
    // useEffect(() => {
    //     if (Redusar) {
    //         const stateDateApi: any = !!startDate ? moment(startDate).format('YYYY-MM-DD') : ""
    //         const endDateApi = !!endDate
    //             ? moment(endDate).format('YYYY-MM-DD')
    //             : '';
    //         getBalanceList(search, categ, stateDateApi, endDateApi, pageneshn)
    //     }
    // }, [startDate, endDate, pageneshn])

    const handleShow = () => {
        const stateDateApi: any = !!startDate ? moment(startDate).format('YYYY-MM-DD') : ""
        const endDateApi = !!endDate
            ? moment(endDate).format('YYYY-MM-DD')
            : '';
        dispatch(counterpartsActions.Delete_bill(ideditdata) as any);
        setLoading(true)
        setTimeout(() => {
            getBalanceList(search, categ, stateDateApi, endDateApi, pageneshn)
            setSelected([])
        }, 4000)
    }
    const rejectSubmit = () => {
        const body = {
            "data": selected
        }
        dispatch(counterpartsActions.Delete_Balk_bill(body) as any);
        const stateDateApi: any = !!startDate ? moment(startDate).format('YYYY-MM-DD') : ""
        const endDateApi = !!endDate
            ? moment(endDate).format('YYYY-MM-DD')
            : '';
        setLoading(true)
        setTimeout(() => {
            getBalanceList(search, categ, stateDateApi, endDateApi, pageneshn)
            setSelected([])
        }, 4000)
    }
    return (
        <>
            <ThemeProvider theme={theme}>
                {/* {loderinapi && <Box
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        top: 150,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 10,
                    }}
                >
                    <CircularProgress color="success" />
                </Box>} */}
                <Grid container spacing={1.9} className='grid_bill_container'>
                    <SkeletonCom loader={loderinapi}
                        element={
                            <Grid item md={2.9} xs={12}>
                                {/* <Box className='box_grid_filter'> */}
                                <div
                                    className="conteanaha_data_ketete"
                                // style={{ width: '48%' }}
                                >
                                    <InputGroup className="balance__filter-wrapper input">
                                        <InputGroup.Text id="basic-addon1" className="icon">
                                            <SearchIcon />
                                        </InputGroup.Text>
                                        <Form.Control
                                            className="balance-search-input"
                                            placeholder="Search by name or invoice no."
                                            aria-label="Search"
                                            aria-describedby="basic-addon1"
                                            disabled={loderinapi}
                                            value={search}
                                            onChange={(event) => {
                                                console.log('testing');
                                                if (event.target.value == '') {
                                                    setSearch(event.target.value);
                                                } else {
                                                    setSearch(event.target.value);
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                </div>
                            </Grid>}></SkeletonCom>
                    <SkeletonCom loader={loderinapi}
                        element={
                            <Grid item md={1.9} xs={12}>
                                <div className=" balance_cat">
                                    <Form.Select
                                    
                                        onChange={(e: any) => {
                                            setCateg(e.target.value);
                                            getBalanceList(search, e.target.value, startDate, endDate, pageneshn);
                                        }}
                                        value={categ}
                                    >
                                        <option selected value="">
                                            Select status
                                        </option>
                                        {statsData?.map(
                                            (arrayElement: any, index: number) => {
                                                return (
                                                    <option
                                                        value={arrayElement.APIdata}
                                                        key={index + 1}
                                                    >
                                                        {arrayElement.name}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Form.Select>
                                </div>

                            </Grid>}></SkeletonCom>
                    <SkeletonCom loader={loderinapi}
                        element={
                            <Grid item md={1.9} xs={12}>
                                <div
                                    id='select_up_div'

                                    className=" balance_cat"
                                >
                                    <DatePickerll
                                        placeholderText="Invoice date"
                                        dateFormat="dd MMMM yyyy"
                                        className='dashboard-container__datepicker'
                                        selected={startDate}
                                        onChange={(date: Date) => {
                                            //@ts-ignore
                                            setStartDate(date);
                                            const endDateApi = !!endDate
                                                ? moment(endDate).format('YYYY-MM-DD')
                                                : '';
                                            const stateDateApi: any = !!date ? moment(date).format('YYYY-MM-DD') : ""
                                            getBalanceList(search, categ, stateDateApi, endDateApi, pageneshn)

                                            console.log(date, 'clicked start');
                                        }}
                                        selectsStart
                                    // startDate={startDate}
                                    // endDate={endDate}
                                    // maxDate={today}
                                    />
                                </div>
                            </Grid>}></SkeletonCom>
                    <SkeletonCom loader={loderinapi}
                        element={
                            <Grid item md={1.9} xs={12}>
                                <div

                                    id="select_up_div"
                                    className=" balance_cat"
                                >
                                    <DatePickerll
                                        placeholderText="Due date"
                                        dateFormat="dd MMMM yyyy"
                                        className='dashboard-container__datepicker'
                                        selected={endDate}
                                        onChange={(date: Date) => {
                                            //@ts-ignore
                                            setEndDate(date);
                                            const endDateApi = !!date
                                                ? moment(date).format('YYYY-MM-DD')
                                                : '';
                                            const stateDateApi: any = !!startDate ? moment(startDate).format('YYYY-MM-DD') : ""
                                            getBalanceList(search, categ, stateDateApi, endDateApi, pageneshn)
                                            console.log('clicked end');
                                        }}
                                        selectsEnd
                                    // startDate={startDate}
                                    // endDate={endDate}
                                    // minDate={startDate}
                                    // maxDate={today}
                                    />
                                </div>
                                {/* </Box> */}

                            </Grid>}></SkeletonCom>


                    <Grid item md={12} xs={12}>
                        <SkeletonCom loader={loderinapi}
                            element={
                                <>
                                    <Paper sx={{ width: '100%', mb: 2, marginTop: "20px", boxShadow: "0px 2px 14px 2px rgba(174, 174, 174, 0.25)" }}>
                                        <TableContainer>
                                            <Table
                                                className='contenar_tebal_liner'
                                                // sx={{ minWidth: 750 }}
                                                aria-labelledby="tableTitle"
                                                size={dense ? 'small' : 'medium'}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                icon={<img src="/Checkbox.svg" />}
                                                                checkedIcon={<img src="/Checkbox_two.svg" />}
                                                                indeterminate={selected.length > 0 && selected.length < selectData.length}
                                                                checked={selectData.length > 0 && selected.length === selectData.length}
                                                                onChange={handleSelectAllClick}
                                                                inputProps={{
                                                                    'aria-label': 'select all desserts',
                                                                }}
                                                            />
                                                        </TableCell>
                                                        {headCells.map((headCell) => (
                                                            <TableCell style={headCell.label == "Description" ? { textAlign: "center" } : {}} >
                                                                {headCell.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {datatabal && datatabal.length > 0 ?
                                                        datatabal.map((data: any, index: any) => {
                                                            const isItemSelected = isSelected(data.id);
                                                            const labelId = `enhanced-table-checkbox-${index}`;
                                                            console.log(data, 'datadata', index);

                                                            return (
                                                                <TableRow

                                                                    // hover
                                                                    // onClick={(event) => handleClick(event, row.id)}
                                                                    role="checkbox"
                                                                    aria-checked={isItemSelected}
                                                                    tabIndex={-1}
                                                                    key={index}
                                                                    selected={isItemSelected}
                                                                    sx={{ cursor: 'pointer' }}
                                                                >
                                                                    <TableCell padding="checkbox">
                                                                        <Checkbox
                                                                            icon={<img src="/Checkbox.svg" />}
                                                                            disabled={data.status == "paid" ? true : false}
                                                                            checkedIcon={<img src="/Checkbox_two.svg" />}
                                                                            onClick={(event) => handleClick(event, data.id)}
                                                                            color="primary"
                                                                            checked={isItemSelected}
                                                                            inputProps={{
                                                                                'aria-labelledby': labelId,
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell onClick={() => {
                                                                        navigate("/bills?id=" + data.payable_id, { replace: true, state: { id: data.payable_id } })
                                                                    }} style={{ cursor: "pointer" }} >
                                                                        {data.document_id}
                                                                    </TableCell>
                                                                    <TableCell onClick={() => {
                                                                        navigate("/bills?id=" + data.payable_id, { replace: true, state: { id: data.payable_id } })
                                                                    }}>
                                                                        {data.counterpart_name}
                                                                    </TableCell>
                                                                    <TableCell onClick={() => {
                                                                        navigate("/bills?id=" + data.payable_id, { replace: true, state: { id: data.payable_id } })
                                                                    }}>
                                                                        {moment(data.created_at).format(
                                                                            'DD-MMM-YYYY'
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell onClick={() => {
                                                                        navigate("/bills?id=" + data.payable_id, { replace: true, state: { id: data.payable_id } })
                                                                    }}>
                                                                        {moment(data.issued_at).format(
                                                                            'DD-MMM-YYYY'
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell onClick={() => {
                                                                        navigate("/bills?id=" + data.payable_id, { replace: true, state: { id: data.payable_id } })
                                                                    }}>
                                                                        {moment(data.due_date).format(
                                                                            'DD-MMM-YYYY'
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell onClick={() => {
                                                                        navigate("/bills?id=" + data.payable_id, { replace: true, state: { id: data.payable_id } })
                                                                    }}>
                                                                        {data.status == "approve_in_progress" ? "Pending" :
                                                                            data.status == "new" ? "New" :
                                                                                data.status == "draft" ? "Draft" :
                                                                                    data.status == "rejected" ? "Rejected" :
                                                                                        data.status == "paid" ? "Paid" :
                                                                                            data.status == "canceled" ? "Canceled" :
                                                                                                data.status == "waiting_to_be_paid" ? "Waiting_to_be_paid"
                                                                                                    : data.status}
                                                                    </TableCell>
                                                                    <TableCell onClick={() => {
                                                                        navigate("/bills?id=" + data.payable_id, { replace: true, state: { id: data.payable_id } })
                                                                    }}>
                                                                        KES{" "}{data.amount / 100}
                                                                    </TableCell>
                                                                    <TableCell style={{ textAlign: "center" }} onClick={() => {
                                                                        navigate("/bills?id=" + data.payable_id, { replace: true, state: { id: data.payable_id } })
                                                                    }}>
                                                                        {!!data.description ? data.description : "-"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {data.status == "waiting_to_be_paid" ?
                                                                            <Button className="pay_buttom" onClick={() => {
                                                                                if (JSON.parse(localUser)?.data?.role_name == 'Director' || JSON.parse(localUser)?.data?.role_name == 'Accountant' || JSON.parse(localUser)?.data?.role_name == 'Admin') {
                                                                                    navigate("/balance?popup=" + data.payable_id, { replace: true, state: { popup: data.payable_id } });
                                                                                }
                                                                            }}>
                                                                                Pay
                                                                            </Button> : ""}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        <IconButton onClick={(e: any) => {
                                                                            if (data.status != "paid") {
                                                                                setIdeditdata(data.id)
                                                                            }

                                                                            if (data.status == "new") {
                                                                                setDelete_data(data.payable_id)
                                                                            }
                                                                            handleClick_datat(e)
                                                                        }}>
                                                                            <MoreVertIcon />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }) : null}
                                                    {emptyRows > 0 && (
                                                        <TableRow
                                                            style={{
                                                                height: (dense ? 33 : 53) * emptyRows,
                                                            }}
                                                        >
                                                            <TableCell colSpan={6} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                    <div style={{ paddingTop: '10px', justifyContent: "space-between" }} className="addnewbtnclass">
                                        <Button
                                            disabled={selected.length > 0 ? false : true}
                                            onClick={() => rejectSubmit()}
                                            color="#F87168"
                                            className='white_btn_two'
                                        >
                                            Delete
                                        </Button>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className="inpagenum">
                                                <p>
                                                    {remove - 9}-{remove}
                                                </p>
                                            </div>
                                            <div className="btnmaindivin">
                                                {pageneshn == 1 ? (
                                                    <button disabled className="nextntmistish">
                                                        <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="nextntmistish"
                                                        onClick={() => {
                                                            setPagelist(pageneshn - 1);
                                                            setRemovedata(remove - 10);
                                                            const endDateApi = !!endDate
                                                                ? moment(endDate).format('YYYY-MM-DD')
                                                                : '';
                                                            const stateDateApi: any = !!startDate ? moment(startDate).format('YYYY-MM-DD') : ""
                                                            getBalanceList(search, categ, stateDateApi, endDateApi, pageneshn - 1)
                                                            //   getBalanceList(pageneshn - 1);
                                                        }}
                                                    >
                                                        <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                                    </button>
                                                )}
                                                {remove >= totalCount ? (
                                                    <button disabled className="nextntmistish">
                                                        <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="nextntmistish"
                                                        onClick={() => {
                                                            setPagelist(pageneshn + 1);
                                                            setRemovedata(remove + 10);
                                                            const endDateApi = !!endDate
                                                                ? moment(endDate).format('YYYY-MM-DD')
                                                                : '';
                                                            const stateDateApi: any = !!startDate ? moment(startDate).format('YYYY-MM-DD') : ""
                                                            getBalanceList(search, categ, stateDateApi, endDateApi, pageneshn + 1)
                                                            //   getBalanceList(pageneshn + 1);
                                                        }}
                                                    >
                                                        <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }></SkeletonCom>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem
                                disabled={delete_data == "" ? true : false}
                                onClick={() => {
                                    navigate("/bills?id=" +
                                        delete_data, { replace: true, state: { id: delete_data } });
                                }}
                                className='menuitem'>Update</MenuItem>
                            <MenuItem
                                disabled={ideditdata == "" ? true : false}
                                onClick={() => {
                                    handleClose()
                                    // handleClickOpendelog()
                                    handleShow()
                                    // deleteData()
                                }} className='menuitem' style={{ color: "#F87161" }}>Delete
                            </MenuItem>
                        </Menu>
                    </Grid>

                </Grid>
            </ThemeProvider>
        </>
    )
}
export default Bill_tebal;
const SearchIcon = () => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13 13L10.0748 10.1341M11.6667 6.33334C11.6667 9.27886 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27886 1 6.33334C1 3.38782 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38782 11.6667 6.33334Z"
            stroke="#111111"
            stroke-width="1.1875"
            stroke-miterlimit="1"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);