import { Box, CircularProgress } from "@mui/material";



export default function AppLoadingScreen(loader:any) {
    return (
        <>
           {loader && <Box sx={{ display: 'flex', position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                {/* <CircularProgress color="success" /> */}
                <img
                          width={45}
                          height={45}
                          src="/Niobi Loading Animation-Cross.gif"
                        />
            </Box>}
        </>
    )
}