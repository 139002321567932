import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { store } from './Store/store';
// import { MoniteSDK } from '@team-monite/sdk-api';
import { datadogRum } from '@datadog/browser-rum';
import App from './features/app/App';
import { AUTH_TOKEN_STORAGE_KEY } from './features/app/consts';
import Root from './Root';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

datadogRum.init({
  applicationId: '97faf56f-dea9-41db-b78b-66b10d4030c7',
  clientToken: 'pubdf47915a5e8ddcfc308f7686c02676d4',
  site: 'us5.datadoghq.com',
  service: 'niobi-app',
  env: 'dev',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 45,
  premiumSampleRate: 10,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});
datadogRum.startSessionReplayRecording();

const instance = createInstance({
  urlBase: 'https://LINK.TO.DOMAIN',
  siteId: 3,
  userId: 'UID76903202', // optional, default value: `undefined`.
  trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
  srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST',
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<Root />);
