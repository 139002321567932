import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

interface Props {
  chart_payment: any;
  name: any;
}

const DonutChart = (props: Props) => {
  const [chartRendered, setChartRendered] = useState(false);

  useEffect(() => {
    // Set chartRendered to true once the component is mounted and rendered
    setChartRendered(true);
  }, []);

  // Render the chart component only if it's mounted and ready
  if (!chartRendered) {
    return null;
  }

  const chartData = {
    series: props.chart_payment,
    options: {
      chart: {
        toolbar: {
          show: false
        },
        dropShadow: {
          enabled: true,
          color: '#111',
          top: -1,
          left: 3,
          blur: 3,
          opacity: 0.2
        }
      },
      stroke: {
        width: 0,
      },
      legend: {
        width: 150,
        fontSize: "10px"
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val: any) {
            return ""
          }
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '35%',
            labels: {
              show: false,
              total: {
                showAlways: false,
                show: false
              },
            },
          }
        }
      },
      labels: props.name,
      colors: [
        '#1C4640',
        '#458F7D',
        '#9B7A73',
        "#D1D1D1",
        "#C1E9E1"
      ],
      dataLabels: {
        dropShadow: {
          blur: 3,
          opacity: 0.8
        }
      },
    },
  };
  // console.log(chartRendered, "!props.payment_nodata", props);

  return (
    <>
      {chartRendered == true && props.chart_payment ?
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="donut"
          height={200}
          width={"100%"}
        /> : ""}
    </>
  );
};

export default DonutChart;