import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Box, IconButton, Typography } from '@mui/material';
import { Button, Table } from "react-bootstrap";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { useNavigate, useLocation } from "react-router-dom";
import './setp2.scss';
const Setp2 = ({ data, onSelect }: any) => {
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0
    return (
        <>
            <Box sx={{ paddingTop: "18px" }}>
                <TableContainer>
                    <Table
                        className='tebal_class_contean'
                    >
                        <thead>
                            <tr>
                                <TableCell className='LinksTableCell'>
                                    Date
                                </TableCell>
                                <TableCell className='LinksTableCell'>
                                    Payment Reference
                                </TableCell>
                                <TableCell className='LinksTableCell'>
                                    Type
                                </TableCell>
                                <TableCell className='LinksTableCell'>
                                    Payment channel
                                </TableCell>

                                <TableCell className='LinksTableCell'>
                                    Description
                                </TableCell>
                                <TableCell className='LinksTableCell'>
                                    Transaction Amount
                                </TableCell>
                                {/* <TableCell className='LinksTableCell'>
                                    Balance
                                </TableCell> */}
                                <TableCell className='LinksTableCell'>
                                    More info
                                </TableCell>
                            </tr>
                        </thead>
                        <tbody>

                            {!!data &&
                                data
                                    .map((element: any, index: any) => {
                                        var TAmount: any = element.transaction_amount;

                                        var tagclass = '';
                                        var textclass = '';
                                        var tagname = element.Type;
                                        if (element.Type == 'pay_in') {
                                            tagclass = 'tag_payin-tag'
                                            textclass = 'text_payin-tag'
                                            tagname = 'Pay In'
                                        } else if (element.Type == 'pay_out') {
                                            tagclass = 'tag_payout-tag'
                                            textclass = 'text_payout-tag'
                                            tagname = 'Pay Out'
                                            TAmount = -element.transaction_amount;
                                        } else if (element.Type == 'fees') {
                                            tagclass = 'tag_fees-tag'
                                            textclass = 'text_fees-tag'
                                            tagname = 'Fees'
                                            TAmount = -element.transaction_amount;
                                        }
                                        return (
                                            <TableRow  >
                                                <TableCell className='TableCell_roww'>
                                                    <Typography className='TYPO_ROW_tebal'>
                                                        {moment(element.created_at).format("DD-MMM-YYYY")}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className='TableCell_roww' >
                                                    <Typography className='TYPO_ROW_tebal'>
                                                        {element.reference}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className='TableCell_roww' >
                                                    <span className={`tag_color ${tagclass}`}>
                                                        {tagname}
                                                    </span>
                                                </TableCell>
                                                <TableCell className='TableCell_roww'>
                                                    <Typography className='TYPO_ROW_tebal'>
                                                        {element.payment_channel}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className='TableCell_roww'>
                                                    <Typography className='TYPO_ROW_tebal'>
                                                        {element.description}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell id="amountColam" className='text-color'>
                                                    <span className={`text_color ${textclass}`} >
                                                        {(parseFloat(TAmount) > 0) ? "+" : "-"}{element.currency} {!!TAmount ? parseFloat(TAmount) > 0 ?

                                                            Number(parseFloat(TAmount).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }) :
                                                            `${Number(parseFloat(TAmount).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }).replace("-", "")}`
                                                            : '0'}
                                                    </span></TableCell>
                                                {/* <TableCell className='TableCell_roww'>
                                                    <Typography className='TYPO_ROW_tebal'>
                                                        {element.currency} {!!element.balance ? Number((parseFloat(element.balance)).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }) : element.balance}
                                                    </Typography>
                                                </TableCell> */}
                                                <TableCell className='TableCell_roww'>
                                                    <Typography className='viwe_buttonTypography' onClick={() => onSelect(element)}>
                                                        View details
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            {emptyRows > 0 && (
                                <TableRow
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}

                        </tbody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}
export default Setp2;