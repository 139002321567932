import { alertActions } from '.'
import { counterpartsService } from "../_services/counterparts.service";
import { counterpartsConstants } from "../_constants/counterparts.constants";
// import toast from 'react-hot-toast';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const counterpartsActions = {
    getAllCounterparts,
    getAllTags,
    getUserCounterparts,
    getPendingPaybles,
    updatePaybles,
    submitPaybles,
    getBudgetTags,
    getAllvat,
    getbulk_payment_payables,
    updatebulk_payment,
    rejectbulk_payment,
    CeretePaybles,
    getAllCounterparts_list,
    geteditCounterparts,
    edit_Counterparts,
    Delete_Counterparts,
    DeletePayee_active,
    Delete_bill,
    Delete_Balk_bill,
    getAllBill,
    deleteCustomer,
    EditCustomerById,
    getCustomerById,
    getCustomer,
    CustomersCreate,
    AlldeleteCustomer
}
function DeletePayee_active(id: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        counterpartsService.deletePayee_servicers(id)
            .then(data => {

                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('OTP verified successful'))
                    dispatch(success(response.data))
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: counterpartsConstants.DELETE_PAYEE_REQUEST } }
    function success(data: any) { return { type: counterpartsConstants.DELETE_PAYEE_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.DELETE_PAYEE_FAILURE } }
}
function AlldeleteCustomer(id: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        counterpartsService.AlldeleteCustomer(id)
            .then(data => {

                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('OTP verified successful'))
                    dispatch(success(response.data))
                    toast.success(response.message);
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: counterpartsConstants.DELETE_COSTOMER_REQUEST } }
    function success(data: any) { return { type: counterpartsConstants.DELETE_COSTOMER_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.DELETE_COSTOMER_FAILURE } }
}
function deleteCustomer(id: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        counterpartsService.deleteCustomer(id)
            .then(data => {

                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('OTP verified successful'))
                    dispatch(success(response.data))
                    toast.success(response.message);
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: counterpartsConstants.DELETE_COSTOMER_REQUEST } }
    function success(data: any) { return { type: counterpartsConstants.DELETE_COSTOMER_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.DELETE_COSTOMER_FAILURE } }
}
function getCustomerById(id:any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        // dispatch(request())

        counterpartsService.getCustomerById(id)
            .then(data => {
                return data.json()
            })
            .then(response => {
                dispatch(success(response.data))
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: counterpartsConstants.GET_CUSTOMER_BY_ID_REQUEST } }
    function success(data: any) { return { type: counterpartsConstants.GET_CUSTOMER_BY_ID_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.GET_CUSTOMER_BY_ID_FAILURE } }
}
function getCustomer() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        // dispatch(request())

        counterpartsService.getCustomer()
            .then(data => {
                return data.json()
            })
            .then(response => {
                dispatch(success(response.data))
                console.log('Called: ', response.data)
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: counterpartsConstants.GET_CUSTOMER_REQUEST } }
    function success(data: any) { return { type: counterpartsConstants.GET_CUSTOMER_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.GET_CUSTOMER_FAILURE } }
}
function getUserCounterparts() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        // dispatch(request())

        counterpartsService.getUserCounterparts()
            .then(data => {
                return data.json()
            })
            .then(response => {
                dispatch(success(response.data))
                console.log('Called: ', response.data)
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: counterpartsConstants.GETUSERCOUNTERPART_REQUEST } }
    function success(data: any) { return { type: counterpartsConstants.GETUSERCOUNTERPART_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.GETUSERCOUNTERPART_FAILURE } }
}

function getbulk_payment_payables(data: any,Limetdata:any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        // dispatch(request())

        counterpartsService.getbulk_payment_payables(data,Limetdata)
            .then(data => {
                return data.json()
            })
            .then(response => {
                dispatch(success(response.data))
                console.log('Called: ', response.data)
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: counterpartsConstants.GETBULK_PAYMENT_PAYABLES_REQUEST } }
    function success(data: any) { return { type: counterpartsConstants.GETBULK_PAYMENT_PAYABLES_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.GETBULK_PAYMENT_PAYABLES_FAILURE } }
}
function getAllCounterparts(setCounterpartsListRender:any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        // dispatch(request())

        counterpartsService.getAllCounterparts()
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                    setCounterpartsListRender(true)
                    // 
                    // toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                // dispatch(success(response.data))
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: counterpartsConstants.GETCOUNTERPARTS_REQUEST } }
    function success(data: any) { return { type: counterpartsConstants.GETCOUNTERPARTS_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.GETCOUNTERPARTS_FAILURE } }
}

function getAllTags() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        // dispatch(request())

        counterpartsService.getAllTags()
            .then(data => {
                return data.json()
            })
            .then(response => {
                dispatch(success(response.data))
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: counterpartsConstants.GETTAGS_REQUEST } }
    function success(data: any) { return { type: counterpartsConstants.GETTAGS_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.GETTAGS_FAILURE } }
}
function getAllvat() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        // dispatch(request())

        counterpartsService.getAllvat_service()
            .then(data => {
                return data.json()
            })
            .then(response => {
                dispatch(success(response.data))
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: counterpartsConstants.GETALLVAT_REQUEST } }
    function success(data: any) { return { type: counterpartsConstants.GETALLVAT_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.GETALLVAT_FAILURE } }
}
function getBudgetTags() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        // dispatch(request())

        counterpartsService.getBudgetTags_services()
            .then(data => {
                return data.json()
            })
            .then(response => {
                dispatch(success(response.data))
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: counterpartsConstants.GETTAGS_BUDGET_REQUEST } }
    function success(data: any) { return { type: counterpartsConstants.GETTAGS_BUDGET_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.GETTAGS_BUDGET_FAILURE } }
}
function getPendingPaybles() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        // dispatch(request())
        dispatch(alertActions.clear());
        //dispatch(success(undefined))
        counterpartsService.getPendingPaybles()
            .then(data => {
                return data.json()
            })
            .then(response => {
                dispatch(success(response.data))
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request(data: any) { return { type: counterpartsConstants.GET_PENDING_PAYABLES_REQUEST, data } }
    function success(data: any) { return { type: counterpartsConstants.GET_PENDING_PAYABLES_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.GET_PENDING_PAYABLES_FAILURE } }
}


function updatePaybles(req: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dispatch(request(req))
        dispatch(alertActions.clear());
        //dispatch(success(undefined))
        counterpartsService.updatePaybles(req)
            .then(data => {
                return data.json()
            })
            .then(response => {
                console.log(response,"responseresponse123");
                if (response.success) {
                    dispatch(success(response.data))
                    // 
                    toast.success(response.message);
                    window.location.reload()
                } else {
                    toast.error(response.message);
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request(data: any) { return { type: counterpartsConstants.UPDATE_PAYABLES_REQUEST, data } }
    function success(data: any) { return { type: counterpartsConstants.UPDATE_PAYABLES_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.UPDATE_PAYABLES_FAILURE } }
}
function updatebulk_payment(req: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dispatch(request(req))
        dispatch(alertActions.clear());
        counterpartsService.updatebulk_payment(req)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request(data: any) { return { type: counterpartsConstants.UPDATE_BULK_PAYMENT_PAYABLES_REQUEST, data } }
    function success(data: any) { return { type: counterpartsConstants.UPDATE_BULK_PAYMENT_PAYABLES_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.UPDATE_BULK_PAYMENT_PAYABLES_FAILURE } }
}
function rejectbulk_payment(req: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dispatch(request(req))
        dispatch(alertActions.clear());
        counterpartsService.rejectbulk_payment(req)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request(data: any) { return { type: counterpartsConstants.REJECT_BULK_PAYMENT_PAYABLES_REQUEST, data } }
    function success(data: any) { return { type: counterpartsConstants.REJECT_BULK_PAYMENT_PAYABLES_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.REJECT_BULK_PAYMENT_PAYABLES_FAILURE } }
}
function submitPaybles(req: any) {

    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        counterpartsService.submitPaybles(req)
            .then(data => {
                dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                    dispatch(alertActions.success('Submit payble successful'))
                    window.location.href = '/bills'
                } else {
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request(data: any) { return { type: counterpartsConstants.SUBMIT_PAYABLES_REQUEST, data } }
    function success(data: any) { return { type: counterpartsConstants.SUBMIT_PAYABLES_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.SUBMIT_PAYABLES_FAILURE } }
}
function CeretePaybles(req: any) {

    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        counterpartsService.CeretePaybles(req)
            .then(data => {
                dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                    dispatch(alertActions.success('Payee created successfully'))
                    toast.success(response.message)
                    setTimeout(() => { window.location.href = '/Counterparts' }, 3000)
                    // 
                } else {
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                    toast.error(response.message)
                    setTimeout(() => { window.location.href = '/Counterparts' }, 3000)
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request(data: any) { return { type: counterpartsConstants.CERETE_COUNTERPARTS_REQUEST, data } }
    function success(data: any) { return { type: counterpartsConstants.CERETE_COUNTERPARTS_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.CERETE_COUNTERPARTS_FAILURE } }
}
function CustomersCreate(req: any) {

    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        counterpartsService.CustomersCreate(req)
            .then(data => {
                dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                    dispatch(alertActions.success('Payee created successfully'))
                    toast.success(response.message)
                    setTimeout(() => { window.location.href = '/customers' }, 3000)
                    // 
                } else {
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                    toast.error(response.message)
                    setTimeout(() => { window.location.href = '/customers' }, 3000)
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request(data: any) { return { type: counterpartsConstants.CUSTOMERS_CREATE_REQUEST, data } }
    function success(data: any) { return { type: counterpartsConstants.CUSTOMERS_CREATE_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.CUSTOMERS_CREATE_FAILURE } }
}
function EditCustomerById(req: any,id:any) {

    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        counterpartsService.EditCustomerById(req,id)
            .then(data => {
                dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                    dispatch(alertActions.success('Payee created successfully'))
                    toast.success(response.message)
                    setTimeout(() => { window.location.href = '/customers' }, 3000)
                    // 
                } else {
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                    toast.error(response.message)
                    setTimeout(() => { window.location.href = '/customers' }, 3000)
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request(data: any) { return { type: counterpartsConstants.CUSTOMERS_EDIT_REQUEST, data } }
    function success(data: any) { return { type: counterpartsConstants.CUSTOMERS_EDIT_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.CUSTOMERS_EDIT_FAILURE } }
}
function getAllCounterparts_list(data: any, type: any, page: any, categ: any, limet: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        counterpartsService.getAllCounterparts_list(data, type, page, categ, limet)
            .then(data => {
                return data.json()
            })
            .then(response => {
                dispatch(success(response.data))
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: counterpartsConstants.GETCOUNTERPARTS_LIST_REQUEST, data } }
    function success(data: any) { return { type: counterpartsConstants.GETCOUNTERPARTS_LIST_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.GETCOUNTERPARTS_LIST_FAILURE } }
}

function geteditCounterparts(data: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        counterpartsService.geteditCounterparts(data)
            .then(data => {
                return data.json()
            })
            .then(response => {
                dispatch(success(response.data))
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: counterpartsConstants.GETUSERCOUNTERPART_EDIT_REQUEST } }
    function success(data: any) { return { type: counterpartsConstants.GETUSERCOUNTERPART_EDIT_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.GETUSERCOUNTERPART_EDIT_FAILURE } }
}
function edit_Counterparts(data: any, id: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        counterpartsService.edit_Counterparts(data, id)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                    toast.success(response.message)
                    setTimeout(() => { window.location.href = '/Counterparts' }, 3000)
                } else {
                    dispatch(failure(response.message));
                    toast.error(response.message)
                    dispatch(alertActions.error(response.message));
                    setTimeout(() => { window.location.href = '/Counterparts' }, 3000)
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: counterpartsConstants.EDIT_COUNTERPARTS_REQUEST } }
    function success(data: any) { return { type: counterpartsConstants.EDIT_COUNTERPARTS_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.EDIT_COUNTERPARTS_FAILURE } }
}
function Delete_Counterparts(id: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {

        counterpartsService.Counterparts_delete_servicers(id)  
        .then(data => {
            return data.json()
        })
        .then(response => {
            if (response.success) {
                toast.success(response.message)
                setTimeout(() => { window.location.href = '/Counterparts' }, 3000)
            } else {
                toast.error(response.message)
                dispatch(alertActions.error(response.message));
                setTimeout(() => { window.location.href = '/Counterparts' }, 3000)
            }
        }
        )

    }


}
function Delete_Balk_bill(id: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {

        counterpartsService.Delete_Balk_bill(id)  
        .then(data => {
            return data.json()
        })
        .then(response => {
            if (response.success) {
                toast.success(response.message)
                // setTimeout(() => { window.location.href = '/bill' }, 3000)
            } else {
                toast.error(response.message)
                dispatch(alertActions.error(response.message));
                // setTimeout(() => { window.location.href = '/bill' }, 3000)
            }
        }
        )

    }


}
function Delete_bill(id: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {

        counterpartsService.Delete_bill(id)  
        .then(data => {
            return data.json()
        })
        .then(response => {
            if (response.success) {
                toast.success(response.message)
                // setTimeout(() => { window.location.href = '/bill' }, 3000)
            } else {
                toast.error(response.message)
                dispatch(alertActions.error(response.message));
                // setTimeout(() => { window.location.href = '/bill' }, 3000)
            }
        }
        )

    }


}
function getAllBill(data: any, type: any, page: any, categ: any, limet: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        counterpartsService.getAllBill(data, type, page, categ, limet)
            .then(data => {
                return data.json()
            })
            .then(response => {
                dispatch(success(response.data))
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: counterpartsConstants.GETBILL_LIST_REQUEST, data } }
    function success(data: any) { return { type: counterpartsConstants.GETBILL_LIST_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.GETBILL_LIST_FAILURE } }
}
function getAllBill_status() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        counterpartsService.getAllBill_status()
            .then(data => {
                return data.json()
            })
            .then(response => {
                dispatch(success(response.data))
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: counterpartsConstants.GETBILL_STATUS_LIST_REQUEST,  } }
    function success(data: any) { return { type: counterpartsConstants.GETBILL_STATUS_LIST_SUCCESS, data } }
    function failure(s: string) { return { type: counterpartsConstants.GETBILL_STATUS_LIST_FAILURE } }
}