export function authHeader(){
    const user = JSON.parse(localStorage.getItem('user'))
    const token = localStorage.getItem('token')

    if(token){
        return {'Authorization': 'Bearer ' + token }
    }
    else{
        return {}
    }
}

export function isUserAuthenticated(){
    return !!(localStorage.getItem('token'));
}

export function getUser(){
    return JSON.parse(localStorage.getItem('user'));
}