import React, {useEffect} from "react";
    interface Props {
        d: any;
    }

    const Accessibility = (props) => {
        const { d } = props;
        useEffect(() => {
            var s = d.createElement("script");
            s.setAttribute("data-account", "O6NukcHxxj");
            s.setAttribute("src", "https://cdn.userway.org/widget.js"); 
            (d.body || d.head).appendChild(s);
            s.setAttribute("data-position", 5)
            s.setAttribute("data-color", "#055041");
        }, []);
    
        return (
            <div>
                <div id="Accessibility"></div>
            </div>
        );
    };
    
    export default Accessibility;