import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import styles from '../../Bulktebal.module.scss';
import { Divider, Typography } from '@mui/material';
import { selectAllCounterpartsListData } from '../../../../../../_reducers/counterparts.reducer';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { getUser, isUserAuthenticated } from '../../../../../../_helpers';
import { counterpartsActions } from '../../../../../../_actions/counterparts.actions';
import { getIn } from 'formik';
import * as Yup from 'yup';
import '../../../../../auth/Auth.css';
import { Link, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { paymentJourneyActions } from '../../../../../../_actions/paymentJourney.actions';
import { selectPaymentJourneyData } from '../../../../../../_reducers/paymentJourney.reducer';
import { CssBaseline, CircularProgress } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Form } from 'react-bootstrap';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY = 'transaction_date';
const DEFAULT_ROWS_PER_PAGE = 5;

interface Props {
  submit_data: any;
}
const ApproveTable = (props: Props) => {
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [selected_two, setSelected_two] = React.useState<readonly string[]>([]);
  const [dense, setDense] = React.useState(false);
  const [datatebal, setDatatebal] = React.useState([]);
  const [filedata, setFile] = React.useState('');
  const counterpartsListData = useSelector((state) =>
    selectAllCounterpartsListData(state)
  );
  const [counterpartsList, setCounterpartsList] = React.useState<any>([]);
  const [tagsList, setTagsList] = useState<any>([]);
  const [requestRender, setrequestRender] = useState(true);
  const [loading, setLoading] = useState(false);
  const [responseRender, setresponseRender] = useState(false);
  const [paybleRender, setPaybleRender] = useState(true);
  const [bankListRender, setBankListRender] = useState<any>(true);
  const [reject_data, setReject_data] = useState<any>();
  const [payeeData, setPayeeData] = useState<any>([]);
  const [remove, setRemovedata] = useState(10);
  const [render, setRender] = useState(true);
  const [pages, setPages] = useState(false);
  const [users, setUsers] = useState<any>();
  const [isUsers, isSetUsers] = useState<any>(true);
  const dataList = useSelector((state) => selectPaymentJourneyData(state));
  const [bankList, setBankList] = useState<any>([]);
  const [TextResan, setTextResan] = useState('');
  const [pageneshn, setPagelist] = useState(1);
  const [totalCount, setTotalCount] = useState(10);
  const [Limetdata, setLimetdata] = useState(10);
  const [fromdata, setFromdata] = useState(0);
  const [todata, setTodata] = useState(0);
  const [selectMothed, setSelectMothed] = useState([
    { name: 10 },
    { name: 25 },
    { name: 50 },
    { name: 75 },
  ]);
  const dispatch = useDispatch();
  const [patternDataArray, setPatternDataArray] = React.useState<any>([
    {
      id: '',
      supplier_id: '',
      document_id: '',
      invoice_date: '',
      due_date: '',
      amount: '',
      description: '',
      categories: [],
      is_current_price: false,
    },
  ]);

  useEffect(() => {
    if (isUsers && getUser) {
      isSetUsers(false);
      setUsers(getUser);
    }
  });
  useEffect(() => {
    if (isUserAuthenticated() && render) {
      setRender(false);
      dispatch(
        counterpartsActions.getbulk_payment_payables(
          pageneshn,
          Limetdata
        ) as any
      );
      dispatch(paymentJourneyActions.getPesaBankList() as any);
    }
  }, [render, isUserAuthenticated]);
  useEffect(() => {
    if (pages == true) {
      setPages(false);
      dispatch(
        counterpartsActions.getbulk_payment_payables(
          pageneshn,
          Limetdata
        ) as any
      );
      setTimeout(() => {
        setPaybleRender(true);
      }, 6000);
    }
  }, [pages]);
  useEffect(() => {
    if (bankListRender && dataList?.GETPESABANKLIST_SUCCESS !== undefined) {
      setBankListRender(false);
      setBankList(dataList.GETPESABANKLIST_SUCCESS);
    }
  }, [dataList, bankListRender]);
  useEffect(() => {
    if (
      paybleRender &&
      counterpartsListData?.GETBULK_PAYMENT_PAYABLES_SUCCESS !== undefined
    ) {
      var newarray: any = [];
      setTotalCount(
        counterpartsListData?.GETBULK_PAYMENT_PAYABLES_SUCCESS?.total
      );
      for (
        let index = 0;
        index <
        counterpartsListData?.GETBULK_PAYMENT_PAYABLES_SUCCESS.data.length;
        index++
      ) {
        const element =
          counterpartsListData?.GETBULK_PAYMENT_PAYABLES_SUCCESS.data[index];
        var object: any;
        if (element.bulk_payment) {
          object = {
            counterpart_name: element.bulk_payment.counterpart_name,
            id: element.payable_id,
            id_data: element.id,
            payment_method: element.bulk_payment.payment_method,
            account_number: element.bulk_payment.account_number,
            paybill_number: element.bulk_payment.paybill_number,
            bank_name: element.bulk_payment.bank_name,
            account_name: element.bulk_payment.account_name,
            comment: '',
            description: element.description,
            categories: element.bulk_payment.categories,
            amount: element.bulk_payment.amount,
            status: element.status,
            sender: element.sender,
          };
        }
        newarray.push(object);
      }
      setFromdata(counterpartsListData?.GETBULK_PAYMENT_PAYABLES_SUCCESS?.from);
      setTodata(counterpartsListData?.GETBULK_PAYMENT_PAYABLES_SUCCESS?.to);
      setPayeeData(newarray);
      setPaybleRender(false);
    }
  }, [counterpartsListData, paybleRender]);
  React.useEffect(() => {
    let rowsOnMount: any = stableSort(
      datatebal,
      getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY)
    );
    rowsOnMount = rowsOnMount.slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
    );
  }, [filedata, counterpartsList, tagsList]);
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = payeeData.map((n: any) => n.id);
      var newrespons_line = [];
      for (let index = 0; index < payeeData.length; index++) {
        const element = payeeData[index];
        var object = {
          id: element.id,
          comment: element.comment,
        };
        newrespons_line.push(object);
      }
      setReject_data(newrespons_line);
      setSelected(newSelected);
      return;
    } else {
      setReject_data([]);
      setSelected([]);
    }
  };
  const handleSelectAllClick_two = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelected = payeeData.map((n: any) => n.id_data);
      setSelected_two(newSelected);
      return;
    }
    setSelected_two([]);
  };
  const dataarr = [];
  const handleClick = (
    event: React.MouseEvent<unknown>,
    index: number,
    Nametwo: string
  ) => {
    const selectedIndex = selected.indexOf(Nametwo);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, Nametwo);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (!!payeeData) {
      var newarrar_loop = [];
      for (let index = 0; index < newSelected.length; index++) {
        const element = newSelected[index];
        const datalistobject = payeeData.find((ele: any) => ele.id === element);
        var object = {
          id: datalistobject.id,
          comment: datalistobject.comment,
        };
        newarrar_loop.push(object);
      }
      setReject_data(newarrar_loop);
    }

    setSelected(newSelected);
  };
  const handleClick_two = (
    event: React.MouseEvent<unknown>,
    index: number,
    Nametwo: string
  ) => {
    const selectedIndex = selected_two.indexOf(Nametwo);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected_two, Nametwo);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected_two.slice(1));
    } else if (selectedIndex === selected_two.length - 1) {
      newSelected = newSelected.concat(selected_two.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected_two.slice(0, selectedIndex),
        selected_two.slice(selectedIndex + 1)
      );
    }

    setSelected_two(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  useEffect(() => {
    if (
      requestRender &&
      counterpartsListData?.REJECT_BULK_PAYMENT_PAYABLES_REQUEST == true
    ) {
      setLoading(counterpartsListData.REJECT_BULK_PAYMENT_PAYABLES_REQUEST);
      setresponseRender(true);
      setrequestRender(false);
    }
  }, [counterpartsListData, requestRender]);

  useEffect(() => {
    if (
      responseRender &&
      counterpartsListData?.REJECT_BULK_PAYMENT_PAYABLES_REQUEST == false
    ) {
      setLoading(false);
      setrequestRender(true);
      setresponseRender(false);
    }
  }, [counterpartsListData, responseRender]);
  const rejectSubmit = () => {
    dispatch(
      counterpartsActions.rejectbulk_payment({ payables: reject_data }) as any
    );
    setLoading(true);
    setTimeout(() => {
      dispatch(
        counterpartsActions.getbulk_payment_payables(pages, Limetdata) as any
      );
    }, 3500);
    setTimeout(() => {
      setPaybleRender(true);
      setLoading(false);
      setSelected([]);
      setSelected_two([]);
    }, 9000);
  };

  const approveSubmit = () => {
    var array = [];
    var select_data: any = [];
    for (let index = 0; index < selected.length; index++) {
      const element = selected[index];
      var objcat = {
        id: element,
      };
      array.push(objcat);
    }
    for (let index = 0; index < selected_two.length; index++) {
      const element = selected_two[index];
      var objcat_aahah: any = {
        id: element,
      };
      select_data.push(objcat_aahah);
    }
    dispatch(
      counterpartsActions.updatebulk_payment({ payables: array }) as any
    );
    setTimeout(() => {
      props.submit_data(select_data);
    }, 2000);
  };
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: { primary: { main: '#000' } },
  });
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <ThemeProvider theme={theme}>
          {loading && (
            <Box
              sx={{
                display: 'flex',
                position: 'absolute',
                top: 150,
                bottom: 0,
                right: 0,
                left: 0,
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 10,
              }}
            >
              <CircularProgress color="success" />
            </Box>
          )}
          <Paper
            sx={{
              width: '100%',
              padding: '10px',
              boxShadow: '0px 4px 12px rgba(89, 87, 87, 0.16)',
            }}
          >
            <TableContainer>
              <Table
                className={styles.addteballist}
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <TableHead className={styles.hedarliath}>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        icon={<img src="/Checkbox.svg" />}
                        checkedIcon={<img src="/Checkbox_two.svg" />}
                        color="primary"
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < payeeData.length
                        }
                        checked={
                          payeeData.length > 0 &&
                          selected.length === payeeData.length
                        }
                        onChange={(e) => {
                          handleSelectAllClick(e);
                          handleSelectAllClick_two(e);
                        }}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <p className={styles.pa_tadaeagagga}>Payee Name</p>
                    </TableCell>
                    <TableCell>
                      <p className={styles.pa_tadaeagagga}>Payment Method</p>
                    </TableCell>
                    <TableCell>
                      <p className={styles.pa_tadaeagagga}>Account Number</p>
                    </TableCell>
                    <TableCell>
                      <p className={styles.pa_tadaeagagga}>Paybill Number</p>
                    </TableCell>
                    <TableCell>
                      <p className={styles.pa_tadaeagagga}>Bank Name</p>
                    </TableCell>

                    <TableCell className={styles.listactivetabal}>
                      <p className={styles.pa_tadaeagagga}>Account Name</p>
                    </TableCell>
                    <TableCell className={styles.listactivetabal}>
                      <p className={styles.pa_tadaeagagga}>Category</p>
                    </TableCell>
                    <TableCell className={styles.listactivetabal}>
                      <p className={styles.pa_tadaeagagga}>Description</p>
                    </TableCell>
                    <TableCell className={styles.listactivetabal}>
                      <p className={styles.pa_tadaeagagga}>Amount</p>
                    </TableCell>
                    <TableCell className={styles.listactivetabal}>
                      <p className={styles.pa_tadaeagagga}>Status</p>
                    </TableCell>
                    <TableCell className={styles.listactivetabal}>
                      <p className={styles.pa_tadaeagagga}>Reason</p>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {payeeData && payeeData.length > 0
                    ? payeeData.map((user: any, index: any) => {
                      const isItemSelected = isSelected(user?.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow hover key={index}>
                          <TableCell className={styles.checkbox_td_datata}>
                            <Checkbox
                              icon={<img src="/Checkbox.svg" />}
                              checkedIcon={<img src="/Checkbox_two.svg" />}
                              color="primary"
                              name={`users.${index}.is_current_price`}
                              checked={isItemSelected}
                              onClick={(event) => {
                                handleClick(event, index, user.id);
                                handleClick_two(event, index, user.id_data);
                                var newArray = [];
                                for (let j = 0; j < payeeData.length; j++) {
                                  const element = payeeData[j];
                                  if (
                                    user.is_current_price == false &&
                                    index == j
                                  ) {
                                    user.is_current_price = true;
                                    newArray.push(user);
                                  } else if (
                                    user.is_current_price == true &&
                                    index == j
                                  ) {
                                    user.is_current_price = false;
                                    newArray.push(user);
                                  } else {
                                    newArray.push(
                                      JSON.parse(JSON.stringify(element))
                                    );
                                  }
                                }
                                setPayeeData(newArray);
                              }}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>

                          <TableCell>
                            <p className={styles.pa_tadaeagagga}>
                              {user?.counterpart_name}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p
                              style={{
                                padding: '8px 0px 0px 8px',
                                overflow: 'scroll',
                              }}
                              className={styles.pa_tadaeagagga}
                            >
                              {user?.payment_method}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p className={styles.pa_tadaeagagga}>
                              {user?.account_number}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p className={styles.pa_tadaeagagga}>
                              {user?.paybill_number}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p className={styles.pa_tadaeagagga}>
                              {user?.bank_name}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p className={styles.pa_tadaeagagga}>
                              {user?.account_name}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p
                              style={{
                                padding: '8px 0px 0px 8px',
                                overflow: 'scroll',
                              }}
                              className={styles.pa_tadaeagagga}
                            >
                              {user?.categories?.map(
                                (data: any, index: any) => {
                                  return (
                                    <>
                                      {`${data.name} ${index == user?.categories?.length - 1
                                        ? ''
                                        : ','
                                        } `}
                                    </>
                                  );
                                }
                              )}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p className={styles.pa_tadaeagagga}>
                              {user?.description}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p className={styles.pa_tadaeagagga}>
                              KES {user?.amount / 100}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p className={styles.pa_tadaeagagga}>
                              {user?.status == "approve_in_progress" ? "Pending" : user?.status}
                            </p>
                          </TableCell>
                          <TableCell>
                            {isItemSelected == true ? (
                              <TextField
                                onChange={(e: any) => {
                                  setTextResan(e.target.value);
                                  var newArray = [];
                                  for (let j = 0; j < payeeData.length; j++) {
                                    const element = payeeData[j];
                                    if (index == j) {
                                      user.comment = e.target.value;
                                      newArray.push(user);
                                    } else {
                                      newArray.push(
                                        JSON.parse(JSON.stringify(element))
                                      );
                                    }
                                  }
                                  var dataArray = [];
                                  for (
                                    let index = 0;
                                    index < reject_data.length;
                                    index++
                                  ) {
                                    const element = reject_data[index];
                                    if (element.id == user.id) {
                                      const obg = {
                                        id: element.id,
                                        comment: e.target.value,
                                      };
                                      dataArray.push(obg);
                                    } else {
                                      dataArray.push(
                                        JSON.parse(JSON.stringify(element))
                                      );
                                    }
                                  }
                                  setReject_data(dataArray);
                                  setPayeeData(newArray);
                                }}
                                type="text"
                                value={user.comment}
                                className={styles.tabalinputdata + ''}
                              />
                            ) : (
                              <p className={styles.pa_tadaeagagga}></p>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={styles.addnewbtnclass}>
              <div className={styles.inpagenum}>
                <p>
                  {fromdata}-{todata}
                </p>
              </div>
              <div>
                <Form.Select
                  className={styles.tabalinputdata}
                  value={Limetdata}
                  style={{ width: '68px' }}
                  onChange={(e: any) => {
                    let text = e.target.value;
                    setLimetdata(text);
                    setPages(true);
                  }}
                >
                  <option selected value="">
                    Select
                  </option>
                  {selectMothed?.map((arrayElement: any, index: number) => {
                    return (
                      <option value={arrayElement.name} key={index + 1}>
                        {arrayElement.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
              <div className={styles.btnmaindivin}>
                {pageneshn == 1 ? (
                  <button disabled className={styles.nextntmistish}>
                    <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                  </button>
                ) : (
                  <button
                    className={styles.nextntmistish}
                    onClick={() => {
                      setPagelist(pageneshn - 1);
                      setRemovedata(remove - 10);
                      setPages(true);
                    }}
                  >
                    <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                  </button>
                )}
                {remove > totalCount ? (
                  <button disabled className={styles.nextntmistish}>
                    <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                  </button>
                ) : (
                  <button
                    className={styles.nextntmistish}
                    onClick={() => {
                      setPages(true);
                      setPagelist(pageneshn + 1);
                      setRemovedata(remove + 10);
                    }}
                  >
                    <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                  </button>
                )}
              </div>
            </div>
            <div
              className={styles.main_conteanat_lijshs}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px 0px',
              }}
            >

              <div className={styles.main_call_rejecta_payy}>
                <Button
                  disabled={selected.length > 0 ? false : true}
                  onClick={() => rejectSubmit()}
                  color="#F87168"
                  className={styles.white_btn_two}
                >
                  Reject
                </Button>
                <Button
                  disabled={selected.length > 0 ? false : true}
                  onClick={() => approveSubmit()}
                  color="#025041"
                  className={styles.save_btn}
                >
                  Proceed to Pay
                </Button>
              </div>
            </div>
          </Paper>
        </ThemeProvider>
      </Box>
    </>
  );
};
export default ApproveTable;
