import { Box, Grid, Typography } from '@mui/material';
import styles from './customers_details.module.scss';
import { Button } from 'react-bootstrap';
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import Setp1 from './setp1';
import Setp2 from './setp2';
import { TransactionDetails } from '../../../Balance/components/TransactionDetails/TransactionDetails';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { counterpartsActions } from '../../../../../../_actions/counterparts.actions';
import { selectAllCounterpartsListData } from '../../../../../../_reducers/counterparts.reducer';
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const Customers_details = ({ data, id }: any) => {
    const [value, setValue] = React.useState(0);
    const [dataList, setData] = React.useState({
        issued: {
            Total_invoices: "15",
            Total_amount: "10"
        },
        paid: {
            Total_invoices: "15",
            Total_amount: "10",
            currancy: "KES"
        },
        overdue: {
            Total_invoices: "15",
            Total_amount: "10",
            currancy: "KES"
        }
    })
    const [RecentActivityList, setRecentActivityList] = React.useState([
        {
            name: "Nexos Computer",
            des: "Quote rejected with the note: consectetur. Porttitor nibh egestas lobortis viverra a natoque id cursus. Aliquet....",
            date: "February 22, 2024"
        },
        {
            name: "Nexos Computer",
            des: "Quote rejected with the note: consectetur. Porttitor nibh egestas lobortis viverra a natoque id cursus. Aliquet....",
            date: "February 22, 2024"
        },
        {
            name: "Nexos Computer",
            des: "Quote rejected with the note: consectetur. Porttitor nibh egestas lobortis viverra a natoque id cursus. Aliquet....",
            date: "February 22, 2024"
        },
        {
            name: "Nexos Computer",
            des: "Quote rejected with the note: consectetur. Porttitor nibh egestas lobortis viverra a natoque id cursus. Aliquet....",
            date: "February 22, 2024"
        }
    ])
    const [paymentData, setPaymentData] = React.useState([
        {
            "id": 2894,
            "user_id": "4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c6",
            "wallet_id": 1881,
            "wallet_user_id": 19,
            "transaction_date": "2024-03-21 04:22:27",
            "payee": null,
            "payment_channel": "M-Pesa",
            "payment_type": "Pay in",
            "invoice_no": null,
            "reference": "CR-7ede646c-68ab-4d0d-9e94-855e6b6babe9",
            "currency": "RWF",
            "Type": "pay_in",
            "beneficiary": "Vitimbi  & Co. Ltd",
            "description": "Deposit made through M-Pesa",
            "fees": 0,
            "transaction_amount": 100,
            "balance": 110,
            "created_at": "2024-03-21T04:22:27.000000Z",
            "updated_at": "2024-03-21T04:22:27.000000Z",
            "mobile": "250783456789",
            "account_number": "70000003",
            "paybill_number": null,
            "till_number": null,
            "payer_id": null,
            "beneficiary_public_name": null,
            "payable_id": null,
            "bank_name": null,
            "bank_code": null,
            "branch_name": null,
            "account_name": null,
            "email": null
        },
        {
            "id": 2894,
            "user_id": "4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c6",
            "wallet_id": 1881,
            "wallet_user_id": 19,
            "transaction_date": "2024-03-21 04:22:27",
            "payee": null,
            "payment_channel": "M-Pesa",
            "payment_type": "Pay in",
            "invoice_no": null,
            "reference": "CR-7ede646c-68ab-4d0d-9e94-855e6b6babe9",
            "currency": "RWF",
            "Type": "pay_in",
            "beneficiary": "Vitimbi  & Co. Ltd",
            "description": "Deposit made through M-Pesa",
            "fees": 0,
            "transaction_amount": 100,
            "balance": 110,
            "created_at": "2024-03-21T04:22:27.000000Z",
            "updated_at": "2024-03-21T04:22:27.000000Z",
            "mobile": "250783456789",
            "account_number": "70000003",
            "paybill_number": null,
            "till_number": null,
            "payer_id": null,
            "beneficiary_public_name": null,
            "payable_id": null,
            "bank_name": null,
            "bank_code": null,
            "branch_name": null,
            "account_name": null,
            "email": null
        },
        {
            "id": 2894,
            "user_id": "4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c6",
            "wallet_id": 1881,
            "wallet_user_id": 19,
            "transaction_date": "2024-03-21 04:22:27",
            "payee": null,
            "payment_channel": "M-Pesa",
            "payment_type": "Pay in",
            "invoice_no": null,
            "reference": "CR-7ede646c-68ab-4d0d-9e94-855e6b6babe9",
            "currency": "RWF",
            "Type": "pay_in",
            "beneficiary": "Vitimbi  & Co. Ltd",
            "description": "Deposit made through M-Pesa",
            "fees": 0,
            "transaction_amount": 100,
            "balance": 110,
            "created_at": "2024-03-21T04:22:27.000000Z",
            "updated_at": "2024-03-21T04:22:27.000000Z",
            "mobile": "250783456789",
            "account_number": "70000003",
            "paybill_number": null,
            "till_number": null,
            "payer_id": null,
            "beneficiary_public_name": null,
            "payable_id": null,
            "bank_name": null,
            "bank_code": null,
            "branch_name": null,
            "account_name": null,
            "email": null
        },
        {
            "id": 2894,
            "user_id": "4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c6",
            "wallet_id": 1881,
            "wallet_user_id": 19,
            "transaction_date": "2024-03-21 04:22:27",
            "payee": null,
            "payment_channel": "M-Pesa",
            "payment_type": "Pay in",
            "invoice_no": null,
            "reference": "CR-7ede646c-68ab-4d0d-9e94-855e6b6babe9",
            "currency": "RWF",
            "Type": "pay_in",
            "beneficiary": "Vitimbi  & Co. Ltd",
            "description": "Deposit made through M-Pesa",
            "fees": 0,
            "transaction_amount": 100,
            "balance": 110,
            "created_at": "2024-03-21T04:22:27.000000Z",
            "updated_at": "2024-03-21T04:22:27.000000Z",
            "mobile": "250783456789",
            "account_number": "70000003",
            "paybill_number": null,
            "till_number": null,
            "payer_id": null,
            "beneficiary_public_name": null,
            "payable_id": null,
            "bank_name": null,
            "bank_code": null,
            "branch_name": null,
            "account_name": null,
            "email": null
        }
    ])
    const [render_two, setRender_two] = React.useState(true);
    const [showTransMoodal, setshowTransMoodal] = React.useState(false);
    const [moreData, setMoreData] = React.useState({})
    const[customarRedusar,setCustomarRedusar]=React.useState(true)
    const[Detels,setDetels]=React.useState<any>()
    const dispatch = useDispatch();
console.log(Detels,"DetelsDetels");

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const counterpartData = useSelector((state) =>
        selectAllCounterpartsListData(state)
      );
    const navigate = useNavigate();
    React.useEffect(() => {
        if (!!id&&render_two) {
            dispatch(counterpartsActions.getCustomerById(id) as any);
            setRender_two(false);
        }
    }, [id])

    React.useEffect(() => {
        if (
          customarRedusar &&
          counterpartData?.GET_CUSTOMER_BY_ID_SUCCESS !== undefined &&
          !!id
        ) {
            setCustomarRedusar(false)
            setDetels(counterpartData?.GET_CUSTOMER_BY_ID_SUCCESS)
        }
    }, [counterpartData, customarRedusar]);
    const theme = createTheme({
        status: {
            danger: "#025041",
        },
    });
    return (
        <>
            <Grid container className={styles.Customers_details_container}>

                <Grid item md={12} xs={12}>
                    <Box className={styles.Customers_details_box}>
                        <Button onClick={() => { navigate("/customers") }}>
                            <BackIcon />Back
                        </Button>
                    </Box>
                    <Box className={styles.calssName_heding}>
                        <Typography>
                            {/* {data.name} */}
                            Computer
                        </Typography>
                    </Box>
                    {showTransMoodal && (
                        <TransactionDetails
                            show={showTransMoodal}
                            onClose={() => {
                                setshowTransMoodal(false);
                                setMoreData('');
                            }}
                            data={moreData}
                        // onSave={(el: boolean) => {
                        //   setShowTopup(false);
                        //   setTopupMethod(el);
                        //   setTopup(true);
                        //   setShowBalanceGrid(false);
                        // }}
                        />
                    )}
                </Grid>
                <Grid item md={12} xs={12}>
                    <Box className={styles.Box_Recent_activity}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <ThemeProvider theme={theme}>
                                <Tabs
                                    sx={{
                                        "& .MuiTabs-indicator": {
                                            backgroundColor: "#025041",
                                            height: "0.5px",
                                        },
                                        "& .MuiTab-root.Mui-selected": {
                                            color: "#025041",
                                            fontSize: 12,
                                            textTransform: "capitalize",
                                        },
                                    }}
                                    value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab className={styles.tab_name} label="Profile" {...a11yProps(0)} />
                                    <Tab className={styles.tab_name} label="Payments" {...a11yProps(1)} />
                                </Tabs>
                            </ThemeProvider>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <Setp1 dataList={dataList} list={RecentActivityList} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <Setp2 data={paymentData}
                                onSelect={(data: any) => {
                                    setshowTransMoodal(true);
                                    setMoreData(data)
                                }} />
                        </CustomTabPanel>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
export default Customers_details;
const BackIcon = () => (
    <svg width="17" height="8" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM1 4.5H17V3.5H1V4.5Z" fill="#1D1D1D" />
    </svg>
)