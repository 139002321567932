import { dashboardConstants } from "../_constants/dashboard.constants";

export function getBalanceReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GETDASHBOARD_FROM_STORE:
            return {
                loading:true
            };
        case dashboardConstants.GET_DASHBOARD_BALANCE_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GETDASHBOARD_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getBalance_currency_rateReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_CURRENCY_RATE_REQUEST:
            return {
                loading:true
            };
        case dashboardConstants.GET_CURRENCY_RATE_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GET_CURRENCY_RATE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getBillReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GETDASHBOARD_FROM_STORE:
            return {
                loading:true
            };
        case dashboardConstants.GET_DASHBOARD_BILL_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GETDASHBOARD_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getRecentReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_DASHBOARD_RECENT_REQUEST:
            return {
                loading:true
            };
        case dashboardConstants.GET_DASHBOARD_RECENT_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GET_DASHBOARD_RECENT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getBudgetdetailsReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_DASHBOARD_BUDGETDETAILS_REQUEST:
            return {
                loading:true
            };
        case dashboardConstants.GET_DASHBOARD_BUDGETDETAILS_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GET_DASHBOARD_BUDGETDETAILS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getUpdatedetailsReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_DASHBOARD_UPDATEDETAILS_REQUEST:
            return {
                loading:true
            };
        case dashboardConstants.GET_DASHBOARD_UPDATEDETAILS_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GET_DASHBOARD_UPDATEDETAILS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getBillpayeeReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_PAYABLE_BY_ID_REQUEST:
            return {
                loading:true
            };
        case dashboardConstants.GET_PAYABLE_BY_ID_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GET_PAYABLE_BY_ID_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getPayableToApproveReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_PAYABLE_TO_APPROVE_REQUEST:
            return {
                loading:true
            };
        case dashboardConstants.GET_PAYABLE_TO_APPROVE_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GET_PAYABLE_TO_APPROVE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getPayableToRejectReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_PAYABLE_TO_REJECT_REQUEST:
            return {
                loading:true
            };
        case dashboardConstants.GET_PAYABLE_TO_REJECT_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GET_PAYABLE_TO_REJECT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getPayableToPendingReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_PAYABLE_TO_PENDING_REQUEST:
            return {
                loading:true
            };
        case dashboardConstants.GET_PAYABLE_TO_PENDING_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GET_PAYABLE_TO_PENDING_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getPayableToPayReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_PAYABLE_TO_PAY_REQUEST:
            return {
                loading:true
            };
        case dashboardConstants.GET_PAYABLE_TO_PAY_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GET_PAYABLE_TO_PAY_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getPayableToCancelReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_PAYABLE_TO_CANCEL_REQUEST:
            return {
                loading:true
            };
        case dashboardConstants.GET_PAYABLE_TO_CANCEL_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GET_PAYABLE_TO_CANCEL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getPayableToUpdateReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_PAYABLE_TO_UPDATE_REQUEST:
            return {
                loading:true
            };
        case dashboardConstants.GET_PAYABLE_TO_UPDATE_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GET_PAYABLE_TO_UPDATE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getCategoryExpensesReducer(state = {}, action: any) {
    switch(action.type) {
        case dashboardConstants.GET_DASHBOARD_CATEGRORY_EXPENSES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case dashboardConstants.GET_DASHBOARD_CATEGRORY_EXPENSES_SUCCESS:
            return {
                ...state,
                data: action.data,
            };
        case dashboardConstants.GET_DASHBOARD_CATEGRORY_EXPENSES_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}
export function getPaymentMethodPayinsReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_PAYMENT_METHOD_PAYINS_REQUEST:
            return {
                loading:true
            };
        case dashboardConstants.GET_PAYMENT_METHOD_PAYINS_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GET_PAYMENT_METHOD_PAYINS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getPayinsReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_PAYINS_REQUEST:
            return {
                loading:true
            };
        case dashboardConstants.GET_PAYINS_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GET_PAYINS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getPayeeExpensesReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_DASHBOARD_PAYEE_EXPENSES_REQUEST:
            return {
                loading:true
            };
        case dashboardConstants.GET_DASHBOARD_PAYEE_EXPENSES_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GET_DASHBOARD_PAYEE_EXPENSES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getPaymentExpensesReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_DASHBOARD_PAYMENT_EXPENSES_REQUEST:
            return {
                loading:true
            };
        case dashboardConstants.GET_DASHBOARD_PAYMENT_EXPENSES_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.GET_DASHBOARD_PAYMENT_EXPENSES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getChartReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_DASHBOARD_CHART_REQUEST:
            return {
                loading:true
            };
        case dashboardConstants.GET_DASHBOARD_CHART_SUCCESS:
            return {
                data: action
            };
        case dashboardConstants.GET_DASHBOARD_CHART_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function documentverificationReducer(state={},action: any) {
    
    switch (action.type) {
        case dashboardConstants.DOCUMENT_VERIFICATION_REQUEST:
            return {
                loading: true,
            };
        case dashboardConstants.DOCUMENT_VERIFICATION_SUCCESS:
            return {
                data: action.data
            };
        case dashboardConstants.DOCUMENT_VERIFICATION_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

// get dashboard-currency reducer
export function getDashboardCurrencyReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_DASHBOARD_CURRENCY_DATA_REQUEST:
            return {
                loading: true
            };
        case dashboardConstants.GET_DASHBOARD_CURRENCY_DATA_SUCCESS:
            return {
                data: action
            };
            
        case dashboardConstants.GET_DASHBOARD_CURRENCY_DATA_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

// get dashboard selected-currency reducer
// export function getDashboardSelectedCurrencyReducer(state={},action: any){
//     switch(action.type){
//         case dashboardConstants.GET_DASHBOARD_SELECTED_CURRENCY_DATA_REQUEST:
//             return {
//                 loading: true
//             };
//         case dashboardConstants.GET_DASHBOARD_SELECTED_CURRENCY_DATA_SUCCESS:
//             return {
//                 data: action
//             };
            
//         case dashboardConstants.GET_DASHBOARD_SELECTED_CURRENCY_DATA_FAILURE:
//             return {
//                 error: action.error
//             };
//         default:
//             return state
//     }
// }


// get bank-detail reducer
export function getBankDetailReducer(state={},action: any){
    switch(action.type){
        case dashboardConstants.GET_BANK_DETAIL_DATA_REQUEST:
            return {
                loading: true
            };
        case dashboardConstants.GET_BANK_DETAIL_DATA_SUCCESS:
            return {
                data: action
            };
            
        case dashboardConstants.GET_BANK_DETAIL_DATA_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}


export const selectAllData = (state: any) => {
    return  {
        'GET_DASHBOARD_BALANCE_SUCCESS': state.getBalanceReducer.data,
        'GET_CURRENCY_RATE_SUCCESS': state.getBalance_currency_rateReducer.data,
        'GET_DASHBOARD_BILL_SUCCESS': state.getBillReducer.data,
        'GETDASHBOARD_FAILURE':state.getBillReducer.error,
        'GET_DASHBOARD_RECENT_SUCCESS': state.getRecentReducer.data,
        'GET_DASHBOARD_RECENT_FAILURE': state.getRecentReducer.error,
        'GET_DASHBOARD_UPDATEDETAILS_SUCCESS': state.getUpdatedetailsReducer.data,
        'GET_DASHBOARD_CATEGRORY_EXPENSES_SUCCESS': state?.getCategoryExpensesReducer?.data,
        'GET_DASHBOARD_CATEGRORY_EXPENSES_FAILURE':state?.getCategoryExpensesReducer?.error,
        'GET_PAYINS_SUCCESS': state.getPayinsReducer.data,
        'GET_PAYINS_FAILURE':state.getPayinsReducer.error,
        'GET_PAYMENT_METHOD_PAYINS_SUCCESS': state.getPaymentMethodPayinsReducer.data,
        'GET_PAYMENT_METHOD_PAYINS_FAILURE':state.getPaymentMethodPayinsReducer.error,

        'GET_DASHBOARD_PAYEE_EXPENSES_SUCCESS': state.getPayeeExpensesReducer.data,
        "GET_DASHBOARD_PAYEE_EXPENSES_FAILURE":state.getPayeeExpensesReducer.error,
        'GET_DASHBOARD_PAYMENT_EXPENSES_SUCCESS': state.getPaymentExpensesReducer.data,
        "GET_DASHBOARD_PAYMENT_EXPENSES_FAILURE": state.getPaymentExpensesReducer.error,
        'GET_DASHBOARD_BUDGETDETAILS_SUCCESS': state.getBudgetdetailsReducer.data,
        'GET_DASHBOARD_BUDGETDETAILS_FAILURE':state.getBudgetdetailsReducer.error,
        'GET_DASHBOARD_CHART_SUCCESS': state.getChartReducer.data,
        'GET_DASHBOARD_CHART_REQUEST': state.getChartReducer,

        'DOCUMENT_VERIFICATION_REQUEST' : state.documentverificationReducer.data,
        'DOCUMENT_VERIFICATION_SUCCESS': state.documentverificationReducer.error,
        'DOCUMENT_VERIFICATION_FAILURE': state.documentverificationReducer.loading,

        'GET_PAYABLE_BY_ID_REQUEST' : state.getBillpayeeReducer.loading,
        'GET_PAYABLE_BY_ID_SUCCESS': state.getBillpayeeReducer.data,
        'GET_PAYABLE_BY_ID_FAILURE': state.getBillpayeeReducer.error,
        'GET_PAYABLE_TO_APPROVE_REQUEST' : state.getPayableToApproveReducer.loading,
        'GET_PAYABLE_TO_APPROVE_SUCCESS': state.getPayableToApproveReducer.data,
        'GET_PAYABLE_TO_APPROVE_FAILURE': state.getPayableToApproveReducer.error,
        // getPayableToRejectReducer
        'GET_PAYABLE_TO_REJECT_REQUEST' : state.getPayableToRejectReducer.loading,
        'GET_PAYABLE_TO_REJECT_SUCCESS': state.getPayableToRejectReducer.data,
        'GET_PAYABLE_TO_REJECT_FAILURE': state.getPayableToRejectReducer.error,

        'GET_PAYABLE_TO_PENDING_REQUEST' : state.getPayableToPendingReducer.loading,
        'GET_PAYABLE_TO_PENDING_SUCCESS': state.getPayableToPendingReducer.data,
        'GET_PAYABLE_TO_PENDING_FAILURE': state.getPayableToPendingReducer.error,

        'GET_PAYABLE_TO_PAY_REQUEST' : state.getPayableToPayReducer.loading,
        'GET_PAYABLE_TO_PAY_SUCCESS': state.getPayableToPayReducer.data,
        'GET_PAYABLE_TO_PAY_FAILURE': state.getPayableToPayReducer.error,

        'GET_PAYABLE_TO_CANCEL_REQUEST' : state.getPayableToCancelReducer.loading,
        'GET_PAYABLE_TO_CANCEL_SUCCESS': state.getPayableToCancelReducer.data,
        'GET_PAYABLE_TO_CANCEL_FAILURE': state.getPayableToCancelReducer.error,

        // getPayableToUpdateReducer
        'GET_PAYABLE_TO_UPDATE_REQUEST' : state.getPayableToUpdateReducer.loading,
        'GET_PAYABLE_TO_UPDATE_SUCCESS': state.getPayableToUpdateReducer.data,
        'GET_PAYABLE_TO_UPDATE_FAILURE': state.getPayableToUpdateReducer.error,

        // GET Dashboard-currency-data reducers
        'GET_DASHBOARD_CURRENCY_DATA_REQUEST': state.getDashboardCurrencyReducer.loading,
        'GET_DASHBOARD_CURRENCY_DATA_SUCCESS': state.getDashboardCurrencyReducer.data,
        'GET_DASHBOARD_CURRENCY_DATA_FAILURE': state.getDashboardCurrencyReducer.error,

         // GET Dashboard-currency-data reducers
        // 'GET_DASHBOARD_SELECTED_CURRENCY_DATA_REQUEST': state.getDashboardSelectedCurrencyReducer.loading,
        // 'GET_DASHBOARD_SELECTED_CURRENCY_DATA_SUCCESS': state.getDashboardSelectedCurrencyReducer.data,
        // 'GET_DASHBOARD_SELECTED_CURRENCY_DATA_FAILURE': state.getDashboardSelectedCurrencyReducer.error,

        // GET Bank-Detail reducers
        'GET_BANK_DETAIL_DATA_REQUEST': state.getBankDetailReducer.loading,
        'GET_BANK_DETAIL_DATA_SUCCESS': state.getBankDetailReducer.data,
        'GET_BANK_DETAIL_DATA_FAILURE': state.getBankDetailReducer.error
    };
}

