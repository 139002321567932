export const payablesConstants = {
    GETPAYABLES_REQUEST: 'GETPAYABLES_REQUEST',
    GETPAYABLES_SUCCESS: 'GETPAYABLES_SUCCESS',
    GETPAYABLES_NEW_SUCCESS: 'GETPAYABLES_NEW_SUCCESS',
    GETPAYABLES_FROM_STORE: 'GETPAYABLES_FROM_STORE',
    GETPAYABLES_FAILURE: 'GETPAYABLES_FAILURE',

    GETSELECTEDPAYABLES_REQUEST: 'GETSELECTEDPAYABLES_REQUEST',
    GETSELECTEDPAYABLES_SUCCESS: 'GETSELECTEDPAYABLES_SUCCESS',
    GETSELECTEDPAYABLES_FROM_STORE: 'GETSELECTEDPAYABLES_FROM_STORE',
    GETSELECTEDPAYABLES_FAILURE: 'GETSELECTEDPAYABLES_FAILURE',

    OTP_REQUEST: 'OTP_REQUEST',
    OTP_SUCCESS: 'OTP_SUCCESS',
    OTP_FAILURE: 'OTP_FAILURE',

      // transactionExist
  GET_TRANSACTIONEXIST_REQUEST: 'GET_TRANSACTIONEXIST_REQUEST',
  GET_TRANSACTIONEXIST_SUCCESS: 'GET_TRANSACTIONEXIST_SUCCESS',
  GET_TRANSACTIONEXIST_FAILURE: 'GET_TRANSACTIONEXIST_FAILURE',
  
    GETPAYABLES_ID_REQUEST: 'GETPAYABLES_ID_REQUEST',
    GETPAYABLES_ID_SUCCESS: 'GETPAYABLES_ID_SUCCESS',
    GETPAYABLES_ID_FAILURE: 'GETPAYABLES_ID_FAILURE',

    PAYABLES_REQUEST: 'PAYABLES_REQUEST',
    PAYABLES_SUCCESS: 'PAYABLES_SUCCESS',
    PAYABLES_FAILURE: 'PAYABLES_FAILURE',

    PAYABLES_RECURRING_SUBMIT_BILL_REQUEST: 'PAYABLES_RECURRING_SUBMIT_BILL_REQUEST',
    PAYABLES_RECURRING_SUBMIT_BILL_SUCCESS: 'PAYABLES_RECURRING_SUBMIT_BILL_SUCCESS',
    PAYABLES_RECURRING_SUBMIT_BILL_FAILURE: 'PAYABLES_RECURRING_SUBMIT_BILL_FAILURE',

    PAYABLES_RECURRING_BILL_REQUEST: 'PAYABLES_RECURRING_BILL_REQUEST',
    PAYABLES_RECURRING_BILL_SUCCESS: 'PAYABLES_RECURRING_BILL_SUCCESS',
    PAYABLES_RECURRING_BILL_FAILURE: 'PAYABLES_RECURRING_BILL_FAILURE',

    INITIATE_PAYMENT_REQUEST:'INITIATE_PAYMENT_REQUEST',
    INITIATE_PAYMENT_SUCCESS:'INITIATE_PAYMENT_SUCCESS',
    INITIATE_PAYMENT_FAILURE:'INITIATE_PAYMENT_FAILURE',

    INITIATE_BULK_PAYMENT_REQUEST:'INITIATE_BULK_PAYMENT_REQUEST',
    INITIATE_BULK_PAYMENT_SUCCESS:'INITIATE_BULK_PAYMENT_SUCCESS',
    INITIATE_BULK_PAYMENT_FAILURE:'INITIATE_BULK_PAYMENT_FAILURE',

    TRANSACTION_BULK_PAYMENT_REQUEST:'TRANSACTION_BULK_PAYMENT_REQUEST',
    TRANSACTION_BULK_PAYMENT_SUCCESS:'TRANSACTION_BULK_PAYMENT_SUCCESS',
    TRANSACTION_BULK_PAYMENT_FAILURE:'TRANSACTION_BULK_PAYMENT_FAILURE',

    COUNTERPART_UPDATE_REQUEST: 'COUNTERPART_UPDATE_REQUEST',
    COUNTERPART_UPDATE_SUCCESS: 'COUNTERPART_UPDATE_SUCCESS',
    COUNTERPART_UPDATE_FAILURE: 'COUNTERPART_UPDATE_FAILURE',

    TRANSACTION_REQUEST:'TRANSACTION_REQUEST',
    TRANSACTION_SUCCESS:'TRANSACTION_SUCCESS',
    TRANSACTION_FAILURE:'TRANSACTION_FAILURE',

    TRANSACTION_SUCCESS_DATA:"TRANSACTION_SUCCESS_DATA",

    BULKPAYABLES_REQUEST:'BULKPAYABLES_REQUEST',
    BULKPAYABLES_SUCCESS:'BULKPAYABLES_SUCCESS',
    BULKPAYABLES_FAILURE:'BULKPAYABLES_FAILURE',

    BULKBILLS_REQUEST:'BULKBILLS_REQUEST',
    BULKBILLS_SUCCESS:'BULKBILLS_SUCCESS',
    BULKBILLS_FAILURE:'BULKBILLS_FAILURE',

    OTPVIREFAT_REQUEST:'OTPVIREFAT_REQUEST',
    OTPVIREFAT_SUCCESS:'OTPVIREFAT_SUCCESS',
    OTPVIREFAT_FAILURE:'OTPVIREFAT_FAILURE',
}