import React, { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import "./NiobiBalanceDetail.scss";

interface Props {
    onSave: any;
    onCancel: any;
}

export const NiobiBalanceDetails = (props: Props) => {

    const onCancel = () => {
        props.onCancel();
    }

    return (
        <div className='niobi-balance-detail'>
            <div className='niobi-balance-detail__heading'>
            Coming Soon
            </div>

            <div className='niobi-balance-detail__btn-row'>
                <Button className='niobi-balance-detail__btn-row__white' onClick={() => onCancel()}>Cancel</Button>
            </div>
          
        </div>
    )
}
