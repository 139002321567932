import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './Topup.scss';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../../../../../../../_helpers';

interface Props {
  show: boolean;
  onClose: any;
  onSave: any;
  onconfirm: any;
}

const CurrencySelectionModal = (props: Props) => {
  const navigate = useNavigate();

  const [showModal, setShow] = useState(props.show);
  const [selectedMethod, setSelectedMethod] = useState('');
  const [selectedMethod_two, setSelectedMethod_two] = useState('');
  const [show, setShowlist] = useState(false);
  const user = getUser()?.data ?? null;

  const handleClick = () => {
    props.onSave();
    setShow(false);
    console.log(selectedMethod);
    navigate('/settings/account-setup', {
      state: {
        selectedMethod,
      },
    });
  };
  const handleNiobiApp = () => {
    props.onconfirm();
    setShow(false);
  };

  console.log(`show modal prop:${showModal}`);
  const onClose = () => {
    setShow(false);
    props.onClose(false);
  };
  return (
    <>
      <>
        <Modal
          show={showModal}
          onHide={onClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="ModalHeader"
          className="topup-modal"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Account Setup</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="label">Choose currency</div>
            <div className="type-card-list">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                className={`type-card ${
                  selectedMethod === 'KES' ? 'active' : ''
                }`}
                onClick={() => setSelectedMethod('KES')}
              >
                <div className="heding_centewr_data">
                  <p className="kes_text_stylee">KES</p>
                  <p className="Kenyan_heding_text">Kenyan Shilling</p>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                className={`type-card ${
                  selectedMethod === 'USD' ? 'active' : ''
                }`}
                onClick={() => setSelectedMethod('USD')}
              >
                <div className="heding_centewr_data">
                  <p className="kes_text_stylee">USD</p>
                  <p className="Kenyan_heding_text">United States Dollar</p>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                className={`type-card ${
                  selectedMethod === 'KES + USD' ? 'active' : ''
                }`}
                onClick={() => setSelectedMethod('KES + USD')}
              >
                <div className="heding_centewr_data">
                  <p className="kes_text_stylee">KES + USD</p>
                  <p className="Kenyan_heding_text">
                    Kenyan Shilling + United States Dollar
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button className="white-btn" onClick={onClose}>
              Cancel
            </Button>

            <Button
              className="green-btn"
              disabled={selectedMethod === ''}
              onClick={handleClick}
            >
              Next
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
};

export default CurrencySelectionModal;
