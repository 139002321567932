import React, { useState } from 'react'
import "./Settings.scss"
import { getUser, isUserAuthenticated } from "../../../../_helpers";
import PhoneInput from 'react-phone-input-2'
import { Button, InputGroup, Table } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PasswordUpdate } from './components/Profile/PasswordUpdate';
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { entityActions } from "../../../../_actions/entity.actions";
import { useDispatch, useSelector } from 'react-redux'
import { selectAllData } from "../../../../_reducers/entity.reducer";
import { settingSelector } from "../../../../_reducers/setting.reducer";
import { settingActions } from "../../../../_actions/setting.actions";
import { useSearchParams } from "react-router-dom";


interface Props {
  onCancel: any;
}
export const UserDetail = () => {
  const [users, setUsers] = useState<any>("");
  const [phone, setPhone] = useState("");
  const [phoneinit, setphoneinit] = useState(true);
  const [showPassword, setShowPass] = useState(false);
  const [entity, setEntity] = useState<any>({
    first_name: '',
    last_name: '',
    company_type: '',
    team: ""
  })
  const [render, setRender] = useState(true);
  const [entityType, setEntityType] = useState<any>();
  const [isInitialTypeRender, setIsInitialTypeRender] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [userRender, setUserRender] = useState(true);
  const [passUpdate, setPassUpdate] = useState(false);
  const [requestRender, setRequestRender] = useState(false);
  const [requestRenderUser, setrequestRenderUser] = useState(true);
  const [renderUser, setRenderUser] = React.useState(true)
  const [userData_data, setUserData_data] = React.useState<any>([]);

  const [responseRender, setresponseRender] = useState(false);
  const userData = useSelector((state) => settingSelector(state))
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (isUserAuthenticated() && render) {
      setRender(false);
      dispatch(settingActions.getRolsDocuments() as any)
      dispatch(settingActions.getTeamDocuments() as any)
    }
  }, [render, isUserAuthenticated]);

  React.useEffect(() => {
    if (renderUser && userData?.TEAMDOCUMENT_SUCCESS !== undefined) {

      setUserData_data(userData.TEAMDOCUMENT_SUCCESS);
      setRenderUser(false);
    }

  }, [userData, renderUser]);
  React.useEffect(() => {
    const user_id = searchParams.get('user_id');
    if (!!user_id) {
      if (user_id == getUser()?.data.user_id) {
        setPassUpdate(true)
      }
      const bodyReq = {
        id: user_id
      }
      dispatch(settingActions.findUserDetail(bodyReq) as any)
    }
  }, [searchParams, getUser]);

  React.useEffect(() => {
    if (isInitialTypeRender && userData?.ROLSDATA_SUCCESS !== undefined) {
      setIsInitialTypeRender(false);
      setEntityType(userData.ROLSDATA_SUCCESS);
    }
  }, [userData, isInitialTypeRender, requestRender]);

  console.log(requestRenderUser, userData, responseRender, "check Response")

  React.useEffect(() => {
    if (requestRenderUser && userData?.FINDUSERDETAIL_REQUEST == true) {
      setresponseRender(true);
      setrequestRenderUser(false);
    }
  }, [userData, requestRenderUser]);

  React.useEffect(() => {
    if (responseRender && userData?.FINDUSERDETAIL_REQUEST == false) {
      setresponseRender(false);
      setRequestRender(true)
    }
  }, [userData, responseRender]);
  React.useEffect(() => {
    if (requestRender && userRender && userData?.FINDUSERDETAIL_SUCCESS !== undefined) {
      setUserRender(false);
      setUsers(userData?.FINDUSERDETAIL_SUCCESS)
      setRequestRender(false)
      var object = {
        first_name: !!userData?.FINDUSERDETAIL_SUCCESS.first_name ? userData?.FINDUSERDETAIL_SUCCESS.first_name : '',
        last_name: !!userData?.FINDUSERDETAIL_SUCCESS.last_name ? userData?.FINDUSERDETAIL_SUCCESS.last_name : '',
        company_type: !!userData?.FINDUSERDETAIL_SUCCESS.role_id ? userData?.FINDUSERDETAIL_SUCCESS.role_id : '',
        team: !!userData?.FINDUSERDETAIL_SUCCESS.team_id ? userData?.FINDUSERDETAIL_SUCCESS.team_id : ''
      }
      setEntity(object)
    }
  }, [userData, userRender, requestRender]);
  console.log(entity, 'entityDetails')
  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required('Please enter your first name.'),
    last_name: Yup.string()
      .required('Please enter your last name.'),
    company_type: Yup.string()
      .required('Role is required')
  })
  const handleSubmit = (e: any) => {
    const user_id = searchParams.get('user_id');
    if (!!user_id && !!e && e.first_name && e.last_name && e.company_type) {
      const reqBody = {
        user_id: user_id,
        first_name: e.first_name,
        last_name: e.last_name,
        role_id: e.company_type,
        team_id: Number(e.team)
      }
      dispatch(settingActions.updateUser(reqBody) as any)
    }
  }

  return (
    <>
      {
        showPassword == false && (
          <div className='user-detail-container' style={{ width: '710px' }}>
            <Formik initialValues={entity} enableReinitialize={true} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {({ errors, touched, isSubmitting, handleChange, values }) => (
                <div className='company-details-container__wrapper'>
                  <Form>
                    <div id='asdaDsdDDddddd' className='user-detail-container__wrapper'>
                      <div className="first-last-name">
                        <div className="input-form-wrapper m-10">
                          <label>First name</label>
                          <Field
                            name="first_name"
                            type="text"
                            placeholder="First Name"
                            className={
                              "text-field field-enable" +
                              (errors.first_name && touched.first_name
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="invalid-feedback red"
                          />
                        </div>
                        <div className="input-form-wrapper m-10">
                          <label>Last name</label>
                          <Field
                            name="last_name"
                            type="text"
                            placeholder="Last Name"
                            className={
                              "text-field field-enable" +
                              (errors.last_name && touched.last_name
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="invalid-feedback red"
                          />
                        </div>
                      </div>
                      <div className='input-form-wrapper'>
                        <label>Email</label>
                        <input type="email" id='disebal' disabled className='text-field readonly' value={!!users ? users.email : ''} />
                      </div>
                      <div className='input-form-wrapper'>
                        <label>Mobile</label>
                        <PhoneInput

                          inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true.valueOf,
                            readOnly: true

                          }}
                          country={'ke'}
                          prefix='+'
                          value={!!users ? users.mobile : ''}

                          onChange={(value, country, e, formattedValue) => {

                          }}

                          inputClass={"inputBx-mobile readonly"}
                          autoFormat={false}
                          disabled
                        />
                      </div>
                      <div className='input-form-wrapper'>
                        <label>Team</label>
                        <Field 
                        name="team"
                          as="select"
                          className={'text-field select-box' 
                          } 
                          >
                          <option value="">--Select--</option>
                          {Array.isArray(userData_data)
                            ? userData_data?.map((element: any) => {
                              return <option key={element.id} value={element.id}>{element.name}</option>
                            })
                            : "data not found"}
                        </Field>
                      </div>
                      {passUpdate ?
                        <div className='input-form-wrapper'>
                          <label>User role</label>
                          <Field name="company_type" disabled as="select" style={{ cursor: "no-drop" }} className={'text-field select-box' + (errors.company_type && touched.company_type ? ' is-invalid' : '')} >
                            <option value="">--Select--</option>
                            {Array.isArray(entityType)
                              ? entityType?.map((element: any) => {
                                return <option key={element.id} value={element.id}>{element.name}</option>
                              })
                              : "data not found"}
                          </Field>
                          <ErrorMessage name="company_type" component="div" className="invalid-feedback red" />
                        </div> :
                        <div className='input-form-wrapper'>
                          <label>User role</label>
                          <Field name="company_type" as="select" className={'text-field field-enable select-box' + (errors.company_type && touched.company_type ? ' is-invalid' : '')} >
                            <option value="">--Select--</option>
                            {Array.isArray(entityType)
                              ? entityType?.map((element: any) => {
                                return <option key={element.id} value={element.id}>{element.name}</option>
                              })
                              : "data not found"}
                          </Field>
                          <ErrorMessage name="company_type" component="div" className="invalid-feedback red" />
                        </div>}
                      <div id='btuutton_list_updet_pass' className="setting-modal__btn-row d-flex flex-center justify-content-center align-items-center gap-10">
                        <div className='aFaFaFfwfffff'>
                          <Button id='button_go_back_list' className='complete-profile__saventmm' variant='custom'
                            onClick={() => window.location.href = '/settings/team'}
                          >   Go Back  </Button>
                        </div>
                        <div className='aFaFaFfwfffff'>
                          <Button id='butto_two_data_submitt' type='submit' className='complete-profile__submitbtnlist' > Save
                          </Button>
                        </div>
                        {passUpdate ? <div className='aFaFaFfwfffff'>
                          <Button id='button_three_updet_pass' style={{textTransform:"capitalize"}} className='complete-profile__submitbtnlist' onClick={() => setShowPass(true)} >Update Password
                          </Button>
                        </div> : ""}

                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>

          </div>
        )}
      {
        showPassword && (<PasswordUpdate onCancel={() => {
          setShowPass(false);
        }} />)

      }
    </>
  )
}
