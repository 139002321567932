import { entityConstants } from "../_constants/entity.constants";

export function getEntityTypesReducer(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.GETENTITYITEMS_SUCCESS:
            return {
                data: action.data
            };
        default:
            return state
    }
}

export function getEntityActivitiesReducer(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.GETENTITYITEMS_FROM_STORE:
            return {
                loading: true
            };
        case entityConstants.GET_ENTITY_ACTIVITY_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case entityConstants.GETENTITYITEMS_FAILURE:
            return {
                error: action.error,
                loading: false
            };
        default:
            return state
    }
}
export function getBillsslist(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.BILLLIST_REQUEST:
            return {
                loading: true
            };
        case entityConstants.BILLLIST_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case entityConstants.BILLLIST_FAILURE:
            return {
                error: action.error,
                loading: false
            };
        default:
            return state
    }
}

export function createEntityReducer(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.ADDTOENTITY_REQUEST:
            return {
                loading: true
            };
        case entityConstants.ADDTOENTITY_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case entityConstants.ADDTOENTITY_FAILURE:
            return {
                error: action.error,
                loading: false
            };
        default:
            return state
    }
}
export function saveEntityReducer(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.SAVE_ENTITY_REQUEST:
            return {
                loading: true
            };
        case entityConstants.SAVE_ENTITY_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case entityConstants.SAVE_ENTITY_FAILURE:
            return {
                error: action.error,
                loading: false
            };
        default:
            return state
    }
}
export function updateEntity(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.UPDATEENTITY_REQUEST:
            return {
                registering: true,
                refreshing: 'true',
                loading: true,
            }
        case entityConstants.UPDATEENTITY_SUCCESS:
            return {
                refreshing: 'false',
                loading: false,
                data: {

                    data: action.data,
                    loading: false,
                }
            }
        case entityConstants.UPDATEENTITY_FAILURE:
            return {
                refreshing: 'false',
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function getEntities(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.GETENTITY_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case entityConstants.GETENTITY_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            };
        case entityConstants.GETENTITY_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}

export function getBusinessDetails(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.BUSINESS_DETAILS_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case entityConstants.BUSINESS_DETAILS_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            };
        case entityConstants.BUSINESS_DETAILS_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}

export function setCurrentStepReduser(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.SET_CURRENT_STEP_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case entityConstants.SET_CURRENT_STEP_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            };
        case entityConstants.SET_CURRENT_STEP_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}
export function updetDataReduser(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.UPDET_DATA_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case entityConstants.UPDET_DATA_SUCCESS:
            return {
                data:action.data,
                loading: false,
                success: true,
            };
        case entityConstants.UPDET_DATA_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}

export function getCurrentStepReducer(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.GET_CURRENT_STEP_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case entityConstants.GET_CURRENT_STEP_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            };
        case entityConstants.GET_CURRENT_STEP_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}


export function getDetailsForNewAPIReducer(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.GET_DETAILS_FOR_NEW_API_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case entityConstants.GET_DETAILS_FOR_NEW_API_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            };
        case entityConstants.GET_DETAILS_FOR_NEW_API_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}

export function saveBusinessDetailsReducers(state = {}, action: any) {
    switch (action.type) {
        case entityConstants.SAVE_BUSINESS_DETAILS_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case entityConstants.SAVE_BUSINESS_DETAILS_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.data,
                loading: false,
                success: true,
            };
        case entityConstants.SAVE_BUSINESS_DETAILS_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}


export const selectAllData = (state: any) => {
    return {
        'GETENTITYITEMS_SUCCESS': state.getEntityTypesReducer.data,
        'GET_ENTITY_ACTIVITY_SUCCESS': state.getEntityActivitiesReducer.data,
        'ADDTOENTITY_SUCCESS': state.createEntityReducer.data,
        'ADDTOENTITY_FAILURE': state.createEntityReducer.error,
        'UPDATEENTITY_SUCCESS': state.updateEntity.data,
        'UPDATEENTITY_REQUEST': state.updateEntity.loading,
        'UPDATEENTITY_FAILURE': state.updateEntity.error,
        'BILLLIST_SUCCESS': state.getBillsslist.data,
        'BILLLIST_REQUEST': state.getBillsslist.loading,
        'BILLLIST_FAILURE': state.getBillsslist.error,
        'GETENTITY_SUCCESS': state.getEntities.data,
        'GETENTITY_REQUEST': state.getEntities.loading,
        'GETENTITY_FAILURE': state.getEntities.error,
        'BUSINESS_DETAILS_SUCCESS': state.getBusinessDetails.data,
        'BUSINESS_DETAILS_REQUEST': state.getBusinessDetails.loading,
        'BUSINESS_DETAILS_FAILURE': state.getBusinessDetails.error,

        'SET_CURRENT_STEP_SUCCESS': state.setCurrentStepReduser.data,
        'SET_CURRENT_STEP_REQUEST': state.setCurrentStepReduser.loading,
        'SET_CURRENT_STEP_FAILURE': state.setCurrentStepReduser.error,

        'UPDET_DATA_REQUEST': state.updetDataReduser.data,
        'UPDET_DATA_SUCCESS': state.updetDataReduser.loading,
        'UPDET_DATA_FAILURE': state.updetDataReduser.error,

        'GET_CURRENT_STEP_SUCCESS': state.getCurrentStepReducer.data,
        'GET_CURRENT_STEP_REQUEST': state.getCurrentStepReducer.loading,
        'GET_CURRENT_STEP_FAILURE': state.getCurrentStepReducer.error,

        'GET_DETAILS_FOR_NEW_API_SUCCESS': state.getDetailsForNewAPIReducer.data,
        'GET_DETAILS_FOR_NEW_API_REQUEST': state.getDetailsForNewAPIReducer.loading,
        'GET_DETAILS_FOR_NEW_API_FAILURE': state.getDetailsForNewAPIReducer.error,

        'SAVE_BUSINESS_DETAILS_SUCCESS': state.saveBusinessDetailsReducers.data,
        'SAVE_BUSINESS_DETAILS_REQUEST': state.saveBusinessDetailsReducers.loading,
        'SAVE_BUSINESS_DETAILS_FAILURE': state.saveBusinessDetailsReducers.error,

        'SAVE_ENTITY_REQUEST': state.saveEntityReducer.data,
        'SAVE_ENTITY_SUCCESS': state.saveEntityReducer.loading,
        'SAVE_ENTITY_FAILURE': state.saveEntityReducer.error,

    };
}