import './Invite_users.scss';
import { Button } from 'react-bootstrap';
const Invited_Successfully = () => {
    return (
        <>
        {/*  */}
            <div className="addinvitelist">
                <p className="hefingtage">User Invited Successfully!</p>
                <p className='addtestdiscertwo'>The invited users have received an email with details
                    on how they will create their account.</p>
                <div className='addnewcanselbtn'>
                    <Button className='retanbtnadad' onClick={() => { window.location.href = '/settings/team' }} >Go Back</Button>
                </div>
            </div>
        </>
    );
}

export default Invited_Successfully;