import Cerete_new from './components/cerete_layout/cerete_new';

interface Props {
    onclick: any;

}
const Cerete_new_page = (props: Props) => {

    return (
        <>
            <div className="balance__header" style={{ marginBottom: '35px' }}>
                <div className="balance__header__name">Connect Accounting Softwares</div>

            </div>
            <Cerete_new onclick={props.onclick} />
        </>
    )
}
export default Cerete_new_page;