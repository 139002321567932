import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import styles from '../Pay.module.scss';
import Detailspayment from './detailspayment';
import Setp2 from './setp2';
import { TransactionDetails } from './TransactionDetails/TransactionDetails'
import { paymentJourneyActions } from "../../../../../_actions/paymentJourney.actions";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useMemo } from "react";
import { selectPaymentJourneyData } from "../../../../../_reducers/paymentJourney.reducer";
import { Button } from 'react-bootstrap';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

interface Props {
    data: any;
    entityid: any;
    shortcode: any;
}

const Setp1 = ({ data, entityid, shortcode }: Props) => {
    const [setp_data, setSetp_data] = useState(1)
    const [success_data, setSuccess_data] = useState("")
    const [dataSuccess, setDataSuccess] = useState("")
    const [payReqrender, setpayReqrender] = useState(false);
    const [payReqSuccrender, setpayReqSuccrender] = useState(false);
    const [payReqResrender, setpayReqResrender] = useState(false);
    const [paymentStep, setpaymentStep] = useState(false);
    const [checkTransapi, setcheckTransapi] = useState(false);
    const [trans, settrans] = useState<any>();
    const [getTrans, setGettrans] = useState(true);
    const [contryCode, setcountry_code] = useState("")
    const [amount, setAmount] = useState(data?.amount == null ? 0 : data?.amount)
    const [currency, setCurrency] = useState(data?.currency == '' ? "" : data?.currency)
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    const dispatch = useDispatch();


    const [payload, setpayload] = useState({})
    const [option, setOption] = useState<any>(

    )
    const [resdata, setResdata] = useState(data)
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    useEffect(() => {
        if (payReqrender && dataList?.PAYLINK_REQUEST == false) {
            setpayReqrender(false);
        }
    }, [dataList, payReqrender]);

    useEffect(() => {
        if (payReqrender && dataList?.PAYLINK_REQUEST == true) {
            setSuccess_data("PANDING")
            setpayReqrender(false);
            setpayReqSuccrender(true);
            setpayReqResrender(true)
        }
    }, [dataList, payReqrender]);
    useEffect(() => {
        if (payReqSuccrender && dataList?.PAYLINK_SUCCESS !== undefined) {
            setpayReqSuccrender(false);
            setpaymentStep(true);
            settrans(dataList.PAYLINK_SUCCESS);
            setcheckTransapi(true);
        }
    }, [dataList, payReqSuccrender]);
    useEffect(() => {
        if (trans && getTrans && !!trans.MerchantRequestID) {
            setGettrans(false);
            dispatch(paymentJourneyActions.TransStatusLink(dataList?.PAYLINK_SUCCESS?.MerchantRequestID) as any);
            let timer = setInterval(() => {
            }, 1000);
            if (!!timer) {
                clearInterval(timer);
            }
        }
    }, [trans, getTrans]);
    useEffect(() => {
        if (payReqResrender && dataList?.PAYLINK_FAILURE !== undefined) {

            console.log('useEffect4')
            setpayReqResrender(false);
            setSuccess_data("FAILURE")
        }
    }, [dataList, payReqResrender]);

    console.log(checkTransapi, dataList, dataList?.TRANSPAYLINK_SUCCESS, 'dataList');
    useEffect(() => {
        if (checkTransapi && dataList?.TRANSPAYLINK_SUCCESS !== undefined) {
            if (dataList?.TRANSPAYLINK_SUCCESS?.status == 'pending') {
                setSuccess_data("PANDING")
                setcheckTransapi(false);
                dispatch(paymentJourneyActions.TransStatusLink(dataList?.PAYLINK_SUCCESS?.MerchantRequestID) as any);
                setTimeout(() => {
                    setcheckTransapi(true);
                }, 1500)
            } else if (dataList?.TRANSPAYLINK_SUCCESS?.status == 'failed'
            ) {
                setSuccess_data("FAILURE")
                setcheckTransapi(false);
            } else if (dataList?.TRANSPAYLINK_SUCCESS?.status == 'success') {
                setcheckTransapi(false);
                setSuccess_data("SUCCESS")
                setOption(dataList?.TRANSPAYLINK_SUCCESS)
                setTimeout(() => {
                    setDataSuccess("SUCCESS")
                }, 4000)
            }
        }
    }, [dataList, checkTransapi]);
    console.log(dataList?.TRANSPAYLINK_SUCCESS, "dataList?.TRANSPAYLINK_SUCCESS");
    const Closepopup = () => {
        setSuccess_data("")
        setDataSuccess("")
    }
    const nextPage = () => {
        dispatch(paymentJourneyActions.CreatePayViaLink(payload) as any);
        setpayReqrender(true);
    }
    return (
        <>
            <ThemeProvider theme={theme}>
                {success_data == "" ?
                    <Grid container>
                       
                        <Grid item md={2}>

                        </Grid>
                        <Grid item md={8} xs={12} sm={12}>

                            <Box className={styles.contenar_box}>
                                <Grid container >
                                    <Grid item md={6} xs={12} sm={12} className={styles.Grid_prive}>
                                        <Box className={styles.box_conteanr_main}>
                                            <Box className={styles.img_main_dev_contean}>
                                                <Box className={styles.img_box_img_contenar}>
                                                    {!!resdata.logo ?
                                                        <img src={!!resdata && !!resdata.logo ? resdata.logo : ""} /> :
                                                        ''}
                                                </Box>

                                            </Box>
                                            <Box className={styles.box_contenar_amount}>
                                                <Typography>
                                                    {!!resdata && !!resdata.business_name ? resdata.business_name : ""}
                                                </Typography>
                                            </Box>
                                            <Box style={{ paddingTop: "0px" }} className={styles.box_contenar_amount}>
                                                <Typography className={styles.des_heding_data}>
                                                    {!!resdata && resdata.item_name ? resdata.item_name : ""}
                                                </Typography>

                                                <Typography className={styles.Typography_amont}>
                                                    <span className={styles.spanclassName}>{!!resdata ? currency : ""}</span>
                                                    {!!resdata ? Number(parseFloat(amount).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }) : "0.00"}
                                                </Typography>

                                            </Box>
                                            <div className={styles.logo_bottom_this}>
                                                <Typography>Powered by</Typography>
                                                <div className={styles.div_img_logo}>
                                                    <a href='http://niobi.co/' target='_blank'>
                                                        <img src='/Preview_logo.svg' width={'100%'} height={'14px'} />
                                                    </a>
                                                </div>
                                                <Typography>/</Typography>  <a target='_blank' href='https://niobi.co/wp-content/uploads/2023/02/Niobi_Terms-of-Use_v1.1.pdf'>Terms </a><Typography>and</Typography><a target='_blank' href='https://niobi.co/wp-content/uploads/2023/02/Niobi_Privacy-Policy_v1.1.pdf'>Privacy.</a>
                                            </div>
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        {setp_data == 1 ?
                                            <Detailspayment
                                                nextpage={setSetp_data}
                                                payload={payload}
                                                setpayload={setpayload}
                                                data={resdata}
                                                setResdata={setAmount}
                                                setCurrency={setCurrency}
                                                resdata={amount}
                                                setcountry_code={setcountry_code}
                                                entityid={entityid}
                                                shortcode={shortcode}
                                            /> :
                                            setp_data == 2 ?
                                                <Setp2
                                                    nextpage={nextPage}
                                                    GoBack={setSetp_data}
                                                    data={resdata}
                                                    entityid={entityid}
                                                    shortcode={shortcode}
                                                    payload={payload}
                                                    setpayload={setpayload}
                                                    contryCode={contryCode}
                                                />
                                                : ""}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item md={2}>

                        </Grid>
                    </Grid> : dataSuccess == "SUCCESS" ?
                        <TransactionDetails Close={Closepopup}
                            shortcode={shortcode}
                            data={option} type={option} />
                        :
                        <div className={styles.main_dev_classs}>
                            {success_data == 'PANDING' ?
                                <div className={styles.div_pading_data}>
                                    <div style={{ textAlign: "center" }}>
                                        <img src="/logo 3.svg" />
                                        <p className={styles.des_data}>Kindly check your phone and <span className="helite_text">enter your Mpesa PIN</span>  to complete the payment.</p>
                                        <img width={45} height={45} src="/Niobi Loading Animation-Cross.gif" />
                                    </div>
                                </div>
                                : success_data == 'SUCCESS' ?
                                    <div className={styles.contenar_main_text_line}>
                                        <div>
                                            <Success_icon />
                                            <p className={styles.classname_text_key_minnn}>
                                                Payment Successful
                                            </p>
                                        </div>
                                    </div>
                                    : success_data == 'FAILURE' ?
                                        <div className={styles.FAILURE_contean}>
                                            <div className={styles.img_dataa}>
                                                <img src="/image 4.png" />
                                                <p className={styles.text_err_contenarr_minn}>
                                                    Payment Failed
                                                </p>
                                                <Button onClick={() => { window.location.reload(); }} className={styles.RetryButton}>Retry</Button>

                                            </div>

                                        </div>
                                        : ""
                            }
                        </div>}
            </ThemeProvider>
        </>
    )
}
export default Setp1;
const Success_icon = () =>
(
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
        <g clip-path="url(#clip0_7237_14869)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 28C0.5 20.5739 3.44999 13.452 8.70101 8.20101C13.952 2.94999 21.0739 0 28.5 0C35.9261 0 43.048 2.94999 48.299 8.20101C53.55 13.452 56.5 20.5739 56.5 28C56.5 35.4261 53.55 42.548 48.299 47.799C43.048 53.05 35.9261 56 28.5 56C21.0739 56 13.952 53.05 8.70101 47.799C3.44999 42.548 0.5 35.4261 0.5 28ZM26.9021 39.984L43.0227 19.8315L40.1107 17.5019L26.3645 34.6789L16.628 26.5664L14.2387 29.4336L26.9021 39.9877V39.984Z" fill="#025041" />
        </g>
        <defs>
            <clipPath id="clip0_7237_14869">
                <rect width="56" height="56" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>
)