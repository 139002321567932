import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import '../../../Settings.scss';

interface Props {
  onSave: any;
}

export const DocumentVerified = (props: Props) => {
  const onSave = () => {
    props.onSave();
  };

  return (
    <div className="document-verified">
      <div className="document-verified__heading">Account Setup Complete</div>

      <div className="document-verified__desc">
  
        <br />
        Your account setup is complete and has been successfully verified.
        <br />
        You can now access all features on our platform
        <br />
      </div>
      <Button className="document-verified__btn" onClick={() => onSave()}>
        Go Back
      </Button>
    </div>
  );
};
