import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));

const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        loading: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
        loading: false,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loading: false,
        error: action.error,
      };
    case userConstants.LOGOUT:
      return {
        loading: false,
      };
    default:
      return state;
  }
}
export const authSelector = (state) => {
  console.log(state, 'state');
  return {
    LOGIN_REQUEST: state.authentication.loading,
    LOGIN_SUCCESS: state.authentication.data,
    LOGIN_FAILURE: state.authentication.error,
  };
};
