import { authHeader, handleResponse } from "../_helpers";

export const paymentJourneyService = {
  getFees,
  getFeesNew,
  getValidateAccount,
  getInvite,
  getPesaBankList,
  AddBankDisbursement,
  Express_Payment_Service,
  AddBankDisbursement_experes,
  Create_integration,
  getIntegration,
  Create_credentials,
  getintegration_apikey,
  credentials_delete,
  getdurationlist,
  Create_budget,
  budget_list_data_service,
  budget_viwe_list_service,
  budget_delete_servicers,
  edit_budget,
  getBulk_payment,
  getbulk_payments_status_active,
  getSave_data,
  getPayBILLNew,
  get_accounting_types,
  create_connection,
  get_connections,
  disable_connection,
  getPaymentLink_shortcode,
  CreatePayViaLink,
  TransStatusLink,
  TransLinkPdf,
  invoicesend,
  invoicesendreceiver,
  Bankdetails,
  PaymentLinkApiList,
  paymentLinkApiCreate,
  getShareablePdfLink,
  PaymentLinkApiDeleteServicers,
  PaymentLinkApiUpdateActive,
  StanbicVerifyAccount,
  getCountries,
  getCountriesDetails,
  getCurrencies,
  getCountriesByCurrency,
  getMobileMethodsByCurrency,
  getBankMethodsByCurrency,
  getBankDetailByCurrency,
  AddBankExperesUsd,
  ChackBalance,
  getCountriesBalances,
  checkTransactionType,
  getMobileMoneyMethods,
  getBankTransfersMethods,
  getPayoutPaymentLimits,
  Express_Payment_v3_Service,
  getAllCurrenciesDetails,
  getSelectedUserCurrency,
  addSelectedUserCurrency,
};

// get fees api
function ChackBalance(currency, amount, payment_method) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/bank/check-balance-v2/${currency}/${amount}/${payment_method}`,
    requestOptions
  ).then(handleResponse);
}
function getFees(amount, payment_method) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/wallet/get-fees/${amount}/${payment_method}`,
    requestOptions
  ).then(handleResponse);
}
function getShareablePdfLink(ref, id) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payment-link-api/get-shareable-pdf-link/${ref}/${id}`,
    requestOptions
  ).then(handleResponse);
}
function getInvite(PointerParams) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(PointerParams),
  };
  console.log(PointerParams, "PointerParams");
  return fetch(
    `${process.env.REACT_APP_API_URL}/invite-to-niobi`,
    requestOptions
  ).then(handleResponse);
}
function getSave_data(PointerParams) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(PointerParams),
  };
  console.log(PointerParams, "PointerParams");
  return fetch(
    `${process.env.REACT_APP_API_URL}/counterparts/update-counterpart`,
    requestOptions
  ).then(handleResponse);
}
function getBulk_payment(PointerParams) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(PointerParams),
  };
  console.log(PointerParams, "PointerParams");
  return fetch(
    `${process.env.REACT_APP_API_URL}/create-bulk-payment-bills`,
    requestOptions
  ).then(handleResponse);
}
function getAllCurrenciesDetails() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/get-all-currencies-details`,
    requestOptions
  ).then(handleResponse);
}
function getFeesNew(amount) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/wallet/get-fees/${amount}`,
    requestOptions
  ).then(handleResponse);
}
function getValidateAccount(account_number, method) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/validate-account/${account_number}/${method}`,
    requestOptions
  ).then(handleResponse);
}
function getCountries() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payment-link-api/get-countries`,
    requestOptions
  ).then(handleResponse);
}
function getCountriesBalances(data) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/get-countries-with-currency-balances`,
    requestOptions
  ).then(handleResponse);
}
function getCountriesDetails(data) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payment-link-api/get-country-payment-methods/${data}`,
    requestOptions
  ).then(handleResponse);
}
function getCountriesByCurrency(data) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payment-link-api/get-countries-by-currency?currency=${data}`,
    requestOptions
  ).then(handleResponse);
}

// get selected-user currency methods Api
function getSelectedUserCurrency(data) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/v2/get-user-countries/${data}`,
    requestOptions
  ).then(handleResponse);
}

// add selected-user currency methods Api
function addSelectedUserCurrency(selectedCurrency) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(selectedCurrency),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/add-user-countries`,
    requestOptions
  ).then(handleResponse);
}

// mobile-money methods Api
function getMobileMethodsByCurrency(data) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/mobile-money/get-currencies-with-payment-methods/${data}`,
    requestOptions
  ).then(handleResponse);
}

// bank-method api
function getBankMethodsByCurrency(data) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/bank-transfer/get-currencies-with-payment-methods/${data}`,
    requestOptions
  ).then(handleResponse);
}

// bank-detail api
function getBankDetailByCurrency(data) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/bank/get-bank-detail-by-choice?currency=${data}`,
    requestOptions
  ).then(handleResponse);
}

function getCurrencies() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payment-link-api/get-currencies`,
    requestOptions
  ).then(handleResponse);
}
function getPayBILLNew(amount) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/get-failed-and-likely-to-faild-accounts/${amount}`,
    requestOptions
  ).then(handleResponse);
}
function StanbicVerifyAccount(amount) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(amount),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/stanbic/verify-account`,
    requestOptions
  ).then(handleResponse);
}
function checkTransactionType(amount) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(amount),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/check-transaction-type`,
    requestOptions
  ).then(handleResponse);
}
function getintegration_apikey(amount) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/integration/get-integration/${amount}`,
    requestOptions
  ).then(handleResponse);
}

function credentials_delete(amount) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/integration/delete-credential/${amount}`,
    requestOptions
  ).then(handleResponse);
}
function budget_list_data_service(amount) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/budget/get-budget-list?id=${amount.id}&date=${amount.date}&type=${amount.type}`,
    requestOptions
  ).then(handleResponse);
}
function budget_viwe_list_service(amount) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/budget/get-budget/${amount}`,
    requestOptions
  ).then(handleResponse);
}

function getPesaBankList() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/bank/get-pesalink-bank-list`,
    requestOptions
  ).then(handleResponse);
}
function budget_delete_servicers(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/budget/delete/${id}`,
    requestOptions
  ).then(handleResponse);
}
function PaymentLinkApiDeleteServicers(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payment-link-api/delete/${id}`,
    requestOptions
  ).then(handleResponse);
}

// budget/get-budget-duration-list
function getdurationlist() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/budget/get-budget-duration-list`,
    requestOptions
  ).then(handleResponse);
}
function getIntegration() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/integration/get-integrations`,
    requestOptions
  ).then(handleResponse);
}
function get_accounting_types() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/codat/get-accounting-types`,
    requestOptions
  ).then(handleResponse);
}
function get_connections() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/codat/get-connections`,
    requestOptions
  ).then(handleResponse);
}
function AddBankDisbursement(bankDetail) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/bank/bank-disbursement`,
    requestOptions
  ).then(handleResponse);
}
function Create_budget(bankDetail) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/budget/create`,
    requestOptions
  ).then(handleResponse);
}
function edit_budget(bankDetail, id) {
  const requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/budget/update/${id}`,
    requestOptions
  ).then(handleResponse);
}
// PaymentLinkApiUpdateActive
function PaymentLinkApiUpdateActive(bankDetail, id) {
  const requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payment-link-api/update/${id}`,
    requestOptions
  ).then(handleResponse);
}
function Create_integration(tetil) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(tetil),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/integration/create`,
    requestOptions
  ).then(handleResponse);
}
function getbulk_payments_status_active(tetil) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(tetil),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/get-bulk-payments-status`,
    requestOptions
  ).then(handleResponse);
}
function Create_credentials(tetil) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(tetil),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/integration/create-credentials`,
    requestOptions
  ).then(handleResponse);
}
function AddBankDisbursement_experes(bankDetail) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/express-bank-payment`,
    requestOptions
  ).then(handleResponse);
}
function AddBankExperesUsd(url, bankDetail) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(`${process.env.REACT_APP_API_URL}${url}`, requestOptions).then(
    handleResponse
  );
}
function create_connection(bankDetail) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/codat/create-connection`,
    requestOptions
  ).then(handleResponse);
}
function disable_connection(bankDetail) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(bankDetail),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/codat/disable-connection`,
    requestOptions
  ).then(handleResponse);
}
function Express_Payment_Service(datalist) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(datalist),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/express-payment`,
    requestOptions
  ).then(handleResponse);
}
function Express_Payment_v3_Service(datalist) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(datalist),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/express-payment-v2`,
    requestOptions
  ).then(handleResponse);
}
function getPaymentLink_shortcode(shortcode) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payment-link-api/get-by-short-code/${shortcode}`,
    requestOptions
  ).then(handleResponse);
}
function CreatePayViaLink(datalist) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(datalist),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payment-link-api/pay`,
    requestOptions
  ).then(handleResponse);
}
function TransStatusLink(shortcode) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payment-link-api/get-transaction-status/${shortcode}`,
    requestOptions
  ).then(handleResponse);
}
function PaymentLinkApiList(shortcode) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payment-link-api/get-by-id/${shortcode}`,
    requestOptions
  ).then(handleResponse);
}
function TransLinkPdf(ref, shortcode) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payment-link-api/get-shareable-pdf-link/${ref}/${shortcode}`,
    requestOptions
  ).then(handleResponse);
}

function invoicesend(datalist) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(datalist),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/invoice/send-to-me`,
    requestOptions
  ).then(handleResponse);
}
function paymentLinkApiCreate(datalist) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(datalist),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payment-link-api/create`,
    requestOptions
  ).then(handleResponse);
}
function invoicesendreceiver(datalist) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(datalist),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/invoice/send-to-receiver`,
    requestOptions
  ).then(handleResponse);
}
function Bankdetails(ref) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/bank/get-bank-details-by-bank_code/${ref}`,
    requestOptions
  ).then(handleResponse);
}
function getMobileMoneyMethods(currency) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/mobile-money/get-currencies-with-payment-methods/${currency}`,
    requestOptions
  ).then(handleResponse);
}
function getBankTransfersMethods(currency) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/bank-transfer/get-currencies-with-payment-methods/${currency}`,
    requestOptions
  ).then(handleResponse);
}
function getPayoutPaymentLimits(payload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/get-payout-payment-limits`,
    requestOptions
  ).then(handleResponse);
}
