import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import "./Payoptions.scss";


const LimitTransactionModel = (props: any) => {
  
  const [showModal, setShow] = useState(props.show);

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  // handle close
  const onClose = () => {
    setShow(false);
    props.onClose(false);
    props.setNewBalanceCheck(false);
    props.setLimitModal(false);
    props.setChackBalance(true);
  };

  // useEffect(()=>{
  //   props.setLoaderPage(false)
  // },[])

  return (
    <>

      <ThemeProvider theme={theme}>
        <Box className="CloseContenar">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="">
          <p className='limitModal_heading'>How do you want to pay?</p>
        </Box>
      </ThemeProvider>

      <Modal.Body>
        <div className="limitModal_Buttons">
            <div className='buttons_wrapper'>
          <div>
            <Button className='rtgf_eft_btn' variant='custom'
            onClick={() =>{
            if(props?.paymentName === "Proceed with RTGS"){
             props.setSelectMethodType("RTGS")
             props.setStartAPI(true)
              onClose()
            } else if(props?.paymentName === "Proceed with EFT"){
             props.setSelectMethodType("EFT")
             props.setStartAPI(true)
              onClose()
            }}}
            >{props?.paymentName} </Button>
            </div>
           <div>
             <Button style={{ display: "flex", alignItems: "center" }} className='pesalink_btn'
             onClick={() =>{
            props.setSelectMethodType("Bank")
            props.setStartAPI(true)
              onClose()
            }}
             >
              <p style={{textTransform:"capitalize"}}>Choose Pesalink Instead</p>
              </Button>
            </div>
            </div>
        </div>
      </Modal.Body>
    </>
  )
}


export default LimitTransactionModel;
