import { Typography } from '@mui/material';
import '../../CeretePaymentLinkFrom.scss';


const Preview = (props:any) => {
console.log(props,"safsasdzdddd");

    return (
        <>
            <div className='previwe_className'>
                <div className='className_logo'>
                {props?.data?.Upload_logo?
                    <div className='img_main_div'>
                       
                        <img src={props?.data?.Upload_logo} />
                        
                    </div>:''}
                   
                </div>
                <div className='des_classname'>
                <Typography className='namw_typogarafi' style={{padding:"8px 0px 0px 0px"}}>{props?.data?.Business_name}</Typography>
                </div>
                <div className='des_classname'>
                    <Typography>{props?.data?.Description}</Typography>
                </div>
                <div className='div_amounttypodiv'>
                    <Typography className='currancy_typo'>{props?.data?.Currancy} </Typography>
                    <Typography className='amountfilddd'>{!!props?.data?.Amount?props?.data?.Amount:props?.data?.Amount==null?"0.00":"0.00"}</Typography>
                </div>
                <div className='logo_bottom_this'>
                        <Typography>Powered by</Typography>
                        <div className='div_img_logo' >
                        <a href='http://niobi.co/' target='_blank'>
                        <img src='/Preview_logo.svg' width={'100%'} height={'100%'} />
                        </a>
                        </div>
                        <Typography>/</Typography>  <a target='_blank' href='https://niobi.co/wp-content/uploads/2023/02/Niobi_Terms-of-Use_v1.1.pdf'>Terms </a><Typography>and</Typography><a target='_blank' href='https://niobi.co/wp-content/uploads/2023/02/Niobi_Privacy-Policy_v1.1.pdf'>Privacy.</a>
                    </div>
            </div>
        </>
    )
}
export default Preview;
const No_img=()=>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
<circle cx="12.5" cy="12.5" r="12.5" fill="#ECEFEE"/>
</svg>
)