import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import '../../../Settings.scss';
import { getBase64 } from '../../../../../../../_helpers/common';
import { getUser } from '../../../../../../../_helpers';
import Form from 'react-bootstrap/Form';
import { settingActions } from '../../../../../../../_actions/setting.actions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  getEntityActivitiesReducer,
  selectAllData,
} from '../../../../../../../_reducers/entity.reducer';
import { entityActions } from '../../../../../../../_actions/entity.actions';
import * as Yup from 'yup';
import { Formik, Field, ErrorMessage } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { settingSelector } from '../../../../../../../_reducers/setting.reducer';
import { ModelumentVerified } from './modelsoon';
import VerifiedPopup from './VerifiedPopup';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { Model_Reject } from './Model_Reject';
import Tooltip from '@mui/material/Tooltip';
import { use } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NewVerfied from './newVerfied';

interface Props {
  onSave: any;
}
interface RequestProps {
  representative: any;
  document_type: string;
  business_documents: any;
  directors: any;
  shareholders: any;
  status: string;
}

export const CompleteProfile = (props: Props) => {
  const [fileTax1, setFileTax1] = useState('Certificate of Incorporation');
  const [fileTax1Base, setFileTax1Base] = useState('Upload Document 3');
  const [fileTax2, setFileTax2] = useState('Upload Document 4');
  const [fileTax2Base, setFileTax2Base] = useState('Upload Document 4');
  const [userDetail, setUserDetail] = useState(false);
  const [typevalue, setType] = useState('');
  const [users, setUsers] = useState<any>();
  const [render, setRender] = useState(true);
  const [entityType, setEntityType] = useState<any>();
  const [loader, setLoader] = useState(false);
  const directorDocStatic: any = {
    frontID: '',
    backID: '',
    KRN_certi: '',
    Passport: '',
  };
  const directorDocStaticTwo = {
    frontID: '',
    backID: '',
    KRN_certi: '',
    Passport: '',
    id: null,
    frontID_new: null,
    backID_new: null,
    KRN_certi_new: null,
    Passport_new: null,
  };

  const directorStatictwo2 = {
    datainput1: '',
    datainput2: '',
    datainput3: '',
  };
  const databusinessdoc = {
    certi_of_incorpo: '',
    CR: '',
    KRA: '',
  };
  const [businessdoc, setBusinessDoc] = useState<any>({});
  const [Directordoc, setDirectorDoc] = useState<any>([directorDocStatic]);
  const [Directordoc_two, setDirectorDoc_two] = useState<any>([
    directorDocStatic,
  ]);
  const [ShareHolderdoc, setShareHoldersDocs] = useState<any>([
    directorDocStaticTwo,
  ]);
  const [ShareHolderdoctwo, setShareHoldersDocstwo] = useState<any>([
    directorStatictwo2,
  ]);
  const [renderEntity, setRenderEntity] = useState(true);
  const [renderEntity_two, setRenderEntity_two] = useState(true);
  const [renderSubmit, setRenderSubmit] = useState(true);
  const [status, setstatus] = useState('waiting_for_submission');
  const [data_go_back, setData_Go_back] = useState<any>(false);
  const [isInitialTypeRender, setIsInitialTypeRender] = useState(true);
  const dispatch = useDispatch();
  const data = useSelector((state) => selectAllData(state));
  const dataEntity: any = useSelector((state) => settingSelector(state));
  React.useEffect(() => {
    setUserDetail(false);
  }, []);

  React.useEffect(() => {
    if (getUser && render) {
      setRender(false);
      setUsers(getUser);
      dispatch(settingActions.getEntityDocuments() as any);
    }
  }, [render, getUser]);

  const commonStaticFASTTT = {
    certi_of_incorpo: '{type}',
    certi_of_compliance: '{type}',
    CR: '{type}',
    KRA: '{type}',
    Passport: '{type}',
    id: null,
    fast_data: '{type}',
    secend_data: '{type}',
    thred_data: '{type}',
    frore_data: '{type}',
    frontID_new: null,
    backID_new: null,
    KRN_certi_new: null,
    Passport_new: null,
  };
  const directorStatic: any = {
    frontID: '{type} {i} National ID Front Picture',
    backID: '{type} {i} National ID Back Picture',
    KRN_certi: '{type} {i} KRA PIN Certificate',
    Passport: '{type} {i} Passport Copy',
    id: null,
    fast_data: '{type}',
    secend_data: '{type}',
    thred_data: '{type}',
    frore_data: '{type}',
    frontID_new: null,
    backID_new: null,
    KRN_certi_new: null,
    Passport_new: null,
  };
  const directorStatictwo = {
    datainput1: "Representative's National ID Front Picture",
    datainput2: "Representative's National ID Back Picture",
    datainput3: "Representative's KRA PIN Certificate",
    datainput4: "Director's Passport Copy",
  };

  const commonStatic: any = {
    frontID: '{type} National ID Front Picture',
    backID: '{type} National ID Back Picture',
    KRN_certi: '{type} KRA PIN Certificate',
    Passport: '{type} Passport Copy',
    id: null,
    fast_data: '{type}',
    secend_data: '{type}',
    thred_data: '{type}',
    frore_data: '{type}',
    frontID_new: null,
    backID_new: null,
    KRN_certi_new: null,
    Passport_new: null,
  };

  const commonStatiTwo: any = {
    frontID: '{type} National ID Front Picture',
    backID: '{type} National ID Back Picture',
    KRN_certi: '{type} KRA PIN Certificate',

    id: null,
    fast_data: '{type}',
    secend_data: '{type}',
    thred_data: '{type}',
    frontID_new: null,
    backID_new: null,
    KRN_certi_new: null,
    Passport_new: null,
  };
  const shareHolderStatic: any = {
    frontID: 'Shareholder  {i} - National ID Front Picture',
    backID: 'Shareholder  {i} - National ID Back Picture',
    KRN_certi: 'Shareholder  {i} - KRA PIN Certificates',
    Passport: "Shareholder's Passport Copy",
    id: null,
    fast_data: '{Shareholder  {i} - National ID Front Picture}',
    secend_data: '{Shareholder  {i} - National ID Back Picture}',
    thred_data: '{Shareholder  {i} - KRA PIN Certificates}',
    frontID_new: null,
    backID_new: null,
    KRN_certi_new: null,
    Passport_new: null,
  };
  React.useEffect(() => {
    if (
      renderEntity_two &&
      dataEntity?.GETENTITYDOCUMENT_SUCCESS !== undefined
    ) {
      if (
        !!dataEntity.GETENTITYDOCUMENT_SUCCESS.data &&
        dataEntity.GETENTITYDOCUMENT_SUCCESS.data.length !== 0
      ) {
        if (
          typeof dataEntity.GETENTITYDOCUMENT_SUCCESS.data == 'object' &&
          dataEntity.GETENTITYDOCUMENT_SUCCESS?.message !==
            'Documents were not found.'
        ) {
          var GetData = dataEntity.GETENTITYDOCUMENT_SUCCESS.data;
          setType(GetData.document_type);
          setUserDetail(true);
          setstatus(GetData.status);
        }
      }
    }
  }, [renderEntity_two, renderEntity]);

  React.useEffect(() => {
    if (renderEntity && dataEntity?.GETENTITYDOCUMENT_SUCCESS !== undefined) {
      setData_Go_back(false);
      if (
        !!dataEntity.GETENTITYDOCUMENT_SUCCESS.data &&
        dataEntity.GETENTITYDOCUMENT_SUCCESS.data.length !== 0
      ) {
        if (typeof dataEntity.GETENTITYDOCUMENT_SUCCESS.data == 'object') {
          var GetData = dataEntity.GETENTITYDOCUMENT_SUCCESS.data;
          if (!!GetData.business_documents) {
            setBusinessDoc({
              certi_of_incorpo: !!GetData.business_documents.certi_of_incorpo
                ? GetData.business_documents.certi_of_incorpo
                : commonStaticFASTTT.certi_of_incorpo,
              certi_of_compliance: !!GetData.business_documents
                .certi_of_compliance
                ? GetData.business_documents.certi_of_compliance
                : commonStaticFASTTT.certi_of_compliance,
              CR: !!GetData.business_documents.CR
                ? GetData.business_documents.CR
                : commonStaticFASTTT.CR,
              KRA: !!GetData.business_documents.KRA
                ? GetData.business_documents.KRA
                : commonStaticFASTTT.KRA,
              director: [directorStatic],
              id: !!GetData.business_documents.id
                ? GetData.business_documents.id
                : commonStaticFASTTT.id,
            });
            setInputListfast({
              certi_of_incorpo: !!GetData.business_documents.certi_of_incorpo
                ? GetData.business_documents.certi_of_incorpo
                : commonStaticFASTTT.certi_of_incorpo,
              certi_of_compliance: !!GetData.business_documents
                .certi_of_compliance
                ? GetData.business_documents.certi_of_compliance
                : commonStaticFASTTT.certi_of_compliance,
              CR: !!GetData.business_documents.CR
                ? GetData.business_documents.CR
                : commonStaticFASTTT.CR,
              KRA: !!GetData.business_documents.KRA
                ? GetData.business_documents.KRA
                : commonStaticFASTTT.KRA,
              director: [directorStatic],
              fast_data: !!GetData.business_documents.data.certi_of_incorpo
                ? GetData.business_documents.data.certi_of_incorpo.file_name
                : null,
              secend_data: !!GetData.business_documents.data.certi_of_compliance
                ? GetData.business_documents.data.certi_of_compliance.file_name
                : null,
              thred_data: !!GetData.business_documents.data.CR
                ? GetData.business_documents.data.CR.file_name
                : null,
              frore_data: !!GetData.business_documents.data.KRA
                ? GetData.business_documents.data.KRA.file_name
                : null,
              certi_of_incorpo_new: !!GetData.business_documents.data
                .certi_of_incorpo
                ? GetData.business_documents.data.certi_of_incorpo.id
                : null,
              certi_of_compliance_new: !!GetData.business_documents.data
                .certi_of_compliance
                ? GetData.business_documents.data.certi_of_compliance.id
                : null,
              CR_new: !!GetData.business_documents.data.CR
                ? GetData.business_documents.data.CR.id
                : null,
              KRA_new: !!GetData.business_documents.data.KRA
                ? GetData.business_documents.data.KRA.id
                : null,
            });
          }
          const directsData = {};

          if (
            !!GetData.directors ||
            !!GetData.partners ||
            !!GetData.proprietor ||
            !!GetData.trustee ||
            !!GetData.official
          ) {
            var DirecArray: any = [];
            var DirecArrayStatic: any = [];

            const Directors: any = !!GetData.directors
              ? Object.values(GetData.directors)
              : !!GetData.partners
              ? Object.values(GetData.partners)
              : !!GetData.proprietor
              ? Object.values(GetData.proprietor)
              : !!GetData.trustee
              ? Object.values(GetData.trustee)
              : !!GetData.official
              ? Object.values(GetData.official)
              : [];
            Directors.map((x: any, i: any) => {
              const Data = {
                frontID: !!Directors[i].frontID
                  ? Directors[i].frontID
                  : '{type} {i} - National ID Front Picture',
                backID: !!Directors[i].backID
                  ? Directors[i].backID
                  : '{type} {i} - National ID Back Picture',
                KRN_certi: !!Directors[i].KRN_certi
                  ? Directors[i].KRN_certi
                  : '{type} {i} - KRA PIN Certificates',
                Passport: !!Directors[i].Passport
                  ? Directors[i].Passport
                  : '{type} Passport Copy',
                id: !!Directors[i].id ? Directors[i].id : null,
                frontID_new: !!Directors[i].data.frontID
                  ? Directors[i].data.frontID.id
                  : null,
                backID_new: !!Directors[i].data.backID
                  ? Directors[i].data.backID.id
                  : null,
                KRN_certi_new: !!Directors[i].data.KRN_certi
                  ? Directors[i].data.KRN_certi.id
                  : null,
                Passport_new: !!Directors[i].data.Passport
                  ? Directors[i].data.Passport.id
                  : null,
                fast_data: !!Directors[i].data.frontID
                  ? Directors[i].data.frontID.file_name
                  : null,
                secend_data: !!Directors[i].data.backID
                  ? Directors[i].data.backID.file_name
                  : null,
                thred_data: !!Directors[i].data.KRN_certi
                  ? Directors[i].data.KRN_certi.file_name
                  : null,
                frore_data: !!Directors[i].data.Passport
                  ? Directors[i].data.Passport.file_name
                  : null,
              };
              const DataStatic = {
                frontID: !!Directors[i].frontID ? Directors[i].frontID : '',
                backID: !!Directors[i].backID ? Directors[i].backID : '',
                KRN_certi: !!Directors[i].KRN_certi
                  ? Directors[i].KRN_certi
                  : '',
                Passport: !!Directors[i].Passport ? Directors[i].Passport : '',
                id: !!Directors[i].id ? Directors[i].id : null,
                frontID_new: !!Directors[i].data.frontID
                  ? Directors[i].data.frontID.id
                  : null,
                backID_new: !!Directors[i].data.backID
                  ? Directors[i].data.backID.id
                  : null,
                KRN_certi_new: !!Directors[i].data.KRN_certi
                  ? Directors[i].data.KRN_certi.id
                  : null,
                Passport_new: !!Directors[i].data.Passport
                  ? Directors[i].data.Passport.id
                  : null,
                fast_data: !!Directors[i].data.frontID
                  ? Directors[i].data.frontID.file_name
                  : null,
                secend_data: !!Directors[i].data.backID
                  ? Directors[i].data.backID.file_name
                  : null,
                thred_data: !!Directors[i].data.KRN_certi
                  ? Directors[i].data.KRN_certi.file_name
                  : null,
                frore_data: !!Directors[i].data.Passport
                  ? Directors[i].data.Passport.file_name
                  : null,
              };
              DirecArray.push(Data);
              DirecArrayStatic.push(DataStatic);
            });

            setDirectors(DirecArray);
            setDirectorDoc(DirecArrayStatic);
            setGroupOneName(DirecArray);
          }
          if (!!GetData.representative) {
            var DirecArray: any = [];
            var DirecArrayStatic: any = [];
            const Directors: any = !!GetData.representative
              ? Object.values(GetData.representative)
              : [];
            Directors.map((x: any, i: any) => {
              const Data = {
                frontID: !!Directors[i].frontID
                  ? Directors[i].frontID
                  : '{type} {i} - National ID Front Picture',
                backID: !!Directors[i].backID
                  ? Directors[i].backID
                  : '{type} {i} - National ID Back Picture',
                KRN_certi: !!Directors[i].KRN_certi
                  ? Directors[i].KRN_certi
                  : '{type} {i} - KRA PIN Certificates',
                Passport: !!Directors[i].Passport
                  ? Directors[i].Passport
                  : '{type} Passport Copy',
                id: !!Directors[i].id ? Directors[i].id : null,
                frontID_new: !!Directors[i].data.frontID
                  ? Directors[i].data.frontID.id
                  : null,
                backID_new: !!Directors[i].data.backID
                  ? Directors[i].data.backID.id
                  : null,
                KRN_certi_new: !!Directors[i].data.KRN_certi
                  ? Directors[i].data.KRN_certi.id
                  : null,
                Passport_new: !!Directors[i].data.Passport
                  ? Directors[i].data.Passport.id
                  : null,
                fast_data: !!Directors[i].data.frontID
                  ? Directors[i].data.frontID.file_name
                  : null,
                secend_data: !!Directors[i].data.backID
                  ? Directors[i].data.backID.file_name
                  : null,
                thred_data: !!Directors[i].data.KRN_certi
                  ? Directors[i].data.KRN_certi.file_name
                  : null,
                frore_data: !!Directors[i].data.Passport
                  ? Directors[i].data.Passport.file_name
                  : null,
              };
              DirecArray.push(Data);
              DirecArrayStatic.push(Data);
            });
            setDirectors_two(DirecArray);
            setDirectorDoc_two(DirecArrayStatic);
          }
          // else{
          //     setDirectors(directorStatic);
          //     // setDirectorDoc(directorDocStatic)
          //     setGroupOneName(commonStatic)
          // }Data
          if (!!GetData.shareholders || !!GetData.beneficiary) {
            var shareArray: any = [];
            var shareArrayStatic: any = [];
            const ShareHolders: any = !!GetData.shareholders
              ? Object.values(GetData.shareholders)
              : !!GetData.beneficiary
              ? Object.values(GetData.beneficiary)
              : [];
            ShareHolders.map((x: any, i: any) => {
              const Data = {
                frontID: !!ShareHolders[i].frontID
                  ? ShareHolders[i].frontID
                  : 'ShareHolders {i} - National ID Front Picture',
                backID: !!ShareHolders[i].backID
                  ? ShareHolders[i].backID
                  : 'ShareHolders {i} - National ID Back Picture',
                KRN_certi: !!ShareHolders[i].KRN_certi
                  ? ShareHolders[i].KRN_certi
                  : 'ShareHolders {i} - KRA PIN Certificates',
                id: !!ShareHolders[i].id ? ShareHolders[i].id : null,
                Passport: !!ShareHolders[i].Passport
                  ? ShareHolders[i].Passport
                  : "Shareholder's Passport Copy",
                frontID_new: !!ShareHolders[i].data.frontID
                  ? ShareHolders[i].data.frontID.id
                  : null,
                backID_new: !!ShareHolders[i].data.backID
                  ? ShareHolders[i].data.backID.id
                  : null,
                KRN_certi_new: !!ShareHolders[i].data.KRN_certi
                  ? ShareHolders[i].data.KRN_certi.id
                  : null,
                Passport_new: !!ShareHolders[i].data.Passport
                  ? ShareHolders[i].data.Passport.id
                  : null,
                fast_data: !!ShareHolders[i].data.frontID
                  ? ShareHolders[i].data.frontID.file_name
                  : null,
                secend_data: !!ShareHolders[i].data.backID
                  ? ShareHolders[i].data.backID.file_name
                  : null,
                thred_data: !!ShareHolders[i].data.KRN_certi
                  ? ShareHolders[i].data.KRN_certi.file_name
                  : null,
                frore_data: !!ShareHolders[i].data.Passport
                  ? ShareHolders[i].data.Passport.file_name
                  : null,
              };
              const DataStatic = {
                frontID: !!ShareHolders[i].frontID
                  ? ShareHolders[i].frontID
                  : '',
                backID: !!ShareHolders[i].backID ? ShareHolders[i].backID : '',
                KRN_certi: !!ShareHolders[i].KRN_certi
                  ? ShareHolders[i].KRN_certi
                  : '',
                id: !!ShareHolders[i].id ? ShareHolders[i].id : null,
                frontID_new: !!ShareHolders[i].data.frontID
                  ? ShareHolders[i].data.frontID.id
                  : null,
                Passport: !!ShareHolders[i].Passport
                  ? ShareHolders[i].Passport
                  : "Director's Passport Copy",

                backID_new: !!ShareHolders[i].data.backID
                  ? ShareHolders[i].data.backID.id
                  : null,
                KRN_certi_new: !!ShareHolders[i].data.KRN_certi
                  ? ShareHolders[i].data.KRN_certi.id
                  : null,
                Passport_new: !!ShareHolders[i].data.Passport
                  ? ShareHolders[i].data.Passport.id
                  : null,

                fast_data: !!ShareHolders[i].data.frontID
                  ? ShareHolders[i].data.frontID.file_name
                  : null,
                secend_data: !!ShareHolders[i].data.backID
                  ? ShareHolders[i].data.backID.file_name
                  : null,
                thred_data: !!ShareHolders[i].data.KRN_certi
                  ? ShareHolders[i].data.KRN_certi.file_name
                  : null,
                frore_data: !!ShareHolders[i].data.Passport
                  ? ShareHolders[i].data.Passport.file_name
                  : null,
              };
              shareArray.push(Data);
              shareArrayStatic.push(DataStatic);
            });
            setShareholder(shareArray);
            setShareHoldersDocs(shareArrayStatic);
            setGroupTwoName(shareArray);
          }
        }
      } else {
        setInputListfast(commonStaticFASTTT);
        setShareholder([shareHolderStatic]);
        setGroupTwoName([commonStatiTwo]);
        setShareHoldersDocs([directorDocStaticTwo]);
        setDirectors([directorStatic]);
        setDirectorDoc([directorDocStatic]);
        setGroupOneName([commonStatic]);
        setBusinessDoc({});
        setData_Go_back(true);
        setBusinessDoc({});
      }
      setRenderEntity(false);
    }
  }, [dataEntity, renderEntity]);

  React.useEffect(() => {
    if (
      renderSubmit &&
      dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS !== undefined
    ) {
      setRenderSubmit(false);
    }
  }, [dataEntity, renderSubmit]);

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
    }
  }, [render]);
  const [submitted, setSubmitted] = useState(false);
  const [directors, setDirectors] = useState([directorStatic]);
  const [directors_two, setDirectors_two] = useState([directorStatic]);
  const [shareholder, setShareholder] = useState([shareHolderStatic]);
  const [directorstwo, setDirectorstwo] = useState([directorStatictwo]);
  const [shareholdertwo, setShareholdertwo] = useState([shareHolderStatic]);
  const [newdata, setNewdata] = useState(false);
  const [groupOneName, setGroupOneName] = useState([commonStatic]);
  const [groupTwoName, setGroupTwoName] = useState([commonStatiTwo]);
  const [datadetels, setdatadetels] = useState<any>({
    director: [directorStatictwo],
  });

  const [inputlfast, setInputListfast] = useState<any>(commonStaticFASTTT);
  const [inputlsecond, setInputListsecond] = useState({
    Certificate: 'Certificate of Incorporation',
    CR: 'CR 12',
    Compliance: 'Certificate of Compliance',
    KRA: 'Company’s KRA PIN Certificate',
    director: [directorStatictwo],
  });
  const [inputlthird, setInputListthird] = useState({
    Certificate: 'Business Registration Certificate',
    CR: 'Partnership Mandate or Deed',
    director: [
      {
        frontID: '',
        backID: '',
        kyc: '',
      },
    ],
  });
  const [inputlfourth, setInputListfourth] = useState({
    Certificate: 'Business Registration Certificate',
    director: [
      {
        frontID: '',
        backID: '',
        kyc: '',
      },
    ],
  });
  const [inputlfiveth, setInputListfiveth] = useState({
    Certificate: 'Certificate of registration',
    Trust: 'Trust deed or Exemption',
    PIN: "Trust's KRA PIN Certificate or Tax Exemption Certificate",
    director: [
      {
        frontID: '',
        backID: '',
        kyc: '',
      },
    ],
  });
  const [inputlsix, setInputListsix] = useState({
    Certificate: 'Certificate of Registration or Exemption',
    Trust: 'Introduction letter from the NGOs Co-ordination Board',
    PIN: 'Constitution of the organisation',
    KRA: 'KRA Pin Certificate or Exemption for the NGO',
    director: [
      {
        frontID: '',
        backID: '',
        kyc: '',
      },
    ],
  });
  const [inputList, setInputList] = useState([{ firstName: '', lastName: '' }]);
  const [inputList2, setInputList2] = useState([
    { firstName: '', lastName: '' },
  ]);
  const [inputListtwo, setInputListtwo] = useState([
    { firstName: '', lastName: '' },
  ]);
  const [datalistsize, setDatalistsize] = useState(true);
  const onSave = () => {
    props.onSave();
  };
  const [entity, setEntity] = useState<any>({
    certi_of_incorpo: '',
    CR12: '',
    KRN_certi: '',
  });
  const handleAddClick_two = () => {
    setDirectors_two([...directors_two, directorStatic]);
  };
  const handleAddClick = () => {
    setInputList([...inputList, { firstName: '', lastName: '' }]);
    setDirectorstwo([...directorstwo, directorStatictwo]);
    setDirectors([...directors, directorStatic]);
    setDirectorDoc([...Directordoc, directorDocStatic]);
    setShareHoldersDocstwo([...ShareHolderdoctwo, directorStatictwo2]);
    setGroupOneName([...groupOneName, commonStatic]);
  };
  const handleRemoveClicktwo = (index: any) => {
    const list = [...inputList2];
    list.splice(index, 1);
    setInputList2(list);
    const listName = [...directorstwo];
    listName.splice(index, 1);
    setDirectorstwo(listName);
    const listNamedoc = [...Directordoc];
    listNamedoc.splice(index, 1);
    setDirectorDoc(listNamedoc);
  };

  const handleRemoveClick_two = (index: any, id: any) => {
    const listName = [...directors_two];
    listName.splice(index, 1);
    setDirectors_two(listName);

    if (!!id) {
      var reqBody = {
        id: id,
      };
      dispatch(settingActions.deleteEntityDocuments(reqBody) as any);
    }
  };
  const handleRemoveClick = (index: any, id: any) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    /* for name */

    const listName = [...directors];
    listName.splice(index, 1);
    setDirectors(listName);

    const listNamedoc = [...Directordoc];
    listNamedoc.splice(index, 1);
    setDirectorDoc(listNamedoc);
    const groupOneNameState = [...groupOneName];
    groupOneNameState.splice(index, 1);
    setGroupOneName(groupOneNameState);
    if (!!id) {
      var reqBody = {
        id: id,
      };
      dispatch(settingActions.deleteEntityDocuments(reqBody) as any);
    }
  };
  const handleAddClick2 = () => {
    setInputList2([...inputList2, { firstName: '', lastName: '' }]);
    setShareholdertwo([...shareholdertwo, shareHolderStatic]);

    setShareholder([...shareholder, shareHolderStatic]);
    setShareHoldersDocs([...ShareHolderdoc, directorDocStaticTwo]);
    setGroupTwoName([...groupTwoName, commonStatiTwo]);
  };
  const handleAddClicktwo2 = () => {
    setInputListtwo([...inputList2, { firstName: '', lastName: '' }]);

    setShareholdertwo([...shareholdertwo, shareHolderStatic]);
  };
  const handleRemoveClicktwo2 = (index: any) => {
    const list = [...inputListtwo];
    list.splice(index, 1);
    setInputListtwo(list);
    const listName = [...shareholder];
    listName.splice(index, 1);
    setShareholdertwo(listName);

    const listNameSharedoc = [...ShareHolderdoc];
    listNameSharedoc.splice(index, 1);
    setShareHoldersDocs(listNameSharedoc);
  };
  const handleRemoveClick2 = (index: any, id: any) => {
    const list = [...inputList2];
    list.splice(index, 1);
    setInputList2(list);
    const listName = [...shareholder];
    listName.splice(index, 1);
    setShareholder(listName);
    const listNameSharedoc = [...ShareHolderdoc];
    listNameSharedoc.splice(index, 1);
    setShareHoldersDocs(listNameSharedoc);
    const groupTwoNameState = [...groupTwoName];
    groupTwoNameState.splice(index, 1);
    setGroupTwoName(groupTwoNameState);
    if (!!id) {
      var reqBody = {
        id: id,
      };
      dispatch(settingActions.deleteEntityDocuments(reqBody) as any);
    }
  };
  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
    }
  }, [render]);

  useEffect(() => {
    if (isInitialTypeRender && data?.GETENTITYITEMS_SUCCESS !== undefined) {
      setIsInitialTypeRender(false);
      setEntityType(data.GETENTITYITEMS_SUCCESS);
    }
  }, [data, isInitialTypeRender]);

  const validationSchema = Yup.object().shape({});
  const handleChangeFile = (
    id: any,
    type: any,
    docType: any,
    title: any,
    file: any,
    name: any
  ) => {
    var reqBody = {
      id: !!id ? id : null,
      document_type: typevalue,
      type: type,
      title: title,
      file: file,
      status: 'waiting_for_submission',
      file_name: name,
    };
    setLoader(true);
    dispatch(settingActions.storeDocuments(reqBody) as any);
    //
    setTimeout(() => {
      dispatch(settingActions.getEntityDocuments() as any);
      setTimeout(() => {
        setRenderEntity(true);
        setLoader(false);
      }, 5000);
    }, 8000);
  };
  const remove_data = (id: any) => {
    console.log('*******id*****', id);
    dispatch(settingActions.deleteDocuments(id) as any);
    setLoader(true);
    setTimeout(() => {
      dispatch(settingActions.getEntityDocuments() as any);
      setTimeout(() => {
        setRenderEntity(true);
        setLoader(false);
      }, 2000);
    }, 4500);
  };
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  const imageUrl =
    'https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/documents/frontID-6ab96d5d-8af3-4e1f-8895-7069fb56f1f32023-08-22.png';

  const [removedFrontID, setRemovedFrontID] = useState(false);
  const [removedBackID, setRemovedBackID] = useState(false);
  const [removedKra, setRemovedKra] = useState(false);
  const [removedFrontID2, setRemovedFrontID2] = useState(false);
  const [removedBackID2, setRemovedBackID2] = useState(false);
  const [removedKra2, setRemovedKra2] = useState(false);
  const [removedFrontID3, setRemovedFrontID3] = useState(false);
  const [removedBackID3, setRemovedBackID3] = useState(false);
  const [removedKra3, setRemovedKra3] = useState(false);
  const [removedComp3, setRemovedComp3] = useState(false);
  const [removedFrontID4, setRemovedFrontID4] = useState(false);
  const [removedBackID4, setRemovedBackID4] = useState(false);
  const [removedKra4, setRemovedKra4] = useState(false);
  const [removedComp4, setRemovedComp4] = useState(false);
  const [removedFrontID5, setRemovedFrontID5] = useState(false);
  const [removedBackID5, setRemovedBackID5] = useState(false);
  const [removedKra5, setRemovedKra5] = useState(false);
  const [removedFrontID6, setRemovedFrontID6] = useState(false);
  const [removedBackID6, setRemovedBackID6] = useState(false);
  const [removedKra6, setRemovedKra6] = useState(false);
  const [removedFrontID7, setRemovedFrontID7] = useState(false);
  const [removedBackID7, setRemovedBackID7] = useState(false);
  const [removedKra7, setRemovedKra7] = useState(false);
  const [removedFrontID8, setRemovedFrontID8] = useState(false);
  const [removedBackID8, setRemovedBackID8] = useState(false);
  const [removedKra8, setRemovedKra8] = useState(false);
  const [removedFrontID9, setRemovedFrontID9] = useState(false);
  const [removedBackID9, setRemovedBackID9] = useState(false);
  const [removedKra9, setRemovedKra9] = useState(false);
  const [removedComp9, setRemovedComp9] = useState(false);
  const [removedFrontID10, setRemovedFrontID10] = useState(false);
  const [removedBackID10, setRemovedBackID10] = useState(false);
  const [removedKra10, setRemovedKra10] = useState(false);
  const [removedFrontID11, setRemovedFrontID11] = useState(false);
  const [removedBackID11, setRemovedBackID11] = useState(false);
  const [removedKra11, setRemovedKra11] = useState(false);
  const [removedFrontID12, setRemovedFrontID12] = useState(false);
  const [removedBackID12, setRemovedBackID12] = useState(false);
  const [removedKra12, setRemovedKra12] = useState(false);
  const [removedComp12, setRemovedComp12] = useState(false);
  const [removedFrontID13, setRemovedFrontID13] = useState(false);
  const [removedBackID13, setRemovedBackID13] = useState(false);
  const [removedKra13, setRemovedKra13] = useState(false);
  const [removedComp13, setRemovedComp13] = useState(false);

  const [fieldsDisabled, setFieldsDisabled] = useState(true);
  const [fieldsDisabled2, setFieldsDisabled2] = useState(true);
  const [fieldsDisabled3, setFieldsDisabled3] = useState(true);
  const [fieldsDisabled4, setFieldsDisabled4] = useState(true);
  const [fieldsDisabled5, setFieldsDisabled5] = useState(true);
  const [fieldsDisabled6, setFieldsDisabled6] = useState(true);
  const [fieldsDisabled7, setFieldsDisabled7] = useState(true);
  const [fieldsDisabled8, setFieldsDisabled8] = useState(true);
  const [fieldsDisabled9, setFieldsDisabled9] = useState(true);
  const [fieldsDisabled10, setFieldsDisabled10] = useState(true);
  const [fieldsDisabled11, setFieldsDisabled11] = useState(true);
  const [fieldsDisabled12, setFieldsDisabled12] = useState(true);
  const [fieldsDisabled13, setFieldsDisabled13] = useState(true);
  // const downloadImage = (imageUrl: string) => {
  //     const a = window.document.createElement("a");
  //     const blob = new Blob([imageUrl], { type: "octet/stream" });
  //     console.log("blob", blob);
  //     const x = window.URL.createObjectURL(blob);
  //     console.log("x", x);
  //     a.href = window.URL.createObjectURL(blob);
  //     a.download = "image.png";
  //     document.body.appendChild(a);
  //     a.click();
  //   };

  //   const downloadImage = () => {
  //     saveAs('image_url', 'image.jpg') // Put your image URL here.
  //   }

  // const downloadImage = async (data: any) => {
  //     console.log(data,'datadata');

  //     try {
  //         const response = await fetch(imageUrl);
  //         const blob = await response.blob();
  //         const url = window.URL.createObjectURL(blob);
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', 'image.png'); // Change the filename if needed
  //         document.body.appendChild(link);
  //         link.click();
  //         link.remove();
  //         console.log(link,'linklink');

  //     } catch (error) {
  //         console.error('Error downloading the image:', error);
  //     }
  // }
  // console.log(inputlfast, ' console.log(inputlfast);');
  console.log(businessdoc, 'businessdocbusinessdoc');

  return (
    <>
      {status == 'waiting_for_submission' ? (
        <div className="complete-profile">
          <Formik
            initialValues={entity}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              const ReqBody = {
                document_type: typevalue,
                business_documents: businessdoc,
                directors: Directordoc,
                shareholders: ShareHolderdoc,
                status: 'submitted',
              };

              const findfast = Directordoc.find((o: any) => o.KRN_certi == '');
              const findtwo = Directordoc.find((o: any) => o.backID == '');
              const findthdee = Directordoc.find((o: any) => o.frontID == '');
              const findfore = Directordoc.find((o: any) => o.Passport == '');

              const findfast_two = Directordoc_two.find(
                (o: any) => o.KRN_certi == ''
              );
              const findtwo_two = Directordoc_two.find(
                (o: any) => o.backID == ''
              );
              const findthdee_two = Directordoc_two.find(
                (o: any) => o.frontID == ''
              );
              const findfore_two = Directordoc_two.find(
                (o: any) => o.Passport == ''
              );

              const findfastss = ShareHolderdoc.find(
                (o: any) => o.KRN_certi == ''
              );
              const findtwoss = ShareHolderdoc.find((o: any) => o.backID == '');
              const findthdeess = ShareHolderdoc.find(
                (o: any) => o.frontID == ''
              );
              const findforess = ShareHolderdoc.find(
                (o: any) => o.Passport == ''
              );
              console.log(
                findfast == null &&
                  findtwo == null &&
                  findthdee == null &&
                  typevalue == '2' &&
                  !!businessdoc.certi_of_incorpo &&
                  businessdoc.certi_of_incorp !== '{type}' &&
                  !!businessdoc.certi_of_compliance &&
                  businessdoc.certi_of_compliance !== '{type}',
                !!businessdoc.certi_of_compliance &&
                  businessdoc.certi_of_compliance !== '{type}',
                businessdoc.certi_of_compliance !== '{type}',
                businessdoc,
                'dgdgggggdggg'
              );
              if (
                !!businessdoc.certi_of_incorpo &&
                !!businessdoc.CR &&
                !!businessdoc.KRA &&
                findfast == undefined &&
                findtwo == undefined &&
                findthdee == undefined &&
                findfore == undefined &&
                findfastss == undefined &&
                findtwoss == undefined &&
                findthdeess == undefined &&
                findforess == undefined &&
                findfast_two == undefined &&
                findtwo_two == undefined &&
                findthdee_two == undefined &&
                typevalue == '6'
              ) {
                console.log('data_submit');
                dispatch(settingActions.submitEntityDocuments() as any);
                setstatus('waiting_for_approval');
              } else if (
                !!businessdoc.certi_of_incorpo &&
                !!businessdoc.CR &&
                !!businessdoc.KRA &&
                findfast == undefined &&
                findtwo == undefined &&
                findthdee == undefined &&
                findfastss == undefined &&
                findtwoss == undefined &&
                findthdeess == undefined &&
                typevalue == '3'
              ) {
                dispatch(settingActions.submitEntityDocuments() as any);
                setstatus('waiting_for_approval');
              } else if (
                findfast == null &&
                findtwo == null &&
                findthdee == null &&
                typevalue == '2' &&
                businessdoc.certi_of_incorpo !== undefined &&
                businessdoc.certi_of_incorp !== '{type}' &&
                businessdoc.certi_of_compliance !== undefined &&
                businessdoc.certi_of_compliance !== '{type}'
              ) {
                setstatus('waiting_for_approval');
                dispatch(settingActions.submitEntityDocuments() as any);
              } else if (
                !!businessdoc.certi_of_incorpo &&
                !!businessdoc.CR &&
                !!businessdoc.KRA &&
                findfast == null &&
                findtwo == null &&
                findthdee == null &&
                (typevalue == '4' || typevalue == '5')
              ) {
                setstatus('waiting_for_approval');
                dispatch(settingActions.submitEntityDocuments() as any);
              } else if (
                !!businessdoc.certi_of_incorpo &&
                findfast == null &&
                findtwo == null &&
                findthdee == null &&
                typevalue == '1'
              ) {
                dispatch(settingActions.submitEntityDocuments() as any);
                setstatus('waiting_for_approval');
              }
              // setRenderSubmit(true)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <>
                <Form onSubmit={handleSubmit}>
                  <ThemeProvider theme={theme}>
                    {userDetail === true &&
                      !!typevalue &&
                      typevalue != '1' &&
                      typevalue != '2' &&
                      typevalue != '5' &&
                      typevalue != '4' && (
                        <>
                          <div className="complete-profile__heading">
                            Please provide the following documents
                          </div>
                          <p className="complete-profile__businesstypo">
                            Business Documents
                          </p>
                          <div className="flax_data_list">
                            <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf).  Please upload notarized documents if entity is registered outside Kenya.">
                              <label className="complete-profile__upload">
                                <div className="complete-profile__upload__label">
                                  {inputlfast.certi_of_incorpo_new == null ||
                                  removedFrontID4 ? (
                                    <span className="complete-profile__upload__txt">
                                      {inputlfast.certi_of_incorpo
                                        ? inputlfast.certi_of_incorpo.replace(
                                            '{type}',
                                            'Certificate of Incorporation'
                                          )
                                        : ''}
                                    </span>
                                  ) : (
                                    <a
                                      href={`${inputlfast.certi_of_incorpo}`}
                                      className="complete-profile__upload__txt"
                                      download
                                    >
                                      {inputlfast.fast_data
                                        ? inputlfast.fast_data.replace(
                                            '{type}',
                                            'Certificate of Incorporation'
                                          )
                                        : ''}
                                    </a>
                                  )}
                                </div>

                                <input
                                  // name="certi_of_incorpo"
                                  type="file"
                                  // value={values.certi_of_incorpo}
                                  onChange={(event: any) => {
                                    var idOfIndex = !!businessdoc.id
                                      ? businessdoc.id
                                      : null;
                                    const newdata = inputlfast;
                                    newdata.certi_of_incorpo =
                                      event.target.files[0].name;
                                    setInputListfast(newdata);
                                    getBase64(
                                      event.target.files[0],
                                      (result: any) => {
                                        var business = businessdoc;
                                        business.certi_of_incorpo = result;
                                        setBusinessDoc(business);
                                        handleChangeFile(
                                          idOfIndex,
                                          'business_documents',
                                          typevalue,
                                          'certi_of_incorpo',
                                          result,
                                          event.target.files[0].name
                                        );
                                        setRemovedFrontID4(false);
                                        setFieldsDisabled4(false);
                                      }
                                    );
                                  }}
                                />

                                {businessdoc.certi_of_incorpo == undefined &&
                                submitted == true ? (
                                  <p className="addnewpere">
                                    This field is required
                                  </p>
                                ) : datalistsize == false &&
                                  submitted == true ? (
                                  'maxdata enter'
                                ) : (
                                  ''
                                )}
                              </label>
                            </Tooltip>
                            {inputlfast.certi_of_incorpo_new == null ||
                            removedFrontID4 ? (
                              <div className="aDADADaDsfgf">
                                <UploadIcon />
                              </div>
                            ) : (
                              <div
                                className="aDADADaDsfgf"
                                onClick={() => {
                                  remove_data(inputlfast.certi_of_incorpo_new);
                                  const newdata = inputlfast;
                                  newdata.certi_of_incorpo = `Certificate of Incorporation`;
                                  newdata.certi_of_incorpo_new = null;
                                  setInputListfast(newdata);
                                  var business = businessdoc;
                                  business.certi_of_incorpo = undefined;
                                  setBusinessDoc(business);
                                  setRemovedFrontID4(true);
                                  setFieldsDisabled4(true);
                                }}
                              >
                                <CloseIcon />
                              </div>
                            )}
                          </div>
                          {typevalue == '6' && (
                            <div className="flax_data_list">
                              <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf).  Please upload notarized documents if entity is registered outside Kenya.">
                                <label className="complete-profile__upload">
                                  <div
                                    // id={!!businessdoc.id ? '' : 'data_disebal' }
                                    className="complete-profile__upload__label"
                                  >
                                    {inputlfast.certi_of_compliance_new ==
                                      null || removedComp4 ? (
                                      <span className="complete-profile__upload__txt">
                                        {inputlfast.certi_of_compliance
                                          ? inputlfast.certi_of_compliance.replace(
                                              '{type}',
                                              'Certificate of Compliance'
                                            )
                                          : ''}
                                      </span>
                                    ) : (
                                      <a
                                        href={`${inputlfast.certi_of_compliance}`}
                                        className="complete-profile__upload__txt"
                                        download
                                      >
                                        {inputlfast.secend_data
                                          ? inputlfast.secend_data.replace(
                                              '{type}',
                                              'Certificate of Compliance'
                                            )
                                          : ''}
                                      </a>
                                    )}
                                  </div>
                                  <input
                                    // name="certi_of_compliance"
                                    // value={values.certi_of_compliance}
                                    //disabled={!!businessdoc.id ? false : true}
                                    // disabled = {fieldsDisabled4}
                                    type="file"
                                    onChange={(event: any) => {
                                      var idOfIndex = !!businessdoc.id
                                        ? businessdoc.id
                                        : null;
                                      const newdata = inputlfast;
                                      newdata.certi_of_compliance =
                                        event.target.files[0].name;
                                      setInputListfast(newdata);
                                      getBase64(
                                        event.target.files[0],
                                        (result: any) => {
                                          var business = businessdoc;
                                          business.certi_of_compliance = result;
                                          setBusinessDoc(business);
                                          handleChangeFile(
                                            idOfIndex,
                                            'business_documents',
                                            typevalue,
                                            'certi_of_compliance',
                                            result,
                                            event.target.files[0].name
                                          );
                                          setRemovedComp4(false);
                                        }
                                      );
                                    }}
                                  />
                                  {businessdoc.certi_of_compliance ==
                                    undefined && submitted == true ? (
                                    <p className="addnewpere">
                                      This field is required
                                    </p>
                                  ) : (
                                    ''
                                  )}
                                </label>
                              </Tooltip>
                              {inputlfast.certi_of_compliance_new == null ||
                              removedComp4 ? (
                                <div className="aDADADaDsfgf">
                                  <UploadIcon />
                                </div>
                              ) : (
                                <div
                                  className="aDADADaDsfgf"
                                  onClick={() => {
                                    remove_data(
                                      inputlfast.certi_of_compliance_new
                                    );
                                    const newdata = inputlfast;
                                    newdata.certi_of_compliance_new = null;
                                    newdata.certi_of_compliance =
                                      'Certificate of Compliance';
                                    setInputListfast(newdata);
                                    var business = businessdoc;
                                    business.certi_of_compliance = undefined;
                                    setBusinessDoc(business);
                                    setRemovedComp4(true);
                                  }}
                                >
                                  <CloseIcon />
                                </div>
                              )}
                            </div>
                          )}
                          <div className="flax_data_list">
                            <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf).  Please upload notarized documents if entity is registered outside Kenya.">
                              <label className="complete-profile__upload">
                                <div
                                  // id={!!businessdoc.id ? '' : 'data_disebal'}
                                  className="complete-profile__upload__label"
                                >
                                  {inputlfast.CR_new == null ||
                                  removedBackID4 ? (
                                    <span className="complete-profile__upload__txt">
                                      {inputlfast.CR
                                        ? inputlfast.CR.replace(
                                            '{type}',
                                            'CR 12'
                                          )
                                        : ''}
                                    </span>
                                  ) : (
                                    <a
                                      href={`${inputlfast.CR}`}
                                      className="complete-profile__upload__txt"
                                      download
                                    >
                                      {inputlfast.thred_data
                                        ? inputlfast.thred_data.replace(
                                            '{type}',
                                            'CR 12'
                                          )
                                        : ''}
                                    </a>
                                  )}
                                </div>
                                <input
                                  // name="CR12"
                                  // value={values.CR}
                                  //disabled={!!businessdoc.id ? false : true}
                                  // disabled = {fieldsDisabled4}
                                  type="file"
                                  onChange={(event: any) => {
                                    var idOfIndex = !!businessdoc.id
                                      ? businessdoc.id
                                      : null;
                                    const newdata = inputlfast;
                                    newdata.CR = event.target.files[0].name;
                                    setInputListfast(newdata);
                                    getBase64(
                                      event.target.files[0],
                                      (result: any) => {
                                        var business = businessdoc;
                                        business.CR = result;
                                        setBusinessDoc(business);
                                        handleChangeFile(
                                          idOfIndex,
                                          'business_documents',
                                          typevalue,
                                          'CR',
                                          result,
                                          event.target.files[0].name
                                        );
                                        setRemovedBackID4(false);
                                      }
                                    );
                                  }}
                                />
                                {businessdoc.CR == undefined &&
                                submitted == true ? (
                                  <p className="addnewpere">
                                    This field is required
                                  </p>
                                ) : (
                                  ''
                                )}
                              </label>
                            </Tooltip>
                            {inputlfast.CR_new == null || removedBackID4 ? (
                              <div className="aDADADaDsfgf">
                                <UploadIcon />
                              </div>
                            ) : (
                              <div
                                className="aDADADaDsfgf"
                                onClick={() => {
                                  remove_data(inputlfast.CR_new);
                                  const newdata = inputlfast;
                                  newdata.CR_new = null;
                                  newdata.CR = `CR 12`;
                                  setInputListfast(newdata);
                                  var business = businessdoc;
                                  business.CR = undefined;
                                  setBusinessDoc(business);
                                  setRemovedBackID4(true);
                                }}
                              >
                                <CloseIcon />
                              </div>
                            )}
                          </div>
                          <div className="flax_data_list">
                            <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                              <label className="complete-profile__upload">
                                <div
                                  // id={!!businessdoc.id ? '' : 'data_disebal'}
                                  className="complete-profile__upload__label"
                                >
                                  {inputlfast.KRA_new == null || removedKra4 ? (
                                    <span className="complete-profile__upload__txt">
                                      {inputlfast.KRA
                                        ? inputlfast.KRA.replace(
                                            '{type}',
                                            'Company’s KRA PIN Certificate'
                                          )
                                        : ''}
                                    </span>
                                  ) : (
                                    <a
                                      href={inputlfast.KRA}
                                      className="complete-profile__upload__txt"
                                      download
                                    >
                                      {inputlfast.frore_data
                                        ? inputlfast.frore_data.replace(
                                            '{type}',
                                            'Company’s KRA PIN Certificate'
                                          )
                                        : ''}
                                    </a>
                                  )}
                                </div>
                                <input
                                  type="file"
                                  // name="KRA"
                                  // value={values.KRA}
                                  //disabled={!!businessdoc.id ? false : true}
                                  // disabled = {fieldsDisabled4}
                                  onChange={(event: any) => {
                                    var idOfIndex = !!businessdoc.id
                                      ? businessdoc.id
                                      : null;
                                    const newdata = inputlfast;
                                    newdata.KRA = event.target.files[0].name;
                                    setInputListfast(newdata);
                                    getBase64(
                                      event.target.files[0],
                                      (result: any) => {
                                        var business = businessdoc;
                                        business.KRA = result;
                                        setBusinessDoc(business);
                                        handleChangeFile(
                                          idOfIndex,
                                          'business_documents',
                                          typevalue,
                                          'KRA',
                                          result,
                                          event.target.files[0].name
                                        );
                                        setRemovedKra4(false);
                                      }
                                    );
                                  }}
                                />
                                {businessdoc.KRA == undefined &&
                                submitted == true ? (
                                  <p className="addnewpere">
                                    This field is required
                                  </p>
                                ) : (
                                  ''
                                )}
                              </label>
                            </Tooltip>
                            {inputlfast.KRA_new == null || removedKra4 ? (
                              <div className="aDADADaDsfgf">
                                <UploadIcon />
                              </div>
                            ) : (
                              <div
                                className="aDADADaDsfgf"
                                onClick={() => {
                                  remove_data(inputlfast.KRA_new);
                                  const newdata = inputlfast;
                                  newdata.KRA_new = null;
                                  newdata.KRA = 'Company’s KRA PIN Certificate';
                                  setInputListfast(newdata);
                                  var business = businessdoc;
                                  business.KRA = undefined;
                                  setBusinessDoc(business);
                                  setRemovedKra4(true);
                                }}
                              >
                                <CloseIcon />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    {userDetail === true && typevalue == '3' ? (
                      <>
                        <p className="complete-profile__businesstypo">
                          Director’s Details
                        </p>
                        {directors.map((x, i: any) => {
                          return (
                            <div className="box">
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Director {i + 1}
                                </p>
                                {i > 0 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick(
                                        i,
                                        !!directors[i].id
                                          ? directors[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {directors[i].frontID_new == null ||
                                      removedFrontID3 ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors[i].frontID
                                            ? directors[i].frontID
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors[i].frontID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors[i].fast_data
                                            ? directors[i].fast_data
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      // maxFileSize={4000000}
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors[i].id
                                          ? directors[i].id
                                          : null;
                                        const newdata = directors;
                                        newdata[i].frontID =
                                          event.target.files[0].name;
                                        setDirectors(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            console.log('result', Directordoc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'directors',
                                              '1',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedFrontID3(false);
                                            setFieldsDisabled3(false);
                                          }
                                        );
                                      }}
                                    />
                                    {directors[i].frontID ==
                                      `{type} {i} National ID Front Picture` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors[i].frontID_new == null ||
                                removedFrontID3 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors[i].frontID_new);
                                      const newDirector = directors;
                                      newDirector[i].frontID_new = null;
                                      newDirector[
                                        i
                                      ].frontID = `Director  {i} - National ID Front Picture`;
                                      setDirectors(newDirector);
                                      setRemovedFrontID3(true);
                                      setFieldsDisabled3(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      // !!directors[i].id ? '' : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {directors[i].backID_new == null ||
                                      removedBackID3 ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors[i].backID
                                            ? directors[i].backID
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors[i].backID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors[i].secend_data
                                            ? directors[i].secend_data
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      // disabled={
                                      //   !!directors[i].id ? false : true
                                      // }
                                      // disabled = {fieldsDisabled}
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors[i].id
                                          ? directors[i].id
                                          : null;
                                        console.log('clicked', directors);
                                        const newdata = directors;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setDirectors(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            handleChangeFile(
                                              idOfIndex,
                                              'directors',
                                              '1',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedBackID3(false);
                                          }
                                        );
                                        console.log('updated', directors);
                                      }}
                                    />
                                    {directors[i].backID ==
                                      `{type} {i} National ID Back Picture` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors[i].backID_new == null ||
                                removedBackID3 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors[i].backID_new);
                                      const newDirector = directors;
                                      newDirector[i].backID_new = null;
                                      newDirector[
                                        i
                                      ].backID = `Director  {i} - National ID Back Picture`;
                                      setDirectors(newDirector);
                                      setRemovedBackID3(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!directors[i].id ? '' : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {directors[i].KRN_certi_new == null ||
                                      removedKra3 ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors[i].KRN_certi
                                            ? directors[i].KRN_certi.replace(
                                                '{type}',
                                                "Director's"
                                              ).replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors[i].KRN_certi}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors[i].thred_data
                                            ? directors[i].thred_data
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      // disabled={
                                      //   !!directors[i].id ? false : true
                                      // }
                                      // disabled = {fieldsDisabled}
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors[i].id
                                          ? directors[i].id
                                          : null;
                                        const newdata = directors;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setDirectors(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            handleChangeFile(
                                              idOfIndex,
                                              'directors',
                                              '1',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedKra3(false);
                                          }
                                        );
                                      }}
                                    />

                                    {directors[i].KRN_certi ==
                                      `{type} {i} KRA PIN Certificate` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors[i].KRN_certi_new == null ||
                                removedKra3 ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors[i].KRN_certi_new);
                                      const newDirector = directors;
                                      newDirector[i].KRN_certi_new = null;
                                      newDirector[
                                        i
                                      ].KRN_certi = `Director  {i} - KRA PIN Certificate`;
                                      setDirectors(newDirector);
                                      setRemovedKra3(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>

                              <div className="complete-profile__btn_box">
                                {directors.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick}
                                  >
                                    + Add another director{' '}
                                  </Button>
                                )}
                              </div>
                            </div>
                          );
                        })}

                        <p className="complete-profile__businesstypo">
                          Shareholders’ Details
                        </p>
                        {shareholder.map((x, i) => {
                          return (
                            <div className="box">
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Shareholder {i + 1}
                                </p>
                                {i >= 1 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick2(
                                        i,
                                        !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {shareholder[i].frontID_new == null ||
                                      removedFrontID ? (
                                        <span className="complete-profile__upload__txt">
                                          {shareholder[i].frontID.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </span>
                                      ) : (
                                        <a
                                          href={shareholder[i].frontID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {shareholder[i].fast_data.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null;
                                        const newdata = shareholder;
                                        newdata[i].frontID =
                                          event.target.files[0].name;
                                        setShareholder(newdata);

                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            handleChangeFile(
                                              idOfIndex,
                                              'shareholders',
                                              '1',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedFrontID(false);
                                            setFieldsDisabled(false);
                                          }
                                        );
                                      }}
                                    />
                                    {shareholder[i].frontID ==
                                      `Shareholder  {i} - National ID Front Picture` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {shareholder[i].frontID_new == null ||
                                removedFrontID ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(shareholder[i].frontID_new);
                                      const newShareholder = shareholder;
                                      newShareholder[i].frontID_new = null;
                                      newShareholder[
                                        i
                                      ].frontID = `Shareholder  {i} - National ID Front Picture`;
                                      setShareholder(newShareholder);
                                      setRemovedFrontID(true);
                                      setFieldsDisabled(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!shareholder[i].id
                                      //     ? ''
                                      //     :'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {shareholder[i].backID_new == null ||
                                      removedBackID ? (
                                        <span className="complete-profile__upload__txt">
                                          {shareholder[i].backID.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </span>
                                      ) : (
                                        <a
                                          href={shareholder[i].backID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {shareholder[i].secend_data.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      // disabled={
                                      //   !!shareholder[i].id ? false : true
                                      // }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null;
                                        const newdata = shareholder;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setShareholder(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            handleChangeFile(
                                              idOfIndex,
                                              'shareholders',
                                              '1',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );

                                            setRemovedBackID(false);
                                          }
                                        );
                                      }}
                                    />
                                    {shareholder[i].backID ==
                                      `Shareholder  {i} - National ID Back Picture` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {shareholder[i].backID_new == null ||
                                removedBackID ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      // handleRemoveDocument(i, )
                                      remove_data(shareholder[i].backID_new);
                                      const newShareholder = shareholder;
                                      newShareholder[i].backID_new = null;
                                      newShareholder[
                                        i
                                      ].backID = `Shareholder  {i} - National ID Back Picture`;
                                      setShareholder(newShareholder);
                                      setRemovedBackID(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!shareholder[i].id
                                      //     ? ''
                                      //     : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {shareholder[i].KRN_certi_new == null ||
                                      removedKra ? (
                                        <span className="complete-profile__upload__txt">
                                          {shareholder[i].KRN_certi.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </span>
                                      ) : (
                                        <a
                                          href={shareholder[i].KRN_certi}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {shareholder[i].thred_data.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      // disabled={
                                      //   !!shareholder[i].id ? false : true
                                      // }
                                      // disabled = {fieldsDisabled}
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null;
                                        const newdata = shareholder;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setShareholder(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            handleChangeFile(
                                              idOfIndex,
                                              'shareholders',
                                              '1',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedKra(false);
                                          }
                                        );
                                      }}
                                    />
                                    {shareholder[i].KRN_certi ==
                                      `Shareholder  {i} - KRA PIN Certificates` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {shareholder[i].KRN_certi_new == null ||
                                removedKra ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(shareholder[i].KRN_certi_new);
                                      const newShareholder = shareholder;
                                      newShareholder[i].KRN_certi_new = null;
                                      newShareholder[
                                        i
                                      ].KRN_certi = `Shareholder  {i} - KRA PIN Certificate`;
                                      setShareholder(newShareholder);
                                      setRemovedKra(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="complete-profile__btn_box">
                                {shareholder.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick2}
                                  >
                                    + Add another shareholder{' '}
                                  </Button>
                                )}
                                {/* {inputList2.length !== 1 && <Button className='complete-profile__removebtn' onClick={() => {handleRemoveClick2(i) }}>Remove</Button>} */}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : userDetail === true && typevalue == '6' ? (
                      <>
                        <p className="complete-profile__businesstypo">
                          Representative's Details
                        </p>

                        {directors_two.map((x, i: any) => {
                          return (
                            <div className="box">
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Representative {i + 1}
                                </p>
                                {i > 0 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick_two(
                                        i,
                                        !!directors_two[i].id
                                          ? directors_two[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {directors_two[i].frontID_new == null ||
                                      removedFrontID2 ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors_two[i].frontID
                                            ? directors_two[i].frontID
                                                .replace(
                                                  '{type}',
                                                  "Representative's"
                                                )
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors_two[i].frontID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors_two[i].fast_data
                                            ? directors_two[i].fast_data
                                                .replace(
                                                  '{type}',
                                                  "Representative's"
                                                )
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      // maxFileSize={4000000}
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors_two[i].id
                                          ? directors_two[i].id
                                          : null;
                                        const newdata = directors_two;
                                        newdata[i].frontID =
                                          event.target.files[0].name;
                                        setDirectors_two(newdata);
                                        // const datavid = datadetels;
                                        // datavid[i].datainput1 = event.target.files[0].name
                                        // setdatadetels(datavid)
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            console.log('result', Directordoc);
                                            var directorDc = Directordoc_two;
                                            directorDc[i].frontID = result;
                                            setDirectorDoc_two(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'representative',
                                              '1',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedFrontID2(false);
                                            setFieldsDisabled2(false);
                                          }
                                        );
                                      }}
                                    />
                                    {directors_two[i].frontID_new == null &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors_two[i].frontID_new == null ||
                                removedFrontID2 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors_two[i].frontID_new);
                                      const newShareholder = shareholder;
                                      newShareholder[i].frontID_new = null;
                                      newShareholder[
                                        i
                                      ].frontID = `Representative's  {i} - National ID Front Picture`;
                                      setShareholder(newShareholder);
                                      setRemovedFrontID2(true);
                                      setFieldsDisabled2(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!directors_two[i].id
                                      //     ? ''
                                      //     : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {directors_two[i].backID_new == null ||
                                      removedBackID2 ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors_two[i].backID
                                            ? directors_two[i].backID
                                                .replace(
                                                  '{type}',
                                                  "Representative's"
                                                )
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors_two[i].backID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors_two[i].secend_data
                                            ? directors_two[i].secend_data
                                                .replace(
                                                  '{type}',
                                                  "Representative's"
                                                )
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      // disabled={
                                      //   !!directors_two[i].id ? false : true
                                      // }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors_two[i].id
                                          ? directors_two[i].id
                                          : null;
                                        console.log('clicked', directors_two);
                                        const newdata = directors_two;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setDirectors_two(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc_two;
                                            directorDc[i].backID = result;
                                            setDirectorDoc_two(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'representative',
                                              '1',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedBackID2(false);
                                          }
                                        );
                                      }}
                                    />
                                    {directors_two[i].backID_new == null &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors_two[i].backID_new == null ||
                                removedBackID2 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors_two[i].backID_new);
                                      const newShareholder = shareholder;
                                      newShareholder[i].backID_new = null;
                                      newShareholder[
                                        i
                                      ].backID = `Representative's  {i} - National ID Back Picture`;
                                      setShareholder(newShareholder);
                                      setRemovedBackID2(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!directors_two[i].id
                                      //     ? ''
                                      //     : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {directors_two[i].KRN_certi_new == null ||
                                      removedKra2 ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors_two[i].KRN_certi
                                            ? directors_two[
                                                i
                                              ].KRN_certi.replace(
                                                '{type}',
                                                "Representative's"
                                              ).replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors_two[i].KRN_certi}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors_two[i].thred_data
                                            ? directors_two[i].thred_data
                                                .replace(
                                                  '{type}',
                                                  "Representative's"
                                                )
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      // disabled={
                                      //   !!directors_two[i].id ? false : true
                                      // }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors_two[i].id
                                          ? directors_two[i].id
                                          : null;
                                        const newdata = directors_two;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setDirectors_two(newdata);
                                        // const datavid = datadetels;
                                        // datavid[i].datainput3 = event.target.files[0].name
                                        // setdatadetels(datavid)
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc_two;
                                            directorDc[i].KRN_certi = result;
                                            setDirectorDoc_two(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'representative',
                                              '1',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedKra2(false);
                                          }
                                        );
                                      }}
                                    />

                                    {directors_two[i].KRN_certi_new == null &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors_two[i].KRN_certi_new == null ||
                                removedKra2 ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(
                                        directors_two[i].KRN_certi_new
                                      );
                                      const newShareholder = shareholder;
                                      newShareholder[i].KRN_certi_new = null;
                                      newShareholder[
                                        i
                                      ].KRN_certi = `Representative's  {i} - KRA PIN Certificate`;
                                      setShareholder(newShareholder);
                                      setRemovedKra2(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>

                              <div className="complete-profile__btn_box">
                                {directors_two.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick_two}
                                  >
                                    + Add another representative{' '}
                                  </Button>
                                )}
                              </div>
                            </div>
                          );
                        })}
                        <p className="complete-profile__businesstypo">
                          Director’s Details{' '}
                        </p>
                        {directors.map((x, i: any) => {
                          return (
                            <div className="box">
                              {/* <p className='complete-profile__director'>Representative {i + 1}</p> */}
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Director {i + 1}
                                </p>
                                {i >= 1 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick(
                                        i,
                                        !!directors[i].id
                                          ? directors[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {directors[i].frontID_new == null ||
                                      removedFrontID3 ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors[i].frontID
                                            ? directors[i].frontID
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors[i].frontID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors[i].fast_data
                                            ? directors[i].fast_data
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors[i].id
                                          ? directors[i].id
                                          : null;
                                        const newdata = directors;
                                        newdata[i].frontID =
                                          event.target.files[0].name;
                                        setDirectors(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].frontID = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'directors',
                                              '2',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedFrontID3(false);
                                            setFieldsDisabled3(false);
                                          }
                                        );
                                      }}
                                    />
                                    {directors[i].frontID_new == null &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors[i].frontID_new == null ||
                                removedFrontID3 ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors[i].frontID_new);
                                      const newDirector = directors;
                                      newDirector[i].frontID_new = null;
                                      newDirector[
                                        i
                                      ].frontID = `Director's  {i} - National ID Front Picture`;
                                      setShareholder(newDirector);
                                      setRemovedFrontID3(true);
                                      setFieldsDisabled3(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!directors[i].id ? '' : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {directors[i].backID_new == null ||
                                      removedBackID3 ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors[i].backID
                                            ? directors[i].backID
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors[i].backID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors[i].secend_data
                                            ? directors[i].secend_data
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      // disabled={
                                      //   !!directors[i].id ? false : true
                                      // }
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors[i].id
                                          ? directors[i].id
                                          : null;
                                        console.log('clicked', directors);
                                        const newdata = directors;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setDirectors(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].backID = result;
                                            setDirectorDoc(directorDc);
                                            setFileTax2Base(result);
                                            handleChangeFile(
                                              idOfIndex,
                                              'directors',
                                              '2',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedBackID3(false);
                                          }
                                        );
                                      }}
                                    />
                                    {directors[i].backID_new == null &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors[i].backID_new == null ||
                                removedBackID3 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors[i].backID_new);
                                      const newDirector = directors;
                                      newDirector[i].backID_new = null;
                                      newDirector[
                                        i
                                      ].backID = `Director  {i} - National ID Back Picture`;
                                      setDirectors(newDirector);
                                      setRemovedBackID3(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!directors[i].id ? '' : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {directors[i].KRN_certi_new == null ||
                                      removedKra3 ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors[i].KRN_certi
                                            ? directors[i].KRN_certi.replace(
                                                '{type}',
                                                "Director's"
                                              ).replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors[i].KRN_certi}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors[i].thred_data
                                            ? directors[i].thred_data
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      // disabled={
                                      //   !!directors[i].id ? false : true
                                      // }
                                      // disabled = {fieldsDisabled3}
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors[i].id
                                          ? directors[i].id
                                          : null;
                                        const newdata = directors;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setDirectors(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].KRN_certi = result;
                                            setDirectorDoc(directorDc);
                                            setFileTax2Base(result);
                                            handleChangeFile(
                                              idOfIndex,
                                              'directors',
                                              '2',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedKra3(false);
                                          }
                                        );
                                      }}
                                    />
                                    {directors[i].KRN_certi_new == null &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors[i].KRN_certi_new == null ||
                                removedKra3 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors[i].KRN_certi_new);
                                      const newDirector = directors;
                                      newDirector[i].KRN_certi_new = null;
                                      newDirector[
                                        i
                                      ].KRN_certi = `Director  {i} - National ID Back Picture`;
                                      setDirectors(newDirector);
                                      var directorDc = Directordoc;
                                      directorDc[i].KRN_certi = null;
                                      setDirectorDoc(directorDc);
                                      setRemovedKra3(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!directors[i].id ? '' : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {directors[i].Passport_new == null ||
                                      removedComp3 ? (
                                        <span className="complete-profile__upload__txt">
                                          {directors[i].Passport
                                            ? directors[i].Passport.replace(
                                                '{type}',
                                                "Director's"
                                              ).replace('{i}', i + 1)
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={directors[i].Passport}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {directors[i].frore_data
                                            ? directors[i].frore_data
                                                .replace('{type}', "Director's")
                                                .replace('{i}', i + 1)
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      // disabled={
                                      //   !!directors[i].id ? false : true
                                      // }
                                      // disabled = {fieldsDisabled3}
                                      onChange={(event: any) => {
                                        var idOfIndex = !!directors[i].id
                                          ? directors[i].id
                                          : null;
                                        const newdata = directors;
                                        newdata[i].Passport =
                                          event.target.files[0].name;
                                        setDirectors(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].Passport = result;
                                            setDirectorDoc(directorDc);
                                            setFileTax2Base(result);
                                            handleChangeFile(
                                              idOfIndex,
                                              'directors',
                                              '2',
                                              'Passport',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedComp3(false);
                                          }
                                        );
                                      }}
                                    />
                                    {directors[i].Passport_new == null &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {directors[i].Passport_new == null ||
                                removedComp3 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(directors[i].Passport_new);
                                      const newdata = directors;
                                      newdata[i].Passport_new = null;
                                      newdata[
                                        i
                                      ].Passport = `Director's Passport Copy`;
                                      setDirectors(newdata);
                                      var directorDc = Directordoc;
                                      directorDc[i].Passport = null;
                                      setDirectorDoc(directorDc);
                                      setRemovedComp3(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="complete-profile__btn_box">
                                {directors.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick}
                                  >
                                    + Add another director
                                  </Button>
                                )}
                                {/* {inputList.length !== 1 && <Button className='complete-profile__removebtn' onClick={handleRemoveClick}>Remove</Button>} */}
                              </div>
                            </div>
                          );
                        })}
                        {shareholder.map((x, i) => {
                          return (
                            <div className="box">
                              <p className="complete-profile__businesstypo">
                                Shareholders’ Details
                              </p>
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Shareholder {i + 1}
                                </p>
                                {i >= 1 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick2(
                                        i,
                                        !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {shareholder[i].frontID_new == null ||
                                      removedFrontID13 ? (
                                        <span className="complete-profile__upload__txt">
                                          {shareholder[i].frontID.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </span>
                                      ) : (
                                        <a
                                          href={shareholder[i].frontID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {shareholder[i].fast_data.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null;

                                        const newdata = shareholder;
                                        newdata[i].frontID =
                                          event.target.files[0].name;
                                        setShareholder(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            setFileTax1Base(result);
                                            var shareHolderDc = ShareHolderdoc;
                                            shareHolderDc[i].frontID = result;
                                            setShareHoldersDocs(shareHolderDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'shareholders',
                                              '2',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedFrontID13(false);
                                            setFieldsDisabled13(false);
                                          }
                                        );
                                      }}
                                    />

                                    {shareholder[i].frontID ==
                                      `Shareholder  {i} - National ID Front Picture` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {shareholder[i].frontID_new == null ||
                                removedFrontID13 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(shareholder[i].frontID_new);
                                      const newdata = shareholder;
                                      newdata[i].frontID_new = null;
                                      newdata[
                                        i
                                      ].frontID = `Shareholder  {i} - National ID Front Picture`;
                                      var shareHolderDc = ShareHolderdoc;
                                      shareHolderDc[i].frontID = null;
                                      setShareHoldersDocs(shareHolderDc);
                                      setRemovedFrontID13(true);
                                      setFieldsDisabled(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf).  Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!shareholder[i].id
                                      //     ? ''
                                      //     : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {shareholder[i].backID_new == null ||
                                      removedBackID13 ? (
                                        <span className="complete-profile__upload__txt">
                                          {shareholder[i].backID.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </span>
                                      ) : (
                                        <a
                                          href={shareholder[i].backID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {shareholder[i].secend_data.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      // disabled={
                                      //   !!shareholder[i].id ? false : true
                                      // }
                                      // disabled = {fieldsDisabled13}
                                      onChange={(event: any) => {
                                        var idOfIndex = !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null;

                                        setFileTax2(event.target.files[0].name);
                                        const newdata = shareholder;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setShareholder(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            setFileTax2Base(result);
                                            var shareHolderDc = ShareHolderdoc;
                                            shareHolderDc[i].backID = result;
                                            setShareHoldersDocs(shareHolderDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'shareholders',
                                              '2',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedBackID13(false);
                                          }
                                        );
                                      }}
                                    />
                                    {shareholder[i].backID ==
                                      `Shareholder  {i} - National ID Back Picture` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {shareholder[i].backID_new == null ||
                                removedBackID13 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(shareholder[i].backID_new);
                                      const newdata = shareholder;
                                      newdata[i].backID_new = null;
                                      newdata[
                                        i
                                      ].backID = `Shareholder  {i} - National ID Back Picture`;
                                      setShareholder(newdata);
                                      var shareHolderDc = ShareHolderdoc;
                                      shareHolderDc[i].backID = null;
                                      setShareHoldersDocs(shareHolderDc);
                                      setRemovedBackID13(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!shareholder[i].id
                                      //     ? ''
                                      //     : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {shareholder[i].KRN_certi_new == null ||
                                      removedKra13 ? (
                                        <span className="complete-profile__upload__txt">
                                          {shareholder[i].KRN_certi.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </span>
                                      ) : (
                                        <a
                                          href={shareholder[i].KRN_certi}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {shareholder[i].thred_data.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      // disabled={
                                      //   !!shareholder[i].id ? false : true
                                      // }
                                      // disabled = {fieldsDisabled13}
                                      type="file"
                                      onChange={(event: any) => {
                                        setFileTax2(event.target.files[0].name);
                                        var idOfIndex = !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null;
                                        const newdata = shareholder;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setShareholder(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            setFileTax2Base(result);
                                            var shareHolderDc = ShareHolderdoc;
                                            shareHolderDc[i].KRN_certi = result;
                                            setShareHoldersDocs(shareHolderDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'shareholders',
                                              '2',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedKra13(false);
                                          }
                                        );
                                      }}
                                    />
                                    {shareholder[i].KRN_certi ==
                                      `Shareholder  {i} - KRA PIN Certificates` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {shareholder[i].KRN_certi_new == null ||
                                removedKra13 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(shareholder[i].KRN_certi_new);
                                      const newdata = shareholder;
                                      newdata[i].KRN_certi_new = null;
                                      newdata[
                                        i
                                      ].KRN_certi = `Shareholder  {i} - KRA PIN Certificates`;
                                      setShareholder(newdata);
                                      var shareHolderDc = ShareHolderdoc;
                                      shareHolderDc[i].KRN_certi = null;
                                      setShareHoldersDocs(shareHolderDc);
                                      setRemovedKra13(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {shareholder[i].Passport_new == null ||
                                      removedComp13 ? (
                                        <span className="complete-profile__upload__txt">
                                          {shareholder[i].Passport.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </span>
                                      ) : (
                                        <a
                                          href={shareholder[i].Passport}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {shareholder[i].frore_data.replace(
                                            '{i}',
                                            (i + 1).toString()
                                          )}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!shareholder[i].id
                                          ? shareholder[i].id
                                          : null;

                                        const newdata = shareholder;
                                        newdata[i].Passport =
                                          event.target.files[0].name;
                                        setShareholder(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            setFileTax1Base(result);
                                            var shareHolderDc = ShareHolderdoc;
                                            shareHolderDc[i].Passport = result;
                                            setShareHoldersDocs(shareHolderDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'shareholders',
                                              '2',
                                              'Passport',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedComp13(false);
                                          }
                                        );
                                      }}
                                    />

                                    {shareholder[i].Passport ==
                                      `Shareholder's Passport Copy` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {shareholder[i].Passport_new == null ||
                                removedComp13 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(shareholder[i].Passport_new);

                                      const newdata = shareholder;
                                      newdata[i].Passport_new = null;
                                      newdata[
                                        i
                                      ].Passport = `Shareholder's Passport Copy`;
                                      setShareholder(newdata);
                                      var shareHolderDc = ShareHolderdoc;
                                      shareHolderDc[i].Passport = null;
                                      setShareHoldersDocs(shareHolderDc);
                                      setRemovedComp13(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="complete-profile__btn_box">
                                {shareholder.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick2}
                                  >
                                    + Add another shareholder{' '}
                                  </Button>
                                )}
                                {/* {inputList2.length !== 1 && <Button className='complete-profile__removebtn' onClick={() => {handleRemoveClick2(i) }}>Remove</Button>} */}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : userDetail === true && typevalue == '2' ? (
                      <>
                        <div className="complete-profile__heading">
                          Please provide the following documents
                        </div>
                        <p className="complete-profile__businesstypo">
                          Business Documents
                        </p>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                            <label className="complete-profile__upload">
                              <div className="complete-profile__upload__label">
                                {inputlfast.certi_of_incorpo_new == null ||
                                removedFrontID6 ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.certi_of_incorpo
                                      ? inputlfast.certi_of_incorpo.replace(
                                          '{type}',
                                          'Business Registration Certificate'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={`${inputlfast.certi_of_incorpo}`}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.fast_data
                                      ? inputlfast.fast_data.replace(
                                          '{type}',
                                          'Business Registration Certificate'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.certi_of_incorpo =
                                    event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event?.target?.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.certi_of_incorpo = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'certi_of_incorpo',
                                        result,
                                        event?.target?.files[0]?.name
                                      );
                                      setRemovedFrontID6(false);
                                      setFieldsDisabled6(false);
                                    }
                                  );
                                }}
                              />
                              {(businessdoc.certi_of_incorpo == undefined ||
                                businessdoc.certi_of_incorpo == '{type}') &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.certi_of_incorpo_new == null ||
                          removedFrontID6 ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.certi_of_incorpo_new);
                                const newdata = inputlfast;
                                newdata.certi_of_incorpo_new = null;
                                newdata.certi_of_incorpo =
                                  'Business Registration Certificate';
                                setInputListfast(newdata);
                                var business = businessdoc;
                                business.certi_of_incorpo = undefined;
                                setBusinessDoc(business);
                                setRemovedFrontID6(true);
                                setFieldsDisabled6(true);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                            <label className="complete-profile__upload">
                              <div
                                // id={!!businessdoc.id ? '' : 'data_disebal'}
                                className="complete-profile__upload__label"
                              >
                                {inputlfast.certi_of_compliance_new == null ||
                                removedBackID6 ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.certi_of_compliance
                                      ? inputlfast.certi_of_compliance.replace(
                                          '{type}',
                                          'Partnership Mandate or Deed'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={`${inputlfast.certi_of_compliance}`}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.secend_data
                                      ? inputlfast.secend_data.replace(
                                          '{type}',
                                          'Partnership Mandate or Deed'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                //disabled={!!businessdoc.id ? false : true}
                                // disabled= {fieldsDisabled6}
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.certi_of_compliance =
                                    event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event?.target?.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.certi_of_compliance = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'certi_of_compliance',
                                        result,
                                        event?.target?.files[0]?.name
                                      );
                                      setRemovedBackID6(false);
                                    }
                                  );
                                }}
                              />
                              {(businessdoc.certi_of_compliance == '{type}' ||
                                businessdoc.certi_of_compliance == undefined) &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.certi_of_compliance_new == null ||
                          removedBackID6 ? (
                            <div className="aDADADaDsfgf">
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.certi_of_compliance_new);
                                const newdata = inputlfast;
                                newdata.certi_of_compliance_new = null;
                                newdata.certi_of_compliance =
                                  'Partnership Mandate or Deed';
                                setInputListfast(newdata);
                                var business = businessdoc;
                                business.certi_of_compliance = undefined;
                                setBusinessDoc(business);
                                setRemovedBackID6(true);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <p className="complete-profile__businesstypo">
                          Partner's Details
                        </p>
                        {groupOneName.map((x, i: any) => {
                          return (
                            <div className="box">
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Partner {i + 1}
                                </p>
                                {i >= 1 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick(
                                        i,
                                        !!directors[i].id
                                          ? directors[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {groupOneName[i].frontID_new == null ||
                                      removedFrontID8 ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].frontID
                                            ? groupOneName[i].frontID
                                                .replace('{type}', "Partner's")
                                                .replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={`${groupOneName[i].frontID}`}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].fast_data
                                            ? groupOneName[i].fast_data
                                                .replace('{type}', "Partner's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].frontID =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);

                                        setFileTax1(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].frontID = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'partners',
                                              '3',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedFrontID8(false);
                                            setFieldsDisabled8(false);
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].frontID == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].frontID_new == null ||
                                removedFrontID8 ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(groupOneName[i].frontID_new);
                                      const newdata = groupOneName;
                                      newdata[i].frontID_new = null;
                                      newdata[i].frontID =
                                        "Partner's {i} National ID Front Picture";
                                      setGroupOneName(newdata);
                                      var directorDc = Directordoc;
                                      directorDc[i].frontID = ``;
                                      setDirectorDoc(directorDc);
                                      setRemovedFrontID8(true);
                                      setFieldsDisabled8(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label
                                    aria-disabled={
                                      !!groupOneName[i].id ? true : false
                                    }
                                    className="complete-profile__upload"
                                  >
                                    <div
                                      // id={
                                      //   !!groupOneName[i].id
                                      //     ? ''
                                      //     : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {groupOneName[i].backID_new == null ||
                                      removedBackID8 ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].backID
                                            ? groupOneName[i].backID
                                                .replace('{type}', "Partner's")
                                                .replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={`${groupOneName[i].backID}`}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].secend_data
                                            ? groupOneName[i].secend_data
                                                .replace('{type}', "Partner's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      // disabled={
                                      //   !!groupOneName[i].id ? false : true
                                      // }
                                      // disabled = {fieldsDisabled8}
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);

                                        setFileTax2(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].backID = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'partners',
                                              '3',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedBackID8(false);
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].backID == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].backID_new == null ||
                                removedBackID8 ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(groupOneName[i].backID_new);
                                      const newdata = groupOneName;
                                      newdata[i].backID_new = null;
                                      newdata[i].backID =
                                        "Partner's {i} National ID Back Picture";
                                      setGroupOneName(newdata);
                                      var directorDc = Directordoc;
                                      directorDc[i].backID = ``;
                                      setDirectorDoc(directorDc);
                                      setRemovedBackID8(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!groupOneName[i].id
                                      //     ? ''
                                      //     : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {groupOneName[i].KRN_certi_new == null ||
                                      removedKra8 ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].KRN_certi
                                            ? groupOneName[i].KRN_certi.replace(
                                                '{type}',
                                                "Partner's"
                                              ).replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={`${groupOneName[i].KRN_certi}`}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].thred_data
                                            ? groupOneName[i].thred_data
                                                .replace('{type}', "Partner's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      // disabled={
                                      //   !!groupOneName[i].id ? false : true
                                      // }
                                      // disabled = {fieldsDisabled8}
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);

                                        setFileTax2(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            setFileTax2Base(result);
                                            var directorDc = Directordoc;
                                            directorDc[i].KRN_certi = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'partners',
                                              '3',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedKra8(false);
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].KRN_certi == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].KRN_certi_new == null ||
                                removedKra8 ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(
                                        groupOneName[i].KRN_certi_new
                                      );
                                      const newdata = groupOneName;
                                      newdata[i].KRN_certi_new = null;
                                      newdata[i].KRN_certi =
                                        "Partner's {i} KRA PIN Certifcate";
                                      setGroupOneName(newdata);
                                      var directorDc = Directordoc;
                                      directorDc[i].KRN_certi = ``;
                                      setDirectorDoc(directorDc);
                                      setRemovedKra8(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="complete-profile__btn_box">
                                {groupOneName.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick}
                                  >
                                    + Add another partner{' '}
                                  </Button>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : userDetail === true && typevalue == '1' ? (
                      <>
                        <div className="complete-profile__heading">
                          Please provide the following documents
                        </div>
                        <p className="complete-profile__businesstypo">
                          Business Documents
                        </p>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                            <label className="complete-profile__upload">
                              <div className="complete-profile__upload__label">
                                {inputlfast.certi_of_incorpo_new == null ||
                                removedFrontID7 ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.certi_of_incorpo
                                      ? inputlfast.certi_of_incorpo.replace(
                                          '{type}',
                                          'Business Registration Certificate'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={`${inputlfast.certi_of_incorpo}`}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.fast_data
                                      ? inputlfast.fast_data.replace(
                                          '{type}',
                                          'Business Registration Certificate'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.certi_of_incorpo =
                                    event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.certi_of_incorpo = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'certi_of_incorpo',
                                        result,
                                        event.target.files[0].name
                                      );
                                      setRemovedFrontID7(false);
                                    }
                                  );
                                }}
                              />
                              {businessdoc.certi_of_incorpo == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.certi_of_incorpo_new == null ||
                          removedFrontID7 ? (
                            <div className="aDADADaDsfgf">
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.certi_of_incorpo_new);
                                const newdata = inputlfast;
                                newdata.certi_of_incorpo_new = null;
                                newdata.certi_of_incorpo =
                                  'Business Registration Certificate';
                                setInputListfast(newdata);
                                var business = businessdoc;
                                business.certi_of_incorpo = undefined;
                                setBusinessDoc(business);
                                setRemovedFrontID7(true);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <p className="complete-profile__businesstypo">
                          Proprietor's Details
                        </p>
                        {groupOneName.map((x, i) => {
                          console.log(groupOneName, 'groupOneName');

                          return (
                            <div className="box">
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Proprietor {i + 1}
                                </p>
                                {i >= 1 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick(
                                        i,
                                        !!directors[i].id
                                          ? directors[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              {/* <p className='complete-profile__director'>Proprietor {i + 1}</p> */}
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    {/* <div > */}
                                    {/* <a download={`${groupOneName[i].frontID}`} href="/path/to/image" title="ImageName"> */}
                                    {/* ZSDADddddADd
                                                                </a> */}
                                    <div
                                      className="complete-profile__upload__label"

                                      // href={`${groupOneName[i].frontID}`}
                                      // download target="_blank"
                                    >
                                      {groupOneName[i].frontID_new == null ||
                                      removedFrontID5 ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].frontID
                                            ? groupOneName[i].frontID.replace(
                                                '{type}',
                                                "Proprietor's"
                                              )
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={`${groupOneName[i].frontID}`}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].fast_data
                                            ? groupOneName[i].fast_data.replace(
                                                '{type}',
                                                "Proprietor's"
                                              )
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].frontID =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].frontID = result;
                                            setDirectorDoc(directorDc);
                                            setFileTax1Base(result);
                                            handleChangeFile(
                                              idOfIndex,
                                              'proprietor',
                                              '4',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedFrontID5(false);
                                            setFieldsDisabled5(false);
                                          }
                                        );
                                      }}
                                    />

                                    {Directordoc[i].frontID == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].frontID_new == null ||
                                removedFrontID5 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />{' '}
                                  </div>
                                ) : (
                                  <div className="aDADADaDsfgf">
                                    <CloseIcon
                                      onClick={() => {
                                        remove_data(
                                          groupOneName[i].frontID_new
                                        );
                                        setRemovedFrontID5(true);
                                        setFieldsDisabled5(true);
                                        const newdata = groupOneName;
                                        newdata[i].frontID_new = null;
                                        newdata[
                                          i
                                        ].frontID = `Proprietor's National ID Front Picture`;
                                        setGroupOneName(newdata);
                                        var directorDc = Directordoc;
                                        directorDc[i].frontID = ``;
                                        setDirectorDoc(directorDc);
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!groupOneName[i].id
                                      //     ? ''
                                      //     : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {groupOneName[i].backID_new == null ||
                                      removedBackID5 ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].backID
                                            ? groupOneName[i].backID.replace(
                                                '{type}',
                                                "Proprietor's"
                                              )
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={`${groupOneName[i].backID}`}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].secend_data
                                            ? groupOneName[
                                                i
                                              ].secend_data.replace(
                                                '{type}',
                                                "Proprietor's"
                                              )
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      // disabled={
                                      //   !!groupOneName[i].id ? false : true
                                      // }
                                      // disabled = {fieldsDisabled5}
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].backID = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'proprietor',
                                              '4',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedBackID5(false);
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].backID == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].backID_new == null ||
                                removedBackID5 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(groupOneName[i].backID_new);
                                      const newdata = groupOneName;
                                      newdata[i].backID_new = null;
                                      newdata[
                                        i
                                      ].backID = `Proprietor's National ID Back Picture`;
                                      setGroupOneName(newdata);
                                      var directorDc = Directordoc;
                                      directorDc[i].backID = ``;
                                      setDirectorDoc(directorDc);
                                      setRemovedBackID5(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!groupOneName[i].id
                                      //     ? ''
                                      //     : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {groupOneName[i].KRN_certi_new == null ||
                                      removedKra5 ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].KRN_certi
                                            ? groupOneName[i].KRN_certi.replace(
                                                '{type}',
                                                "Proprietor's"
                                              )
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={`${groupOneName[i].KRN_certi}`}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].thred_data
                                            ? groupOneName[
                                                i
                                              ].thred_data.replace(
                                                '{type}',
                                                "Proprietor's"
                                              )
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      // disabled={
                                      //   !!groupOneName[i].id ? false : true
                                      // }
                                      // disabled = {fieldsDisabled5}
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].KRN_certi = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'proprietor',
                                              '4',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedKra5(false);
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].KRN_certi == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].KRN_certi_new == null ||
                                removedKra5 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />{' '}
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(
                                        groupOneName[i].KRN_certi_new
                                      );
                                      const newdata = groupOneName;
                                      newdata[i].KRN_certi_new = null;
                                      newdata[
                                        i
                                      ].KRN_certi = `Proprietor's KRA PIN Certificate`;
                                      setGroupOneName(newdata);
                                      var directorDc = Directordoc;
                                      directorDc[i].KRN_certi = ``;
                                      setDirectorDoc(directorDc);
                                      setRemovedKra5(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="complete-profile__btn_box">
                                {groupOneName.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick}
                                  >
                                    + Add another proprietor
                                  </Button>
                                )}
                                {/* {inputList.length !== 1 && <Button className='complete-profile__removebtn' onClick={handleRemoveClick}>Remove</Button>} */}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : userDetail === true && typevalue == '5' ? (
                      <>
                        <div className="complete-profile__heading">
                          Please provide the following documents
                        </div>
                        <p className="complete-profile__businesstypo">
                          Business Documents
                        </p>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                            <label className="complete-profile__upload">
                              <div className="complete-profile__upload__label">
                                {inputlfast.certi_of_incorpo_new == null ||
                                removedFrontID12 ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.certi_of_incorpo
                                      ? inputlfast.certi_of_incorpo.replace(
                                          '{type}',
                                          'Certificate of registration'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={inputlfast.certi_of_incorpo}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.fast_data
                                      ? inputlfast.fast_data.replace(
                                          '{type}',
                                          'Certificate of registration'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.certi_of_incorpo =
                                    event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.certi_of_incorpo = result;
                                      setBusinessDoc(business);
                                      console.log(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'certi_of_incorpo',
                                        result,
                                        event.target.files[0].name
                                      );
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'certi_of_incorpo',
                                        result,
                                        event.target.files[0].name
                                      );
                                      setRemovedFrontID12(false);
                                      setFieldsDisabled12(false);
                                    }
                                  );
                                }}
                              />
                              {businessdoc.certi_of_incorpo == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.certi_of_incorpo_new == null ||
                          removedFrontID12 ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.certi_of_incorpo_new);
                                const newdata = inputlfast;
                                newdata.certi_of_incorpo_new = null;
                                newdata.certi_of_incorpo =
                                  'Certificate of registration';
                                setInputListfast(newdata);
                                var business = businessdoc;
                                business.certi_of_incorpo = undefined;
                                setBusinessDoc(business);
                                setFieldsDisabled12(true);
                                setRemovedFrontID12(true);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                            <label className="complete-profile__upload">
                              <div
                                // id={!!businessdoc.id ? '' : 'data_disebal'}
                                className="complete-profile__upload__label"
                              >
                                {inputlfast.certi_of_compliance_new == null ||
                                removedComp12 ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.certi_of_compliance
                                      ? inputlfast.certi_of_compliance.replace(
                                          '{type}',
                                          'Trust deed or Exemption'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={inputlfast.certi_of_compliance}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.secend_data
                                      ? inputlfast.secend_data.replace(
                                          '{type}',
                                          'Trust deed or Exemption'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                //  disabled={!!businessdoc.id ? false : true}
                                // disabled = {fieldsDisabled12}
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.certi_of_compliance =
                                    event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.certi_of_compliance = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'certi_of_compliance',
                                        result,
                                        event.target.files[0].name
                                      );
                                      setRemovedComp12(false);
                                    }
                                  );
                                }}
                              />
                              {businessdoc.certi_of_compliance == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.certi_of_compliance_new == null ||
                          removedComp12 ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.certi_of_compliance_new);
                                const newdata = inputlfast;
                                newdata.certi_of_compliance_new = null;
                                newdata.certi_of_compliance =
                                  'Trust deed or Exemption';
                                setInputListfast(newdata);
                                var business = businessdoc;
                                business.certi_of_compliance = undefined;
                                setBusinessDoc(business);
                                setRemovedComp12(true);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>

                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                            <label className="complete-profile__upload">
                              <div
                                // id={!!businessdoc.id ? '' : 'data_disebal'}
                                className="complete-profile__upload__label"
                              >
                                {inputlfast.CR_new == null ||
                                removedBackID12 ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.CR
                                      ? inputlfast.CR.replace(
                                          '{type}',
                                          "Trust's KRA PIN Certificate or Tax Exemption Certificate"
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={inputlfast.CR}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.thred_data
                                      ? inputlfast.thred_data.replace(
                                          '{type}',
                                          "Trust's KRA PIN Certificate or Tax Exemption Certificate"
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                // disabled={!!businessdoc.id ? false : true}
                                //  disabled = {fieldsDisabled12}
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  // newdata.fast_trustee_three =
                                  //   event.target.files[0].name;
                                  newdata.CR_new = event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.CR = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'CR',
                                        result,
                                        event.target.files[0].name
                                      );
                                      setRemovedBackID12(false);
                                    }
                                  );
                                }}
                              />
                              {businessdoc.CR == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.CR_new == null || removedBackID12 ? (
                            <div className="aDADADaDsfgf">
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.CR_new);
                                const newdata = inputlfast;
                                newdata.CR =
                                  "Trust's KRA PIN Certificate or Tax Exemption Certificate";
                                newdata.CR_new = null;
                                setInputListfast(newdata);
                                var business = businessdoc;
                                business.CR = undefined;
                                setBusinessDoc(business);
                                setRemovedBackID12(true);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <p className="complete-profile__businesstypo">
                          Trustee's Details
                        </p>
                        {groupOneName.map((x, i) => {
                          return (
                            <div className="box">
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Trustee {i + 1}
                                </p>
                                {i >= 1 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick(
                                        i,
                                        !!directors[i].id
                                          ? directors[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {groupOneName[i].frontID_new == null ||
                                      removedFrontID11 ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].frontID
                                            ? groupOneName[i].frontID
                                                .replace('{type}', "Trustee's")
                                                .replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={groupOneName[i].frontID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].fast_data
                                            ? groupOneName[i].fast_data
                                                .replace('{type}', "Trustee's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdataGroup = groupOneName;
                                        newdataGroup[i].frontID =
                                          event.target.files[0].name;
                                        setGroupOneName(newdataGroup);
                                        setFileTax1(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].frontID = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'trustee',
                                              '5',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedFrontID11(false);
                                            setFieldsDisabled11(false);
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].frontID == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].frontID_new == null ||
                                removedFrontID11 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(groupOneName[i].frontID_new);
                                      const newdataGroup = groupOneName;
                                      newdataGroup[i].frontID_new = null;
                                      newdataGroup[i].frontID =
                                        "Trustee's {i} National ID Front Picture";
                                      setGroupOneName(newdataGroup);
                                      var directorDc = Directordoc;
                                      directorDc[i].frontID = ``;
                                      setDirectorDoc(directorDc);
                                      setRemovedFrontID11(true);
                                      setFieldsDisabled11(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!groupOneName[i].id
                                      //     ? ''
                                      //     : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {groupOneName[i].backID_new == null ||
                                      removedBackID11 ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].backID
                                            ? groupOneName[i].backID
                                                .replace('{type}', "Trustee's")
                                                .replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={groupOneName[i].backID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].secend_data
                                            ? groupOneName[i].secend_data
                                                .replace('{type}', "Trustee's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      // disabled={
                                      //   !!groupOneName[i].id ? false : true
                                      // }
                                      // disabled = {fieldsDisabled11}
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);

                                        setFileTax2(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].backID = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'trustee',
                                              '5',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedBackID11(false);
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].backID == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].backID_new == null ||
                                removedBackID11 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(groupOneName[i].backID_new);
                                      const newdata = groupOneName;
                                      newdata[i].backID_new = null;
                                      newdata[i].backID =
                                        "Trustee's {i} National ID Bacl Picture";
                                      setGroupOneName(newdata);
                                      var directorDc = Directordoc;
                                      directorDc[i].backID = ``;
                                      setDirectorDoc(directorDc);
                                      setRemovedBackID11(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!groupOneName[i].id
                                      //     ? ''
                                      //     : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {groupOneName[i].KRN_certi_new == null ||
                                      removedKra11 ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].KRN_certi
                                            ? groupOneName[i].KRN_certi.replace(
                                                '{type}',
                                                "Trustee's"
                                              ).replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={groupOneName[i].KRN_certi}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].thred_data
                                            ? groupOneName[i].thred_data
                                                .replace('{type}', "Trustee's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      // disabled={
                                      //   !!groupOneName[i].id ? false : true
                                      // }
                                      // disabled = {fieldsDisabled11}
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);

                                        setFileTax2(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].KRN_certi = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'trustee',
                                              '5',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedKra11(false);
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].KRN_certi == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].KRN_certi_new == null ||
                                removedKra11 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(
                                        groupOneName[i].KRN_certi_new
                                      );
                                      const newdata = groupOneName;
                                      newdata[i].KRN_certi_new = null;
                                      newdata[i].KRN_certi =
                                        "Trustee's {i} KRA PIN Certificate";
                                      setGroupOneName(newdata);
                                      var directorDc = Directordoc;
                                      directorDc[i].KRN_certi = ``;
                                      setDirectorDoc(directorDc);
                                      setRemovedKra11(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="complete-profile__btn_box">
                                {groupOneName.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick}
                                  >
                                    + Add another trustee
                                  </Button>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : userDetail === true && typevalue == '4' ? (
                      <>
                        <div className="complete-profile__heading">
                          Please provide the following documents
                        </div>
                        <p className="complete-profile__businesstypo">
                          Business Documents
                        </p>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                            <label className="complete-profile__upload">
                              <div className="complete-profile__upload__label">
                                {inputlfast.certi_of_incorpo_new == null ||
                                removedFrontID9 ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.certi_of_incorpo
                                      ? inputlfast.certi_of_incorpo.replace(
                                          '{type}',
                                          'Certificate of Registration or Exemption'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={inputlfast.certi_of_incorpo}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.fast_data
                                      ? inputlfast.fast_data.replace(
                                          '{type}',
                                          'Certificate of Registration or Exemption'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.certi_of_incorpo =
                                    event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.certi_of_incorpo = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'certi_of_incorpo',
                                        result,
                                        event.target.files[0].name
                                      );
                                      setRemovedFrontID9(false);
                                      setFieldsDisabled9(false);
                                    }
                                  );
                                }}
                              />
                              {businessdoc.certi_of_incorpo == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.certi_of_incorpo_new == null ||
                          removedFrontID9 ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.certi_of_incorpo_new);
                                const newdata = inputlfast;
                                newdata.certi_of_incorpo_new = null;
                                newdata.certi_of_incorpo =
                                  'Certificate of Registration or Exemption';
                                setInputListfast(newdata);
                                var business = businessdoc;
                                business.certi_of_incorpo = undefined;
                                setBusinessDoc(business);
                                setRemovedFrontID9(true);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                            <label className="complete-profile__upload">
                              <div
                                // id={!!businessdoc.id ? '' : 'data_disebal'}
                                className="complete-profile__upload__label"
                              >
                                {inputlfast.certi_of_compliance_new == null ||
                                removedComp9 ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.certi_of_compliance
                                      ? inputlfast.certi_of_compliance.replace(
                                          '{type}',
                                          'Introduction letter from the NGOs Co-ordination Board'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={inputlfast.certi_of_compliance}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.secend_data
                                      ? inputlfast.secend_data.replace(
                                          '{type}',
                                          'Introduction letter from the NGOs Co-ordination Board'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                //  disabled={!!businessdoc.id ? false : true}
                                // disabled = {fieldsDisabled9}
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.certi_of_compliance =
                                    event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.certi_of_compliance = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'certi_of_compliance',
                                        result,
                                        event.target.files[0].name
                                      );
                                      setRemovedComp9(false);
                                    }
                                  );
                                }}
                              />
                              {businessdoc.certi_of_compliance == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.certi_of_compliance_new == null ||
                          removedComp9 ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.certi_of_compliance_new);
                                const newdata = inputlfast;
                                newdata.certi_of_compliance_null = null;
                                newdata.certi_of_compliance =
                                  'Introduction letter from the NGOs Co-ordination Board';
                                setInputListfast(newdata);
                                var business = businessdoc;
                                business.certi_of_compliance = undefined;
                                setBusinessDoc(business);
                                setRemovedComp9(true);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                            <label className="complete-profile__upload">
                              <div
                                // id={!!businessdoc.id ? '' : 'data_disebal'}
                                className="complete-profile__upload__label"
                              >
                                {inputlfast.CR_new == null || removedBackID9 ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.CR
                                      ? inputlfast.CR.replace(
                                          '{type}',
                                          'Constitution of the organization'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={inputlfast.CR}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.thred_data
                                      ? inputlfast.thred_data.replace(
                                          '{type}',
                                          'Constitution of the organization'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                // disabled = {fieldsDisabled9}
                                //disabled={!!businessdoc.id ? false : true}
                                type="file"
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.CR = event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.CR = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'CR',
                                        result,
                                        event.target.files[0].name
                                      );
                                      setRemovedBackID9(false);
                                    }
                                  );
                                }}
                              />
                              {businessdoc.CR == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.CR_new == null || removedBackID9 ? (
                            <div className="aDADADaDsfgf">
                              {' '}
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.CR_new);
                                const newdata = inputlfast;
                                newdata.CR_new = null;
                                newdata.CR = 'Constitution of the organization';
                                setInputListfast(newdata);
                                var business = businessdoc;
                                business.CR = undefined;
                                setBusinessDoc(business);
                                setRemovedBackID9(true);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <div className="flax_data_list">
                          <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                            <label className="complete-profile__upload">
                              <div
                                // id={!!businessdoc.id ? '' : 'data_disebal'}
                                className="complete-profile__upload__label"
                              >
                                {inputlfast.KRA_new == null || removedKra9 ? (
                                  <span className="complete-profile__upload__txt">
                                    {inputlfast.KRA
                                      ? inputlfast.KRA.replace(
                                          '{type}',
                                          'KRA Pin Certificate or Exemption for the NGO'
                                        )
                                      : ''}
                                  </span>
                                ) : (
                                  <a
                                    href={inputlfast.KRA}
                                    className="complete-profile__upload__txt"
                                    download
                                  >
                                    {inputlfast.frore_data
                                      ? inputlfast.frore_data.replace(
                                          '{type}',
                                          'KRA Pin Certificate or Exemption for the NGO'
                                        )
                                      : ''}
                                  </a>
                                )}
                              </div>
                              <input
                                type="file"
                                //  disabled={!!businessdoc.id ? false : true}
                                // disabled = {fieldsDisabled9}
                                onChange={(event: any) => {
                                  var idOfIndex = !!businessdoc.id
                                    ? businessdoc.id
                                    : null;
                                  const newdata = inputlfast;
                                  newdata.KRA = event.target.files[0].name;
                                  setInputListfast(newdata);
                                  getBase64(
                                    event.target.files[0],
                                    (result: any) => {
                                      var business = businessdoc;
                                      business.KRA = result;
                                      setBusinessDoc(business);
                                      handleChangeFile(
                                        idOfIndex,
                                        'business_documents',
                                        typevalue,
                                        'KRA',
                                        result,
                                        event.target.files[0].name
                                      );
                                      setRemovedKra9(false);
                                    }
                                  );
                                }}
                              />
                              {businessdoc.KRA == undefined &&
                              submitted == true ? (
                                <p className="addnewpere">
                                  This field is required
                                </p>
                              ) : (
                                ''
                              )}
                            </label>
                          </Tooltip>
                          {inputlfast.KRA_new == null || removedKra9 ? (
                            <div className="aDADADaDsfgf">
                              <UploadIcon />
                            </div>
                          ) : (
                            <div
                              className="aDADADaDsfgf"
                              onClick={() => {
                                remove_data(inputlfast.KRA_new);
                                const newdata = inputlfast;
                                newdata.KRA_new = null;
                                newdata.KRA =
                                  'KRA Pin Certificate or Exemption for the NGO';
                                setInputListfast(newdata);
                                var business = businessdoc;
                                business.KRA = undefined;
                                setBusinessDoc(business);
                                setRemovedKra9(true);
                              }}
                            >
                              <CloseIcon />
                            </div>
                          )}
                        </div>
                        <p className="complete-profile__businesstypo">
                          Official's Details
                        </p>
                        {groupOneName.map((x, i) => {
                          return (
                            <div className="box">
                              <div className="complete-profile__btn_box">
                                <p className="complete-profile__director">
                                  Official {i + 1}
                                </p>
                                {i >= 1 ? (
                                  <Button
                                    className="complete-profile__removebtn"
                                    onClick={() => {
                                      handleRemoveClick(
                                        i,
                                        !!directors[i].id
                                          ? directors[i].id
                                          : null
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div className="complete-profile__upload__label">
                                      {groupOneName[i].frontID_new == null ||
                                      removedFrontID10 ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].frontID
                                            ? groupOneName[i].frontID
                                                .replace('{type}', "Official's")
                                                .replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={groupOneName[i].frontID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].fast_data
                                            ? groupOneName[i].fast_data
                                                .replace('{type}', "Official's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].frontID =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);
                                        setFileTax1(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].frontID = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'official',
                                              '6',
                                              'frontID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedFrontID10(false);
                                            setFieldsDisabled10(false);
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].frontID == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].frontID_new == null ||
                                removedFrontID10 ? (
                                  <div className="aDADADaDsfgf">
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(groupOneName[i].frontID_new);
                                      const newdata = groupOneName;
                                      newdata[i].frontID_new = null;
                                      newdata[i].frontID =
                                        "Official's {i} National ID Front Picture";
                                      setGroupOneName(newdata);
                                      var directorDc = Directordoc;
                                      directorDc[i].frontID = ``;
                                      setDirectorDoc(directorDc);
                                      setRemovedFrontID10(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!groupOneName[i].id
                                      //     ? ''
                                      //     : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {groupOneName[i].backID_new == null ||
                                      removedBackID10 ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].backID
                                            ? groupOneName[i].backID
                                                .replace('{type}', "Official's")
                                                .replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={groupOneName[i].backID}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].secend_data
                                            ? groupOneName[i].secend_data
                                                .replace('{type}', "Official's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      // disabled={
                                      //   !!groupOneName[i].id ? false : true
                                      // }
                                      // disabled = {fieldsDisabled10}
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].backID =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);
                                        setFileTax2(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].backID = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'official',
                                              '6',
                                              'backID',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedBackID10(false);
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].backID == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].backID_new == null ||
                                removedBackID10 ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(groupOneName[i].backID_new);
                                      const newdata = groupOneName;
                                      newdata[i].backID_new = null;
                                      newdata[i].backID =
                                        "Official's {i} National ID Back Picture";
                                      setGroupOneName(newdata);
                                      var directorDc = Directordoc;
                                      directorDc[i].backID = ``;
                                      setDirectorDoc(directorDc);
                                      setRemovedBackID10(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="flax_data_list">
                                <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                                  <label className="complete-profile__upload">
                                    <div
                                      // id={
                                      //   !!groupOneName[i].id
                                      //     ? ''
                                      //     : 'data_disebal'
                                      // }
                                      className="complete-profile__upload__label"
                                    >
                                      {groupOneName[i].KRN_certi_new == null ||
                                      removedKra10 ? (
                                        <span className="complete-profile__upload__txt">
                                          {groupOneName[i].KRN_certi
                                            ? groupOneName[i].KRN_certi.replace(
                                                '{type}',
                                                "Official's"
                                              ).replace('{i}', '')
                                            : ''}
                                        </span>
                                      ) : (
                                        <a
                                          href={groupOneName[i].KRN_certi}
                                          className="complete-profile__upload__txt"
                                          download
                                        >
                                          {groupOneName[i].thred_data
                                            ? groupOneName[i].thred_data
                                                .replace('{type}', "Official's")
                                                .replace('{i}', '')
                                            : ''}
                                        </a>
                                      )}
                                    </div>
                                    <input
                                      // disabled={
                                      //   !!groupOneName[i].id ? false : true
                                      // }
                                      // disabled = {fieldsDisabled10}
                                      type="file"
                                      onChange={(event: any) => {
                                        var idOfIndex = !!groupOneName[i].id
                                          ? groupOneName[i].id
                                          : null;
                                        const newdata = groupOneName;
                                        newdata[i].KRN_certi =
                                          event.target.files[0].name;
                                        setGroupOneName(newdata);

                                        setFileTax2(event.target.files[0].name);
                                        getBase64(
                                          event.target.files[0],
                                          (result: any) => {
                                            var directorDc = Directordoc;
                                            directorDc[i].KRN_certi = result;
                                            setDirectorDoc(directorDc);
                                            handleChangeFile(
                                              idOfIndex,
                                              'official',
                                              '6',
                                              'KRN_certi',
                                              result,
                                              event.target.files[0].name
                                            );
                                            setRemovedKra10(false);
                                          }
                                        );
                                      }}
                                    />
                                    {Directordoc[i].KRN_certi == `` &&
                                    submitted == true ? (
                                      <p className="addnewpere">
                                        This field is required
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                </Tooltip>
                                {groupOneName[i].KRN_certi_new == null ||
                                removedKra10 ? (
                                  <div className="aDADADaDsfgf">
                                    {' '}
                                    <UploadIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="aDADADaDsfgf"
                                    onClick={() => {
                                      remove_data(
                                        groupOneName[i].KRN_certi_new
                                      );
                                      const newdata = groupOneName;
                                      newdata[i].KRN_certi_new = null;
                                      newdata[i].KRN_certi =
                                        "Official's {i} KRA PIN Certificate";
                                      setGroupOneName(newdata);
                                      var directorDc = Directordoc;
                                      directorDc[i].KRN_certi = undefined;
                                      setDirectorDoc(directorDc);
                                      setRemovedKra10(true);
                                    }}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                              <div className="complete-profile__btn_box">
                                {groupOneName.length - 1 === i && (
                                  <Button
                                    className="complete-profile__ADDBTNHHA"
                                    onClick={handleAddClick}
                                  >
                                    + Add another official
                                  </Button>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <div className="complete-profile__heading">
                          Please complete your account setup{' '}
                        </div>
                        <Form.Select
                          style={{ fontSize: '13px' }}
                          className="complete-profile__SELECT"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setType(e.target.value);
                            setGroupOneName([commonStatic]);
                            setGroupTwoName([commonStatiTwo]);
                            setDirectorDoc([directorDocStatic]);
                            setShareHoldersDocs([directorDocStaticTwo]);
                          }}
                        >
                          <option>Type of Organisation</option>
                          {Array.isArray(entityType)
                            ? entityType?.map((element: any) => {
                                return (
                                  <option key={element.id} value={element.id}>
                                    {element.name}
                                  </option>
                                );
                              })
                            : 'data not found'}
                        </Form.Select>
                        {userDetail == true && typevalue == '' ? (
                          <p className="listtexterrr">This field is required</p>
                        ) : (
                          ''
                        )}
                        <Button
                          className="complete-profile__btn2"
                          onClick={() => setUserDetail(true)}
                        >
                          Next
                        </Button>
                      </>
                    )}

                    {userDetail === true && typevalue != '' && (
                      <Box className="complete-profile__boxbtndisplay">
                        {data_go_back == true ? (
                          <Button
                            className="complete-profile__saventmm"
                            onClick={() => {
                              setType('0');
                              setUserDetail(false);
                            }}
                          >
                            Go Back
                          </Button>
                        ) : (
                          ''
                        )}
                        <Button
                          id="button_back"
                          className="payDetails-modal__btn-row__white"
                          onClick={() => {
                            window.location.href = '/settings/verification';
                          }}
                        >
                          Go Back
                        </Button>
                        <Button
                          type="submit"
                          className="complete-profile__submitbtnlist"
                          onClick={() => {
                            setSubmitted(true);
                          }}
                        >
                          Submit
                        </Button>
                      </Box>
                    )}
                  </ThemeProvider>
                </Form>
              </>
            )}
          </Formik>
        </div>
      ) : status == 'approved' && users?.data?.entity?.status == 'VERIFIED' ? (
        <ThemeProvider theme={theme}>
          <VerifiedPopup page="lastpage" onSave={true} />
        </ThemeProvider>
      ) : status == 'rejected' ? (
        <ThemeProvider theme={theme}>
          <Model_Reject onSave={true} />
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={theme}>
          <NewVerfied page="lastpage" onSave={true} />
        </ThemeProvider>
        // <ModelumentVerified page="lastpage" onSave={true} />
      )}
      {loader && (
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 150,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 10,
            }}
          >
            <CircularProgress color="success" />
          </Box>
        </ThemeProvider>
      )}
    </>
  );
};

export const ShareholderCom = (props: Props) => {};
const UploadIcon = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 14V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V14M4 6L9 1L14 6M9 1V13"
      stroke="#025041"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
