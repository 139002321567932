import React, { useEffect, useState } from 'react';
import styles from './selectCurrencyWorkspace.module.scss';
import { paymentJourneyActions } from '../../../../../../_actions/paymentJourney.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectPaymentJourneyData } from '../../../../../../_reducers/paymentJourney.reducer';
import { getUser } from "../../../../../../_helpers";
import CurrencySelectModal from "./currencySelectionModal/currencySelectionModal";
import Loader from '../../../../Layout/Settings/components/Profile/components/AccountSetup/Loader';
import { toast } from 'react-toastify';
import { dashboardActions } from '../../../../../../_actions';



const SelectCurrencyWorkSpace = () => {
  const dispatch = useDispatch();

  const [render, setRender] = useState(true);
  const [dataRender, setDataRender] = useState(false);
  const [dataAPi, setDataAPI] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dataList = useSelector((state) => selectPaymentJourneyData(state));
  const currentUserID = getUser()?.data?.user_id;
  const currentUserEntityID = getUser()?.data?.entity?.id;
  const [showLoader, setShowLoader] = useState(true);
  const handleClose = () => setShowModal(false);

  // api-call
  useEffect(() => {
    if (render) {
      dispatch(paymentJourneyActions.getSelectedUserCurrency(currentUserID) as any);
      setRender(false);
      setDataRender(true);
    }
  }, [render]);


  useEffect(() => {
    if (
      dataRender &&
      dataList?.GET_SELECTED_USER_CURRENCIES_DETAILS_SUCCESS !== undefined
    ) {
      const newArray = dataList?.GET_SELECTED_USER_CURRENCIES_DETAILS_SUCCESS.map((element : any) => {
        return {
          currency: element.currency,
          flag: element.flag,
          id: element.id,
          name: element.name,
          value: element.currency === 'KES' ? true : element.isSelected || false,
          balance: element.hasBalance
        };
      });
      setDataAPI(newArray);
    }
  }, [dataRender, dataList?.GET_SELECTED_USER_CURRENCIES_DETAILS_SUCCESS]);
  

  // handle-submit
  const handleSubmit = () => {
    setLoading(true)
    const newArray = dataAPi.filter((data: any) => data.value).map((item : any) => item.id);
    const body = {
      countries: newArray,
      user_id: currentUserID,
      entity_id: currentUserEntityID,
    };

    dispatch(paymentJourneyActions.addSelectedUserCurrency(body) as any);

    setTimeout(() => {
      setLoading(false)
      toast.success('Currencies have been updated successfully!');
      dispatch(dashboardActions.getDashboardCurrencyData() as any)
    }, 3000);

  };

  // for loader
   useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  
   return (
    <>
      {showLoader || !dataAPi || dataAPi.length === 0 ? (
        <Loader />
      ) : (
        <>
          <div className={styles.box_header}>
            <p className={styles.box_heading}>Select your preferred currencies</p>
          </div>
          <div className={styles.box_wraper}>
            <div className={styles.box_body}>
              <div className={styles.box_section}>
                <div className={styles.country_name}>Africa</div>
                {dataAPi.map((item: any, key: any) => (
                  <>
                    {item.currency !== 'USD' && (
                      <div className={styles.currency_list}>
                        <div className={styles.leftAligned}>
                          <div className={styles.country_name_img}>
                            <img src={item?.flag} alt="" />
                            <span>{item?.name}</span>
                          </div>
                        </div>
                        <div className={styles.centerAligned}>
                          <div className={styles.currency_name}>
                            {item?.currency}
                          </div>
                        </div>
                        <div className={styles.rightAligned}>
                          <div className={styles.currency_check}>
                            <input
                              type="checkbox"
                              checked={item.value}
                              className={styles.inputCurrency}
                              id={`currency-${item.id}`}
                              name={`currency-${item.id}`}
                              onChange={(e: any) => {
                                const isUnchecking = !e.target.checked;
                                if (item.balance === true && isUnchecking) {
                                  setShowModal(true);
                                  return;
                                }
                                const updatedArray = dataAPi.map((element: any) =>
                                  element.id === item.id
                                    ? { ...element, value: !element.value }
                                    : element
                                );
                                setDataAPI(updatedArray);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}

                <div className={`${styles.country_name} mt-3`}>Global</div>
                {dataAPi.map((item: any, key: any) => (
                  <>
                    {item.currency === 'USD' && (
                      <div className={styles.currency_list}>
                        <div className={styles.leftAligned}>
                          <div className={styles.country_name_img}>
                            <img src={item?.flag} alt="" />
                            <span>{item?.name}</span>
                          </div>
                        </div>
                        <div className={styles.centerAligned}>
                          <div className={styles.currency_name}>
                            {item?.currency}
                          </div>
                        </div>
                        <div className={styles.rightAligned}>
                          <div className={styles.currency_check}>
                            <input
                              type="checkbox"
                              checked={item.value}
                              className={styles.inputCurrency}
                              id={`currency-${item.id}`}
                              name={`currency-${item.id}`}
                              onChange={(e: any) => {
                                const isUnchecking = !e.target.checked;
                                if (item.balance === true && isUnchecking) {
                                  setShowModal(true);
                                  return;
                                }
                                const updatedArray = dataAPi.map((element: any) =>
                                  element.id === item.id
                                    ? { ...element, value: !element.value }
                                    : element
                                );
                                setDataAPI(updatedArray);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
              <div className={styles.buttonSection}>
                <button
                  type="submit"
                  className={styles.submitButton}
                  onClick={handleSubmit}
                >
                  {loading === true && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  <span>Update</span>
                </button>
              </div>
            </div>
          </div>

          {/* currency check modal */}
          {showModal && (
            <CurrencySelectModal handleClose={handleClose} showModal={showModal} />
          )}
        </>
      )}
    </>
  );

};

export default SelectCurrencyWorkSpace;
