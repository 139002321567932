import CeretePaymentLinkFrom from './components/CeretePaymentLink/CeretePaymentLinkFrom';
import styles from './Payment_Link.module.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Box, Typography } from '@mui/material';

const CeretePaymentLink = () => {
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    return (
        <>
            <ThemeProvider theme={theme}>
                <Box>
                    <Box className={styles.heder_box}>
                        <Typography>Payment Links</Typography>
                    </Box>
                    <CeretePaymentLinkFrom />
                </Box>
            </ThemeProvider>
        </>
    )
}
export default CeretePaymentLink;