import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import "../../../Settings.scss";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


interface Props {
    onSave: any;
    page: string;
}

const VerifiedPopup = (props: Props) => {

    const onSave = () => {
        props.onSave();
    }

    return (
        <div className="contenarPage">
            <div className="haderContean">
                    <div className="CloseDiv">
                        <IconButton onClick={() => {
                            if (props?.page == "fastpage") {
                                onSave()
                            } else {
                                window.location.reload()
                            }
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className="heding_Contean">
                        <p>Account Setup Complete</p>

                    </div>
                </div>
            <div className="colamContean">
                <span>Congratulations!</span>
                <br />
                <p>Your account setup is complete and has been successfully verified.</p>
                <p>You now have access to all features on our platform.</p>

                <br />
                <p>In case of any queries, reach out to us at <span>support@niobi.co</span></p>
            </div>
        </div>
        // <div className='document-verified'>
        //     <div className='document-verified__heading'>
        //         Account Setup Complete</div>

        //     <div className='document-verified__desc'>
        //         <span>Congratulations!</span>
        //         <br />
        //         Your account setup is complete and has been successfully verified.
        //          You now have access to all features on our platform.

        //         <br />
        //         <br />
        //         In case of any queries, reach out to us at <span>support@niobi.co</span>
        //     </div>
        //     <Button className='document-verified__btn' onClick={() =>{
        //         if(props?.page=="fastpage"){
        //             onSave()
        //         }else{
        //         window.location.reload()
        //         }
        //     }}>Go Back </Button>
        // </div>
    )
}
export default VerifiedPopup;