import Cerete_new_page from './cerete_new';
import Accounting_System_page from './Accounting_System_page';
import QuickBooks from './components/QuickBooks/QuickBooks';
import Integrations_page from './integrations_page';
import { getUser, isUserAuthenticated } from "../../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { selectPaymentJourneyData } from "../../../../_reducers/paymentJourney.reducer";
import { paymentJourneyActions } from "../../../../_actions/paymentJourney.actions";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import DataSync_page from './DataSync_page';

const Sales = () => {
    const [create_new, setCreate_new] = useState('1')
    const [data_page, setData_Pape] = useState<any>([])
    const [render, setRender] = React.useState(true);
    const [render_two, setRender_two] = React.useState(true);
    const dispatch = useDispatch();
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    let navigate = useNavigate();
    console.log(create_new, 'create_newcreate_new');

    React.useEffect(() => {
        if (isUserAuthenticated() && render) {
            dispatch(paymentJourneyActions.get_connections() as any)
            setRender(false)
        }
    }, [render, isUserAuthenticated]);

    React.useEffect(() => {
        if (render_two && dataList.GET_CONNECTION_SUCCESS !== undefined) {
            // setCreate_new('5')
            // if(dataList.GET_CONNECTION_SUCCESS)
            console.log(dataList.GET_CONNECTION_SUCCESS.length, 'dataList.GET_CONNECTION_SUCCESS');
            if (dataList.GET_CONNECTION_SUCCESS.length > 0) {
                setCreate_new('5')
            }
            setRender_two(false)

        }
    }, [render_two, dataList]);
    return (
        <>
            {create_new == '1' ?
         
                <Cerete_new_page
                    onclick={() => {
                        setCreate_new('2')
                    }}
                /> 
                : create_new == '2' ?
                    <Accounting_System_page
                        onclick={(data: any) => {
                            setCreate_new('3')
                            setData_Pape(data)
                        }}
                    /> : create_new == '3' ?
                        <QuickBooks
                            onclick={(data:any) => {
                                setCreate_new('4')
                                setData_Pape(data)
                            }}
                            onCansal={() => {
                                setCreate_new('2')
                            }
                            }
                            data={data_page}
                        /> : create_new == '4' ?
                            <DataSync_page
                                onclick={() => {

                                    setCreate_new('5')
                                }}
                                data={data_page}
                            /> : create_new == '5' ?
                                <Integrations_page

                                    onclick={(data: any) => {
                                        setCreate_new('4')
                                        setData_Pape(data)
                                    }}
                                    onCansal={() => {
                                        setCreate_new('2')
                                    }
                                    }
                                /> : ""}
            {/* {create_new == '1' ?
                <Cerete_new_page
                    onclick={() => {
                        setCreate_new('2')
                    }}
                />
                : create_new == '2' ?
                    <Accounting_System_page
                        onclick={(data: any) => {
                            setCreate_new('3')
                            setData_Pape(data)
                        }}
                    />
                    : create_new == '3' ?
                        <QuickBooks
                            onclick={() => {
                                setCreate_new('4')
                            }}
                            onCansal={() => {
                                setCreate_new('2')
                            }
                            }
                            data={data_page}
                        /> : create_new == '4' ?
                            <DataSync_page
                                onclick={() => {
                                    setCreate_new('5')
                                }}
                                data={data_page}
                            />
                            : create_new == '5' ? 
                            <Integrations_page

                                onclick={(data: any) => {
                                    setCreate_new('4')
                                    setData_Pape(data)
                                }}
                                onCansal={() => {
                                    setCreate_new('2')
                                }
                                }
                            /> : ""} */}
        </>
    )
}
export default Sales;