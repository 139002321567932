import { Button } from 'react-bootstrap';
import style from './cerete_new.module.scss'


interface Props {
    onclick: any;

}
const Cerete_new = (props: Props) => {
    console.log(props.onclick,"hereeee ccc");

    const onSave = () => {
        props.onclick()
    }
    return (
        <>
            <div className={style.main_dev_contean}>
                <p>
                    If you manage your books in accounting software, you can set
                    it up with the button below.
                </p>
                <Button onClick={() => { onSave() }}>
                    Craete Accounting  Software
                </Button>
            </div>
        </>
    )
}
export default Cerete_new;