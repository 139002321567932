import styles from './Pesalink_filed.module.scss';
import { Button, InputGroup } from 'react-bootstrap';

interface Props{
    onSave:any;
    onCancel:any;
}
const Pesalink_filed = (props:Props) => {

    return (
        <>
            <div className={styles.contenar_main_text_line}>
                <img src="/image 4.png" />

                <p className={styles.des_Pesalink}>
                    Pesalink seems to be experiencing delays. Would you like to make this payment through the bank's paybill instead? If yes, please click the button below.
                </p>
                <div className={styles.button_bottom} >
                    <Button className={styles.Cancel_Button} variant='custom'
                    onClick={() => { props.onCancel(); }}
                    >   Cancel  </Button>
                    <Button type='submit'
                    onClick={props.onSave}
                        className={styles.Continue_button} >

                        Continue
                        {/* {loading == true && <span className="spinner-border spinner-border-sm mr-1"></span>} */}
                    </Button>
                </div>
            </div>
        </>
    )
}
export default Pesalink_filed;