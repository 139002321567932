import React, { useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';
import Modal from 'react-bootstrap/Modal';
import "./Topup.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { getUser } from "../../../../../../_helpers";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { alpha, createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
const MobileIcon = () => (
    <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 19H9V18H5V19ZM2 22C1.45 22 0.979333 21.8043 0.588 21.413C0.196 21.021 0 20.55 0 20V2C0 1.45 0.196 0.979 0.588 0.587C0.979333 0.195667 1.45 0 2 0H12C12.55 0 13.021 0.195667 13.413 0.587C13.8043 0.979 14 1.45 14 2V20C14 20.55 13.8043 21.021 13.413 21.413C13.021 21.8043 12.55 22 12 22H2ZM2 17V20H12V17H2ZM2 15H12V5H2V15ZM2 3H12V2H2V3ZM2 17V20V17ZM2 3V2V3Z" fill="#025041" />
    </svg>
);
const VisaCardIcon = () => (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM18 14H2V8H18V14ZM18 4H2V2H18V4Z" fill="#025041" />
    </svg>
);

interface Props {
    show: boolean;
    onClose: any;
    onSave: any;
    onconfiam: any;
}

export const TopupModal_two = (props: Props) => {
    const navigate = useNavigate();

    const [showModal, setShow] = useState(props.show);
    const [selectedMethod, setSelectedMethod] = useState('');
    const [show, setShowlist] = useState(false);
    const [render, setRender] = useState(true);
    const [topupDetails, setTopupDetails] = useState<any>();
    const [topupDetailsRender, setTopupDetailsRender] = useState(true);
    const [savePaymentDetailsRender, setSavePaymentDetailsRender] = useState(true);

    const [showBankSuccess, setshowBankSuccess] = useState(false);
    const [bankResponse, setBankResponse] = useState<any>();
    const [bankResponseShow, setBankResponseshow] = useState<any>(false);
    const user = getUser()?.data ?? null;

    // const [bankResponse, setBankResponse] = useState<any>({
    //     "payment_method_id": 1,
    //     "amount": "1",
    //     "payment_reference": "qqqq",
    //     "path": "payment-documents/qqqq.jpeg",
    //     "url": "https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/payment-documents/qqqq.jpeg",
    //     "updated_at": "2023-05-19T06:15:08.000000Z",
    //     "created_at": "2023-05-19T06:15:08.000000Z",
    //     "id": 1,
    //     "status": "success",
    //     "document": true
    // });
    // const data = useSelector((state) => selectAllBalanceLIstData(state));
    // const dispatch = useDispatch();

    // useEffect(() => {
    //     if (isUserAuthenticated() && render) {
    //         setRender(false);
    //         dispatch(balanceActions.getTopUpDetails() as any);
    //   }
    // }, [render, isUserAuthenticated]);

    // useEffect(() => {
    //   if (topupDetailsRender && data?.GET_TOPUP_DETAILS_SUCCESS !== undefined) {
    //     setTopupDetailsRender(false);
    //     setTopupDetails(data.GET_TOPUP_DETAILS_SUCCESS);
    //   }
    // }, [data, topupDetailsRender]);

    // useEffect(() => {
    //     if (savePaymentDetailsRender && data?.SAVE_PAYMENT_DETAILS_SUCCESS !== undefined) {
    //         setSavePaymentDetailsRender(false);
    //         setBankResponse(data.SAVE_PAYMENT_DETAILS_SUCCESS.data)
    //         setShowlist(false)
    //         setshowBankSuccess(true);
    //     }
    // }, [data, savePaymentDetailsRender]);

    const handleClose = () => {
        setShowlist(false)
        setshowBankSuccess(false)
        props.onClose(false);
    }

    const onSaveBank = () => {
        setBankResponseshow(true)
        // setShowlist(false)
        // props.onClose(false);
    }
    const handleShow = () => {
        props.onSave()
        setShow(false);
        setShowlist(true);
    }
    const hadleshow_two = () => {
        props.onconfiam()
        setShow(false);
    }
    console.log(bankResponseShow, 'bankResponseShow')
    const onClose = () => {
        setShow(false);
        setshowBankSuccess(false)
        props.onClose(false);
    };

    // console.log(selectedMethod, 'selectedMethod')
    // const onSave = () => {
    //     setShow(false);
    //     if (selectedMethod === 'mobile') {
    //         props.onSave();
    //     } else {
    //         props.onSave(false);
    //     }
    // };
    const theme = createTheme({
        status: {
          danger: orange[500],
        },
      });
    return (
        <>
            <>
            <ThemeProvider theme={theme}>
                <Modal
                    show={showModal}
                    onHide={onClose}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby='ModalHeader'
                    className='topup-modal'
                    centered
                    size="lg"
                >
                    {/* <Modal.Header closeButton>
                        <Modal.Title>Create Bills</Modal.Title>
                    </Modal.Header> */}
                    <Box className="CloseContenar">
                        <IconButton onClick={() => {
                            onClose();
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box className="heding_contean">
                        <Typography>
                        Create Bills
                        </Typography>
                    </Box>
                    <Modal.Body>
                        <div className='label'>Choose bill creation method</div>
                        <div className='type-card-list'>
                            <div id='main_fast_buttun_data' className={`type-card-twoo ${selectedMethod === 'Create Manually' ? 'active' : ''}`}
                                onClick={() =>{
                                    setSelectedMethod('Create Manually')
                                    hadleshow_two()
                                }}>
                                {/* <div>
                                    <MobileIcon />
                                    <span className='type-card__name'>via M-Pesa</span>
                                </div> */}
                                <span id='sdFsfsfsfsfff' className='type-card__name'>Create One Off Bill</span>
                                {/* <div className='type-card__detail'>
                                Express Payment
                                </div> */}
                            </div>
                            <div className={`type-card-twoo ${selectedMethod === 'Create Recurring Bills' ? 'active' : ''}`}
                                onClick={() =>{
                                    setSelectedMethod('Create Recurring Bills')
                                    handleShow()
                                }}>
                                <span id='sdFsfsfsfsfff' className='type-card__name'>Create Recurring Bill</span>

                                {/* <div>
                                    <VisaCardIcon />
                                    <span className='type-card__name'>via Bank Account</span>
                                </div>
                                <div className='type-card__detail'>
                                    Directly top up your Balance using money from your Bank
                                </div> */}
                            </div>
                        </div>
                    </Modal.Body>

                    {/* <Modal.Footer>
                        <Button className='white-btn' onClick={onClose}>Cancel</Button>

                        <Button style={{ border: "1px solid #025041" }} className='green-btn' disabled={selectedMethod === ''}
                            onClick={() => {
                                if (selectedMethod == "Create Manually") {
                                    hadleshow_two()
                                } else {
                                    
                                }
                            }}
                        >Next</Button>

                    </Modal.Footer> */}
                </Modal>
                </ThemeProvider>
            </>
            <>




            </>
        </>
    )
}