
import { Button, Grid, Typography } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './user_management.module.css'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {useSelector } from "react-redux";
import { selectPaymentJourneyData } from "../../../../_reducers/paymentJourney.reducer";
import React from 'react';
import { Link, useNavigate } from "react-router-dom";

const Redayrect_data = (props) => {
    const [addexpressSuccrender, setaddexpressSuccrender] = React.useState(true);
    const [trans, settrans] = React.useState();
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    React.useEffect(() => {
        if (addexpressSuccrender && dataList?.CREATE_CREDENTIALS_SUCCESS !== undefined) {
            setaddexpressSuccrender(false);
            settrans(dataList.CREATE_CREDENTIALS_SUCCESS);
        }
    }, [dataList, addexpressSuccrender]);
    return (
        <>
            <Grid container className={styles.contenar_layout_darara}>
                <Grid item md={12} className={styles.sjssjss_layout_key_datya}>
                    <Typography className={styles.text_heding_linee}>Create new API credentials </Typography>
                    <div>
                        <label className={styles.label_ahagagahhaagga}>
                            ID
                        </label>
                        <div className={styles.afaddagfaggaffa}>
                            <Typography className={styles.afaddaffaggaggafgaf}>
                                {!!trans?trans.client_id:""}
                            </Typography>
                            <CopyToClipboard text={!!trans?trans.client_id:""}>
                                <Button className={styles.affaggah_button_data}><ContentCopyIcon /></Button>
                            </CopyToClipboard>
                        </div>
                        <label className={styles.label_ahagagahhaagga} style={{marginTop:"30px"}}>
                            Secret
                        </label>
                        <div className={styles.afaddagfaggaffa}>
                            <Typography className={styles.afaddaffaggaggafgaf}>
                                {!!trans?trans.secret_key:""}
                            </Typography>
                            <CopyToClipboard text={!!trans?trans.secret_key:""}>
                                <Button className={styles.affaggah_button_data}><ContentCopyIcon /></Button>
                            </CopyToClipboard>
                        </div>
                        <div style={{marginTop:"30px",padding:'17px 14px'}} className={styles.afaddagfaggaffa_twoo}>
                        <Typography className={styles.afaddaffaggaggafgaf}>
                        Please store it somewhere safe as soon as you navigate away from this dialog, you will not be able to retrieve or restore this secret.
                            </Typography>
                        </div>
                    </div>
                    <CopyToClipboard text={!!trans?trans.secret_key:""}>
                    <Button className={styles.shhsjsh_close_data} 
                    onClick={() => { 
                        props.onSAVE()
                         }}>Copy Secret and Close</Button></CopyToClipboard>
                </Grid>
            </Grid>

        </>
    )
}
export default Redayrect_data;