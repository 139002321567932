import React, { useContext } from 'react'
import { useSearchParams } from 'react-router-dom';
import { PayableDetail } from './components/PayableGrid/PayableGrid';
import { getPayableGridData } from '../mock/payableMock';


export const Payables = () => {
  const PAYABLE_ID = 'id';
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get(PAYABLE_ID);

  return (
    <>
      <PayableDetail data={getPayableGridData()}></PayableDetail>
    </>
  )
}
