import { getUser } from './auth-header';

export function getProperties() {
  const user = getUser();

  if (!user) {
    return null;
  }

  const userData = user.data;

  if (!userData) {
    return null;
  }

  const { email, name, user_id } = userData;

  if (!email || !name || !user_id) {
    return null;
  }

  return { email, name, user_id };
}
