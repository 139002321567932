import styles from './Payment_Link.module.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Box, Typography } from '@mui/material';
import UserPage from './components/User_link/userpage/userpage';
const Detels_payment = () => {
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    return (
        <>
            <ThemeProvider theme={theme}>
                <Box>
                    <Box className={styles.heder_box}>
                        <Typography>Payment Links</Typography>
                    </Box>
                    <UserPage />
                </Box>
            </ThemeProvider>
        </>
    )
}
export default Detels_payment;