import { settingConstants } from "../_constants/setting.constants";

export function getDocuments(state = {}, action) {
    switch (action.type) {
        case settingConstants.GETDOCUMENT_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case settingConstants.GETDOCUMENT_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            };
        case settingConstants.GETDOCUMENT_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}

export function storeDocuments(state = {}, action) {
    switch (action.type) {
        case settingConstants.STOREDOCUMENT_REQUEST:
            return {
                loading: true
            };
        case settingConstants.STOREDOCUMENT_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case settingConstants.STOREDOCUMENT_FAILURE:
            return {
                error: action.error,
                loading: false
            };
        default:
            return state
    }
}


export function InviteDocuments(state = {}, action) {
    switch (action.type) {
        case settingConstants.INVITEDOCUMENT_REQUEST:
            return {
                loading: true
            };
        case settingConstants.INVITEDOCUMENT_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case settingConstants.INVITEDOCUMENT_FAILURE:
            return {
                error: action.error,
                loading: false
            };
        default:
            return state
    }
}
export function delete_data_Documents(state = {}, action) {
    switch (action.type) {
        case settingConstants.DELETE_DOCUMENT_REQUEST:
            return {
                loading: true
            };
        case settingConstants.DELETE_DOCUMENT_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case settingConstants.DELETE_DOCUMENT_FAILURE:
            return {
                error: action.error,
                loading: false
            };
        default:
            return state
    }
}
export function submitDocuments(state = {}, action) {
    switch (action.type) {
        case settingConstants.SUBMITDOCUMENT_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case settingConstants.SUBMITDOCUMENT_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            };
        case settingConstants.SUBMITDOCUMENT_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}
const initialState = {
    data: {
        data: {
            business_documents: {
                CR: 1,
                //   certi_of_incorpo: 1, 
                KRA: 1,
                certi_of_compliance: 1
                // or some initial value
                // ... other properties
            },
            // ... other properties
        },
        // ... other properties
    },
};
export function getEntityDocumentsReducer(state = {}, action) {
    switch (action.type) {
        case settingConstants.GETENTITYDOCUMENT_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case settingConstants.GETENTITYDOCUMENT_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            };
        case settingConstants.GETENTITYDOCUMENT_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}
export function getRolsDocumentsReducer(state = {}, action) {
    switch (action.type) {
        case settingConstants.ROLSDATA_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case settingConstants.ROLSDATA_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            };
        case settingConstants.ROLSDATA_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}
export function getPermissionsDocumentsReducer(state = {}, action) {
    switch (action.type) {
        case settingConstants.PERMISSIONS_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case settingConstants.PERMISSIONS_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            };
        case settingConstants.PERMISSIONS_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}
export function getUserDocuments(state = {}, action) {
    switch (action.type) {
        case settingConstants.USERDOCUMENT_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case settingConstants.USERDOCUMENT_SUCCESS:
            return {
                data: action.data,
                loading: false,
                success: true,
            };
        case settingConstants.USERDOCUMENT_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}
export function getUserteamDocuments(state = {}, action) {
    switch (action.type) {
        case settingConstants.USERTEAMDOCUMENT_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case settingConstants.USERTEAMDOCUMENT_SUCCESS:
            return {
                data: action.data,
                loading: false,
                success: true,
            };
        case settingConstants.USERTEAMDOCUMENT_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}
export function getTeamDocumentsReducer(state = {}, action) {
    switch (action.type) {
        case settingConstants.TEAMDOCUMENT_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case settingConstants.TEAMDOCUMENT_SUCCESS:
            return {
                data: action.data,
                loading: false,
                success: true,
            };
        case settingConstants.TEAMDOCUMENT_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}
export function submitEntityDocumentsReducer(state = {}, action) {
    switch (action.type) {
        case settingConstants.SUBMITENTITYDOCUMENT_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case settingConstants.SUBMITENTITYDOCUMENT_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            };
        case settingConstants.SUBMITENTITYDOCUMENT_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}

export function getUserDetailReducer(state = {}, action) {
    switch (action.type) {
        case settingConstants.GETUSERDETAIL_REQUEST:
            return {
                registering: true,
                loading: true,
                data: undefined
            }
        case settingConstants.GETUSERDETAIL_SUCCESS:
            return {
                data: !!(action.data) ? action.data : undefined,
                loading: false,
                success: true,
            };
        case settingConstants.GETUSERDETAIL_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}
export function findUserDetailReducer(state = {}, action) {
    switch (action.type) {
        case settingConstants.FINDUSERDETAIL_REQUEST:
            return {
                registering: true,
                loading: true,
                data: undefined
            }
        case settingConstants.FINDUSERDETAIL_SUCCESS:
            return {
                data: !!(action.data) ? action.data : undefined,
                loading: false,
                success: true,
            };
        case settingConstants.FINDUSERDETAIL_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}
export function getSave_data(state = {}, action) {
    switch (action.type) {
        case settingConstants.CERETE_TEAM_REQUEST:
            return {
                loading: true
            };
        case settingConstants.CERETE_TEAM_SUCCESS:
            return {
                data: action.data
            };
        case settingConstants.CERETE_TEAM_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function getTeamByIdReducer(state = {}, action) {
    switch (action.type) {
        case settingConstants.GETTEAMBYID_REQUEST:
            return {
                registering: true,
                loading: true,
                data: undefined
            }
        case settingConstants.GETTEAMBYID_SUCCESS:
            return {
                data: !!(action.data) ? action.data : undefined,
                loading: false,
                success: true,
            };
        case settingConstants.GETTEAMBYID_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}
export const settingSelector = (state) => {
    return {
        'GETDOCUMENT_SUCCESS': state.getDocuments.data,
        'GETDOCUMENT_REQUEST': state.getDocuments.loading,
        'GETDOCUMENT_FAILURE': state.getDocuments.error,
        'GETENTITYDOCUMENT_SUCCESS': state.getEntityDocumentsReducer.data,
        'GETENTITYDOCUMENT_REQUEST': state.getEntityDocumentsReducer.loading,
        'GETENTITYDOCUMENT_FAILURE': state.getEntityDocumentsReducer.error,
        'CERETE_TEAM_SUCCESS': state.getSave_data.data,
        'CERETE_TEAM_FAILURE': state.getSave_data.error,
        'DELETE_DOCUMENT_SUCCESS': state.delete_data_Documents.data,
        'ROLSDATA_SUCCESS': state.getRolsDocumentsReducer.data,
        'ROLSDATA_REQUEST': state.getRolsDocumentsReducer.loading,
        'ROLSDATA_FAILURE': state.getRolsDocumentsReducer.error,
        'PERMISSIONS_SUCCESS': state.getPermissionsDocumentsReducer.data,
        'PERMISSIONS_REQUEST': state.getPermissionsDocumentsReducer.loading,
        'PERMISSIONS_FAILURE': state.getPermissionsDocumentsReducer.error,
        'USERDOCUMENT_SUCCESS': state.getUserDocuments.data,
        'USERDOCUMENT_REQUEST': state.getUserDocuments.loading,
        'USERDOCUMENT_FAILURE': state.getUserDocuments.error,
        'USERTEAMDOCUMENT_SUCCESS': state.getUserteamDocuments.data,
        'USERTEAMDOCUMENT_REQUEST': state.getUserteamDocuments.loading,
        'USERTEAMDOCUMENT_FAILURE': state.getUserteamDocuments.error,
        'TEAMDOCUMENT_SUCCESS': state.getTeamDocumentsReducer.data,
        'TEAMDOCUMENT_REQUEST': state.getTeamDocumentsReducer.loading,
        'TEAMDOCUMENT_FAILURE': state.getTeamDocumentsReducer.error,
        'SUBMITDOCUMENT_SUCCESS': state.submitDocuments.data,
        'SUBMITDOCUMENT_REQUEST': state.submitDocuments.loading,
        'SUBMITDOCUMENT_FAILURE': state.submitDocuments.error,
        'STOREDOCUMENT_REQUEST': state.storeDocuments.loading,
        'INVITEDOCUMENT_REQUEST': state.InviteDocuments.loading,
        'SUBMITENTITYDOCUMENT_SUCCESS': state.submitEntityDocumentsReducer.data,
        'SUBMITENTITYDOCUMENT_REQUEST': state.submitEntityDocumentsReducer.loading,
        'SUBMITENTITYDOCUMENT_FAILURE': state.submitEntityDocumentsReducer.error,
        'GETUSERDETAIL_SUCCESS': state.getUserDetailReducer.data,
        'GETUSERDETAIL_REQUEST': state.getUserDetailReducer.loading,
        'GETUSERDETAIL_FAILURE': state.getUserDetailReducer.error,
        'FINDUSERDETAIL_SUCCESS': state.findUserDetailReducer.data,
        'FINDUSERDETAIL_REQUEST': state.findUserDetailReducer.loading,
        'FINDUSERDETAIL_FAILURE': state.findUserDetailReducer.error,
        'GETTEAMBYID_SUCCESS': state.getTeamByIdReducer.data,
        'GETTEAMBYID_REQUEST': state.getTeamByIdReducer.loading,
        'GETTEAMBYID_FAILURE': state.getTeamByIdReducer.error,
    };
}