
import "./Gethelp.scss";


const Redayrect_page = () => {

    return (
        <>
            <div className='gethelp'>
                <div className="balance__header">
                    <div className="balance__header__name">Contact Us</div>

                </div>
                <div className="dashboard-container">
                    <div className="box_add_new_data">
                   <p className="redyarect_tecttssfs"> Reach us anytime on email: <a className="add_a_ldidhdhddggdg" href="mailto:support@niobi.co"> support@niobi.co</a></p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Redayrect_page;