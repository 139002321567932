import React, { useEffect, useState } from 'react';
import { MoniteProvider, PayablesTable } from '@team-monite/ui-widgets-react';
import { MoniteSDK } from '@team-monite/sdk-api';
import { GrantType, ObtainTokenPayload } from '@team-monite/sdk-api';
import App from './features/app/App';
import { AUTH_TOKEN_STORAGE_KEY } from './features/app/consts';
import { store } from './Store/store';
import { Provider } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { ToastContainer } from 'react-toastify';
import './features/i18n/index';
import { format } from 'path';
import amplitude from 'amplitude-js';
import {
  createMuiTheme,
  ThemeProvider,
  createTheme,
  Button,
  StyledEngineProvider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// import { ThemeProvider, createMuiTheme  } from "styled-components";
// import { MuiThemeProvider,StylesProvider } from "@material-ui/core"

import posthog from 'posthog-js';
import { red, green, blue } from '@mui/material/colors';
import { NotificationsProvider } from './features/app/NotificationContext';

const RootLIS = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    backgroundColor: red[500],
  },
  [theme.breakpoints.up('md')]: {
    backgroundColor: blue[500],
  },
  [theme.breakpoints.up('lg')]: {
    backgroundColor: green[500],
  },
}));

const Root = () => {
  const [render, setRender] = useState(true);
  var instance1 = amplitude
    .getInstance()
    .init('6007b8607bf513d1302167ae6a6c8cf9');
  const breakpoints = [576, 768, 992, 1200];

  React.useEffect(() => {
    setRender(false);
    console.log('Getting here to log event');
    const event = 'Website Visited';
    const eventProperties = {
      userId: null,
      userName: null,
      email: null,
      pageUrl: window.location.href,
    };
    amplitude.getInstance().setUserProperties({});
    amplitude.getInstance().logEvent(event, eventProperties);

    posthog.init('phc_CuMYeuQL32nl4cv7c18bdsuyyxgn4Ol8CPdjZw4CRxW', {
      api_host: 'https://app.posthog.com',
    });
    posthog.capture(event, eventProperties);
  }, [render]);

  //let location = useLocation();

  var localUser: any = localStorage.getItem('user');
  var monite_id = 'c6d40989-f8df-4c9d-a72c-8a4297ebe1d9';
  var user_monite_id = '24487962-1852-4ef4-8d33-08d2acf59c14';
  var token_list = '';
  if (localUser != null) {
    monite_id = JSON.parse(localUser)?.data?.hasEntity
      ? JSON.parse(localUser)?.data?.entity?.monite_id
      : 'c2818ff7-1a9e-4d1e-9edd-86a7ca32c1a1';
    user_monite_id = JSON.parse(localUser)?.data?.monite_user
      ? JSON.parse(localUser)?.data?.monite_user?.monite_id
      : '24487962-1852-4ef4-8d33-08d2acf59c14';
    // token = JSON.parse(localUser).data.monite_access_token.data.hasEntity ? JSON.parse(localUser.data.monite_access_token).data.entity.monite_id : 'c2818ff7-1a9e-4d1e-9edd-86a7ca32c1a1';
  }
  if (localUser != null) {
    token_list = JSON.parse(localUser)?.data?.monite_access_token
      ? JSON.parse(localUser)?.data?.monite_access_token
      : 'c2818ff7-1a9e-4d1e-9edd-86a7ca32c1a1';
  }
  var client_id = 'c6d40989-f8df-4c9d-a72c-8a4297ebe1d9';
  const monite: any = new MoniteSDK({
    apiUrl: 'https://api.monite.com/v1',
    entityId: monite_id,
    fetchToken: async () => {
      const request: ObtainTokenPayload = {
        grant_type: GrantType.ENTITY_USER,
        client_id: client_id,
        client_secret: '55f99047-3686-4fa6-afdf-7b21305ad8ff',
        entity_user_id: user_monite_id,
      };

      const response = await fetch('https://api.monite.com/v1/auth/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-monite-version': '2023-03-14', //Monite API version
        },
        body: JSON.stringify(request),
      });

      return response.json();
    },
    //currencyLocale: 'en-US'
  });
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: { primary: { main: '#000' } },
  });
  // const themeline = createTheme(

  //   {
  //     breakpoints: ["40em", "52em", "70em"],
  //     palette: { primary: { main: '#000' } },
  //   }
  // )

  //store.setMoniteApp(monite);
  const string = '#025041';
  return (
    <MoniteProvider
      monite={monite}
      locale={{
        currencyLocale: 'en-US',
        messages: {
          Counterpart: 'Payee',
          'Failed to load Counterparts.': 'Failed to load Payees.',
          'Set this counterpart as:': 'Set this payee as',
          Vendor: 'Payee',
          Vendors: 'Payees',
          Suppliers: 'Payees',
          'Search by name': 'Payees',
          'Customers & Vendors': 'Customer & Payees',
          Customers: 'Customer',
          'Tax number': 'KRA Pin No.',
          'Address line 1': 'Address 1',
          'Address line 2': 'Address 2',
          'ZIP code': 'Postal Address',
          'State / Area / Province': 'County',
          'VAT ID': 'KRA Pin No.',
          'Create Counterpart – Company': 'Create payee - Organisation',
          'Company name': 'Organisation name',
          Tags: 'Categories',
          Tag: 'Category',
          'Create New Tag': 'Create New Category',
          'Edit tag': 'Edit category',
          'tag?': 'category?',
        },
      }}
      theme={{
        search: {
          filterBackgroundColorHover: '#025041',
        },
        select: {
          filterBackgroundColorHover: '#025041',
          borderColorHover: string,
          borderShadowHover: string,
        },
        datePicker: {
          filterBackgroundColorHover: '#025041',
        },
        tableHeader: {
          fontFamily: 'Poppins',
          fontSize: '13px',
          fontWeight: '500',
          textColor: '#000',
          backgroundColorHover: '#fafafa',
          backgroundColorActive: '#fafafa',
        },
        tableBody: {
          fontFamily: 'Poppins',
          fontSize: '14px',
          fontWeight: '400',
          textColor: '#6D6B6B',
          backgroundColorHover: '#fafafa',
          backgroundColorActive: '#fafafa',
        },

        colors: {
          blue: '#025041',
        },

        // primary30: string,
        // primary50: string,
        // primary60: string,
        // primary95: string,
        // neutral10: string,
        // neutral30: string,
        // neutral50: string,
        // neutral70: string,
        // neutral80: string,
        // neutral90: string,
        // neutral95: string,
        // neutral100: string,
        // success50: string,
        // success95: string,
        // warning50: string,
        // warning95: string,
        // error50: string,
        // error95: string,
        // special50: string,
        // special95: string,
        // green: string,
        // black: string,
        // white: string,
        // blue: string,
        // navy: string,
        // orange: string,
        // red: string,
        // purple: string,
        button: {
          textColorContained: 'white',
          primaryColor: string,
          secondaryColor: 'white',
          // textColorContainedSecondary: 'white',
        },
        // select: {
        //   borderColorHover: string,
        //   borderShadowHover: string,
        // },
        loading: {
          color: string,
          // backgroundColor: string,
        },
        checkbox: {
          checkMarkColorHover: string,
          borderColorHover: string,
        },
        input: {
          borderColorHover: string,
          borderShadowHover: string,
          fontSize: '16px',
        },

        ///children={}
      }}
    >
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          {/* <ThemeProvider theme={theme}> */}

          <NotificationsProvider>
            <App />
          </NotificationsProvider>

          {/* <Toaster containerStyle={{
            //  display: "none"
            backgroundColor: 'transparent'

          }} /> */}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            newestOnTop={false}
            hideProgressBar={true}
            closeOnClick
            rtl={false}
            limit={1}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton={false}
          />

          {/* </ThemeProvider> */}
        </StyledEngineProvider>
      </Provider>
    </MoniteProvider>
  );
};

export default Root;
