import React, { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import "./PaymentError.scss";

interface Props {
    onSave: any;
    data_err: any;
}

export const PaymentError = (props: Props) => {

    const onSave = () => {
        props.onSave();
    }


    return (
        <div className='niobi-payment-error'>
            <div>
                <div style={{ textAlign: "center", padding: "5px 0px 0px 0px" }}>
                    <img width={50} height={50} src="/Niobi Loading Animation-Check.gif" />
                </div>
                <div style={{ textAlign: "center", padding: "10px 0px 0px 0px" }}>
                    <div className="heding_tesyssjsj_Fail">
                        <p>Transaction Failed</p>
                        <span className='erroe_messge' style={{textAlign: "center", fontSize: "14px", marginTop: "10px"}}> {!!props.data_err ? props.data_err : "Your transaction failed, please try again."}</span></div>
                </div>
                <div style={{ textAlign: "center", padding: "15px 0px 0px 0px" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button className="balance__save-btn" style={{ marginTop: "13px" }} onClick={() => onSave()}>Close</Button>
                    </div>
                </div>

            </div>

        </div>
    )
}
