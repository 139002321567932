import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';
import { Button, Table } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Box, IconButton, Typography } from '@mui/material';
import styles from './tebal.module.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { paymentJourneyActions } from "../../../../../../../../_actions/paymentJourney.actions";
import ShareIcon from '@mui/icons-material/Share';
import { Tooltip } from 'react-tooltip';
import {WhatsappShareButton, EmailShareButton } from 'react-share';
const headCells = [
    {
        id: 'transaction_date',
        numeric: false,
        disablePadding: true,
        label: 'Date',
    },
    {
        id: 'reference',
        numeric: false,
        disablePadding: true,
        label: 'Payment reference',
    },
    {
        id: 'Type',
        numeric: false,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'beneficiary',
        numeric: true,
        disablePadding: false,
        label: 'Payee',
    },
    {
        id: 'category',
        numeric: true,
        disablePadding: false,
        label: 'Category',
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description ',
    },
    {
        id: 'transaction_amount',
        numeric: false,
        disablePadding: false,
        label: 'Transaction amount',
    },
    {
        id: 'transaction_status',
        numeric: false,
        disablePadding: false,
        label: 'Transaction Status',
    },
    {
        id: 'balance',
        numeric: false,
        disablePadding: false,
        label: 'Closing balance',
    },
    {
        id: 'more_info',
        numeric: false,
        disablePadding: false,
        label: 'More Info',
    },
];

const Tebal = (props) => {
    const rows = props.data ? props.data : [];
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElWhatsApp, setAnchorElWhatsApp] = React.useState(null);
    const [ideditdata, setIdeditdata] = React.useState('')
    const [short_code, setShort_code] = React.useState("")
    const [shareLink, setSreeaLink] = useState('')
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    const openWhatsApp = Boolean(anchorElWhatsApp)
    const navigate = useNavigate();

    const handleClick_datat = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClose_twoo = () => {
        setAnchorElWhatsApp(null);
    };
    const handleClick = (event) => {
        setAnchorElWhatsApp(event.currentTarget);
    };
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    const deleteData = () => {
        dispatch(paymentJourneyActions.PaymentLinkApiDeleteActive(ideditdata));
    }
    return (
        <>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    className='menuitem'
                    onClick={() => {
                        navigate("/settings/CeretePaymentLink?short_code=" + short_code, { replace: true, state: { short_code: short_code } });
                    }}
                >Edit</MenuItem>
                <MenuItem onClick={() => {
                    deleteData()
                    handleClose()
                }} className='menuitem' style={{ color: "#F87161" }}>Delete
                </MenuItem>
            </Menu>
            <Menu
                id="basic-menu"
                anchorEl={anchorElWhatsApp}
                open={openWhatsApp}
                onClose={handleClose_twoo}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{ "& .MuiMenu-list": { padding: "0px" } }}
            >
                <div style={{ padding: "5px" }}>
                    <div style={{ height: "27px" }}>
                        <WhatsappShareButton
                            url={shareLink}
                            onClick={handleClose_twoo}
                        >
                            Send via WhatsApp
                        </WhatsappShareButton>
                    </div>
                    <div style={{ height: "27px" }}>
                        <EmailShareButton
                            url={shareLink}
                            body={`${process.env.REACT_APP_API_URL}/get-transaction-pdf-link?hash=${shareLink}${'\n'}`}
                        >
                            Send via Email
                        </EmailShareButton>

                    </div>
                </div>
            </Menu>
            <TableContainer>
                <Table
                    className={styles.tebal_class_contean}
                >
                    <thead>
                        <tr>
                            <TableCell sx={{ width: "30%" }} className={styles.LinksTableCell}>
                            Payment Link
                            </TableCell>
                            <TableCell className={styles.LinksTableCell}>
                            Status
                            </TableCell>
                            <TableCell className={styles.LinksTableCell}>

                            Last Updated
                            </TableCell>
                            <TableCell className={styles.LinksTableCell} style={{textAlign:"center"}}>
                            Product Description
                            </TableCell>
                            <TableCell className={styles.LinksTableCell}>

                            Link Amount
                            </TableCell>
                            <TableCell className={styles.LinksTableCell}>
                            Payments Collected
                            </TableCell>
                            
                            <TableCell className={styles.LinksTableCell}>
                            </TableCell>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            props.data
                                .map((element, index) => {
                                    var TAmount = element.transaction_amount;


                                    return (
                                        <TableRow key={element.id} >
                                            <TableCell className={styles.TableCell_roww}>
                                                <Box className={styles.Typography_Box}>
                                                    <Typography style={{ cursor: "pointer" }} onClick={() => {
                                                        navigate("/settings/Details_payment_Link?id=" +
                                                            element.id, { replace: true, state: { id: element.id } });
                                                    }}>
                                                        {element.link}
                                                    </Typography>
                                                    {/* console.log(details payment link added) */}
                                                    <div>
                                                        <Button onClick={(e) => {
                                                            setSreeaLink(element.link)
                                                            handleClick(e)
                                                        }} style={{ marginRight: "10px" }} className={styles.button_ContentCopyIcon}>
                                                            <ShareIcon />
                                                        </Button>
                                                        <CopyToClipboard text={element.link}>   
                                                            <Button data-tooltip-id="my-tooltip-click" className={styles.button_ContentCopyIcon}>
                                                                <ContentCopyIcon />
                                                            </Button>
                                                        </CopyToClipboard>
                                                    </div>
                                                </Box>
                                            </TableCell>
                                            <TableCell className={styles.TableCell_roww}>
                                                <Typography className={element.status == "inactive" ? styles.inactiveTypography : styles.TypographyStatus}>
                                                    {element.status=="active"?"Active":element.status=="inactive"?"Inactive":""}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={styles.TableCell_roww}>
                                                <Typography className={styles.TypographyUpdated_at}>
                                                    {moment(element.updated_at).format("DD-MMM-YYYY")}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={styles.TableCell_roww}>
                                                <Typography style={{textAlign:"center"}} className={styles.TypographyUpdated_at}>
                                                   {!!element.description?element.description:"-"}
                                                </Typography>
                                            </TableCell>
                                       
                                            <TableCell className={styles.TableCell_roww}>
                                            {element.amount==0?
                                                <Typography className={styles.TypographyUpdated_at}>
                                                -
                                                </Typography>
                                                :<Typography className={styles.TypographyUpdated_at}>

                                                {element.currency}{" "}{element.amount}
                                                </Typography>}
                                            </TableCell>
                                            <TableCell className={styles.TableCell_roww}>
                                                <Typography className={styles.TypographyUpdated_at}>
                                                {element.total_transactions}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ width: "5%" }} className={styles.TableCell_roww}>
                                                <IconButton disabled={element.status == "inactive" ? true : false} onClick={(e) => {
                                                    handleClick_datat(e)
                                                    setIdeditdata(element.id)
                                                    setShort_code(element.short_code)
                                                }}>
                                                    <MoreVertIcon />
                                                </IconButton>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        {emptyRows > 0 && (
                            <TableRow
                            >
                                <TableCell colSpan={9} />
                            </TableRow>
                        )}

                    </tbody>
                </Table>
            </TableContainer>
            <Tooltip
                id="my-tooltip-click"
                content="link has been copied"
                events={['click']}
            />
        </>
    )
}
export default Tebal;
